import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";

function EwayBills({ navigation }) {

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleNewButtonClick = () => {
    handleNavigation("EwayBillsNew")
  };

  const [itemId, setItemID] = useState();
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const handleEdit = (id) => {
    setItemID(id);
    setShowCard(false);
    setShowUpdate(true);
  };

  const [invoices, setInvoices] = useState([]);
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure ? Once deleted, you will not be able to recover this invoice!"
    );

    if (userConfirmed) {
      // Send a DELETE request to your backend API
      fetch(BASE_URL + `/deleteInvoice/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwtToken}`
        },
      })
        .then((response) => {
          if (response.status === 200) {
            // Invoice deleted successfully, update the UI
            setInvoices((prevInvoices) =>
              prevInvoices.filter((invoice) => invoice.id !== id)
            );
            swal({
              icon: "success",
              title: "Invoice Deleted Successfully",
            });
          } else {
            console.error("Failed to delete invoice");
          }
        })
        .catch((error) => {
          console.error("Error deleting invoice:", error);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Invoice deletion canceled!");
    }
  };


  const fetchInvoices = () => {
    $("#DataTables_Table_0").DataTable();
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchEwayBills`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwtToken}`
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoices(data.responseMessage1);
          setLoading(false)
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();

      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();

      });
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  let i = 1;

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div className="mt-2" style={{ color: '#3C7BD4', fontSize: 20, fontWeight: '600', wordWrap: 'break-word', textAlign: "left" }}>e-Way Bills</div>
            <div className="d-flex">
              <div className="">
                  <button
                    className="btn btn-primary px-1 py-2 new-purchase"
                    onClick={handleNewButtonClick}
                  >
                    + New e-Way Bill
                  </button>
              </div>
              <div className="ml-2 px-1 py-2 icon-dot">
                <i className="fa fa-ellipsis-v " style={{ fontSize: 18, color: "gray" }}></i>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCard &&
        <div className="container-fluid">
          <div className="page-content ">
            <Container fluid={true} className="">
              <Row>
                <Col>
                  <Card>
                    {invoices && invoices.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabindex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowspan="1"
                                          colspan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Date
                                        </th>
                                        <th
                                          className="sorting"
                                          tabindex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowspan="1"
                                          colspan="1"
                                          aria-label="TRANSACTION#: activate to sort column ascending"
                                        >
                                          TRANSACTION#
                                        </th>
                                        <th
                                          className="sorting"
                                          tabindex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowspan="1"
                                          colspan="1"
                                          aria-label="CUSTOMER NAME: activate to sort column ascending"
                                        >
                                          CUSTOMER NAME
                                        </th>
                                        <th
                                          className="sorting"
                                          tabindex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowspan="1"
                                          colspan="1"
                                          aria-label="CUSTOMER GSTIN: activate to sort column ascending"
                                        >
                                          CUSTOMER GSTIN
                                        </th>
                                        <th
                                          className="sorting"
                                          tabindex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowspan="1"
                                          colspan="1"
                                          aria-label="TOTAL: activate to sort column ascending"
                                        >
                                          TOTAL
                                        </th>
                                        <th
                                          className="sorting"
                                          tabindex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowspan="1"
                                          colspan="1"
                                        >
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {invoices.map((invoice) => (
                                        <tr role="row" className="odd" key={invoice.id}>
                                          <td>{invoice.date}</td>                                         
                                          <td>{invoice.transactionType}</td>                                         
                                          <td>{invoice.customerName}</td>
                                          <td>{invoice.customerGstin}</td>
                                          <td>{invoice.total}</td>
                                          <td>
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() => handleEdit(invoice.invoiceNumber)}
                                            >
                                              <i className="fa fa-edit text-primary"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() => handleDelete(invoice.id)}
                                            >
                                              <i className="fa fa-trash text-danger"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabindex="0"
                                        aria-con
                                        trols="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Invoice
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Order Number
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Customer Name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Due Date
                                      </th>

                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Total
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      }

    </div>
  );
}

export default EwayBills;
