import React from "react";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';

function GstFiling({navigation}) {
    const handleNavigation = (key) => {
        if (navigation.state.routes[navigation.state.index].key !== key) {
          navigation.navigate(key);
        }
      };

    const handleOpenGstR1 = () =>{
        handleNavigation("GstR1")
    }
    const handleOpenGstR2 = () =>{
        handleNavigation("GstR2")
    }

  return (
    <>
      <div style={styles.container}>
        <div style={styles.header}>
          <h5 style={styles.headerText}>GSTR Summary</h5>
        </div>
        <div style={styles.cardContainer}>
          {/* Second Card */}
          <div style={styles.card}>
            <div style={styles.cardBody}>
              <div style={styles.iconSection}>
                <ShoppingCartIcon style={styles.icon} />
              </div>
              <div style={styles.textSection}>
                <h6 style={styles.cardTitle}>Sales Return GSTR 1</h6>
                <p style={styles.cardText}>GSTR summary based on your sales of goods and capitals for the current month</p>
              </div>
              <div style={styles.buttonSection}>
                <button onClick={handleOpenGstR1} style={styles.button}>Go to summary</button>
              </div>
            </div>
          </div>

          {/* Third Card */}
          <div style={styles.card}>
            <div style={styles.cardBody}>
              <div style={styles.iconSection}>
                <ReceiptIcon style={styles.icon} />
              </div>
              <div style={styles.textSection}>
                <h6 style={styles.cardTitle}>Purchase Return GSTR 2</h6>
                <p style={styles.cardText}>GSTR summary for the inward supplies you've received during the tax period</p>
              </div>
              <div style={styles.buttonSection}>
                <button onClick={handleOpenGstR2} style={styles.button}>Go to summary</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    padding: '10px',
    maxWidth: '600px',
    marginLeft: '0',
    marginTop:"5px",
  },
  header: {
    marginBottom: '20px',
  },
  headerText: {
    fontSize: '1.5rem',
    color: '#007bff',
    letterSpacing: '1px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  card: {
    border: '1px solid #ddd',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '600px',
    marginLeft: '0',
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
  },
  iconSection: {
    flex: '0 0 20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textSection: {
    flex: '1 1 60%',
    paddingLeft: '10px',
  },
  buttonSection: {
    flex: '0 0 20%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    margin: '0',
  },
  cardText: {
    margin: '5px 0',
    fontSize: '0.9rem',
    color: '#555',
  },
  button: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  icon: {
    color: 'black',
    fontSize: '60px', // Increased size for better visibility
  },
};

export default GstFiling;
