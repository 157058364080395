import React, { useState } from "react";
import { XCircle, User } from "phosphor-react"
function FeesExport(props) {

    const handleCancleClick = () => {
        props.handleCancle();
    }

    return (
        <div className="container my-3">
            <div className="border border-2 p-2" >
                <div className="px-2 d-flex justify-content-between">
                    <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}><b>Export</b></h4>
                    <button className="btn" onClick={handleCancleClick}><XCircle size={32} weight="fill" /></button>
                </div>
                <hr />
                <div className="container">

                    <p className="my-3">You can export your data from Code World in CSV or XLS format.</p>

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label   mg-b-0"><span className="text-dashed-underline"><b>Select Module : </b></span></label>
                        </div>
                        <div className="" style={{width:"200px"}}>
                            <select className="form-control">
                                <option value="">Select</option>
                                <option value="invoice">Invoice</option>
                                <option value="estimate">Estimate</option>
                                <option value="sales order">Sales Order</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label   mg-b-0"><span className="text-dashed-underline"><b>Select Status : </b></span></label>
                        </div>
                        <div className="" style={{width:"200px"}}>
                            <select className="form-control">
                                <option value="">Select</option>
                                <option value="all">All</option>
                                <option value="draft">Draft</option>
                                <option value="pending">Pending</option>
                                <option value="sent">Sent</option>
                                <option value="paid">Paid</option>
                                <option value="overdue">Overdue</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label required mg-b-0"><b>Date Ranges : </b></label>
                        </div>
                        
                        <label className="form-label required mg-b-0">From : </label>
                        <div className="col-md-2">
                            <input className="form-control" name="file" type="date" />
                        </div>
                        <label className="form-label required mg-b-0">To : </label>
                        <div className="col-md-2">
                            <input className="form-control ml-1" name="file" type="date" />
                        </div>
                    </div>

                    <div className="radio row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label"><span className="text-dashed-underline"><b>Employee Type : </b></span>  </label>
                        </div>
                        <div>
                            <label className="rdiobox"><input className="mx-1" type="radio" value="Permanent" checked/><span>CSV (Comma Separated Value)</span></label>
                        </div>                                               
                    </div>

                    <div className="my-2 text-center">
                        <button className="btn btn-success">Export</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FeesExport;