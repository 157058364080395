import React,{useState,useEffect} from "react";
import { XCircle,User } from "phosphor-react"
import swal from "sweetalert";
import { AES, enc } from "crypto-js";

function ManageSalesPersonUpdate(props) {
    const handleCancleClick = () => {
        props.handleCancle();
    }

    const encryptedUniqueId = localStorage.getItem("uniqueId1");

  // Decrypt the uniqueId using your decryption method
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8); 


  const { id } = props;
  const [itemData, setItemData] = useState({
        uniqueId: decryptedUniqueId,
        name: "",
        email:"",
  });

  const [responseData, setResponseData] = useState(null);
  console.log("Fetched id Update: ",  id );
  // Define the URL for the GET request
  const apiUrl = `http://app.zeroserp.in/api/editSalesPerson/${id}`; // Replace with your GET endpoint

  const postData = () => {
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify({uniqueId: decryptedUniqueId}), // Send the itemData as JSON in the request body
    })
      .then((response) => response.json())
      .then((data) => {
          console.log("POST Request Response:", data.responseMessage[0]);
        const Data=data.responseMessage[0];
        setItemData({
            name: Data.name || "",
            email: Data.email || "",
            // loginId: Data.loginId || "",
            // password: Data.password || "",
            // purchaseAcccount: Data.purchaseAcccount || "",
            // purchaseDate: Data.purchaseDate || "",
            // expiryDate: Data.expiryDate || "",
          });
        // setItemData({
        //     name:Data.name
        // })
    
        // Handle the response data if needed
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  

  useEffect(() => {
    postData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("ItemData : ", itemData);
      const apiUrl = `http://app.zeroserp.in/api/updateSalesperson/${id}`;

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(itemData),
      };

      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json().then((data) => {
              console.log("Data updated successfully:", data);
              swal({
                icon: "success",
                title: "Updated Successfully"
              });
            });
          } else {
            swal({
              icon: "error",
              title: "Data is Not Updated",
            });
          }
        })
        .catch((error) => {
          console.error("Error posting data:", error);
          swal({
            icon: "error",
            title: "Failed to update data",
          });
        });
      props.handleCancle();
    } 
    const handleItemDataChange = (event) => {
        const { name, value } = event.target;
        setItemData({ ...itemData, [name]: value });
        
      };

    return (
        <div className="container my-3">
            <div className="border border-2 p-2" >
                <div className="px-2 d-flex justify-content-between">
                <h4 className="mt-3 mx-2"><b>Update Sales Person</b></h4>
                    <button className="btn" onClick={handleCancleClick}><XCircle size={32} weight="fill" /></button>
                </div>
                <hr/>
                <div className="container">
                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2 ">
                            <label className="form-label required mg-b-0"><b>Name : </b></label>
                        </div>
                        <div className="col-md-6">
                            <input className="form-control" placeholder="Name" name="name" value={itemData.name} // Bind the value to itemData.name
                onChange={handleItemDataChange} type="text"  />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label required mg-b-0"><b>Email : </b></label>
                        </div>
                        <div className="col-md-6">
                            <input className="form-control" placeholder="Email" name="email" value={itemData.email} // Bind the value to itemData.name
                onChange={handleItemDataChange} type="text" />
                        </div>
                    </div>

                    
                    <div className="my-2 text-right">
                        <button className="btn btn-success"onClick={handleSubmit}>Update</button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ManageSalesPersonUpdate;