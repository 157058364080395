import React, { useEffect, useState } from "react";
import { XCircle } from "phosphor-react";
import Select from "react-select";
function FeesNew(props) {

    const handleCancleClick = () => {
        props.handleCancle();
    }

    const [row, setRow] = useState([1]);

    const handleAdd = () => {
        const val = [...row, []];
        setRow(val);
    }

    const handleDelete = (i) => {
        const val = [...row];
        val.splice(i, 1)
        setRow(val);
    }

    const [currentDate, setCurrentDate] = useState(new Date().toISOString().substring(0, 10));
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString());
        }, 1000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    const primaryContact = []

    return (
        <div className="container my-3">
            <div className="border border-2 p-2" >
                <div className="px-2 d-flex justify-content-between">
                    <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}><b>Add Student Fees</b></h4>
                    <button className="btn" onClick={handleCancleClick}><XCircle size={32} weight="fill" /></button>
                </div>
                <hr />
                <div className="container">

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label   mg-b-0"><span className="text-dashed-underline"><b>Primary Contact : </b></span></label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <Select options={primaryContact} placeholder="Select..." />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                        <div className="col-md-2" >
                            <label className="form-label   mg-b-0"><span className="text-dashed-underline"><b>Date : </b></span></label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control" name="date" value={currentDate} onChange={(e) => { setCurrentDate(e.target.value) }} type="date" />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label   mg-b-0"><span className="text-dashed-underline"><b>Time : </b></span></label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control" name="time" value={currentTime} onChange={(e) => { setCurrentTime(e.target.value) }} type="text" readOnly />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 ml-1 align-items-center">
                        <table className="table border border-1 col-md-6">


                            <thead>
                                <th>Subject</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </thead>
                            {row.map((data, i) => {
                                return (
                                    <tbody>
                                        <td><input className="form-control" type="text" placeholder="Enter Subject" /></td>
                                        <td><input className="form-control" type="text" placeholder="Enter Amount" /></td>
                                        <td><button className="btn btn-danger" onClick={handleDelete}>Delete</button></td>
                                    </tbody>
                                )
                            })
                            }
                        </table>
                    </div>

                    <div className="row align-items-center ml-1 mg-b-20">
                        <button className="btn border border-1" onClick={handleAdd}>Add</button>
                    </div>

                    <div className="row mg-t-15 pt-5 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <h5 className="form-label   mg-b-0"><span className="text-dashed-underline"><b>Total (  ₹  ) : </b></span></h5>
                        </div>
                        <div>
                            <h5><b>0.00</b></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-2">
                <button className="btn btn-success">Save</button>
                <button className="btn btn-dark mx-2" onClick={handleCancleClick}>Cancle</button>
            </div>
        </div>
    )
}

export default FeesNew;