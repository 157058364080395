import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import NoDataImage from "../../../asset/images/nodata.jpg";
import PaymentsMadeDetails from "./PaymentsMadeDetails";
function PaymentsMade({ navigation }) {
  const [showCard, setShowCard] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentCount, setPaymentCount] = useState("");
  const [id, setId] = useState("");
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handlePaymentClick = () => {
    handleNavigation("PaymentsMadeNew");
  };
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
  });

  const [paymentData, setPaymentData] = useState("");

  const fetchDataAndInitializeDataTable = () => {
    $("#DataTables_Table_0").DataTable();
    const jwtToken = localStorage.getItem("jwtToken");
    setLoading(true);
    const apiUrl = BASE_URL + "/fetchPurchaseRecords";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("POST request failed");
        }
      })
      .then((responseData) => {
        const mergedData = mergeArrays(responseData.responseMessage);
        setPaymentData(mergedData);
        setLoading(false);

        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);

        $("#DataTables_Table_0").DataTable();
      });
  };
  const mergeArrays = (data) => {
    const mergedData = [];
    const uniquePaymentCounts = new Set();

    data.forEach((innerArray) => {
      innerArray.forEach((item) => {
        const existingRow = mergedData.find(
          (row) => row.paymentCount === item.paymentCount
        );

        if (!existingRow) {
          uniquePaymentCounts.add(item.paymentCount);
          mergedData.push({ ...item, bills: [item.billsNumber] });
        } else {
          existingRow.bills.push(item.billsNumber);
        }
      });
    });

    return mergedData;
  };
  useEffect(() => {
    fetchDataAndInitializeDataTable();
  }, []);

  const handleOpen = (paymentCount, vendorId) => {
    setId(vendorId);
    setPaymentCount(paymentCount);
    setShowCard(false);
    setShowDetails(true);
  };

  const handleCancelClick = () => {
    $("#DataTables_Table_0").DataTable();
    fetchDataAndInitializeDataTable();
    setShowCard(true);
    setShowDetails(false);
    setShowUpdate(false);
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Payment Made
            </div>
            <div className="d-flex">
              <div className="">
                <button
                  className="btn btn-primary px-1 py-2 new-purchase"
                  onClick={handlePaymentClick}
                >
                  + New Payment
                </button>
              </div>
              <div className="ml-2 px-1 py-2 icon-dot">
                <i
                  className="fa fa-ellipsis-v "
                  style={{ fontSize: 18, color: "gray" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card>
                  {paymentData && paymentData.length > 0 ? (
                    <>
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Payment#
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Reference
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Vendor name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Bills
                                      </th>

                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Mode
                                      </th>

                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Amount
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="text1">
                                    {paymentData.map((data) => (
                                      <tr
                                        role="row"
                                        className="odd"
                                        key={data.id}
                                      >
                                        <td>{data.paymentDate}</td>

                                        <td>
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleOpen(
                                                data.paymentCount,
                                                data.vendorId
                                              )
                                            }
                                          >
                                            {data.paymentCount}
                                          </a>{" "}
                                        </td>
                                        <td>{data.reference}</td>
                                        <td>{data.vendorName}</td>
                                        <td>
                                          {" "}
                                          {data.bills.map((bills, index) => (
                                            <React.Fragment key={bills}>
                                              {index > 0 && <br />}
                                              {""}
                                              {/* Add line break for all elements except the first one */}
                                              {bills}
                                            </React.Fragment>
                                          ))}
                                        </td>
                                        <td>{data.paymentMode}</td>
                                        <td>₹{data.payment}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-sort="ascending"
                                      aria-label="Date: activate to sort column descending"
                                    >
                                      DATE
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Type: activate to sort column ascending"
                                    >
                                      INVOICES#
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Amount BTC: activate to sort column ascending"
                                    >
                                      ORDER NUMBER
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="BTC Remaining: activate to sort column ascending"
                                    >
                                      CUSTOMER NAME
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      DUE DATE
                                    </th>

                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      AMOUNT
                                    </th>

                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      BALANCE DUE
                                    </th>

                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      STATUS
                                    </th>

                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      ACTION UNIT
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {showDetails && (
        <PaymentsMadeDetails
          handleCancel={handleCancelClick}
          paymentCount={paymentCount}
          vendorId={id}
        />
      )}
    </div>
  );
}
export default PaymentsMade;
