import React, { useState, useEffect } from "react";
import "./UserDetails.css"; // Import the CSS file for styling
import usericon from "../../../asset/images/user-circlea.png";
import Modal from "react-bootstrap/Modal";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import Select from "react-select";
import swal from "sweetalert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function UsersDetails(props) {
  const handleCancel = () => {
    props.handleCancel();
  };
  const [userDataSingle, setUserDataSingle] = useState([]);
  const [selectedTab, setSelectedTab] = useState("recentActivities"); // Initial tab
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State variable to manage showing password

  const handleModalClose = () => {
    setShowInviteModal(false);
  };
  const handleOpen = (tab) => {
    setSelectedTab(tab);
  };
  const [companyName, setCompanyName] = useState("");
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    fetchItemData();
    fetchData();
    fetchData2();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const fetchItemData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/fetchSubUserProfile`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUserData(data.responseMessage);
        setCompanyName(data.responseMessage[0].state);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        userId: props.userId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          const userState = data.responseMessage.state;
          setSelectState({ value: userState, label: userState });
          setSelectedPaidThrough({
            value: portfolioItem.role,
            label: portfolioItem.role,
          });

          setUserDataSingle(portfolioItem);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData2 = (id) => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          const userState = data.responseMessage.state;
          setSelectState({ value: userState, label: userState });
          setSelectedPaidThrough({
            value: portfolioItem.role,
            label: portfolioItem.role,
          });

          setUserDataSingle(portfolioItem);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  const [selectedPaidThrough, setSelectedPaidThrough] = useState(null);
  const [selectState, setSelectState] = useState(null);

  const paidThrough = [
    { value: "Admin", label: "Admin" },
    { value: "Staff", label: "Staff" },
  ];
  const statesInIndia = [
    {
      value: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
    },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Dadra and Nagar Haveli", label: "Dadra and Nagar Haveli" },
    { value: "Daman and Diu", label: "Daman and Diu" },
    { value: "Delhi", label: "Delhi" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Ladakh", label: "Ladakh" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  const handlePaidThroughChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setSelectedPaidThrough(selectedValue);
  };
  const handelStateChanage = (selectedOption) => {
    setSelectState(selectedOption);
  };
  const handleInviteClick = () => {
    setShowInviteModal(true);
  };

  const handleSaveClick = (id) => {
    fetch(BASE_URL + "/updateSubUser", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        id: userDataSingle.id,
        name: userDataSingle.name,
        role: userDataSingle.role,
        uniqueId: decryptedUniqueId,
        contact: userDataSingle.contact,
        password: userDataSingle.password,
      }),
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Data Added Successfully",
            text: "",
          });
          handleModalClose();
          handleClose();
          fetchItemData();
        } else {
          swal({
            icon: "error",
            title: "Failed to post employee data",
            text: "",
          });
          handleShow();
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        handleClose();
      });
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this item!"
    );
    const jwtToken = localStorage.getItem("jwtToken");

    if (userConfirmed) {
      // If the user clicks "OK", proceed with the deletion
      fetch(BASE_URL + `/deleteSubUser/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          uniqueId: decryptedUniqueId,
        }),
      })
        .then((response) => {
          if (response.status === "success") {
            // Remove the deleted item from the local state

            alert("Item Deleted Successfully");
          } else {
            console.error("Failed to delete Item");
            handleCancel();
          }
        })
        .catch((error) => {
          console.error("Error deleting Item:", error);
        });
    } else {
      // If the user clicks "Cancel", do nothing
      alert("Item deletion canceled!");
    }
  };

  return (
    <>
      <div className="">
        <div className="d-flex align-items-center ml-1 mt-4 mb-3">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={handleCancel}
              className="arrow-child"
            />
          </div>
          <div className="newcustomer-head ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              User Details
            </h5>
          </div>
        </div>

        {/* <div className="col-lg-12 p-0"> */}
        <div
          className="card border-2"
          style={{
            borderRadius: "14px",
            height: "auto",
            marginBottom: "0px",
          }}
        >
          <div className="custom-templates-container">
            <div className="left-users custom-scrollbar">
              <div className="mb-0">
                {userData.map((data, index) => (
                  <>
                    <div
                      className={`clearfix users-row nav-link mt-0`}
                      onClick={() => fetchData2(data.id)}
                    >
                      <div className="d-flex align-items-center">
                        <AccountCircleIcon
                          className="icon-menu"
                          style={{ width: "30px", color: "black" }}
                        />{" "}
                        <div className="about ml-2">
                          <div className="name">{data.name}</div>
                          <div className="status"> {data.email} </div>
                        </div>
                      </div>
                    </div>
                    {index < userData.length - 0 && (
                      <div
                        className="horizontal-line"
                        style={{ marginTop: "auto" }}
                      ></div>
                    )}
                  </>
                ))}
              </div>
            </div>

            <div className="middle-line"></div>

            <div className="right-users">
              <div className="chat-header clearfix">
                <div className="d-flex">
                  <div className="d-flex align-items-center flex-grow-1">
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#view_info"
                    >
                      <AccountCircleIcon
                        className="icon-menu"
                        style={{
                          width: "50px",
                          height: "80px",
                          color: "black",
                        }}
                      />{" "}
                    </a>
                    <div className="chat-about ml-2">
                      <h5 className="d-inline">{userDataSingle.name}</h5>
                      <div className="d-inline ml-2">
                        <span className="badge badge-info m-0 hidden-sm-down">
                          {userDataSingle.role}
                        </span>
                      </div>
                      <div className="useremail">
                        <span className="text-muted">
                          {userDataSingle.email}
                        </span>
                      </div>
                      <div className="role mt-3">
                        <span>Role: {userDataSingle.role}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 hidden-sm text-right">
                    <a href="#" className="btn btn-sm btn-outline-secondary mr-1" onClick={handleInviteClick}>
                      <i className="fa fa-edit"></i>
                    </a>
                    {userDataSingle.role !== "Admin" && (
                      <a href="javascript:void(0);" className="btn btn-sm btn-outline-info mr-1" onClick={() => handleDelete(userDataSingle.id)}>
                        <i className="fa fa-trash text-danger"></i>
                      </a>
                    )}
                    <a href="javascript:void(0);" className="btn btn-sm btn-outline-warning" onClick={handleCancel}>
                      X
                    </a>
                  </div> */}
                  <div className="">
                    <button
                      className="btn btn-primary d-inline-flex align-items-center"
                      onClick={handleShow}
                      style={{ borderRadius: "7px" }}
                    >
                      <i className="fa fa-edit mr-2"></i>
                      Edit
                    </button>
                    {userDataSingle.role !== "Admin" && (
                      <div className="d-inline ml-2">
                        <button
                          className="btn btn-primary d-inline-flex align-items-center"
                          onClick={() => handleDelete(userDataSingle.id)}
                          style={{ borderRadius: "7px" }}
                        >
                          <ion-icon name="trash-outline"></ion-icon>{" "}
                          <div className="d-inline ml-1">Delete</div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="body" style={{ padding: "0px" }}>
                <ul className="nav nav-tabs-new mt-3">
                  <li className="nav-item mr-3" style={{ cursor: "pointer" }}>
                    <button
                      className={`btn top-nav-buttons ${
                        selectedTab === "recentActivities"
                          ? "btn-customer-link"
                          : "withoutbtn"
                      }`}
                      onClick={() => handleOpen("recentActivities")}
                    >
                      {/* <i className="fa fa-check-circle "></i> */}
                      <span>Recent Activities</span>
                    </button>
                  </li>

                  <li className="nav-item mr-3" style={{ cursor: "pointer" }}>
                    <button
                      className={`btn top-nav-buttons ${
                        selectedTab === "moreDetails"
                          ? "btn-customer-link"
                          : "withoutbtn"
                      }`}
                      onClick={() => handleOpen("moreDetails")}
                    >
                      {/* <i className="fa fa-shopping-cart "></i> */}
                      <span>More Details</span>
                    </button>
                  </li>
                </ul>

                <hr className="" style={{ margin: "0.28rem 0px 0px 0px" }} />

                <div className="tab-content">
                  {selectedTab === "recentActivities" && (
                    <div className="recentactivities-container">
                      {/* <div className=""> */}
                      <div className="recents-inner-container">
                        <div className="recents-list">
                          <div className="row">
                            <div className="tree-node col-lg-8">
                              <div className="time text-muted">
                                <small>21 April 2024 12:42 PM</small>
                              </div>
                              <div className="tree-node-leaf">
                                <div className="description">
                                  Recurring Invoice 1 Created
                                </div>
                                <div className="text-muted font-xs">
                                  by Abc Xyz
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  )}
                  {selectedTab === "moreDetails" && (
                    <div className="body"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      {/*Update User Popup */}
      <Modal show={show} onHide={handleClose}>
        <div class="modal-header d-flex align-items-center pb-2 pt-2">
          <h5 class="modal-title fs-5" id="newcustomerPopup">
            Update User
          </h5>
          <button type="button" className="btn pr-0" onClick={handleClose}>
            <ion-icon
              name="close-circle-outline"
              size="large"
              style={{ width: "28px" }}
            ></ion-icon>
          </button>
        </div>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="username">
              Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="Enter username"
              name="name"
              value={userDataSingle.name || ""}
              onChange={(e) =>
                setUserDataSingle({
                  ...userDataSingle,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">
              Email<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              name="email"
              value={userDataSingle.email || ""}
              onChange={(e) =>
                setUserDataSingle({
                  ...userDataSingle,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">
              Contact<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id=""
              placeholder="Enter Contact Number"
              name="contact"
              value={userDataSingle.contact || ""}
              onChange={(e) =>
                setUserDataSingle({
                  ...userDataSingle,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">
              Password<span style={{ color: "red" }}>*</span>
            </label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
                className="form-control"
                id=""
                placeholder="Enter Password"
                name="password"
                value={userDataSingle.password || ""}
                onChange={(e) =>
                  setUserDataSingle({
                    ...userDataSingle,
                    password: e.target.value, // Update password field
                  })
                }
              />
              <span
                className="toggle-password-icon"
                onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on icon click
              >
                {showPassword ? (
                  <ion-icon name="eye-off-outline"></ion-icon> // Show eye-off icon when password is visible
                ) : (
                  <ion-icon name="eye-outline"></ion-icon> // Show eye icon when password is hidden
                )}
              </span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="state">
              State<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={userDataSingle.state}
              readOnly
            />
          </div>
          {userDataSingle.role === "Admin" || "admin" ? (
            <div className="form-group">
              <label htmlFor="role">
                Role<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="form-control"
                value={userDataSingle.role}
                name="role"
                readOnly
              />
              {/* <br /> */}
              <span style={{ color: "black" }}>
                {" "}
                Unrestricted access to all modules.
              </span>
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="role">
                Role<span style={{ color: "red" }}>*</span>
              </label>
              <Select
                options={paidThrough}
                onChange={handlePaidThroughChange}
                value={selectedPaidThrough}
                name="role"
                placeholder="Select..."
                isSearchable
                required
              />
            </div>
          )}
          {/* {userDataSingle.role === "Admin" ? <span style={{ color: "grey" }}> Unrestricted access to all modules.</span> : ""} */}
        </Modal.Body>
        <Modal.Footer className="p-2">
          <div className="modal-bottombtns">
            <button
              type="button"
              class="modalbtn-1 btn mt-0"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              type="button"
              class="modalbtn-3 btn btn-primary"
              onClick={handleSaveClick}
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UsersDetails;
