import React, { useState } from "react";
import newzerosblack from "../../asset/images/zarpblackcrop.png";
import swal from "sweetalert"; // import { OptionsManager } from "@fullcalendar/core/OptionsManager";
import CryptoJS from "crypto-js";
import { BASE_URL } from "../Dashboard/CommonApi";
import { AuthURL } from "../Dashboard/CommonApi";
import "./Login.css";
import Carousel from "react-bootstrap/Carousel";
import srcimage from "../../asset/images/newImages/1.png";
import srcimage1 from "../../asset/images/newImages/2.png";
import Format from "./Format";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Alert from "@mui/material/Alert";
import { AES, enc } from "crypto-js";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: true,
      show2: false,
      navigateform: false,
      errors: {},
      companyName: "",
      email: "",
      contact: "",
      password: "",
      state: "Maharashtra", // Set the default state here
      loginEmail: "",
      loginPass: "",
      forgetEmail: "",
      forgetPass: "",
      confirmPass: "",
      loading: false,
      btn1: true,
      btn2: false,
      btn3: false, // Add loading state property
      currentDataFormatIndex: 0,
      emailforAuth: "",
      // termsAccepted: false,
      otplessInstance: null,
    };
  }
  autoFetchData = async () => {
    const apiUrl = AuthURL + "/refreshToken";
    const refreshToken1 = localStorage.getItem("refreshToken");

    const tryApiCall = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken: refreshToken1 }),
        });

        if (!response.ok) {
          throw new Error("POST request failed");
        }

        const responseData = await response.json();
        const token = responseData.jwtToken;
        const refreshToken = responseData.refreshToken;
        const uniqueId = responseData.uniqueId;
        const encryptedUniqueId = CryptoJS.AES.encrypt(
          uniqueId,
          "1234567812345678"
        ).toString();

        localStorage.setItem("jwtToken", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("uniqueId1", encryptedUniqueId);
        this.setState({ navigateform: true });

        // Stop reloading when API call is successful
        clearInterval(reloadInterval);
      } catch (error) {
        console.error("Error:", error);
        localStorage.removeItem("uniqueId1");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("refreshToken");
        clearInterval(reloadInterval);
      }
    };

    const reloadInterval = setInterval(() => {
      tryApiCall();
    }, 1000); // Retry every 1 second until successful

    // Immediately try to call the API on first load
    tryApiCall();
  };

  componentDidMount() {
    this.dataFormatInterval = setInterval(this.changeDataFormat, 5000);
    window.history.pushState(null, null, window.location.pathname);

    this.handlePopState = () => {
      window.history.pushState(null, null, window.location.pathname);
    };
    window.addEventListener("popstate", this.handlePopState);

    this.autoFetchData();
    const callback = (userinfo) => {
      const emailMap = userinfo.identities.find(
        (item) => item.identityType === "EMAIL"
      );

      const mobileMap = userinfo.identities.find(
        (item) => item.identityType === "MOBILE"
      );

      const token = userinfo.token;
      const email = emailMap?.identityValue;
      const mobile = mobileMap?.identityValue;
      const name = emailMap?.name || mobileMap?.name;

      console.log(userinfo);

      // Implement your custom logic here.
    };

    if (window.OTPless) {
      const instance = new window.OTPless(callback);
      this.setState({ otplessInstance: instance });
    } else {
      console.error("OTPless SDK is not loaded.");
    }
  }

  componentWillUnmount() {
    clearInterval(this.dataFormatInterval);
    window.removeEventListener("popstate", this.handlePopState);
  }

  oauth = (provider) => {
    const { otplessInstance } = this.state;
    if (otplessInstance) {
      otplessInstance.initiate({ channel: "OAUTH", channelType: provider });
    } else {
      console.error("OTPless instance is not initialized.");
    }
  };

  changeDataFormat = () => {
    const { currentDataFormatIndex } = this.state;
    // Determine the next data format index
    const nextDataFormatIndex = (currentDataFormatIndex + 1) % 2; // Assuming you have 3 data formats

    // Update the state to change the data format
    this.setState({
      currentDataFormatIndex: nextDataFormatIndex,
    });
  };

  validation = () => {
    let errors = {};
    const namePattern = /^[A-Za-z\s]+$/;
    const passPattern = /^[A-Za-z0-9_@./#&+-]*$/;
    const contactPattern = /^[6-9]{1}[0-9]{9}$/;
    const emailPattern = /^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}(\.[a-z]{2,4})?$/;

    if (!this.state.companyName) {
      errors.companyName = "* Company Name is required";
    }

    if (!this.state.email) {
      errors.email = "* Email is required";
    } else if (!emailPattern.test(this.state.email)) {
      errors.email = "* Enter a valid email address";
    }

    if (!this.state.contact) {
      errors.contact = "* Contact Number is required";
    } else if (!contactPattern.test(this.state.contact)) {
      errors.contact = "* Enter a valid Contact Number";
    }

    if (!this.state.password) {
      errors.password = "* Password is required";
    } else if (!passPattern.test(this.state.password)) {
      errors.password = "* Invalid Password";
    }

    if (this.state.termCheck !== "YES") {
      errors.termCheck = "* You must accept the terms and conditions";
    }

    return errors;
  };


  validation1 = () => {
    let errors = {};
    const emailPattern = /^[a-z0-9._%+-]+@[a-z]+\.[a-z]{2,4}$/;

    if (!this.state.loginEmail) {
      errors.loginEmail = "* Email is required";
      console.log("email is req");
    } else if (!emailPattern.test(this.state.loginEmail)) {
      errors.loginEmail = "* Enter a valid email address";
    }

    if (!this.state.loginPass) {
      errors.loginPass = "*Password is required";
    }

    if (!this.state.confirmPass) {
      errors.confirmPass = "*Confirm password is required";
    }

    return errors;
  };

  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const errors = { ...this.state.errors };
    delete errors[name]; // Remove the error for the specific field being changed

    const newValue = type === "checkbox" ? (checked ? "YES" : "NO") : value;
    this.setState({ [name]: newValue, errors, validationMessage1: "" });
  };


  callback = (userinfo) => {
    const emailMap = userinfo.identities.find(
      (item) => item.identityType === "EMAIL"
    );

    const mobileMap = userinfo.identities.find(
      (item) => item.identityType === "MOBILE"
    )?.identityValue;

    const token = userinfo.token;

    const email = emailMap?.identityValue;
    const mobile = mobileMap?.identityValue;
    const name = emailMap?.name || mobileMap?.name;

    console.log(userinfo);

    // Implement your custom logic here.
  };

  handleGoogleSignIn = async (e) => {
    e.preventDefault();
    window.location = BASE_URL + "/oauth2/authorization/google";
  };

  onSuccess = (res) => {
    console.log("LOGIN SUCCESS! current user", res.profileObj);
  };

  onFailure = (res) => {
    console.log("Login Failed", res);
  };

  premiumStyle = {
    fontFamily: "YourFontName, sans-serif", // Replace 'YourFontName' with the actual font name
    fontSize: "16px", // Replace '16px' with the desired font size
  };

  sendData = (e) => {
    e.preventDefault();
    const validationErrors = this.validation();
    this.setState({ loading: true });
    const jwtToken = localStorage.getItem("jwtToken");

    const { companyName, email, contact, password, state, termCheck } =
      this.state;
    const [emailName] = email.split("@");

    const values = {
      companyName: companyName,
      email: email,
      contact: contact,
      password: password,
      state: state || "Maharashtra",
      name: emailName,
      termCheck: termCheck, // No need to change this, it's already set to "YES" or "NO"
    };

    if (Object.keys(validationErrors).length === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(values),
      };
      // First fetch for signup
      fetch(AuthURL + "/signUp", requestOptions)
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.responseMessage);
            });
          }
          return response.json();
        })
        .then((data) => {
          swal({
            icon: "success",
            title: "Registered Successfully",
          });
          this.setState({ loading: false });
          this.setState({ show: false, show1: true, show2: false });
        })
        .catch((error) => {
          console.error("Error: ", error);
          swal({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
          this.setState({ loading: false });
        });
    } else {
      // Validation failed, update the state with errors
      this.setState({
        errors: validationErrors,
        loading: false, // Set loading to false if validation fails
      });
    }
  };


  validate = () => {
    const errors = {};
    const { forgetEmail, forgetPass, confirmPass } = this.state;
    let validationMessage = "";

    if (!forgetEmail) {
      errors.forgetEmail = "Email is required";
      validationMessage += "Email is required\n";
    }
    if (!forgetPass) {
      errors.forgetPass = "Password is required";
      validationMessage += "Password is required\n";
    }
    if (!confirmPass) {
      errors.confirmPass = "Confirm Password is required";
      validationMessage += "Confirm Password is required\n";
    }
    if (forgetPass && confirmPass && forgetPass !== confirmPass) {
      errors.confirmPass = "Passwords do not match";
      validationMessage += "Passwords do not match\n";
    }

    this.setState({ validationMessage1: validationMessage });

    return errors;
  };

  updatePassword = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const errors = this.validate();
    let newValidationMessage = "";
    if (Object.keys(errors).length > 0) {
      for (const key in errors) {
        if (errors[key]) {
          newValidationMessage += `${errors[key]}\n`;
        }
      }
      this.setState({
        errors,
        loading: false,
        validationMessage1: newValidationMessage,
      });
      if (newValidationMessage) {
        window.scrollTo(0, 0); // Scroll to top
        return;
      }
    } else {
      this.setState({ validationMessage1: "" });
    }

    const jwtToken = localStorage.getItem("jwtToken");
    const { forgetEmail, forgetPass, confirmPass } = this.state;
    const values = {
      email: forgetEmail,
      password: forgetPass,
      confirmPassword: confirmPass,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(values),
    };

    fetch(AuthURL + "/forgetPassword", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (!response.ok) {
            throw new Error(
              data.responseMessage || "Failed to update password"
            );
          }
          return data;
        });
      })
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Password Changed Successfully",
          });
          this.setState({
            loading: false,
            show: false,
            show1: true,
            show2: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        swal({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
        this.setState({ loading: false });
      });
  };

  getData = (e) => {
    console.log("getData Clicked");
    e.preventDefault();
    this.setState({ loading: true });

    const { loginEmail, loginPass } = this.state;
    const values = {
      email: loginEmail,
      password: loginPass,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    fetch(AuthURL + "/login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const uniqueId = data.uniqueId; // Get uniqueId from response
          const token = data.JWTToken;
          const refreshToken = data.refreshToken;
          const userId = data.userId;
          const encryptedUniqueId = CryptoJS.AES.encrypt(
            uniqueId,
            "1234567812345678"
          ).toString();
          localStorage.setItem("uniqueId1", encryptedUniqueId);
          localStorage.setItem("userId", userId);
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("refreshToken", refreshToken);
          // Get the current date and format it as dd-mm-yyyy
          const currentDate = new Date();
          const formattedCurrentDate = `${String(
            currentDate.getDate()
          ).padStart(2, "0")}-${String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          )}-${currentDate.getFullYear()}`;

          // Store the formatted date in local storage
          localStorage.setItem("loginDate", formattedCurrentDate);

          swal({
            icon: "success",
            title: "Logged In Successfully",
          });
          this.setState({ loading: false, navigateform: true });

          // Redirect to another page if needed
        } else if (data.responseMessage === "Trial Period Expired") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
            buttons: {
              goToRegister: {
                text: "Activate",
                value: "register",
                className: "swal-button--register",
              },
            },
          }).then((value) => {
            switch (value) {
              case "retry":
                break;
              case "register":
                window.location.href = `https://zarp.software/app/paymentScreen?email=${loginEmail}`;
                break;
            }
          });
          this.setState({ loading: false });
        } else if (data.responseCode === "500") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
          });
          this.setState({ loading: false });
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
          });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
        this.setState({ loading: false });
      });
  };

  getAuth = (email) => {
    console.log("getData Clicked");
    this.setState({ loading: true });

    const values = {
      email: email,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    fetch(AuthURL + "/authCheck", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const uniqueId = data.uniqueId; // Get uniqueId from response
          const token = data.JWTToken;
          const refreshToken = data.refreshToken;
          const userId = data.userId;

          // Encrypt the uniqueId using crypto-js
          const encryptedUniqueId = CryptoJS.AES.encrypt(
            uniqueId,
            "1234567812345678"
          ).toString();

          localStorage.setItem("uniqueId1", encryptedUniqueId);
          localStorage.setItem("userId", userId);
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("refreshToken", refreshToken);

          swal({
            icon: "success",
            title: "Logged In Successfully",
          });
          this.setState({ loading: false, navigateform: true });

          // Redirect to another page if needed
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
            buttons: {
              goToRegister: {
                text: "Activate",
                value: "register",
                className: "swal-button--register",
              },
            },
          }).then((value) => {
            switch (value) {
              case "retry":
                // Code to retry login
                break;
              case "register":
                window.location.href = `https://zarp.software/app/paymentScreen?email=${email}`;
                break;
            }
          });
          this.setState({ loading: false });
        } else if (data.responseCode === "500") {
          swal({
            icon: "error",
            title: "Login Failed",
            text: data.responseMessage,
          });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Login Failed",
          text: error.message,
        });
        this.setState({ loading: false });
      });
  };

  handleGoogleSignInSuccess = (credentialResponse) => {
    const decode = jwtDecode(credentialResponse?.credential);
    console.log(decode.email);
    this.setState({ emailforAuth: decode.email }, () => {
      // Call another function after state has been set
      this.getAuth(decode.email);
    });
    // You can perform further actions like setting state or calling functions here
  };

  handleGoogleSignInError = () => {
    console.log("login failed");
    // You can handle errors here
  };

  handleButtonClick = (event) => {
    event.preventDefault(); // Prevent page reload
    // Trigger Google sign-in when the button is clicked
    GoogleLogin.signIn({
      onSuccess: this.handleGoogleSignInSuccess,
      onError: this.handleGoogleSignInError,
    });
  };

  render() {
    const {
      companyName,
      email,
      contact,
      password,
      state,
      errors,
      loginEmail,
      loginPass,
      forgetEmail,
      forgetPass,
      confirmPass,
      loading,
      currentDataFormatIndex,
    } = this.state;
    const { navigation } = this.props;
    {
      this.state.navigateform && navigation.navigate("dashboard");
    }

    return (
      <div id="wrapper scrollofthesignupage " className="">
        <div className="vertical-align-wrap   ">
          <div className="vertical-align-middle auth-main  ">
            <div className="auth-box signupscrollview">
              <div className="top" style={{ marginTop: "10px" }}>
                <img
                  src={newzerosblack}
                  alt="Zeros ERP"
                  style={{ width: "145px", height: "46px" }}
                />
              </div>
              {this.validationMessage1 && (
                <Alert severity="error" className="mt-0">
                  {this.validationMessage1.split("\n").map((message, index) => (
                    <div key={index}>{message}</div>
                  ))}
                </Alert>
              )}
              {this.state.show && (
                <div
                  className="signuphiddendata"
                  style={{ marginTop: "-20px" }}
                >
                  <div
                    class="header"
                    style={{
                      fontFamily: "YourFontName, sans-serif",
                      width: "100%",
                      fontSize: "40px",
                      color: "black",
                    }}
                  >
                    <p className="registertext">Register</p>
                    <h5 class="leadtext">
                      Experience PREMIUM plan for 14 days.
                    </h5>
                  </div>
                  <div class="body">
                    <form class="form-auth-small">
                      <div class="form-group ">
                        {/* <label
                          htmlFor="companyName"
                          class="control-label sr-only"
                        >
                          Company Name
                        </label> */}
                        <div class="input-group mb-3">
                          <div className="signupfirstline">
                            <div className="signuptext">Company Name</div>
                            <input
                              type="text"
                              className="form-control"
                              name="companyName"
                              value={companyName}
                              onChange={this.handleInputChange}
                              placeholder="Company Name"
                              required
                              style={{
                                width: "92%",
                                height: "46px",
                                flexShrink: "0",
                                borderRadius: "7px",
                                background: "#f6f6f6",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                          {errors.companyName && (
                            <div className="text-danger mb-2">
                              {errors.companyName}
                            </div>
                          )}{" "}
                        </div>
                      </div>
                      <div class="form-group singlerow">
                        <div class="input-group mb-3">
                          <div className="signupsecondrow">
                            <div className="signuptext">Email</div>

                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={email}
                              onChange={this.handleInputChange}
                              placeholder="Email Address"
                              style={{
                                width: "92%",
                                height: "46px",
                                flexShrink: "0",
                                borderRadius: "7px",
                                background: "#f6f6f6",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                          {errors.email && (
                            <div className="text-danger mb-2">
                              {errors.email}
                            </div>
                          )}{" "}
                        </div>
                      </div>
                      <div class="form-group singlerow">
                        {/* <label for="signup-email" class="control-label sr-only">
                          Contact Number
                        </label> */}
                        <div class="input-group mb-3">
                          <div className="signupthirdrow">
                            <div className="signuptext">Mobile Number</div>
                            <input
                              type="type"
                              className="form-control"
                              id="contact"
                              name="contact"
                              value={contact}
                              onChange={this.handleInputChange}
                              placeholder="Mobile Number"
                              required
                              style={{
                                width: "92%",
                                height: "46px",
                                flexShrink: "0",
                                borderRadius: "7px",
                                background: "#f6f6f6",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                          {errors.contact && (
                            <div className="text-danger mb-2">
                              {errors.contact}
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="form-group singlerow">
                        <label
                          for="signup-password"
                          class="control-label sr-only"
                        >
                          Password
                        </label>
                        <div class="input-group mb-3">
                          <div className="signupthirdrow">
                            <div className="signuptext">Password</div>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              name="password"
                              value={password}
                              onChange={this.handleInputChange}
                              placeholder="Password"
                              style={{
                                width: "92%",
                                height: "46px",
                                flexShrink: "0",
                                borderRadius: "7px",
                                background: "#f6f6f6",
                                marginTop: "5px",
                              }}
                            />

                            {errors.password && (
                              <div className="text-danger mb-2">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="form-group singlerow">
                        <label
                          for="signup-password"
                          class="control-label sr-only"
                        >
                          State
                        </label>
                        <div class="input-group mb-3">
                          <div className="signupthirdrow">
                            <div className="signuptext">State</div>

                            <select
                              className="form-control"
                              name="state"
                              style={{
                                width: "92%",
                                height: "46px",
                                flexShrink: "0",
                                borderRadius: "7px",
                                background: "#f6f6f6",
                                marginTop: "5px",
                              }}
                              value={state}
                              onChange={this.handleInputChange}
                            >
                              <option value="Maharashtra" selected>
                                Maharashtra
                              </option>
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Andaman and Nicobar Islands">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadar and Nagar Haveli">
                                Dadar and Nagar Haveli
                              </option>
                              <option value="Daman and Diu">
                                Daman and Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra" selected>
                                Maharashtra
                              </option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* <div class="form-group" style={{ color: "black" }}>
                        <label>Your data will be in INDIA data center.</label>
                      </div> */}
                      <div className="form-group mt-4">
                        <label className="ckbox">
                          <input
                            type="checkbox"
                            name="termCheck"
                            checked={this.state.termCheck === "YES"}
                            onChange={this.handleInputChange}
                            required
                          />
                          <span className="ml-1" style={{ color: "black" }}>
                            I agree to the <a href="#">Terms of Service </a> and{" "}
                            <a href="#">Privacy Policy</a>.
                          </span>
                        </label>{" "}
                        {errors.termCheck && (
                              <div className="text-danger mb-2">
                                {errors.termCheck}
                              </div>
                            )}
                      </div>

                      <br />
                      {loading ? (
                        <div class="spinner"></div>
                      ) : (
                        <button
                          type="submit "
                          style={{ width: "92%", marginTop: "10px" }}
                          className="btn  signInbtn"
                          onClick={this.sendData}
                        >
                          SIGN UP
                        </button>
                      )}

                      <div class="separator-linethrough  orline">
                        <span>or</span>
                      </div>

                      {/* <GoogleOAuthProvider clientId="162642513424-t0kgf33c6sfcdmgob5samcakcsu9aunq.apps.googleusercontent.com">
                        <div
                          style={{
                            marginLeft: "0%",
                          }}
                        >
                          <GoogleLogin
                            onSuccess={this.handleGoogleSignInSuccess}
                            onError={this.handleGoogleSignInError}
                          />
                        </div>
                      </GoogleOAuthProvider> */}

                      <div class="text-center mt-2" style={{ width: "92%" }}>
                        <span
                          class="helper-text btnsignuptext"
                          style={{ color: "black" }}
                        >
                          <b> Already have a ZARP account ? </b>
                          <a
                            // href="page-login.html"
                            href="#"
                            onClick={() => {
                              // navigation.navigate("page-register.html");
                              this.setState({
                                show1: true,
                                show: false,
                                show2: false,
                                loginEmail: "",
                                loginPass: "",
                              });
                            }}
                          >
                            <b>Sign in</b>
                          </a>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {this.state.show1 && (
                <div className="cardlogin">
                  <div className="loginhead">
                    <p className="">Login To Your Account</p>
                  </div>
                  <div className="body">
                    <form className="form-auth-small" action="index.html">
                      <div className="form-group ">
                        <label
                          for="signin-email"
                          className="control-label sr-only"
                        >
                          Email
                        </label>

                        <div className="emailbox">
                          <div className="text1">Email</div>
                          <div class="input-group mb-3 inputbox">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email Address"
                              name="loginEmail"
                              value={loginEmail}
                              onChange={this.handleInputChange}
                              style={{
                                width: "92%",
                                height: "46px",
                                flexShrink: "0",
                                borderRadius: "7px",
                                background: "#f6f6f6",
                                marginTop: "5px",
                              }}
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="text2">Password</div>
                        <div class="input-group mb-3  passwordbox">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="loginPass"
                            value={loginPass}
                            onChange={this.handleInputChange}
                            style={{
                              width: "92%",
                              height: "46px",
                              flexShrink: "0",
                              borderRadius: "7px",
                              background: "#f6f6f6",
                              marginTop: "5px",
                            }}
                          />
                        </div>
                        <span className="helper-text m-b-10 forgetbtn d-flex justify-content-end">
                          <div className="mr-1">
                            <i className="fa fa-lock"></i>
                          </div>
                          <div>
                            <a
                              href="#"
                              onClick={() => {
                                this.setState({
                                  show: false,
                                  show1: false,
                                  show2: true,
                                  loginPass: "",
                                  confirmPass: "",
                                });
                              }}
                            >
                              <b> Forgot Password?</b>
                            </a>
                          </div>
                        </span>
                      </div>
                      {/* <div className="form-group clearfix">
                        <label className="fancy-checkbox element-left">
                          <input type="checkbox" />
                          <span>Remember me</span>
                        </label>
                      </div> */}
                      {/* <br /> */}
                      {loading ? (
                        <div class="spinner"></div>
                      ) : (
                        <button
                          type="submit"
                          className="btn  signInbtn"
                          onClick={this.getData}
                        >
                          SIGN IN
                        </button>
                      )}

                      <div class="separator-linethrough ">
                        <span>or</span>
                      </div>

                      {/* <button onClick={() => this.oauth("GMAIL")}>
                        Continue with Gmail
                      </button> */}

                      {/* <GoogleOAuthProvider clientId="162642513424-t0kgf33c6sfcdmgob5samcakcsu9aunq.apps.googleusercontent.com">
                        <div
                          style={{
                            marginLeft: "0%",
                          }}
                        >
                          <GoogleLogin
                            onSuccess={this.handleGoogleSignInSuccess}
                            onError={this.handleGoogleSignInError}
                          />
                        </div>
                      </GoogleOAuthProvider> */}
                      <div
                        className="text-center mb-2 mt-2"
                        style={{ width: "92%" }}
                      >
                        <span>
                          <span className="btnsignuptext">
                            Don't have an account?{" "}
                          </span>
                          <a
                            href="#"
                            onClick={() => {
                              this.setState({
                                companyName: "",
                                email: "",
                                contact: "",
                                password: "",
                                state: "",
                              });
                              // navigation.navigate("page-register.html");
                              this.setState({
                                show: true,
                                show1: false,
                                show2: false,
                              });
                            }}
                          >
                            <b> Sign up</b>
                          </a>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {this.state.show2 && (
                <div className="cardlogin">
                  <div className="loginhead">
                    <p className="">Change ZARP Password</p>
                  </div>
                  <div className="body">
                    <form className="form-auth-small" action="index.html">
                      <div className="form-group ">
                        <label
                          for="signin-email"
                          className="control-label sr-only"
                        >
                          Email
                        </label>

                        <div className="emailbox">
                          <div className="text1">Email</div>
                          <div class="input-group mb-3 inputbox">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Your Email Address"
                              name="forgetEmail"
                              value={forgetEmail}
                              onChange={this.handleInputChange}
                              style={{
                                width: "92%",
                                height: "46px",
                                flexShrink: "0",
                                borderRadius: "7px",
                                background: "#f6f6f6",
                                marginTop: "5px",
                              }}
                            />{" "}
                            {errors.forgetEmail && (
                              <div className="text-danger mb-2">
                                {errors.forgetEmail}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="text2">Password</div>
                        <div class="input-group mb-3  passwordbox">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="forgetPass"
                            value={forgetPass}
                            onChange={this.handleInputChange}
                            style={{
                              width: "92%",
                              height: "46px",
                              flexShrink: "0",
                              borderRadius: "7px",
                              background: "#f6f6f6",
                              marginTop: "5px",
                            }}
                          />
                          {errors.forgetPass && (
                            <div className="text-danger mb-2">
                              {errors.forgetPass}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="text2">Confirm Password</div>
                        <div class="input-group mb-3  passwordbox">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            name="confirmPass"
                            value={confirmPass}
                            onChange={this.handleInputChange}
                            style={{
                              width: "92%",
                              height: "46px",
                              flexShrink: "0",
                              borderRadius: "7px",
                              background: "#f6f6f6",
                              marginTop: "5px",
                            }}
                          />
                          {errors.confirmPass && (
                            <div className="text-danger mb-2">
                              {errors.confirmPass}
                            </div>
                          )}
                        </div>
                      </div>
                      {loading ? (
                        <div class="spinner"></div>
                      ) : (
                        <button
                          type="button"
                          className="btn signInbtn"
                          onClick={this.updatePassword}
                        >
                          Update Password
                        </button>
                      )}

                      <div class="separator-linethrough ">
                        <span>or</span>
                      </div>

                      <div
                        className="text-center mb-2 mt-2"
                        style={{ width: "92%" }}
                      >
                        <span>
                          <span className="btnsignuptext">Back to</span>
                          <a
                            href="#"
                            onClick={() => {
                              // this.setState({
                              //   companyName: "",
                              //   email: "",
                              //   contact: "",
                              //   password: "",
                              //   state: "",
                              // });
                              // navigation.navigate("page-register.html");
                              this.setState({
                                show: false,
                                show1: true,
                                show2: false,
                                forgetEmail: "",
                                forgetPass: "",
                                confirmPass: "",
                              });
                            }}
                          >
                            <b> Login</b>
                          </a>
                          ?
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="App rightpartofsingin">
            <Carousel>
              <Carousel.Item interval={3000}>
                <Format key="1" img={srcimage} />
              </Carousel.Item>

              <Carousel.Item interval={3000}>
                <Format key="1" img={srcimage1} />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
