import React from "react";
import ManageCategoryNew from "./ManageCategoryNew";

class ManageCategory extends React.Component {
    
  constructor(props) {
    super(props)
    this.state = {
      isCompose: false,
      show: false
    }
  }
  handleNewButtonClick = () => {
    this.setState({ show: true });
    console.log("handleNewButtonClick clicked...")
  }

  handleCancelNew = () => {
    this.setState({ show: false });
  }

  render() {
    return (
      <div className="container-fluid">
        {/* {!this.state.show && <Header headerText="Manage Card" mainNavigate="Shop" currentPage="Manage Card" childNav={this.state.isCompose ? "Compose" : ""} />} */}
        <div className="row clearfix ">

          {!this.state.show && <div className="container mx-2 mb-2">
            <div className="d-flex justify-content-between">

              <div>
                <h4>Manage Category</h4>
              </div>

              <div className="d-flex">
                {/* <form id="navbar-search" className="navbar-form search-form">
                  <input className="form-control" placeholder="Search here..." type="text" />
                  <button type="button" className="btn btn-default"><i className="icon-magnifier"></i></button>
                </form> */}
                <div className="">
                  {!this.state.show && <button className="btn btn-secondary" onClick={this.handleNewButtonClick}>+ NEW</button>}
                </div>
              </div>
            </div>
          </div>}
        </div>

      </div>
      
    );
  }
}
export default ManageCategory;
