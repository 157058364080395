import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css"; // Import styles for the rich text editor
import { AES, enc } from "crypto-js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { BASE_URL } from "../CommonApi";
import * as XLSX from "xlsx";
import ReactToPrint from "react-to-print";
import Select, { components } from "react-select"; // Import components from react-select
import DatePicker from "react-datepicker"; // Import DatePicker for manual date selection
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles

function ProfitAndLoss({ navigation }) {
  const lineStyle = {
    margin: "5px 0", // Add some margin between the lines
    border: "none",
    borderBottom: "2px solid #ccc", // Style the horizontal line
    width: "100%", // Set the width of the horizontal line
  };

  const blueTextStyle = {
    color: "blue",
  };

  const [loading, setLoading] = useState(true);
  const componentRef = useRef();

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const cancle = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleNavigation("reports");
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const options = [
    { value: "today", label: "Today" },
    { value: "this_week", label: "This week" },
    { value: "this_month", label: "This month" },
    { value: "this_year", label: "This year" },
    { value: "this_quarter", label: "This Quarter" },
    { value: "yesterday", label: "Yesterday" },
    { value: "previous_week", label: "Previous Week" },
    { value: "previous_month", label: "Previous Month" },
    { value: "previous_quarter", label: "Previous Quarter" },
    { value: "previous_year", label: "Previous Year" },
    { value: "custom", label: "Custom" },
  ];
  const [profitLoss, SetProfitLoss] = useState([]);
  const jwtToken = localStorage.getItem("jwtToken");
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const formatDate1 = (date) => {
    return date.toLocaleDateString("en-GB").replace(/\//g, "-"); // DD-MM-YYYY format
  };
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const [itemData, setItemData] = useState({
    organizationName: "",
    companyName: "",
  });
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          // const fetchid = portfolioItem.id;
          // setId(fetchid);

          setItemData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            name: portfolioItem.name,
            role: portfolioItem.role,

            uniqueId: portfolioItem.uniqueId,
          });
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedOption) {
      const today = new Date();
      switch (selectedOption.value) {
        case "today":
          setFromDate(today);
          setToDate(today);
          break;
        case "yesterday":
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          setFromDate(yesterday);
          setToDate(yesterday);
          break;
        case "this_week":
          const sunday = new Date(today);
          sunday.setDate(today.getDate() - today.getDay());
          const saturday = new Date(today);
          saturday.setDate(today.getDate() + (6 - today.getDay()));
          setFromDate(sunday);
          setToDate(saturday);
          break;
        case "previous_week":
          const prevSunday = new Date(today);
          prevSunday.setDate(today.getDate() - today.getDay() - 7);
          const prevSaturday = new Date(today);
          prevSaturday.setDate(today.getDate() - today.getDay() - 1);
          setFromDate(prevSunday);
          setToDate(prevSaturday);
          break;
        case "this_month":
          const firstDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          const lastDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );
          setFromDate(firstDayOfMonth);
          setToDate(lastDayOfMonth);
          break;
        case "previous_month":
          const prevMonthStart = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
          );
          const prevMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          );
          setFromDate(prevMonthStart);
          setToDate(prevMonthEnd);
          break;
        case "this_quarter":
          const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
          const quarterStartDate = new Date(
            today.getFullYear(),
            quarterStartMonth,
            1
          );
          const quarterEndDate = new Date(
            today.getFullYear(),
            quarterStartMonth + 3,
            0
          );
          setFromDate(quarterStartDate);
          setToDate(quarterEndDate);
          break;
        case "previous_quarter":
          const prevQuarterStartMonth =
            Math.floor((today.getMonth() - 3) / 3) * 3;
          const prevQuarterStartDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth,
            1
          );
          const prevQuarterEndDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth + 3,
            0
          );
          setFromDate(prevQuarterStartDate);
          setToDate(prevQuarterEndDate);
          break;
        case "this_year":
          const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
          const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
          setFromDate(firstDayOfYear);
          setToDate(lastDayOfYear);
          break;
        case "previous_year":
          const prevYearStart = new Date(today.getFullYear() - 1, 0, 1);
          const prevYearEnd = new Date(today.getFullYear() - 1, 11, 31);
          setFromDate(prevYearStart);
          setToDate(prevYearEnd);
          break;
          case "custom":
          setFromDate(today);
          setToDate(today);
          break;
        default:
          setFromDate(null);
          setToDate(null);
          break;
      }

      // Call fetchStatement1 with the appropriate arguments
      // fetchStatement1(custID, fromDate, toDate);
    }
  }, [selectedOption]); // This effect runs when selectedOption state changes

  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      fetchStatement1(fromDate, toDate);
    }
  }, [fromDate, toDate]); // This effect runs when either fromDate or toDate changes

  const [dataPost, setDataPost] = useState({
    uniqueId: decryptedUniqueId,
    fromDate: formatDate1(fromDate),
    toDate: formatDate1(toDate),
  });

  useEffect(() => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/profitAndLoss`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(dataPost), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          SetProfitLoss(Data);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const fetchStatement1 = (fromDate, toDate) => {
    fetch(BASE_URL + `/profitAndLoss`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        fromDate: formatDate1(fromDate),
        toDate: formatDate1(toDate),
      }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          SetProfitLoss(Data);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  const handleDownload = () => {
    // Ensure profitLoss data is available

    // Format data for Excel
    const data = [
      // Header row with bold text
      [
        { value: "ACCOUNT", bold: true },
        { value: "ACCOUNT CODE", bold: true },
        { value: "TOTAL", bold: true },
      ],
      // Data rows
      ["Opening Income", ""],
      ["Sales", "", { value: profitLoss.sales, color: "green" }], // Change color
      [
        "Total Operating Income",
        "",
        { value: profitLoss.totalForOperatingIncome, bold: true },
      ], // Bold
      ["Cost of Goods Sold", ""],
      [
        "Total Cost of Goods Sold",
        "",
        { value: profitLoss.totalForCostOfGoodsSold, color: "red" },
      ], // Change color
      ["Gross Profit", "", { value: profitLoss.grossProfit, bold: true }], // Bold
      // Add more data here as needed
    ];

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add a worksheet to the workbook
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Apply additional styling
    // For example, setting cell colors
    XLSX.utils.sheet_set_range_style(ws, "C1", "C6", {
      fill: {
        type: "pattern",
        patternType: "solid",
        fgColor: { rgb: "FFFF00" },
      }, // Yellow background color
    });
    XLSX.utils.book_append_sheet(wb, ws, "Profit_Loss_Data");

    // Save the workbook as an .xls file
    XLSX.writeFile(wb, "Profit_Loss_Data.xls");
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      border: "1px solid #ccc",
      borderRadius: "4px",
      paddingLeft: "6px",
      width: "100%",
      maxWidth: "400px", // Ensure responsiveness
      boxSizing: "border-box",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: "400px",
    }),
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <span className="custom-control-icon">📅</span>
      {children}
    </components.Control>
  );

  return (
    <>
     <style>
        {`
          .custom-datepicker-wrapper {
            position: relative;
            display: inline-block;
            width: 200px;
          }

          .custom-datepicker-calendar {
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          .react-datepicker__input-container {
            width: 100%;
          }

          .react-datepicker__input-container input {
            width: 100%;
            height: 40px;
            padding: 0 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }

          .custom-label {
            display: block;
            margin-bottom: 5px;
            font-size: 16px;
            color: #333;
          }

          .custom-date-picker {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        `}
      </style>

      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={cancle}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5
            className="ml-1 mb-0"
            style={{ letterSpacing: "1px", color: "#086bd5de" }}
          >
            Profit and Loss
          </h5>
        </div>
      </div>
      <div className="horizontal-section my-3 mx-0">
        <div className="d-flex justify-content-between mt-1 mb-1 ml-3">
          <div className="d-flex align-items-center">
            <Select
              value={selectedOption}
              onChange={handleOptionChange}
              options={options}
              styles={customStyles}
              components={{ Control: CustomControl }}
            />
          </div>
          {selectedOption.value === "custom" && (
            <div className="d-flex align-items-center ml-auto">
             <div className="custom-date-picker">
                <label htmlFor="from-date" className="custom-label mr-2">
                  From Date:
                </label>{" "}
                <DatePicker
                  id="from-date"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                  style={{backgroundColor:"white"}}
                />
              </div>
              <div className="custom-date-picker">
                <label htmlFor="to-date" className="custom-label mr-2">
                  To Date:
                </label>
                <DatePicker
                  id="to-date"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                />
              </div>
            </div>
          )}
          <div className="d-flex align-items-center">
            <ReactToPrint
              trigger={() => (
                <span
                  className="input-group-text"
                  id="pdf-icon"
                  style={{
                    backgroundColor: "#F6F6F6",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  📑
                </span>
              )}
              content={() => componentRef.current}
            />{" "}
          </div>
        </div>
        <div ref={componentRef} style={{ backgroundColor: "white" }}>
          <div
            className="container-fluid col-md-8"
            style={{ textAlign: "center", color: "black" }}
          >
            <h5 style={{ fontStyle: "normal" }}>{itemData.companyName}</h5>
            <h4
              style={{ fontStyle: "normal", cursor: "pointer" }}
              onClick={handleDownload}
            >
              Profit and Loss
            </h4>
            <p className="mb-1">Basis: Concept of Accounting</p>
            <p style={{ fontWeight: "normal" }}>
              FromTo {formatDate(fromDate)} - ToDate {formatDate(toDate)}
            </p>
            {/* <p style={noteStyle} onClick={cancle}>
            + Add Temporary Note
          </p> */}

            {/* First horizontal line */}
            <hr style={lineStyle} />

            {/* Section 1: Account and Total */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Account</div>
              <div>Total</div>
            </div>

            {/* Second horizontal line */}
            <hr style={lineStyle} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Opening Income</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div className="ml-3" style={blueTextStyle}>
                Discount
              </div>
              <div style={blueTextStyle}>{profitLoss.discount}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
                blueTextStyle,
              }}
            >
              <div className="ml-3" style={blueTextStyle}>
                Other Charges
              </div>
              <div style={blueTextStyle}>{profitLoss.otherCharges}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
                blueTextStyle,
              }}
            >
              <div className="ml-3 " style={blueTextStyle}>
                Sales
              </div>
              <div style={blueTextStyle}>{profitLoss.sales}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Total for Operating Income</div>
              <div>{profitLoss.totalForOperatingIncome}</div>
            </div>
            <hr style={lineStyle} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Cost of Goods Sold</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Total for Cost of Goods Sold </div>
              <div>{profitLoss.totalForCostOfGoodsSold}</div>
            </div>
            <hr style={lineStyle} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div></div>
              <div>Gross Profit</div>
              <div>{profitLoss.grossProfit}</div>
            </div>
            <hr style={lineStyle} />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Operating Expense</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Total for Operating Expense</div>
              <div>{profitLoss.totalForOperatingExpense}</div>
            </div>
            <hr style={lineStyle} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div></div>
              <div>Operating Profit</div>
              <div>{profitLoss.operatingProfit}</div>
            </div>

            <hr style={lineStyle} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Non Operating Income</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Total For Non Operating Income</div>
              <div>0.00</div>
            </div>
            <hr style={lineStyle} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Non Operating Expense</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div>Total For Non Operating Expense</div>
              <div>0.00</div>
            </div>
            <hr style={lineStyle} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div></div>
              <div>Net Profit/Loss</div>
              <div>{profitLoss.netProfitLoss}</div>
            </div>
            <hr style={lineStyle} />
          </div>
        </div>
        <div>
          <p
            style={{
              marginTop: "20px",
              display: "inline-block",
              marginLeft: "8px",
            }}
          >
            **Amount is displayed in your base currency{" "}
            <span
              style={{
                backgroundColor: "rgb(56,138,16)",
                padding: "2px 4px",
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              INR
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default ProfitAndLoss;
