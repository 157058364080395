import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import InvoiceUpdate from "./InvoicesUpdate";
import InvoicesNew from "./InvoicesNew";
import swal from "sweetalert";
import "./InvoiceDetails.css";
import TinyMceEditor from "../Settings/TinyMceEditor";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Spinner } from "react-bootstrap"; // Import the Bootstrap Spinner component
import { CiMail, CiEdit } from "react-icons/ci";
import { MdOutlinePrint } from "react-icons/md";
import ProformaUpdate from "./ProformaUpdate";
import numberToWords from "number-to-words";
import { SettingsApplicationsOutlined } from "@mui/icons-material";

function ProformaDetails(props) {
  const { newinvoiceNumber, custId } = props;
  const [invoices, setInvoices] = useState([]);
  const [invoiceCount, setInvoiceCount] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoading3, setIsLoading3] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [itemDetails, setItemDetails] = useState([]);
  const [invoiceNumber, setInvoiceId] = useState(null);
  const [invoiceEdit, setInvoiceEdit] = useState(false);
  const [clickedInvoiceNumber, setClickedInvoiceNumber] = useState(""); // Define invoiceNumber
  const [detailsPage, setDetailsPage] = useState(true);
  const [state, setState] = useState(true);
  const [someName, setSomeName] = useState(true);
  const [firstName, setFirstName] = useState(true);
  const [lastName, setLastName] = useState(true);
  const [openMail, setMail] = useState(false);
  const [userEmail, setUserEmail] = useState([]);
  const [custEmail, setCustomerEmail] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const jwtToken = localStorage.getItem("jwtToken");
  const [totalAmount, setTotalAmount] = useState(0);
  const [Image, setImage] = useState(null);
  const formatAmountInWords = (amount) => {
    const [integerPart, fractionalPart] = amount.toString().split(".");
    let amountInWords = numberToWords.toWords(integerPart);
  
    if (fractionalPart) {
      const fractionalInWords = numberToWords.toWords(fractionalPart);
      amountInWords += ` and ${fractionalInWords} paisa`;
    }
  
    // Capitalize the first letter
    amountInWords = amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1);
  
    return amountInWords;
  };
  const amountInWords = formatAmountInWords(totalAmount);

  const [companyName, setCompanyName] = useState([]);
  const [pdfLoading, setPdfLoading] = useState(false);

  const [userDetails, setUserDetails] = useState([]);
  const [editorContent, setEditorContent] = useState("");

  const [openNew, setopenNew] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const [showOfflinePay, setOfflinePay] = useState(false);
  const [showTaxDeducted, setShowTaxDeducted] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [subject, setSubject] = useState("");
  const [offline, setOffline] = useState({});
  const [fetchedInvoiceNumber, setFetchedInvoiceNumber] = useState("");
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [formData, setFormData] = useState({
    vendorName: "",
    billsNumber: "",
    vendorId: "",
    amountReceived: invoiceDetails.receivables || "", // Initialize with the current value
    note: "",
    paidThrough: "",
    paymentDate: "",
    paymentMode: "",
    reference: "",
    paymentCount: "",
    depositeTo: "",
    bankCharges: "",
    taxDeducted: "",

    // Add other fields as needed
  });
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleCustomerEmail = (event) => {
    setCustomerEmail(event.target.value);
  };
  const handleCancleClick = () => {
    props.handleCancel();
  };

  useEffect(() => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchProFormaInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const count = data.responseMessage1;
          setInvoiceCount(count);
          setInvoices(data.responseMessage);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchProFormaByInvoiceNumber/${newinvoiceNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const itemDetails = data.responseMessage;
          setItemDetails(itemDetails);

          const invoiceDetails = data.responseMessage[0];
          setInvoiceDetails(invoiceDetails);
          setTotalAmount(data.responseMessage[0].total);

          const paymentInv = data.responseMessage[0].invoiceNumber;
          setOffline(paymentInv);
          // getCustomerData();
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, [newinvoiceNumber]);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const userId = localStorage.getItem("userId");

    fetch(BASE_URL + `/fetchUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userDetails = data.responseMessage;
          setImage(
            `https://documents.zarp.software/zarp_images/${userDetails.logo}`
          );
          setUserDetails(userDetails);
          const companyName = data.responseMessage.companyName;
          setCompanyName(companyName);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getCurruntNumberList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ type: "invoice", uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setFetchedInvoiceNumber(data.responseMessage);
        } else {
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Number List:", error);
      });
  }, []);

  const [bankData, setBankData] = useState([]);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const postData = {
    uniqueId: decryptedUniqueId,
  };

  useEffect(() => {
    const apiUrl = BASE_URL + "/fetchBankForPdf";
    const makePostRequest = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(postData),
        });

        if (response.ok) {
          const responseData = await response.json();

          const filteredData = responseData.responseMessage;
          setBankData(filteredData);
          setLoading(false);
        } else {
          console.error("POST request failed");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    makePostRequest();
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchCustomerById/${custId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setCustomerData(Data);

          const custEmail = data.responseMessage.email;

          setCustomerEmail(custEmail);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, [custId]);


  const handleCancelUpdate = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    setopenNew(false);
  };

  const handleCancelNew = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    setopenNew(false);
  };

  const getCustomerData = (customerId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchCustomerById/${customerId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const custEmail = data.responseMessage.email;
          setCustomerEmail(custEmail);
          setCustomerData(data.responseMessage);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleInvoiceClick = (invoiceNumber) => {
    setClickedInvoiceNumber(invoiceNumber); // Set invoiceNumber

    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchProFormaByInvoiceNumber/${invoiceNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const itemDetails = data.responseMessage;
          setItemDetails(itemDetails);
          const invoiceDetails = data.responseMessage[0];
          setInvoiceDetails(invoiceDetails);
          setTotalAmount(data.responseMessage[0].total);

          const paymentInv = data.responseMessage[0].invoiceNumber;
          setOffline(paymentInv);
          // fetchOffline();
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  const handleEditClick = (invoiceNumber) => {
    setInvoiceId(invoiceNumber);
    setInvoiceEdit(true);
    setDetailsPage(false);
    setopenNew(false);
  };
  const handleMailClick = () => {
    setInvoiceEdit(false);
    setDetailsPage(false);
    setopenNew(false);
    setMail(true);
  };

  const handleCloseMailClick = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    setopenNew(false);
    setMail(false);
  };

  const handleAddNew = () => {
    setDetailsPage(false);
    setInvoiceEdit(false);
    setopenNew(true);
  };

  const itemDetails1 = itemDetails.map((item) => {
    return {
      items: item.itemsDetails || "",
      quantity: item.quantity || "",
      rate: item.rate || "",
      discount: item.discount || "",
      amount: item.amount || "",
      discountType: item.discountType || "",
      size: item.size || "",
      tax: item.tax || "",
    };
  });

  const font = {
    color: "red",
  };

  const handleBankSelect = () => {
    setShowTaxDeducted(true);
  };

  const handleTaxDeducted = () => {
    setShowTaxDeducted(false);
  };

  const savePDF = () => {
    // Check if PDF data is available
    if (pdfData) {
      // Create an anchor element
      const link = document.createElement("a");
      link.href = pdfData;

      // Define the filename for the PDF
      const fileName = "{invoiceDetails.invoiceNumber}.pdf";

      // Set the download attribute with the desired filename
      link.setAttribute("download", fileName);

      // Programmatically trigger a click event on the anchor to start the download
      link.click();
    } else {
      console.error("Error: PDF data is not available.");
    }
  };

  const showPDF = async () => {
    // const doc = new jsPD F('p', 'pt', 'letter');
    setPdfLoading(true);
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const formDataObject = {
      total: invoiceDetails.total,
      invoiceNumber: invoiceDetails.invoiceNumber,
      invoiceDate: invoiceDetails.invoiceDate,
      dueDate: invoiceDetails.dueDate,
      payButtonNow: invoiceDetails.payButtonNow,
      organizationName: companyName,
      customerName: invoiceDetails.customerName,
      companyName: invoiceDetails.customerName,
      terms: invoiceDetails.terms,
      items: itemDetails1,
      subTotal: invoiceDetails.subtotal,
      adjustment: invoiceDetails.adjustment,
      totalDue: invoiceDetails.total,
      notes: invoiceDetails.customerNote,
      state: invoiceDetails.state,
      discountCheck: invoiceDetails.discountCheck,
      sizeCheck: invoiceDetails.sizeCheck,
      taxCheck: invoiceDetails.taxCheck,
      address: userDetails.address,
      uniqueId: userDetails.uniqueId,
      name: bankData.name || "",
      bank: bankData.bankName || "",
      accountNo: bankData.accountNumber || "",
      ifscCode: bankData.ifsc || "",
      type: bankData.accountType || "",
      igst: invoiceDetails.igst || "",
      cgst: invoiceDetails.cgst || "",
      sgst: invoiceDetails.sgst || "",

      // type:invoiceDetails.discountType,
    };

    try {
      const response = await fetch(BASE_URL + "/generate-pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        const pdfBlob = await response.blob();
        setPdfData(URL.createObjectURL(pdfBlob));
        setPdfLoading(false);
        // Set the PDF data for preview (optional)
      } else {
        setPdfLoading(false);
        console.error("Error: Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const ConvertToInvoice = async () => {
    // const doc = new jsPD F('p', 'pt', 'letter');
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const formDataObject = {
      total: invoiceDetails.total,
      invoiceNumber: fetchedInvoiceNumber,
      oldProFormaNumber: invoiceDetails.invoiceNumber,
      invoiceDate: invoiceDetails.invoiceDate,
      dueDate: invoiceDetails.dueDate,
      payButtonNow: invoiceDetails.payButtonNow,
      organizationName: companyName,
      customerName: invoiceDetails.customerName,
      companyName: invoiceDetails.customerName,
      terms: invoiceDetails.terms,
      items: itemDetails1,
      subTotal: invoiceDetails.subtotal,
      adjustment: invoiceDetails.adjustment,
      totalDue: invoiceDetails.total,
      notes: invoiceDetails.customerNote,
      state: invoiceDetails.state,
      discountCheck: invoiceDetails.discountCheck,
      sizeCheck: invoiceDetails.sizeCheck,
      taxCheck: invoiceDetails.taxCheck,
      uniqueId: userDetails.uniqueId,
      type: bankData.accountType || "",
      igst: invoiceDetails.igst || "",
      cgst: invoiceDetails.cgst || "",
      sgst: invoiceDetails.sgst || "",
      status: "Draft",
      manualCheck: "No",
      // type:invoiceDetails.discountType,
    };

    try {
      const response = await fetch(BASE_URL + "/convertToInvoice", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        swal({
          icon: "success",
          title: "Successfully Converted to Invoice",
        });
        handleCancleClick();
      } else {
        swal({
          icon: "error",
          title: "Failed to convert  invoice",
        });
        console.error("Error: Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleUpdate = () => {
    showPDF();
    setShowPdf(true);
    setShowDetails(false);
  };

  const initialContent = `
  <div className="main-container" style="margin: 10px; padding: 10px; text-align: center; background-color: #4190f2;">
  <h2 style="margin: 10px; padding: 10px; text-align: center;">${invoiceDetails.invoiceNumber}</h2>
  </div>

<div className="content-container">
  <h4 style="margin: 20px; padding: 20px;"> Dear ${customerData.customerName},</h4>
  <p style="margin: 20px; padding: 20px;" className="my-custom-paragraph">Thank you for your business. Your pro forma can be viewed, printed and downloaded as PDF from the link below. You can also choose to pay it online.</p>
  <div className="card" style="border: 1px solid #e8deb5; border-radius: 3px; padding: 26px; margin: 26px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); width: 50%; margin: 0 auto;  background-color: #fefff1;">
      <h4 style="margin: 5px; padding: 5px; text-align: center;">Pro Forma Amount</h4>
      <h4 style=" text-align: center; color:red;">₹ ${invoiceDetails.total}</h4>
      <table style="width: 100%; text-align: center;">
      <tr>
          <td>Pro Forma No</td>
          <td>${invoiceDetails.invoiceNumber}</td>
      </tr>
      <tr>
          <td>Pro Forma Date</td>
          <td>${invoiceDetails.invoiceDate}</td>
      </tr>
      <tr>
          <td>Due Date</td>
          <td>${invoiceDetails.dueDate}</td>
      </tr>
  </table>
  <div style="text-align: center; padding-top:5px;">
  </div>
  </div>
    <div style=" padding: 10px; width: 80%; margin: 0 auto;">
      <h4>Regards</h3>
      <p>${companyName}</p>
    </div>
</div>`;

  const handleMailSubmit = async () => {
    const postData = {
      to: custEmail,
      from: userEmail,
      subject: subject,
      body: editorContent,
      total: invoiceDetails.total,
      invoiceNumber: invoiceDetails.invoiceNumber,
      invoiceDate: invoiceDetails.invoiceDate,
      dueDate: invoiceDetails.dueDate,
      organizationName: companyName,
      customerName: customerData.customerName,
      companyName: companyName,
      terms: invoiceDetails.terms,
      items: itemDetails1,
      name: bankData.name,
      branch: bankData.bankName,
      accountNo: bankData.accountNumber,
      ifscCode: bankData.ifsc,
      type: bankData.accountType,
      totalInWords: amountInWords,
      subTotal: invoiceDetails.subtotal,
      adjustment: invoiceDetails.adjustment,
      totalDue: invoiceDetails.total,
      notes: invoiceDetails.customerNote,
      state: customerData.billState,
      address: userDetails.address,
      imageName: userDetails.logo,
      uniqueId: decryptedUniqueId,
      sizeColumn: invoiceDetails.sizeCheck,
      discountColumn: invoiceDetails.discountCheck,
      taxColumn: invoiceDetails.taxCheck,
      igst: invoiceDetails.igst,
      cgst: invoiceDetails.cgst,
      sgst: invoiceDetails.sgst,
      orderNo: invoiceDetails.referenceNumber,
      orgMob1: userDetails.contact,
      organizationStreetName: userDetails.address1,
      organizationAddress: userDetails.address2,
      organizationPinCode: userDetails.zipcode,
      custmorAddress: customerData.billAddress1,
      custmorCity: customerData.billCity,
      custmorMob: customerData.billPhone,
      termsAndCondition: invoiceDetails.termsAndCondition,
      // organizationGSTNo:gstNumber.gstin,
    };

    setIsLoading3(true);
    const response = await fetch(BASE_URL + "/send-email-with-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Mail Send Successfully",
          });
          handleCloseMailClick();
          setIsLoading3(false);
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading3(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading3(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchOffline = async (customerId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const requestBody = {
      uniqueId: decryptedUniqueId,
      customerId: customerId,
      invoiceNumber: offline,
    };
    fetch(BASE_URL + `/fetchOfflinePayments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const paymentDetails = data.responseMessage;
          const paymentCount = data.paymentCount;
          setFormData((prevData) => ({
            ...prevData,
            amountReceived: paymentDetails.receivables,
            paymentCount: paymentCount,
            // Set other fields as needed
          }));
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  const insertPayment = async () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const formDataObject = {
      customerName: invoiceDetails.customerName,
      invoiceNumber: invoiceDetails.invoiceNumber,
      customerId: invoiceDetails.customerId,
      uniqueId: decryptedUniqueId,
      amountReceived: formData.amountReceived,
      note: formData.note,
      paidThrough: formData.paidThrough,
      paymentDate: currentDate,
      paymentMode: formData.paymentMode,
      reference: formData.reference,
      bankCharges: formData.bankCharges,
      depositeTo: formData.depositeTo,
      taxDeducted: formData.taxDeducted,
      // Add other fields as needed
    };

    try {
      const response = await fetch(BASE_URL + "/offlinePayments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        swal({
          icon: "success",
          title: "Payment Added Successfully",
        });
        setOfflinePay(false);
        setDetailsPage(true);
        setShowDetails(true);
      } else {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  let i = 1;

  function groupByTax(items) {
    return items.reduce((acc, item) => {
      (acc[item.tax] = acc[item.tax] || []).push(item);
      return acc;
    }, {});
  }

  const groupedItems = groupByTax(itemDetails);

  function groupByTax1(items) {
    return items.reduce((acc, item) => {
      const taxRate = item.tax;
      if (!acc[taxRate]) {
        acc[taxRate] = [];
      }
      acc[taxRate].push(item);
      return acc;
    }, {});
  }

  const groupedItems1 = groupByTax1(itemDetails);

  const handlePrint = () => {
    const printableContent = document.getElementById("printable-content");
    if (printableContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <!DOCTYPE html>
 <html lang="en">
 <head>
     <title>${invoiceDetails.invoiceNumber}</title>
     <style>
         .item {
             margin: 10px 0;
         }
         *{
             box-sizing: border-box;
         }
         @media print {
             .page-break {
                 display: block;
                 page-break-before: always;
             }
         }
         body {
             font-family: Arial, sans-serif;
             margin: 0px;
             padding: 0px;          
             height: 100vh;
         }
         .invoice-container {
             max-width: 900px;
             margin: auto;
             padding: 10px;
             /* border: 1px solid #ddd; */
             /* background-color: #fff; */
             /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
         }
         .table {
             width: 100%;
             height: 100%;
             border-collapse: collapse;
             margin-top: -1px;
         }
         .table th {
             padding: 8px;
             border: 1px solid #ddd;
         }
         .table td {
             padding: 6px;
             border: 1px solid #ddd;
         }
         .table thead th {
             background-color: #f2f2f2;
             font-weight: bold;
         }
         .bg-light {
             background-color: black;
         }
         .fw-600 {
             font-weight: 600;
         }
         .text-center {
             text-align: center;
         }
         .text-end {
             text-align: right;
         }
         .text-uppercase {
             text-transform: uppercase;
         }
         .mb-0 {
             margin-bottom: -1px;
             margin-left: -0.5px;
         }
         .py-1 {
             padding-top: 0.25rem;
             /* padding-bottom: 0.25rem; */
         }
         .btn-group {
             display: inline-flex;
         }
         .btn {
             padding: 10px 20px;
             border: none;
             background-color: #007bff;
             color: #fff;
             text-decoration: none;
             cursor: pointer;
         }
         .btn:hover {
             background-color: #0056b3;
         }
         .fst-italic {
             font-style: italic;
         }
         .first-box {
             display: flex;
             position: relative;
         }
         .shop-info {
             position: relative;
             left: 20px;
             top: -17px;
             width: 80%;
         }
         .cust-info {
             position: relative;
             left: 38px;
             top: -17px;
         }
         .shop-logo {
             /* position: relative; */
             padding-left: 10px;
         }
         .sub-total {
             display: flex;
             justify-content: end;
             justify-content: space-between;
         }
         .sub-total-name {
             display: flex;
         }
         .sub-total-value {
             /* position: relative; */
             left: -6px;
         }
         .bill-text {
             font-size: 14px;
             padding: 10px;
             margin: 0px 0px;
         }
         .bank-detail {
             display: flex;
             flex-direction: row;
             justify-content: space-between;
             height: 120px;
         }        
         .bank-detail-main p {
             font-size: 10px;
             margin: 0px;
             padding: 2px 5px;
             font-weight: bold;
             position: relative;
             bottom: 10px;
         }
         .bank-detail-main p span {
             font-size: 10px;
             margin: 0px;
             padding: 2px 15px;
             font-weight: 200;
         }
         .bank-scanner img {
             /* border: 1px solid black;  */
             position: relative;
             top: -26px;
            left:90px
         }      
         .bank-scanner p {
             /* border: 1px solid black;  */
             position: relative;
             top: -11px;
             left: 90px;
         }
         #total-table {
             border-collapse: collapse;
             margin-top: 18px;
             border: 1px solid #ddd;
             width: 100%;
         }
         .table-container {
             display: table;
             width: 100%;
             /* Ensures container fills the available width */
             position: relative;
         }
         .float-container {
             width: 100%;
             /* Ensures container fills the available width */
         }
         .float-item {
             float: left;
             width: 30%;
             /* Adjust width as needed */
             margin-right: 10px; 
             /* Space between items */
             /* Optional: for visual clarity */
             padding: 10px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item1 {
             float: left;
             width: 70%;
             /* Adjust width as needed */
             margin-right: 15%;
             /* Space between items */
 
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item2 {
             float: left;
             width: 30%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item3 {
             float: left;
             width: 70%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item4 {
             float: left;
             width: 30%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item5 {
             float: left;
             width: 70%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item6 {
             float: left;
             width: 30%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item7 {
 
             float: left;
             width: 60%;
             /* Adjust width as needed */
             margin-right: 60%;
             /* Space between items */
             padding: 0px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item8 {
             float: right;
             width: 40%;
             /* Adjust width as needed */
             height: 150px;
             margin-right: 20%;
             /* Space between items */
             padding: 0px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         #toatal-table tr td {
             border: 1px solid #ddd;
             border-collapse: collapse;
         }
         @media print {
             .no-break {
                 page-break-inside: avoid;
                 /* Prevents break inside element */
             }
             .page-break-before {
                 page-break-before: always;
                 /* Forces a break before element */
             }
             .page-break-after {
                 page-break-after: always;
                 /* Forces a break after element */
             }
             .backgroun-dark{
              background-color:#f2f2f2 !important;
              -webkit-print-color-adjust:exact;
              print-color-adjust:exact;
             }
 
             // table,th,td{
             // margin:0px;
             // border-collapse:collapse;
             // border:1px solid #ddd;
             // -webkit-print-color-adjust:exact;
             // print-color-adjust:exact;
             // }
         }
 
         // table{
         // margin:0px;
         // width:100%;
         // border-collapse:collapse;
         // border:1px solid #ddd;
         // }
 
         // th,td{
         // border:1px solid #ddd;
         // }
         // tr{
         // border:1px solid #ddd;
         // }
 
         .font-heading{
             font-size: 16px;
             font-weight: bold;
         }
         tr td{
             border-collapse: collapse;
         }
 
         .pt-5{
         padding-top:5px;
         }
 
         /* tr{
             box-sizing: border-box  ;
             padding: 20px;
             /* display: inline; */
     </style>
 </head>
 <body>
     <!-- Container -->
     <div class=" main-table container-fluid invoice-container">
         <div class="box1" style="position: relative">
             <div class="table-responsive">
                 <table class="table table-bordered border border-secondary mb-0"
                     style="height: 100%; border-collapse: collapse; width: 100%;">
                     <tbody style="border-collapse: collapse;">
                         <tr>
                             <td style="width: 60%   ;">
                                 <div class="first-box">
                                     <div class="shop-logo">
                                         <img src=${Image} width="100" height="100" />
                                     </div>
                                     <div class="shop-info">
                                         <h3 style="margin: 14px 0px 5px 0px;">${companyName || ""
         }</h3>
 
                                          <address>
                                         ${userDetails.address1 || ""} ${userDetails.address2 || ""}<br>${userDetails.zipcode || ""} ${userDetails.city || ""}
                                         <br>${userDetails.state || ""} <br>
                                         ${userDetails.contact ? `MOB:${userDetails.contact || ""}<br>`:""} 
                                          ${invoiceDetails.gstNumber ? `GST NO: ${invoiceDetails.gstNumber}` : ""}
                                         </address>
                                     </div>
                                 </div>
                             </td>
                             <td colspan="2" class="text-center">
                                 <h3>
                                     PRO FORMA INVOICE
                                 </h3>
                         </tr>
 
                         <tr style="border:none;">
                             <td class="col-7" style="border-left: 1px solid #ddd; border-bottom: none;">
                                 <p style="font-size: 12px; position: relative; top: -10px; left: 14px;">Buyer (Bill
                                     To)
                                 </p>
                                 <div class="row gx-2 gy-2">
                                     <div class="col-auto"></div>
                                     <div class="col">
                                         <div class="cust-info">
                                             <h4 style="margin: 14px 0px 5px 0px;" class="font-heading"> ${invoiceDetails.customerName || ""
         }</h4>
 
                                             <address style="font-size: 15px;">
         ${customerData.billAddress1 ? `${customerData.billAddress1}<br />` : ""}
         ${customerData.billCity || customerData.billZipcode
           ? `
             ${customerData.billCity ? `${customerData.billCity} ` : ""}
             ${customerData.billZipcode ? `${customerData.billZipcode}` : ""}
             <br />
         `
           : ""
         }
         ${invoiceDetails.state || customerData.billCountry
           ? `
             ${invoiceDetails.state ? `${invoiceDetails.state} ` : ""}
             ${customerData.billCountry ? `${customerData.billCountry}` : ""}
             <br />
         `
           : ""
         }
         ${customerData.mobile ? `MOB: ${customerData.mobile}<br />` : ""}
         ${customerData.gstin ? `GST No: ${customerData.gstin}<br />` : ""}
     </address>
                                         </div>
 
                                     </div>
                                 </div>
                             </td>
                             <td style=" margin: 0px; padding: 0px;">
 
                                 <!-- <div class="col-5" >Invoice No :<span class="float-end">:</span></div>
                                         <div class="col-7">Invoice Date :</div>
                                         <div class="col-5">Due :<span class="float-end">:</span></div>
                                         <div class="col-7">Terms :</div>
                                         <div class="col-7">P.O. :</div> -->
                                 <table
                                     style="margin: 0px; padding: 0px; width: 100%; padding: 0px; margin: 0px; border-collapse: collapse; height:100%;">
                                     <tr>
                                         <td style="width: 100px; border: none; width: 50%;">Pro Forma No:</td>
                                         <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">
                                             ${invoiceDetails.invoiceNumber || ""}
                                         </td>
                                     </tr>
                                     <tr>
                                         <td style="border-left:none; border-right: none; border-top: 1px solid #ddd;">
                                             Pro Forma Date:</td>
                                         <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">
                                             ${invoiceDetails.invoiceDate || ""}
                                         </td>
                                     </tr>
                                     <tr>
                                         <td style="border-left:none; border-right: none; border-top: 1px solid #ddd;">
                                             Due:</td>
                                         <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">
                                             ${invoiceDetails.dueDate || ""}
                                         </td>
                                     </tr>
                                     ${invoiceDetails.terms ?`
                                     <tr>
                                         <td style="border-left:none; border-right: none; border-top: 1px solid #ddd;">
                                             Terms:</td>
                                         <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">
                                         ${invoiceDetails.terms || ""}
                                         </td>
                                     </tr>`:""}
                                     ${invoiceDetails.orderNumber ?`
                                     <tr style="border-bottom:none ;">
                                         <td
                                             style="border-left:none; border-right: none; border-top: 1px solid #ddd; border-bottom: none;">
                                             P O: </td>
                                         <td
                                             style="border-left:1px solid #ddd; border-right: none; border-top: 1px solid #ddd; border-bottom: none;">
                                             ${invoiceDetails.orderNumber || ""}
                                         </td>
                                     </tr>`:""}
                                 </table>
 
                             </td>
                         </tr>
                     </tbody>
                 </table>
                 <table class="table table-sm mb-0"
                     style="width: 100%; padding:0px; margin: 0px; border: none; border-collapse: collapse;">
                     <tr class="bg-light backgroun-dark" style="border-collapse: collapse; ">
                         <td class="col-1 text-center  font-heading"><strong>SrNo</strong></td>
                         <td class="col-6 font-heading"><strong>Product Name</strong></td>
                         ${invoiceDetails.hsnCheck === "YES"
           ? `<td class="col-1 text-center font-heading"><strong>HSN</strong></td>`
           : ""
         }
                         ${invoiceDetails.sizeCheck === "YES"
           ? '<td class="col-1 text-center font-heading"><strong>Size</strong></td>'
           : ""
         }
                         <td class="col-1 text-center font-heading"><strong>Qty</strong></td>
                         <td class="col-2 text-end font-heading"><strong>Rate</strong></td>
                         ${invoiceDetails.discountCheck === "YES"
           ? '<td class="col-2 text-end font-heading"><strong>Discount</strong></td>'
           : ""
         }
                         ${invoiceDetails.taxCheck === "YES"
           ? '<td class="col-2 text-end font-heading"><strong>Tax</strong></td>'
           : ""
         }
                         <td class="col-2 text-end font-heading"><strong>Amount</strong></td>
                     </tr>
                     ${itemDetails
           .map(
             (item, index) => `
                     <tr class="produc-des " style="page-break-inside: avoid;">
                         <td class="col-1 text-center bill-text">${index + 1
               }</td>
                         <td class="col-6 bill-text" style="white-space: pre-line;">${item.itemsDetails || ""
               }<br />${item.description2 || ""}</td>
                         ${invoiceDetails.hsnCheck === "YES"
                 ? `<td class="col-2 text-end bill-text">${item.hsnSac || ""
                 }</td>`
                 : ""
               }
                                           ${invoiceDetails.sizeCheck === "YES"
                 ? `<td class="col-1 text-center bill-text">${item.size || ""
                 }</td>`
                 : ""
               }
                                           <td class="col-1 text-center bill-text">${item.quantity || ""
               }</td>
                                           <td class="col-1 text-center bill-text">${item.rate || ""
               }</td>
                                           ${invoiceDetails.discountCheck ===
                 "YES"
                 ? `<td class="col-1 text-center bill-text">${item.discount || ""
                 }(${item.discountType || ""
                 })</td>`
                 : ""
               }
                                           ${invoiceDetails.taxCheck === "YES"
                 ? `<td class="col-1 text-center bill-text">${item.tax || ""
                 }%</td>`
                 : ""
               }
                                           <td class="col-2 text-end bill-text">${item.amount || ""
               }</td>
                                       </tr>`
           )
           .join("")}
                                       
                   
                 </table>
             </div>
             <div class="box2" style="position: relative; top: 0px; page-break-inside: avoid;">
                 <!-- <div class="breckingpg"></div> -->
                 <table style=" border-collapse: collapse; border: none;  width: 100%;;" id="toatal-table">
                     <tr class="bg-light fw-600 backgroun-dark">
                         <td class="col-7 py-1" style="width:60%; border: none; border-left: 1px solid #ddd;"></td>
                         <td class="" style="width:40% ;border: none; border-right: 1px solid #ddd; border-left: 1px solid #ddd;">
                             <div class="sub-total">
                                 <div class="sub-total-name font-heading pt-5" style="padding-left:5px;">
                                     Sub Total:
                                 </div>
                                 <div class="sub-total-valuept-5 " style="padding-right:5px;">
                                 ${invoiceDetails.subtotal || ""}
                                 </div>
                             </div>
                             <span class="float-end"></span>
                         </td>
                     </tr>
                 </table>
             </div>
             <table style="border-collapse: collapse; width: 100%; margin: 0; padding: 1px; page-break-inside: avoid ;" id="total-table">
                 <tr>
                     <!-- Total In Word Section -->
                     <td style="width: 60%; vertical-align: top; padding: 0; border-right: 1px solid #ddd;border-bottom: none; border-top: none; border-collapse: collapse;">
                         <h4 style="margin: 0; padding-left:5px;" class="font-header pt-5">Total In Word</h4>
                         <p 
                             style="margin: 0; font-size: 15px; width: 90%;   text-indent: 2em;">
                            Indian Rupee ${amountInWords || ""}  Only                      </p>
                     </td>
                     <!-- CGST and SGST Section -->
                     <td style="width: 40%; vertical-align: top; padding: 0;">
                         <div style="display: flex; flex-direction: column; justify-content: flex-start; height: 100%; padding-top: 5px;">
                          ${invoiceDetails.adjustment && invoiceDetails.adjustment != 0 && invoiceDetails.adjustment != 0.0 && invoiceDetails.adjustment != "0"
           ? `
                             <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                        <div class="sub-total-name" style="width: 50%;">${invoiceDetails.adjustmentLabel || "Adjustment"}:
                                       </div>
                                       <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">₹${invoiceDetails.adjustment}</div>
                                       </div>
                                       ` : ""}
                             ${invoiceDetails.overallDiscount > 0 || 0.0 ? `<div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                              <div class="sub-total-name" style="width: 50%;">Discount(
                                   ${invoiceDetails.totalDiscount}
                                   ${invoiceDetails.totalDiscountType})
                             </div>
                             <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">-₹${invoiceDetails.overallDiscount}</div>
                             </div>`: ""}
                             ${invoiceDetails.taxCheck === "YES"
           ? userDetails.state !== invoiceDetails.state
             ? Object.entries(groupedItems1)
               .map(([taxRate, items]) => {
                 let totalIGST = 0.0;
                 // Loop through items to accumulate IGST
                 items.forEach((item) => {
                   const igstValue =
                     parseFloat(item.igst) || 0.0;
                   if (item.customerTaxType === 1) {
                     totalIGST += igstValue * 2;
                   } else {
                     totalIGST += igstValue;
                   }
                 });
                 return `
                            <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                          <div class="sub-total-name" style="width: 50%;">IGST (${taxRate}%):
                                         </div>
                                         <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalIGST.toFixed(
                   2
                 )}</div>
                                         </div>
                                       `;
               })
               .join("")
             : Object.entries(groupedItems)
               .map(([taxRate, items]) => {
                 let totalCGST = 0.0;
                 let totalSGST = 0.0;
                 items.forEach((item) => {
                   const cgstValue =
                     parseFloat(item.cgst) || 0.0;
                   const sgstValue =
                     parseFloat(item.sgst) || 0.0;
 
                   totalCGST += cgstValue;
                   totalSGST += sgstValue;
                 });
                 return `
                                      <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                          <div class="sub-total-name" style="width: 50%;">CGST (${taxRate / 2
                   }%):
                                         </div>
                                         <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalCGST.toFixed(
                     2
                   )}</div>
                                         </div>
                                          <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                          <div class="sub-total-name" style="width: 50%;">SGST (${taxRate / 2
                   }%):
                                         </div>
                                         <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalSGST.toFixed(
                     2
                   )}</div>
                                         </div>
                                             `;
               })
               .join("")
           : ""
         }
                             ${invoiceDetails.value > 0
           ? invoiceDetails.type === "TDS"
             ? `
                                     <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                       <div class="sub-total-name" style="width: 50%;">TDS (${invoiceDetails.tdsTax})</div>
                                       <div class="sub-total-value" style="width: 50%; text-align: right;padding-right:5px;">₹${invoiceDetails.value}</div>
                                     </div><br/>`
             : invoiceDetails.type === "TCS"
               ? `
                                       <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                         <div class="sub-total-name" style="width: 50%;">TCS (${invoiceDetails.tcsTax})</div>
                                         <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">₹${invoiceDetails.value}</div>
                                       </div><br/>`
               : ""
           : ""
         }
                         </div>
                     </td>
                 </tr>
             </table>
             <table style="border-collapse: collapse; margin-top: 0px; width: 100%; border: none; border-left: 1px solid #ddd; border-radius:1px solid #ddd ;" id="total-table">
                 <tr>
                     <td style="width: 60%; border-right: 1px solid #ddd; ">
                         <div style="display: inline-block; font-weight: bold; padding-bottom: 5px; padding-left:3px;" class="font-header pt-5">
                             Note:
                         </div>
                         <div style="display: flex; align-items: start;">
                             <span style="font-size: 14px; padding-left:6px;word-break: break-word; text-indent:2em;">${invoiceDetails.customerNote||""}
                             </span>
                         </div>
                     </td>
                     <td style="width: 40%; vertical-align: top;  border-right: 1px solid #ddd;" class=" backgroun-dark">
                         <div class="sub-total" style="vertical-align: top;">
                             <div class="sub-total-name font-heading pt-5" style="font-weight: bold; padding-left:5px;">
                                 Total:
                             </div>
                             <div class="sub-total-value font-heading pt-5" style="font-size:14px; font-weight: bold; padding-right:5px;">
                                 ${invoiceDetails.total || ""
         }                            </div>
                         </div>
                     </td>
                 </tr>
             </table>
             <div class="breakingele"></div>

             <table style=" border-collapse: collapse; margin-top:0px; width: 100%; vertical-align:top; break-inside:avoid;" id="toatal-table">
                 <tr>
                     <td class="col-7 text-1" style="width:60%;margin-top:20px;height:100%; vertical-align:top;">
                         <div style="margin:5px">
                             <div class="fw-600 font-heading">Terms &amp; Condition :</div>
                             <div >
                                 <p><span style="font-size: 14px; padding-left:6px;word-break: break-word; text-indent:2em;">${invoiceDetails.termsAndCondition || ""}</span></p>
                             </div>
                         </div>
                     </td>
                     <td class="col-5 pe-1 text-end" style="width:40%; margin-top:20px; height:100%; vertical-align: bottom;">
                         <div class="text-end"
                             style="height:120px; display: flex; flex-direction: column; align-items: flex-end;vertical-align: bottom ; justify-content: flex-end;margin:5px">
                             For, ${companyName}
                             <div class="text-1 fst-italic mt-5">(Authorised Signatory)</div>
                         </div>
                     </td>
                 </tr>
             </table>                
         </div>
 </body>
 </html>      
         `);
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.onafterprint = () => {
          printWindow.close();
          printWindow.onafterprint = null;
        };
        printWindow.print();
      }
    };
  }
  return (
    <>
      {detailsPage && (
        <>
          {/****Head****/}
          <div
            className="d-flex justify-content-between mt-3"
            style={{ padding: "5px 5px 0px 5px" }}
          >
            <div className="d-flex align-items-center ">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="headinvoice ml-2">
                <h5
                  className="ml-1 mb-0 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  All Details
                </h5>
                <p className="ml-1 mb-0 text-muted">{invoiceCount} pro forma</p>
              </div>
            </div>
            <div className="add-invoice pr-2 d-flex align-items-baseline">
              {/* <button
                    className="btn btn-primary px-1 py-2 new-purchase"
                    onClick={""}
                  >
                    + New Invoices
                  </button> */}
            </div>
          </div>

          <div
            className="card border-2 invoice-border mt-3"
            style={{
              borderRadius: "20px",
              height: "auto",
              marginBottom: "0px",
            }}
          >
            {/* <hr style={{ marginTop: "6px", marginBottom: "0px" }} /> */}
            <div className="custom-templates-container">
              {/* Left Sidebar */}
              <div className="left-invoice custom-scrollbar">
                {/* <h6 className="mt-2 ml-4 mb-2">
                <strong>All INVOICE</strong>
              </h6> */}
                {/* <button
                      className="btn btn-success btn-icon"
                      onClick={handleAddNew}
                      type="button"
                      style={{ textAlign: "left" }}
                    >
                      +
                    </button> */}
                {/* <div className="horizontal-line"></div> */}

                <div>
                  <div
                    className="text-left custom-scrollbar"
                    style={{ cursor: "pointer" }}
                  >
                    {invoices.map((invoice, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="invoice-row"
                          onClick={() => {
                            handleInvoiceClick(invoice.invoiceNumber);
                            fetchOffline(invoice.customerId);
                            getCustomerData(invoice.customerId);
                            setShowDetails(true);
                            setOfflinePay(false);
                            setShowPdf(false);
                          }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "1px",
                            }}
                          >
                            {invoice.customerName}
                          </p>

                          <p
                            className="text-muted mt-1"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            {invoice.invoiceNumber}
                          </p>
                          <div className="d-flex justify-content-between mt-1">
                            <p
                              className=""
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginLeft: "10px",
                                marginBottom: "0px",
                              }}
                            >
                              {`₹ ${invoice.total}`}
                            </p>
                            <span className="fs-1 text-muted">
                              {invoice.dueDate}
                            </span>
                          </div>
                          <p
                            className=""
                            style={{
                              marginLeft: "10px",
                              marginBottom: "0px",
                              marginTop: "10px",
                            }}
                          >
                            {invoice.status}
                          </p>
                        </div>
                        {index < invoices.length - 0 && (
                          <div className="horizontal-line"></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className="middle-line"></div>

              {/* Right Side Content */}
              {showDetails && (
                <div className="right-invoice">
                  {/* Top Template Bar */}

                  <ul
                    className="nav nav-tabs-new d-flex"
                    style={{ padding: "0px 0px 10px 0px" }}
                  >
                    <h6
                      className="flex-grow-1 mb-0 ml-1"
                      style={{ color: "#3C7BD4" }}
                    >
                      {invoiceDetails.invoiceNumber}
                    </h6>
                    <li className="nav-item mr-1 cursor-pointer">
                      <a
                        className="nav-link"
                        href="#"
                        role="button"
                        onClick={() =>                          
                          handleEditClick(invoiceDetails.invoiceNumber)
                        }
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiEdit size={18} /> {/* Set the size to 24 */}
                        <span>Edit</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item mr-1">
                      <a
                        className="nav-link "
                        onClick={() =>
                          handleMailClick(invoiceDetails.invoiceNumber)
                        }
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiMail size={18} /> {/* Set the size to 24 */}
                        <span>Mail</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item" id="printable-content">
                      <a
                        className="nav-link "
                        onClick={handlePrint}
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <MdOutlinePrint size={18} /> {/* Set the size to 24 */}
                        <span>Print</span>{" "}
                      </a>
                    </li>
                  </ul>

                  <div className="horizontal-line"></div>

                  {/* Section Below Top Template Bar */}
                  <div className="custom-container">
                    <div
                      className="section"
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <div className="print-section border border-2" style={{ position: "relative", zIndex: 1, }}>
                        <div className="top-template-bar1">
                          <div className="top-bar-icons">
                            <span className="ml-2 mt-2">
                              Convert this pro forma invoice in to invoice{" "}
                            </span>
                          </div>

                          <div className="top-bar-icons">
                            <button
                              className="btn btn-primary"
                              onClick={ConvertToInvoice}
                            >
                              Convert to Invoice
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*****Invoice*****/}
                    <div className="section1 custom-scrollbar invoice-data m-0">
                      <div className="invoice-container">
                        <div className="invoice-head border-bottom-0">
                          <div className="invoice-head-top">
                          {userDetails && (
                              <div className="invoice-head-top-left text-start p-2">
                                <ul className="text-start mb-0">
                                  {companyName && (
                                    <li className="text-bold">{companyName}</li>
                                  )}
                                  {userDetails.address1 && (
                                    <li>{userDetails.address1}</li>
                                  )}
                                  {userDetails.address2 && (
                                    <li>{userDetails.address2}</li>
                                  )}
                                  {userDetails.zipcode && (
                                    <li>{userDetails.zipcode}</li>
                                  )}
                                  {userDetails.city && (
                                    <li>{userDetails.city}</li>
                                  )}
                                  {userDetails.state && (
                                    <li>{userDetails.state}</li>
                                  )}
                                  {userDetails.businessLocation && (
                                    <li>{userDetails.businessLocation}</li>
                                  )}
                                  {userDetails.contact && (
                                    <li><span className="text-bold">MOB :</span>{" "}{userDetails.contact}</li>
                                  )}
                                  {invoiceDetails.gstNumber && (
                                    <li>
                                      <span className="text-bold">GST NO:</span>{" "}
                                      {invoiceDetails.gstNumber}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                            <div className="invoice-head-top-right text-end p-2 d-flex align-items-end justify-content-end">
                              <h3 className="mb-0">Pro Forma Invoice</h3>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div className="invoice-head-bottom p-0">
                            <div className="invoice-head-bottom-left right-border p-2">
                              <ul>
                                <div className="row">
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      <b>Invoice No </b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      : {invoiceDetails.invoiceNumber}
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      <b>Invoice Date </b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      : {invoiceDetails.invoiceDate}
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      <b>Terms</b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      : {invoiceDetails.terms}
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li>
                                      <b>Due Date</b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li>: {invoiceDetails.dueDate}</li>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div
                            className="invoice-head-middle"
                            style={{ backgroundColor: "#f2f3f4" }}
                          >
                            <div className="invoice-head-middle-left text-start pl-2">
                              <p className="mb-0">
                                <span className="text-bold">Bill To: </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div className="invoice-head-middle">
                            <div className="invoice-head-middle-left text-start pl-2">
                            <p className="mb-0">
                                {invoiceDetails.customerName && (
                                  <span className="text-primary">
                                    {invoiceDetails.customerName}
                                  </span>
                                )}
                                {customerData.billAddress1 && (
                                  <>
                                    <br />
                                    {customerData.billAddress1}
                                  </>
                                )}
                                {customerData.billCity &&
                                  customerData.billZipcode && (
                                    <>
                                      <br />
                                      {customerData.billCity}{" "}
                                      {customerData.billZipcode}
                                    </>
                                  )}
                                {invoiceDetails.state && (
                                  <>
                                    <br />
                                    {invoiceDetails.state}
                                  </>
                                )}
                                {customerData.billCountry && (
                                  <>
                                    <br />
                                    {customerData.billCountry}
                                  </>
                                )}
                                {customerData.mobile && (
                                  <>
                                    <br />
                                    {customerData.mobile}
                                  </>
                                )}
                                {customerData.gstin && (
                                  <>
                                    <br />
                                    <span className="text-bold">
                                      GST No:
                                    </span>{" "}
                                    {customerData.gstin}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                        </div>
                        <div className="overflow-view">
                          <div className="invoice-body">
                            <table>
                              <thead>
                                <tr>
                                  <td
                                    className="td-left-border text-bold"
                                    style={{ border: "0px" }}
                                  >
                                    ID
                                  </td>
                                  <td className="text-bold">
                                    Items & Description
                                  </td>
                                  {invoiceDetails.hsnCheck === "YES" && (
                                    <td className="text-bold">HSN</td>
                                  )}
                                  {invoiceDetails.sizeCheck === "YES" && (
                                    <td className="text-bold">Size</td>
                                  )}
                                  <td className="text-bold">QTY</td>
                                  <td className="text-bold">Rate</td>
                                  {invoiceDetails.discountCheck === "YES" && (
                                    <td className="text-bold">Discount</td>
                                  )}
                                  {invoiceDetails.taxCheck === "YES" && (
                                    <td className="text-bold">Tax</td>
                                  )}
                                  <td className="text-bold">Amount</td>
                                </tr>
                              </thead>
                              <tbody>
                                {itemDetails.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ border: "0px" }}>{i++}</td>
                                    <td style={{whiteSpace: "pre-line"}}>
                                      {item.itemsDetails}
                                      <br />
                                      {item.description2}
                                    </td>
                                    {invoiceDetails.hsnCheck === "YES" && (
                                      <td>{item.hsnSac}</td>
                                    )}
                                    {invoiceDetails.sizeCheck === "YES" && (
                                      <td>{item.size}</td>
                                    )}
                                    <td>{item.quantity}</td>
                                    <td>{item.rate}</td>
                                    {invoiceDetails.discountCheck === "YES" && (
                                      <td>
                                        {item.discount} {item.discountType}
                                      </td>
                                    )}
                                    {invoiceDetails.taxCheck === "YES" && (
                                      <td>{item.tax}%</td>
                                    )}
                                    <td>{item.amount}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ width: "100%", marginTop: "1px" }}>
                              <div
                                style={{
                                  width: "50%",
                                  padding: "4px 4px 3px 7px",
                                  float: "left",
                                }}
                              >
                                <div style={{ margin: "10px 0 5px" }}>
                                  <div style={{ paddingRight: "10px" }}>Total In Words</div>
                                  <span>
                                    <b>
                                      <i>Indian Rupee {amountInWords} Only</i>
                                    </b>
                                  </span>
                                </div>
                                <div style={{ paddingTop: "15px" }}>
                                  <p
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                    }}
                                    className="pcs-notes"
                                  >
                                    <h6>Terms And Condition:</h6>
                                    {invoiceDetails.termsAndCondition}
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{ width: "43.6%", float: "right" }}
                                className="pcs-totals"
                              >
                                <table
                                  className="left-border"
                                  id="itemTable"
                                  cellSpacing="0"
                                  border="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td valign="middle">Sub Total</td>
                                      <td
                                        id="tmp_subtotal"
                                        valign="middle"
                                        style={{ width: "110px" }}
                                      >
                                        ₹{invoiceDetails.subtotal}
                                      </td>
                                    </tr>
                                    {invoiceDetails.adjustment !== 0 && invoiceDetails.adjustment !== "0" && invoiceDetails.adjustment !=="0.0" ?
                                      <tr>
                                        <td valign="middle">{invoiceDetails.adjustmentLabel || "Adjustment"}</td>
                                        <td
                                          id="tmp_total"
                                          valign="middle"
                                          style={{ width: "110px" }}
                                        >
                                          ₹{invoiceDetails.adjustment}
                                        </td>
                                      </tr>
                                    :""}
                                    {invoiceDetails.overallDiscount !== "0" && (
                                      <tr>
                                        <td valign="middle">
                                          Discount(
                                          {invoiceDetails.totalDiscount}
                                          {invoiceDetails.totalDiscountType})
                                        </td>
                                        <td
                                          id="tmp_total"
                                          valign="middle"
                                          style={{ width: "110px" }}
                                        >
                                          - ₹{invoiceDetails.overallDiscount}
                                        </td>
                                      </tr>
                                    )}


                                    {invoiceDetails.taxCheck === "YES" &&
                                      (userDetails.state !==
                                        invoiceDetails.state
                                        ? Object.entries(groupedItems1).map(
                                          ([taxRate, items]) => {
                                            let totalIGST = 0.0;

                                            // Loop through items to accumulate IGST
                                            items.forEach((item) => {
                                              const igstValue =
                                                parseFloat(item.igst) || 0.0;
                                              if (
                                                item.customerTaxType === 1
                                              ) {
                                                totalIGST += igstValue * 2;
                                              } else {
                                                totalIGST += igstValue;
                                              }
                                            });

                                            return (
                                              <React.Fragment key={taxRate}>
                                                <tr>
                                                  <td className="headings">
                                                    IGST ({taxRate}%):
                                                  </td>
                                                  <td className="headings">
                                                    {totalIGST.toFixed(2)}
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            );
                                          }
                                        )
                                        : Object.entries(groupedItems).map(
                                          ([taxRate, items]) => {
                                            let totalCGST = 0.0;
                                            let totalSGST = 0.0;

                                            items.forEach((item) => {
                                              const cgstValue =
                                                parseFloat(item.cgst) || 0.0;
                                              const sgstValue =
                                                parseFloat(item.sgst) || 0.0;

                                              totalCGST += cgstValue;
                                              totalSGST += sgstValue;
                                            });

                                            return (
                                              <React.Fragment key={taxRate}>
                                                <tr>
                                                  <td className="headings">
                                                    CGST ({taxRate / 2}%):
                                                  </td>
                                                  <td className="headings">
                                                    {totalCGST.toFixed(2)}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="headings">
                                                    SGST ({taxRate / 2}%):
                                                  </td>
                                                  <td className="headings">
                                                    {totalSGST.toFixed(2)}
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            );
                                          }
                                        ))}

                                    <tr
                                      style={{ height: "10px" }}
                                      className="pcs-balance"
                                    >
                                      <td valign="middle">
                                        <b>Total</b>
                                      </td>
                                      <td
                                        id="tmp_balance_due"
                                        valign="middle"
                                        style={{ width: "110px" }}
                                      >
                                        <strong>₹{invoiceDetails.total}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingTop: "5px",
                                        }}
                                        colSpan="2"
                                      >
                                        <div
                                          style={{ minHeight: "75px" }}
                                        ></div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan="2"
                                      >
                                        <label
                                          style={{ marginBottom: "0px" }}
                                          className="pcs-totals"
                                        >
                                          Authorized Signature
                                        </label>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ clear: "both" }}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showOfflinePay && (
                <div className="right-invoice">
                  <h5>Payment for {invoiceDetails.invoiceNumber}</h5>
                  <div className="custom-container">
                    <div
                      className="section1 custom-scrollbar invoice-data m-0 p-3"
                      style={{ maxHeight: "700px", overflowX: "hidden" }}
                    >
                      <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-6">
                          <label
                            className="form-label required mg-b-0"
                            style={font}
                          >
                            Customer Name*
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label
                            className="form-label required mg-b-0"
                            style={font}
                          >
                            Payment #*
                          </label>
                        </div>
                      </div>
                      <div className="row mg-t-8 align-items-center mg-b-20">
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            placeholder="Customer Name"
                            name="customerName"
                            type="text"
                            value={invoiceDetails.customerName}
                            readOnly
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-control"
                            placeholder="Payment"
                            name="Payment"
                            type="text"
                            value={formData.paymentCount}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="container my-2 border border-2 payment-gateway">
                        <div className="row pt-3 align-items-center mg-b-20">
                          {/* <!-- Bottom Left Section --> */}
                          <div className="col-md-5 ">
                            <label className="form-label required" style={font}>
                              Amount Received(INR)
                            </label>
                          </div>

                          <div className="col-md-6 ">
                            <input
                              className="form-control"
                              placeholder="Amount Received(INR)"
                              name="amountReceived"
                              type="text"
                              value={formData.amountReceived}
                              onChange={handleChange}
                            />{" "}
                          </div>
                        </div>

                        <div className="row pt-3 align-items-center mg-b-20">
                          {/* <!-- Bottom Left Section --> */}
                          <div className="col-md-5 ">
                            <label className="form-label required">
                              Bank Charges (if any)
                            </label>
                          </div>

                          <div className="col-md-6 ">
                            <input
                              className="form-control"
                              placeholder="Bank Charges (if any)"
                              name="bankCharges"
                              type="text"
                            />{" "}
                          </div>
                        </div>
                        <hr />

                        <div className="row pt-3 align-items-center mg-b-20">
                          {/* <!-- Bottom Left Section --> */}
                          <div className="col-md-5">
                            <label className="form-label required">
                              Tax deducted?
                            </label>
                          </div>

                          <div className="col-md-7">
                            <label className="fancy-radio custom-color-green">
                              <input
                                name="taxDeducated"
                                id="tax"
                                value=""
                                type="radio"
                                onClick={handleTaxDeducted}
                              />
                              <span>
                                <i></i>No Tax deducted
                              </span>
                            </label>

                            <label className="fancy-radio custom-color-green">
                              <input
                                name="taxDeducated"
                                id="tax2"
                                value=""
                                type="radio"
                                onClick={handleBankSelect}
                              />
                              <span>
                                <i></i>Yes, TDS (Income Tax)
                              </span>
                            </label>
                          </div>
                        </div>

                        {showTaxDeducted && (
                          <div className="row pt-3 align-items-center mg-b-20">
                            {/* <!-- Bottom Left Section --> */}
                            <div className="col-md-5 ">
                              <label
                                className="form-label required"
                                style={font}
                              >
                                TDS Tax Account*
                              </label>
                            </div>

                            <div className="col-md-6 ">
                              <input
                                className="form-control"
                                placeholder="TDS Tax Account"
                                name="tdsTaxAccount"
                                type="text"
                              />{" "}
                            </div>
                          </div>
                        )}
                        {showTaxDeducted && (
                          <div
                            className="row pt-3 align-items-center mg-b-20"
                            style={font}
                          >
                            {/* <!-- Bottom Left Section --> */}
                            <div className="col-md-5 ">
                              <label className="form-label required">
                                Amount Withheld*
                              </label>
                            </div>

                            <div className="col-md-6 ">
                              <input
                                className="form-control"
                                placeholder="Amount Withheld"
                                name="amountWithheld"
                                type="text"
                              />{" "}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-4">
                          <label
                            className="form-label required mg-b-0"
                            style={font}
                          >
                            Payment Date*
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className="form-label required mg-b-0">
                            Payment Mode
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label
                            className="form-label required mg-b-0"
                            style={font}
                          >
                            Deposit To*
                          </label>
                        </div>
                      </div>
                      <div className="row mg-t-8 align-items-center mg-b-20">
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="date"
                            value={currentDate}
                            onChange={(e) => setCurrentDate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4">
                          <select
                            className="form-control"
                            name="paymentMode"
                            value={formData.paymentMode}
                            onChange={handleChange}
                          >
                            <option>Select Payment Mode</option>
                            <option value="cash">Cash</option>
                            <option value="Bank Remittance">
                              Bank Remittance
                            </option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="Check">Check</option>
                            <option value="Credit Card">Credit card</option>
                            <option value="Bank Account">Bank Account</option>
                          </select>
                        </div>
                        <div className="col-md-4">
                          <select
                            className="form-control"
                            name="depositeTo"
                            value={formData.depositeTo}
                            onChange={handleChange}
                          >
                            <option>Select Deposit</option>
                            <option value="Bank">Bank</option>
                            <option value="Petty Cash">Petty Cash</option>
                            <option value="Undeposited Fund">
                              Undeposited Fund
                            </option>
                            <option value="Employee Reimbursements">
                              Employee Reimbursements
                            </option>
                            <option value="Opening Balance Adjustment">
                              Opening Balance Adjustment
                            </option>
                            <option value="TDS Payable">TDS Payable</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mg-t-8 pt-3 align-items-center mg-b-20">
                        <div className="col-md-6">
                          <label className="form-label required mg-b-0">
                            Reference #*
                          </label>
                        </div>
                      </div>

                      <div className="row mg-t-8 align-items-center mg-b-20">
                        <div className="col-md-12">
                          <input
                            className="form-control"
                            placeholder="reference"
                            name="reference"
                            type="text"
                            value={formData.reference}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row mg-t-8 pt-3 align-items-center mg-b-20">
                        <div className="col-md-6">
                          <label className="form-label required mg-b-0">
                            Notes
                          </label>
                        </div>
                      </div>

                      <div className="row mg-t-8 align-items-center mg-b-20">
                        <div className="col-md-12">
                          <textarea
                            className="form-control"
                            placeholder="Notes"
                            name="note"
                            type="text"
                            value={formData.note}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row mg-t-8 pt-3 align-items-center mg-b-20">
                        <div className="container-fluid">
                          <div className="row">
                            {/* <div className="col-md-10">
                              <label
                                className="fancy-checkbox mb-0"
                                style={emailStyle}
                              >
                                <input type="checkbox" name="checkbox" />
                                <span className="pl-2">
                                  Email a "thank you" note for this payment
                                </span>
                              </label>
                            </div> */}

                            {/* <div className="mg-t-8 pt-3 align-items-center mg-b-20"> */}
                            <div className="col-md-3">
                              <button
                                className="btn btn-success"
                                onClick={insertPayment}
                              >
                                Record Payment
                              </button>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showPdf && (
                <div className="right-invoice">
                  <div className="">
                    <h6
                      className="flex-grow-1 mb-0 ml-1"
                      style={{ color: "#3C7BD4" }}
                    >
                      {invoiceDetails.invoiceNumber}
                    </h6>
                    <br />
                    {pdfLoading ? (
                      <div className="text-center" style={{ marginTop: "50%" }}>
                        {/* Show spinner while loading */}
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <embed
                        src={pdfData}
                        type="application/pdf"
                        width="100%"
                        height="750px"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {invoiceEdit && (
        <ProformaUpdate
        invoiceNumber1={invoiceNumber}
          handleCancel={handleCancelUpdate}
        />
      )}

      {openMail && (
        <>
          <div className="d-flex align-items-center ml-1 mt-4">
            <div
              className="cursor-pointer d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "35px",
                width: "38px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon
                width={2}
                onClick={handleCloseMailClick}
                className="arrow-child"
              />
            </div>
            <div className="headitems ml-2">
              <h5
                className="ml-1 mb-0"
                style={{ letterSpacing: "1px", color: "#086bd5de" }}
              >
                Mail
              </h5>
            </div>
          </div>
          <div
            className="profit-loss-section my-3 mx-0"
            style={{
              padding: "30px",
              backgroundColor: "#fff",
              borderRadius: "9px",
            }}
          >
            <br />
            <div className="radio row mg-t-15 pt-1 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">From</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="from"
                  id=""
                  name="from"
                  type="text"
                  value={userEmail}
                  onChange={handleUserEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-10  align-items-left mg-b-20">
              <div className="col-md-1">
                <label className="form-label">Send To</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Send To"
                  id=""
                  name="to"
                  type="text"
                  value={custEmail}
                  onChange={handleCustomerEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">CC</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="CC"
                  id=""
                  name=""
                  type="text"
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15  align-items-center">
              <div className="col-md-1">
                <label className="form-label">Subject</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Subject"
                  id=""
                  value={subject}
                  name="subject"
                  type="text"
                  onChange={handleSubjectChange}
                />
              </div>
            </div>
            <br />
            <div className="radio col-md-12 row mg-t-15 align-items-center">
              <div className="col-md-1 pl-0">
                {/* <label className="form-label">
   <b>Subject : </b>
 </label> */}
              </div>
              <div className="col-md-10 pl-0">
                <div className="body">
                  <TinyMceEditor
                    onChange={setEditorContent}
                    initialContent={initialContent}
                  />

                  <div className="mx-10 mt-4">
                    <button
                      className="btn-1 btn mt-0"
                      onClick={handleCloseMailClick}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn-3 btn btn-primary"
                      onClick={handleMailSubmit}
                    >
                      {isLoading3 ? "Sending..." : "Send"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {openNew && <InvoicesNew handleCancle={handleCancelNew} />}
    </>
  );
}

export default ProformaDetails;
