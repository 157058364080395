// import React, { useState, useEffect } from "react";
// import { XCircle } from "phosphor-react";
// import { AES, enc } from "crypto-js";

// function ManagePortfolioUpdate(props) {
//   const handleCancelClick = () => {
//     props.handleCancel();
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//     setIsDragging(true);
//   };

//   const handleDragLeave = () => {
//     setIsDragging(false);
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     setIsDragging(false);

//     const file = event.dataTransfer.files[0];
//     setSelectedFile(file);
//   };

//   const encryptedUniqueId = localStorage.getItem("uniqueId1");
//   const decryptedUniqueId = AES.decrypt(
//     encryptedUniqueId,
//     "1234567812345678"
//   ).toString(enc.Utf8);

//   const [isDragging, setIsDragging] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);

//   const { id } = props;

//   const [itemData, setItemData] = useState({
//     title: "",
//     description: "",
//     files: "",
//     uniqueId: decryptedUniqueId,
//   });

//   const apiUrl = `http://localhost:8096/api/editPortfolio/${id}`;

//   const fetchData = () => {
//     fetch(apiUrl, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ uniqueId: decryptedUniqueId }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success" && data.responseMessage.length > 0) {
//           const portfolioItem = data.responseMessage[0];
//           setItemData({
//             title: portfolioItem.title,
//             description: portfolioItem.description,
//             files: portfolioItem.files,
//             uniqueId: portfolioItem.uniqueId,
//           });
//         } else {
//           console.error("Error fetching portfolio data");
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching portfolio data:", error);
//       });
//   };

//   useEffect(() => {
//     fetchData();
//   }, [id]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setItemData({
//       ...itemData,
//       [name]: value,
//     });
//   };

//   const handleFileInputChange = (event) => {
//     const file = event.target.files[0];
//     setSelectedFile(file);
//     console.log("Selected file name:", file.name);
//   };

//   const handleSaveClick = () => {
//     if (!itemData.title || !itemData.description) {
//       alert("Title and description are required.");
//       return;
//     }

//     const titleAndDescriptionData = {
//       title: itemData.title,
//       description: itemData.description,
//       uniqueId: itemData.uniqueId,
//     };

//     fetch(`http://localhost:8096/api/updatePortfolio/${id}`, {
//       method: "PUT",
//       body: JSON.stringify(titleAndDescriptionData),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           console.log("Title and description updated successfully");

//           if (selectedFile) {
//             const formData = new FormData();
//             formData.append("files", selectedFile);

//             fetch(`http://localhost:8096/api/files/${id}`, {
//               method: "POST",
//               body: formData,
//             })
//               .then((imageResponse) => imageResponse.json())
//               .then((imageData) => {
//                 if (imageData.status === "success") {
//                   console.log("Image added successfully");
//                 } else {
//                   console.error("Error adding image");
//                 }
//               })
//               .catch((imageError) => {
//                 console.error("Error adding image", imageError);
//               });
//           }
//         } else {
//           console.error("Error updating title and description");
//         }
//       })
//       .catch((error) => {
//         console.error("Error updating title and description", error);
//       });
//   };

//   return (
//     <div className="container my-3">
//       <div className="border border-2 p-2">
//         <div className="px-2 d-flex justify-content-between">
//           <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}>
//             <b>Add New Portfolio</b>
//           </h4>
//           <button className="btn" onClick={handleCancelClick}>
//             <XCircle size={32} weight="fill" />
//           </button>
//         </div>
//         <hr />

//         <div className="container">
//           <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
//             <div className="col-md-2">
//               <label className="form-label required mg-b-0">
//                 <b>Title : </b>
//               </label>
//             </div>
//             <div className="col-md-3 pl-0">
//               <input
//                 className="form-control"
//                 placeholder="Title"
//                 value={itemData.title}
//                 name="title"
//                 type="text"
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//           <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
//             <div className="col-md-2">
//               <label className="form-label font-weight-bold mg-b-0">
//                 Portfolio Image
//               </label>
//             </div>
//             <div
//               className={`drag-drop-input ${isDragging ? "dragging" : ""}`}
//               onDragOver={handleDragOver}
//               onDragLeave={handleDragLeave}
//               onDrop={handleDrop}
//             >
//               {itemData.files ? (
//                 <img
//                   src={itemData.files}
//                   alt="Portfolio"
//                   className="img-fluid"
//                   style={{ maxWidth: "240px" }}
//                 />
//               ) : (
//                 <div className="text-center">
//                   <p className="instructions">Drag and drop a file here</p>
//                   <p className="or my-3">-OR-</p>
//                   <label className="file-input-label">
//                     Browse
//                     <input
//                       type="file"
//                       style={{ display: "none" }}
//                       onChange={handleFileInputChange}
//                       accept="image/*"
//                     />
//                   </label>
//                 </div>
//               )}
//             </div>
//             <div className="col-md-4">
//               <font-xs className="text-muted">
//                 This Portfolio Image will appear on the documents (estimates,
//                 invoices, etc.) that are created.
//               </font-xs>
//               <br />
//               <small className="text-muted">
//                 Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of 1MB.
//               </small>
//             </div>
//           </div>
//           <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
//             <div className="col-md-2">
//               <label className="form-label required mg-b-0">
//                 <b>Description : </b>
//               </label>
//             </div>
//             <div className="col-md-3 pl-0">
//               <textarea
//                 className="form-control"
//                 value={itemData.description}
//                 name="description"
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="my-2">
//         <button
//           className="btn btn-success"
//           type="button"
//           onClick={handleSaveClick}
//         >
//           Save
//         </button>
//         <button className="btn btn-dark mx-2" onClick={handleCancelClick}>
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// }

// export default ManagePortfolioUpdate;


import React, { useState, useEffect } from "react";
import { XCircle } from "phosphor-react";
import { AES, enc } from "crypto-js";

function ManagePortfolioUpdate(props) {
  const handleCancelClick = () => {
    props.handleCancel();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
    const imageURL = URL.createObjectURL(file);
    setImageSrc(imageURL);
  };

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageName, setImageName] = useState("");

  const { id } = props;

  const [itemData, setItemData] = useState({
    title: "",
    description: "",
  });

  const apiUrl = `http://localhost:8096/api/editPortfolio/${id}`;

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && data.responseMessage.length > 0) {
          const portfolioItem = data.responseMessage[0];
          setItemData({
            title: portfolioItem.title,
            description: portfolioItem.description,
          });
          setImageName(portfolioItem.files); // Set the image name
          setImageSrc(`https://demo.vmmhs.org/uploads/car-rental1.png`); // Construct image URL
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id, encryptedUniqueId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemData({
      ...itemData,
      [name]: value,
    });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const imageURL = URL.createObjectURL(file);
    setImageSrc(imageURL);
  };

  const handleSaveClick = () => {
    if (!itemData.title || !itemData.description) {
      alert("Title and description are required.");
      return;
    }

    const titleAndDescriptionData = {
      title: itemData.title,
      description: itemData.description,
      files: selectedFile, // Include the image file in the data
    };

    const formData = new FormData();
    formData.append("title", titleAndDescriptionData.title);
    formData.append("description", titleAndDescriptionData.description);
    formData.append("files", titleAndDescriptionData.files);

    fetch(`http://localhost:8096/api/updatePortfolio/${id}`, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log("Title, description, and image updated successfully");
          // Update the image URL if needed
          // setImageSrc(data.imageURL);
        } else {
          console.error("Error updating title, description, and image");
        }
      })
      .catch((error) => {
        console.error("Error updating title, description, and image", error);
      });
  };

  return (
    <div className="container my-3">
      <div className="border border-2 p-2">
        <div className="px-2 d-flex justify-content-between">
          <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}>
            <b>Add New Portfolio</b>
          </h4>
          <button className="btn" onClick={handleCancelClick}>
            <XCircle size={32} weight="fill" />
          </button>
        </div>
        <hr />

        <div className="container">
          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                <b>Title : </b>
              </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="Title"
                value={itemData.title}
                name="title"
                type="text"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label font-weight-bold mg-b-0">
                Portfolio Image
              </label>
            </div>
            <div
              className={`drag-drop-input ${isDragging ? "dragging" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {imageSrc ? (
                <img
                  src={imageSrc}
                  alt="Portfolio"
                  className="img-fluid"
                  style={{ maxWidth: "240px" }}
                />
              ) : (
                <div className="text-center">
                  <p className="instructions">Drag and drop a file here</p>
                  <p className="or my-3">-OR-</p>
                  <label className="file-input-label">
                    Browse
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      accept="image/*"
                    />
                  </label>
                </div>
              )}
            </div>
            <div className="col-md-4">
              <font-xs className="text-muted">
                This Portfolio Image will appear on the documents (estimates,
                invoices, etc.) that are created.
              </font-xs>
              <br />
              <small className="text-muted">
                Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of 1MB.
              </small>
              {imageName && (
                <div>
                  <p>Image Name: {imageName}</p>
                </div>
              )}
            </div>
          </div>

          <div className="my-2">
            <button
              className="btn btn-success"
              type="button"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button className="btn btn-dark mx-2" onClick={handleCancelClick}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagePortfolioUpdate;

