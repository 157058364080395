import React, { useState, useEffect } from "react";
import Select from "react-select";
import { addDays, endOfMonth, addMonths } from "date-fns";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../../Dashboard/CommonApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Form, Row, Col, Modal } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";

function PurchaseOrdersUpdate(props) {
  const [row, setRow] = useState([
    {
      itemDetails: "",
      account: "",
      hsnSac: "",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
    },
  ]);

  const [manualCheck, setManualCheck] = useState("No");

  const tds = [
    { value: "Commission or Brokerag 5%", label: "Commission or Brokerag 5%" },
    {
      value: "Commission or Brokerag (Reduced) 3.75%",
      label: "Commission or Brokerag (Reduced) 3.75%",
    },
    { value: "Divident 10%", label: "Divident 10%" },
    { value: "Divident (Reduced) 7.5%", label: "Divident (Reduced) 7.5%" },
    {
      value: "Other Interest then securities 10%",
      label: "Other Interest then securities 10%",
    },
    {
      value: "Other Interest then securities (Reduced) 7.5%",
      label: "Other Interest then securities (Reduced) 7.5%",
    },
    {
      value: "Payment of Contractors Others 2%",
      label: "Payment of Contractors Others 2%",
    },
    {
      value: "Payment of Contractors Others (Reduced) 1.5%",
      label: "Payment of Contractors Others (Reduced) 1.5%",
    },
    {
      value: "Payment of Contractors Others HUD/Indiv 1%",
      label: "Payment of Contractors Others HUD/Indiv 1%",
    },
    {
      value: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      label: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
    },
    { value: "Professional Fees 10%", label: "Professional Fees 10%" },
    {
      value: "Rent on land or furniture etc 10%",
      label: "Rent on land or furniture etc 10%",
    },
  ];

  const jwtToken = localStorage.getItem("jwtToken");

  const handleAdd = () => {
    setUserDetails([
      ...userDetails,
      {
        itemDetails: "",
        hsnSac: "",
        size: "",
        quantity: "1",
        rate: "0.0",
        discount: "0.0",
        discountType: "",
        tax: "0",
      },
    ]);
  };

  const handleDeleteRow = (i) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this purchase order item!"
    );

    if (userConfirmed) {
      const updatedRow = [...row];
      if (updatedRow.length === 1) {
        alert("Cannot delete the last remaining row Data.");
        return;
      }
      updatedRow.splice(i, 1);
      setRow(updatedRow);
      updateTotal(updatedRow);
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Delivery Challan deletion canceled!");
    }
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      props.handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  //calculateAmount
  // const calculateAmount = (data, taxRate) => {
  //   const quantity = parseFloat(data.quantity || 0);
  //   const rate = parseFloat(data.rate || 0);
  //   const discount = parseFloat(data.discount || 0);
  //   const amount = quantity * rate;

  //   let discountedAmount = amount;

  //   if (data.discountType === "%") {
  //     discountedAmount -= (amount * discount) / 100;
  //   } else if (data.discountType === "₹") {
  //     discountedAmount -= discount;
  //   }

  //   let calculatedTaxAmount = 0;
  //   if (taxRate > 0) {
  //     calculatedTaxAmount = discountedAmount * taxRate;
  //   }

  //   const calculatedAmount = discountedAmount + calculatedTaxAmount;

  //   return calculatedAmount.toFixed(2);
  // };

  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;

    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }

    return amount;
  };

  //CalculateTaxAmount
  const calculateTaxAmount = (amount, taxRate) => {
    if (taxRate > 0) {
      let taxIncrease = 0;

      // Set tax increase based on tax rate
      switch (taxRate) {
        case 0.05:
          taxIncrease = 0.5;
          break;
        case 0.12:
          taxIncrease = 1.0;
          break;
        case 0.18:
          taxIncrease = 1.5;
          break;
        case 0.28:
          taxIncrease = 2.0;
          break;
        default:
          break;
      }

      return amount * (taxRate + taxIncrease);
    }
    return 0;
  };

  const calculateIGST = () => {
    let totalIGST = 0;

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        // const increasedTaxAmount = amount * taxRate;
        const increasedTaxAmount = parseFloat(amount) * taxRate / 100;
        totalIGST += increasedTaxAmount;
      }
    });

    return totalIGST.toFixed(2);
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateSGSTandCGST = () => {
    let totalSGST = 0;
    let totalCGST = 0;

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      // const increasedTaxAmount = parseFloat(amount) - amount / (1 + taxRate);
      const increasedTaxAmount = parseFloat(amount) * taxRate / 100;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      totalSGST += sgst;
      totalCGST += cgst;
    });
    return {
      totalSGST: totalSGST.toFixed(2),
      totalCGST: totalCGST.toFixed(2),
    };
  };

  const calculateGroupedSGSTandCGST = (rows) => {
    const taxGroups = userDetails.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST

      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;

      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }

      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;

      return acc;
    }, {});

    return taxGroups;
  };


  const calculateSubTotal = () => {
    let subTotal = 0;
    userDetails.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const [selectedTerms, setSelectedTerms] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [dueDateChangedManually, setDueDateChangedManually] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedTaxRate, setSelectedTaxRate] = useState(0);
  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID
  const [adjustmentValue, setAdjustmentValue] = useState("");
  const [oppositeValue, setOppositeValue] = useState("");
  const [formData, setFormData] = useState([]);
  const [ids, setIds] = useState([]);
  const [userDetails, setUserDetails] = useState([
    {
      itemDetails: "",
      hsnSac: "",
      size: "",
      quantity: "",
      rate: "",
      discount: "",
      discountType: "%",
      tax: "",
      selectedTaxRate: 0,
      oldQuantity: "",
      description1: "",
      description2: "",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [vendorNames, setVendorNames] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [discountCheck, setDiscountCheck] = useState("");
  const [discountAmountTds, setDiscountAmountTds] = useState(null);
  const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState([]);
  const [invoiceNumberNext, setInvoiceNumberNext] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [show1, setShow1] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);

  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const copyAdd = () => {
    if (show1) {
      setInvoiceNumber(invoiceNumberPrefix + invoiceNumberNext);
      setFormData({
        ...formData,
        purchaseNumber: invoiceNumberPrefix + invoiceNumberNext,
      });
      setManualCheck("No");
    } else {
      setManualCheck("Yes");
      setInvoiceNumber("");
      setFormData({
        ...formData,
        purchaseNumber: "",
      });
    }

    // Close the popup
    closeInvoice();
  };

  const closeInvoice = () => {
    setShowInvoice(false);
  };

  const fetchBill = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Prepare the data to send in the POST request
    const requestData = {
      // Include any other data you need to send in the request
      uniqueId: decryptedUniqueId,
      type: "purchaseorder",
    };

    // Define the POST request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
        // Add any other headers if required
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    // Make the POST request to your API endpoint
    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Assuming your API returns an object with prefix and next number properties
        setInvoiceNumberPrefix(data.responseMessage.prefix);
        setInvoiceNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const handleDiscountChange = (e, i) => {
    const { name, value } = e.target;
    const newValues =
      name === "discount" ? { discount: value } : { discountType: value };
    const newRow = [...row];
    newRow[i] = { ...newRow[i], ...newValues };
    setRow(newRow);
    updateTotal(newRow);
  };

  const handleQuantityChange = (e, i) => {
    const newQuantity = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], quantity: newQuantity };
    setRow(newRow);
    updateTotal(row);
  };

  const handleRateChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate: newRate };
    setRow(newRow);
    updateTotal(row);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
    setTotal(calculatedTotal.toFixed(2));
  };

  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      // Calculate the opposite value only if the adjustmentValue is a valid number
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      // If adjustmentValue is not a valid number, set the opposite value to an empty string
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [oldPurchaseNumber, setOldPurchaseNumber] = useState("");
  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState("");

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const fetchData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const userId = localStorage.getItem("userId");

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userState = data.responseMessage;
          setUserState(userState);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const Data = data.responseMessage[0].businessRegistered;
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST() || userDetails.igst);
    const adjustment = parseFloat(adjustmentValue || formData.adjustment) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total2 = total1 - disValue;
    const total = discountAmountTcs + total2;
    return total.toFixed(2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setManualCheck("Yes");
    if (name === "adjustment") {
      setAdjustmentValue(value);
    }
  };

  const handleInputChangess = (e, i) => {
    handleDiscountChange(e, i);
    handleRateChange(e, i);
    handleQuantityChange(e, i);
    const { name, value } = e.target;
    const updatedUserDetails = [...userDetails];
    updatedUserDetails[i][name] = value;

    setUserDetails(updatedUserDetails);

    if (name === "tax") {
      const taxRate = parseFloat(value);
      setSelectedTaxRate(taxRate);
    }
  };

  // Fetch customer data
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const handleCustomerChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setFetchedCustomerName(selectedOption.value);
    setSelectedCustomer(selectedValue);
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );
    if (selectedCustomerObject) {
      setSelectedCustomerId(selectedCustomerObject.id);
    } else {
      console.log("Selected Customer: (Not found)");
    }
  };

  const [fetchedVendorName, setFetchedVendorName] = useState("");
  const [fetchedTcsTax, setFetchedTcsTax] = useState("");
  const [fetchedPaymentTerms, setFetchedPaymentTerms] = useState("");
  const [fetchedCustomerName, setFetchedCustomerName] = useState("");

  const handleVendorChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setFetchedVendorName(selectedOption.value);
    setSelectedVendor(selectedValue);
    setFormData({ ...formData, vendorName: selectedValue });
    const selectedVendorObject = vendorNames.find(
      (vendor) => vendor.vendorDisplayName === selectedValue
    );
    const fetchId = formData.vendorId;
    const selectId = selectedVendorObject.id;

    if (fetchId == selectId) {
      setCustomerState(formData.state);
      console.log("formData.state : ", formData.state);
      setSelectedVendorId(selectedVendorObject.id);
    } else if (selectedVendorObject) {
      setSelectedVendorId(selectedVendorObject.id);
      const vendorTerms = selectedVendorObject.paymentTerms;
      const customerState = selectedVendorObject.billingState;
      console.log("selectedVendorObject.billingState : ", selectedVendorObject.billingState);
      setCustomerState(customerState);
      setSelectedTerms(vendorTerms)
    }
  };

  const { purchaseOrderNumber } = props;

  // fetch purchase order by fetchByPurchaseNumber
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchByPurchaseNumber/${purchaseOrderNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setUserDetails(data.responseMessage);
          const itemIds = data.responseMessage.map((item) => item.id);
          setIds(itemIds);
          const Data = data.responseMessage[0];
          setBussinessRegistered(Data.taxCheck);
          setOldPurchaseNumber(Data.purchaseNumber);
          setDiscountCheck(Data.discountCheck);
          setCustomerState(Data.state);
          setItemDescriptions(data.responseMessage.map(item => ({
            itemDetails: item.itemDetails,
            description: item.description2 || ""
          })));
          setFormData({
            vendorName: Data.vendorName,
            vendorId: Data.vendorId,
            deliverTo: Data.deliverTo,
            customerName: Data.customerName,
            purchaseNumber: Data.purchaseNumber,
            state: Data.state,
            reference: Data.reference,
            purchaseDate: Data.purchaseDate,
            terms: Data.terms,
            dueDate: Data.dueDate,
            itemDetails: Data.itemDetails,
            account: Data.account,
            quantity: Data.quantity,
            rate: Data.rate,
            discount: Data.discount,
            discountType: Data.discountType,
            tax: Data.tax,
            amount: Data.amount,
            customerNote: Data.customerNote,
            subtotal: Data.subtotal,
            adjustmentLabel: Data.adjustmentLabel,
            adjustment: Data.adjustment,
            type: Data.type,
            total: Data.total,
            igst: Data.igst,
            sgst: Data.sgst,
            cgst: Data.cgst,
            value: Data.value,
            termsAndConditions: Data.termsAndConditions,
          });
          setDiscountAmountTcs(parseFloat(Data.value));
          setFetchedVendorName(Data.vendorName);
          setFetchedTcsTax(Data.tcsTax);
          setFetchedPaymentTerms(Data.terms);
          setFetchedCustomerName(Data.customerName);
          setDisAllValue(Data.totalDiscount || "");
          setDiscountType(Data.totalDiscountType || "₹");
          setSelectedTcsOption(Data.tcsTax);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [validationMessage1, setValidationMessage1] = useState("");
  const [showInvDate, setShowInvDate] = useState(true);

  const insertDataIntoDatabase = async () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    let newValidationMessage = "";

    if (!formData.vendorName) {
      newValidationMessage += "Please select a vendor.\n";
    } else if (!formData.purchaseNumber) {
      newValidationMessage += "Please enter purchase number.\n";
    } else if (!formData.purchaseDate) {
      newValidationMessage += "Please select a purchase date.\n";
    }

    userDetails.forEach((item, index) => {
      if (item.itemDetails.trim() === "") {
        newValidationMessage += `Item name is required.\n`;
      }
      if (item.rate.trim() === "") {
        newValidationMessage += `Rate is required.\n`;
      } else if (item.rate.trim() === "0.0") {
        newValidationMessage += `Rate cannot be 0.0.\n`;
      }
      if (item.quantity.trim() === "") {
        newValidationMessage += `Quantity is required.\n`;
      }
      if (item.account === "") {
        newValidationMessage += `A valid account must be selected.\n`;
      }
    });

    setValidationMessage1(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true);

    const formDataObject = {
      uniqueId: decryptedUniqueId,
      ids: ids.map((id) => ({ id })),
      items: userDetails.map((user) => ({
        itemDetails: user.itemDetails,
        quantity: user.quantity,
        rate: user.rate || formData.rate,
        discount: user.discount || formData.discount,
        discountType: user.discountType || formData.discountType,
        tax: user.tax || formData.selectedTaxRate,
        amount: calculateAmount(user) || 0,
        account: user.account,
        oldQuantity: user.quantity || formData.quantity,
        description1: user.description1 || "",
        description2: user.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst: userState.state === customerState ? (calculateAmount(user) * (user.tax) / 100) / 2 : 0,
        sgst: userState.state === customerState ? (calculateAmount(user) * (user.tax) / 100) / 2 : 0,
        igst: userState.state !== customerState ? (calculateAmount(user) * (user.tax) / 100) : 0
      })),
      vendorId: selectedVendorId || formData.vendorId,
      deliverTo: formData.deliverTo,
      vendorName: fetchedVendorName,
      customerName: fetchedCustomerName,
      purchaseNumber: formData.purchaseNumber,
      oldPurchaseNumber: oldPurchaseNumber,
      purchaseDate: dateValue || formData.purchaseDate,
      terms: formData.terms,
      dueDate: dateValue1 || formData.dueDate,
      salesPerson: formData.salesPerson,
      reference: formData.reference,
      subject: formData.subject,
      subtotal: calculateSubTotal(),
      total: calculateTotal(),
      value: discountAmountTcs,
      tcsTax: fetchedTcsTax?.value,
      adjustment: formData.adjustment,
      adjustmentLabel: formData.adjustmentLabel,
      overallDiscount: disValue || 0,
      totalDiscountType: discountType,
      totalDiscount: disAllValue || 0,
      customerNote: formData.customerNote,
      termsAndConditions: formData.termsAndConditions,
      taxCheck: bussinessRegistered,
      discountCheck: discountCheck,
      status: "Sent",
      manualCheck: manualCheck,
    };

    try {
      const response = await fetch(BASE_URL + "/updatePurchaseOrder", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        swal({
          icon: "success",
          title: `Updated Purchase Order`,
        });
        setIsLoading(false);
        props.handleCancel();
      } else {
        const responseData = await response.json();
        console.error(
          "Failed to update purchase order. Server response:",
          responseData
        );
        swal({
          icon: "error",
          title: `Failed to Update Purchase Order!`,
          text: responseData.responseMessage || "An error occurred",
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error: ", error);
      swal({
        icon: "error",
        title: `Error occurred while updating the purchase order`,
        text: error.message || "An unexpected error occurred",
      });
      setIsLoading(false);
    }
  };

  const insertDataIntoDatabaseAsDraft = async () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    let newValidationMessage = "";

    if (!formData.vendorName) {
      newValidationMessage += "Please select a vendor.\n";
    } else if (!formData.purchaseNumber) {
      newValidationMessage += "Please enter purchase number.\n";
    } else if (!formData.purchaseDate) {
      newValidationMessage += "Please select a purchase date.\n";
    }

    userDetails.forEach((item, index) => {
      if (item.itemDetails.trim() === "") {
        newValidationMessage += `Item name is required.\n`;
      }
      if (item.rate.trim() === "") {
        newValidationMessage += `Rate is required.\n`;
      } else if (item.rate.trim() === "0.0") {
        newValidationMessage += `Rate cannot be 0.0.\n`;
      }
      if (item.quantity.trim() === "") {
        newValidationMessage += `Quantity is required.\n`;
      }
      if (item.account === "") {
        newValidationMessage += `A valid account must be selected.\n`;
      }
    });

    setValidationMessage1(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true);

    const formDataObject = {
      uniqueId: decryptedUniqueId,
      ids: ids.map((id) => ({ id })),
      items: userDetails.map((user) => ({
        itemDetails: user.itemDetails,
        quantity: user.quantity,
        rate: user.rate || formData.rate,
        discount: user.discount || formData.discount,
        discountType: user.discountType || formData.discountType,
        tax: user.tax || formData.selectedTaxRate,
        amount: calculateAmount(user) || 0,
        account: user.account,
        oldQuantity: user.quantity || formData.quantity,
        description1: user.description1 || "",
        description2: user.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst: userState.state === customerState ? (calculateAmount(user) * (user.tax) / 100) / 2 : 0,
        sgst: userState.state === customerState ? (calculateAmount(user) * (user.tax) / 100) / 2 : 0,
        igst: userState.state !== customerState ? (calculateAmount(user) * (user.tax) / 100) : 0
      })),
      vendorId: selectedVendorId || formData.vendorId,
      deliverTo: formData.deliverTo,
      vendorName: fetchedVendorName,
      customerName: fetchedCustomerName,
      purchaseNumber: formData.purchaseNumber,
      oldPurchaseNumber: oldPurchaseNumber,
      purchaseDate: dateValue || formData.purchaseDate,
      terms: formData.terms,
      dueDate: dateValue1 || formData.dueDate,
      salesPerson: formData.salesPerson,
      reference: formData.reference,
      subject: formData.subject,
      subtotal: calculateSubTotal(),
      total: calculateTotal(),
      value: discountAmountTcs,
      tcsTax: fetchedTcsTax?.value,
      adjustment: formData.adjustment,
      adjustmentLabel: formData.adjustmentLabel,
      overallDiscount: disValue || 0,
      totalDiscountType: discountType,
      totalDiscount: disAllValue || 0,
      customerNote: formData.customerNote,
      termsAndConditions: formData.termsAndConditions,
      taxCheck: bussinessRegistered,
      discountCheck: discountCheck,
      status: "Draft",
      manualCheck: manualCheck,
    };

    try {
      const response = await fetch(BASE_URL + "/updatePurchaseOrder", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        swal({
          icon: "success",
          title: `Updated Purchase Order`,
        });
        setIsLoading(false);
        props.handleCancel();
      } else {
        const responseData = await response.json();
        console.error(
          "Failed to update purchase order. Server response:",
          responseData
        );
        swal({
          icon: "error",
          title: `Failed to Update Purchase Order!`,
          text: responseData.responseMessage || "An error occurred",
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error: ", error);
      swal({
        icon: "error",
        title: `Error occurred while updating the purchase order`,
        text: error.message || "An unexpected error occurred",
      });
      setIsLoading(false);
    }
  };


  const [itemName, setItemName] = useState("");
  const [showItemModal, setShowItemModal] = useState(false);

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const [itemDescriptions, setItemDescriptions] = useState([]);


  const handleItem = (event) => {
    event.preventDefault();

    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );

      if (existingIndex !== -1) {
        // Update existing description
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;
        return updatedDescriptions;
      } else {
        // Add new description
        return [...prevDescriptions, newItemDescription];
      }
    });

    setUserDetails((prevRows) =>
      prevRows.map((row) =>
        row.itemDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      )
    );

    setShowItemModal(false);
  };

  const handleItemShow = (itemDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: '' });
    }
    setItemName(itemDetails);
    setShowItemModal(true);
  };

  const fetchItemData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/getAllItemData`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);
  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...userDetails];
    newRow[index].itemDetails = value;
    setUserDetails(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...userDetails];
    newRow[index].itemDetails = value;
    setUserDetails(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...userDetails];
    newRow[index].itemDetails = selectedItem;
    setUserDetails(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const jwtToken = localStorage.getItem("jwtToken");
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const rate = data.data.salesPrice;
        const hsnSac = data.data.hsnCode;
        const description1 = data.data.description;
        const itemName = data.data.name;
        const updatedRow = [...userDetails];
        updatedRow[index].rate = rate;
        updatedRow[index].description1 = description1;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].itemDetails = itemName;
        setUserDetails(updatedRow);
        setUserDetails(updatedRow);
        setSearchResults([]);
        handleAdd();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...userDetails];
    updatedRow[index].tax = newTaxRate;
    setUserDetails(updatedRow);
    calculateTotal();
  };

  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);

    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  const [selectedTcsOption, setSelectedTcsOption] = useState(null);

  const tcs = [
    // { value: "0 GST [0%]", label: "0 GST [0%]",discount:0 },
    { value: "5 GST [5%]", label: "5 GST [5%]", discount: 5 },
    { value: "12 GST [12%]", label: "12 GST [12%]", discount: 12 },
    { value: "18 GST [18%]", label: "18 GST [18%]", discount: 18 },
    { value: "28 GST [28%]", label: "28 GST [28%]", discount: 28 },
  ];

  const [discountAmountTcs, setDiscountAmountTcs] = useState(0.0);

  const applyDiscountTcs = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tcsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTcs(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state

    return tcsDis;
  };

  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
    setFetchedTcsTax(selectedOption);
    const subtotal = calculateSubTotal();

    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedOption.discount
      );
      console.log(
        `Subtotal: ${subtotal}, Discounted Total: ${discountedTotal}`
      );
    }
  };

  function convertDateStringToDate(dateString) {
    if (!dateString) {
      return null;
    }
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  const dateObject = convertDateStringToDate(formData.purchaseDate);

  const [showDueDate, setShowDueDate] = useState(true);
  const [showDuePerTerm, setShowDuePerTerm] = useState(false);

  const updateDueDate = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(
        normalDateFormat(
          addDays(showDuePerTerm == true ? startDate : dateObject, daysToAdd)
        )
      );
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(
        normalDateFormat(
          endOfMonth(showDuePerTerm == true ? startDate : dateObject)
        )
      );
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(
        normalDateFormat(
          endOfMonth(
            addMonths(showDuePerTerm == true ? startDate : dateObject, 1)
          )
        )
      );
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(
        normalDateFormat(showDuePerTerm == true ? startDate : dateObject)
      );
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const updateDueDate1 = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(date, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(date)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(date, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(date));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  useEffect(() => {
    if (currentDate && selectedTerms && !dueDateChangedManually) {
      updateDueDate(new Date(currentDate), selectedTerms);
    }
  }, [currentDate, selectedTerms, dueDateChangedManually]);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");
  const [dateValue1, setDateValue1] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    setFormData({ ...formData, terms: selectedTerms || formData.terms });
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
    updateDueDate1(date, selectedTerms || formData.terms);
  };

  const handleDate1 = (date) => {
    setStartDate1(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue1(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const vendorOptions = vendorNames.map((names) => ({
    label: names.vendorDisplayName,
    value: names.vendorDisplayName,
  }));

  const optionsCustomers = custNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));

  const optionPaymentTerms = [
    { value: "Net 15", label: "Net 15" },
    { value: "Net 30", label: "Net 30" },
    { value: "Net 45", label: "Net 45" },
    { value: "Net 60", label: "Net 60" },
    { value: "Due End Of The Month", label: "Due End Of The Month" },
    { value: "Due End Of Next Month", label: "Due End Of Next Month" },
    { value: "Due On Receipt", label: "Due On Receipt" },
    { value: "Custom", label: "Custom" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
    setFetchedPaymentTerms(selectedOption.value);
    updateDueDate(dateValue, selectedOption.value);
  };

  const handleCreateVendor = (event) => {
    // setSelectedOption(event.target.value); // Update the selected option when the dropdown changes
    const { name, value } = event.target;
    setFormDataVendor((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const applyDiscount = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tdsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTds(discountAmount.toFixed(2)); // Set discountAmount in state

    return tdsDis;
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      deliverTo: value,
    }));
    // setDilvTo(value === "Customer");
  };

  const fetchAllVendors = () => {
    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setVendorNames(data.responseMessage);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  };

  useEffect(() => {
    fetchAllVendors();
  }, []);

  useEffect(() => {
    if (fetchedVendorName) {
      const selectedCustomerObject = vendorNames.find(
        (person) => person.vendorDisplayName === fetchedVendorName
      );

      if (selectedCustomerObject) {
        const customerState = selectedCustomerObject.billingState;
        console.log("customerState : ", customerState);
        setCustomerState(customerState);
      }
    }
  }, [fetchedVendorName, vendorNames]);


  const customStyles2 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "10px",
      // minheight: "38px",
      height: "20px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "20px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      // height: "38px",
    }),
  };
  const [formDataVendor, setFormDataVendor] = useState({
    uniqueId: decryptedUniqueId,
    primaryContact: "Mr.",
    companyName: "",
    vendorDisplayName: "",
    vendorEmail: "",
    vendorPhone1: "",
    vendorPhone2: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "",
    paymentTerms: "Net 15",
    facebook: "",
    twitter: "",
    billingAttention: "",
    billingCountry: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZipcode: "",
    billingPhone: "",
    shipAttention: "",
    shipCountry: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipcode: "",
    shipPhone: "",
    sourceOfSupply: "[MH] - Maharashtra",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        firstName: "",
        lastName: "",
        email: "",
        workphone: "",
        mobile: "",
      },
    ],
  });
  const [showNewVendor, setShowNewVendor] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const handleNewVendorClose = () => setShowNewVendor(false);
  const handleNewVendorShow = () => setShowNewVendor(true);
  const handleAddVendor = () => {
    let newValidationMessage = "";

    if (!formDataVendor.vendorDisplayName) {
      newValidationMessage += "Please enter a vendor display name.\n";
    } else if (
      formDataVendor.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formDataVendor.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }
    setValidationMessage(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + "/insertVendor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formDataVendor),
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Vendor Created",
            text: "",
          });
          setShowNewVendor(false);
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Vendor!",
            text: "",
          });
          setShowNewVendor(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShowNewVendor(true);
      });
  };

  const generateNamePairs = () => {
    const { primaryContact, firstName, lastName, companyName } = formDataVendor;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${primaryContact} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  useEffect(() => {
    if (selectedTcsOption && selectedTcsOption.discount) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedTcsOption.discount
      );
    }
  }, [userDetails]);

  return (
    <>
      <Row>
        <Col>
          <div className="my-3">
            <div className="d-flex align-items-center ml-1 mt-4">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  Update Purchase Order
                </h5>
              </div>
            </div>
          </div>
          {validationMessage1 && (
            <Alert severity="error" className="mt-0">
              {validationMessage1.split("\n").map((message, index) => (
                <div key={index}>{message}</div>
              ))}
            </Alert>
          )}{" "}
          <Card className="card-style p-4">
            {/* <Card.Body> */}
            <Row>
              <Col className="" md="3">
                <Form.Group>
                  <label
                    className="text-primary"
                    style={{ fontSize: 16, fontWeight: "600" }}
                  >
                    VENDOR INFO
                  </label>
                </Form.Group>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col className="" md="3">
                  <Form.Group>
                    <label
                      className="label-title"
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Vendor Name <span className="valid"> *</span>
                    </label>
                    <Select
                      value={vendorOptions.find(
                        (option) => option.value === fetchedVendorName
                      )}
                      onChange={handleVendorChange}
                      options={vendorOptions}
                      placeholder="Select a Vendor"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />
                    <button
                      type="button"
                      class="btn p-0"
                      onClick={handleNewVendorShow}
                    >
                      <span
                        class="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        New Vendor
                      </span>
                    </button>

                    <Modal
                      size="lg"
                      show={showNewVendor}
                      onHide={handleNewVendorClose}
                    >
                      <div class="modal-header d-flex align-items-center pb-2 pt-2">
                        <h5 class="modal-title fs-5" id="newcustomerPopup">
                          New Vendor
                        </h5>
                        <button
                          type="button"
                          className="btn pr-0"
                          onClick={handleNewVendorClose}
                        >
                          <ion-icon
                            name="close-circle-outline"
                            size="large"
                            style={{ width: "28px" }}
                          ></ion-icon>
                        </button>
                      </div>
                      <Modal.Body className="p-0 pb-3">
                        {validationMessage && (
                          <Alert severity="error" className="mt-0">
                            {validationMessage
                              .split("\n")
                              .map((message, index) => (
                                <div key={index}>{message}</div>
                              ))}
                          </Alert>
                        )}{" "}
                        <div className="container">
                          <div className="row d-inline-flex">
                            <div className="modalinputchild">
                              <div className="text-style"> Salutation </div>
                              <select
                                className="form-control"
                                name="primaryContact"
                                value={formDataVendor.primaryContact}
                                onChange={handleCreateVendor}
                                style={{
                                  borderRadius: "9px",
                                  backgroundColor: "#f5f5f5",
                                  width: "200px",
                                  height: "35px",
                                  fontSize: "13px",
                                  marginTop: "10px",
                                }}
                              >
                                <option value="Mr.">Mr.</option>
                                <option value="Mrs.">Mrs.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Miss.">Miss.</option>
                                <option value="Dr.">Dr.</option>
                              </select>
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">First Name</div>
                              <input
                                className="form-control"
                                placeholder="First Name"
                                name="firstName"
                                value={formDataVendor.firstName}
                                onChange={handleCreateVendor}
                                id="firstName"
                                type="text"
                              />
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Last Name</div>
                              <input
                                className="form-control"
                                placeholder="Last Name"
                                id="lastName"
                                name="lastName"
                                value={formDataVendor.lastName}
                                onChange={handleCreateVendor}
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="row d-inline-flex pt-2">
                            <div className="modalinputchild">
                              <div className="text-style"> Vendor Email</div>
                              <input
                                className="form-control"
                                placeholder="Customer Email"
                                name="vendorEmail"
                                type="text"
                                value={formDataVendor.vendorEmail}
                                onChange={handleCreateVendor}
                              />
                            </div>

                            <div className="modalinputchild">
                              <div className="text-style">
                                {" "}
                                Vendor Display Name{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                              </div>
                              <input
                                className="form-control"
                                placeholder="Vendor Display Name"
                                list="vendorDisplayName"
                                name="vendorDisplayName"
                                value={formDataVendor.vendorDisplayName}
                                onChange={handleCreateVendor}
                                style={{
                                  borderRadius: "7px",
                                  backgroundColor: "#f5f5f5",
                                  width: "200px",
                                  height: "40px",
                                  fontSize: "13px",
                                  marginTop: "10px",
                                }}
                              />
                              <datalist id="vendorDisplayName">
                                {generateNamePairs().map((pair, index) => (
                                  <option key={index} value={pair} />
                                ))}
                              </datalist>{" "}
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Company Name</div>
                              <input
                                className="form-control"
                                placeholder="Add company name"
                                name="companyName"
                                type="text"
                                value={formDataVendor.companyName}
                                onChange={handleCreateVendor}
                              />
                            </div>
                          </div>
                          <div className="row d-inline-flex pt-2">
                            <div className="modalinputchild">
                              <div className="text-style">Vendor Phone</div>
                              <input
                                className="form-control"
                                placeholder="Work Phone"
                                name="vendorPhone1"
                                type="text"
                                value={formDataVendor.vendorPhone1}
                                onChange={handleCreateVendor}
                              />
                            </div>
                            <div className="modalinputchild">
                              <div className="text-style">Mobile</div>
                              <input
                                className="form-control"
                                placeholder="Mobile"
                                name="vendorPhone2"
                                type="text"
                                value={formDataVendor.vendorPhone2}
                                onChange={handleCreateVendor}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="p-2">
                        <div className="modal-bottombtns">
                          <button
                            type="button"
                            class="modalbtn-1 btn mt-0"
                            onClick={handleNewVendorClose}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="modalbtn-3 btn btn-primary"
                            onClick={handleAddVendor}
                          >
                            Create Vendor
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Deliver To
                    </label>
                    <div className="mt-2">
                      <label className="rdiobox d-inline-flex align-items-center">
                        <input
                          className="mr-1"
                          type="radio"
                          name="cus_type1"
                          id="cus_bus1"
                          value="Organization"
                          checked={formData.deliverTo === "Organization"}
                          onChange={handleRadioChange}
                        />
                        <span>Organization</span>
                      </label>
                      <label className="rdiobox ml-3 d-inline-flex align-items-center">
                        <input
                          className="mx-1"
                          type="radio"
                          name="cus_type1"
                          id="cus_indivi1"
                          value="Customer"
                          checked={formData.deliverTo === "Customer"}
                          onChange={handleRadioChange}
                        />
                        <span>Customer</span>
                      </label>
                    </div>
                  </Form.Group>
                </Col>
                {formData.deliverTo === "Customer" && (
                  <Col md="3">
                    <div className="form-group mb-0">
                      <label
                        htmlFor="customerSelect"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Select Customer
                      </label>
                      <Select
                        options={optionsCustomers}
                        value={optionsCustomers.find(
                          (option) => option.value === fetchedCustomerName
                        )}
                        onChange={handleCustomerChange} // Passing the function directly
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            height: "46px",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            height: "19px",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              <Row className="mt-3">
                <Col md="3">
                  <Form.Group>
                    <label
                      className=""
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Purchase Number<span className="valid"> *</span>
                    </label>
                    <Form.Control
                      placeholder="Purchase Number"
                      type="text"
                      name="purchaseNumber"
                      value={formData.purchaseNumber}
                      onChange={handleInputChange}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "38px",
                        "::placeholder": {
                          color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                        },
                      }}
                    />
                    <button
                      type="button"
                      className="btn p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#generatePop"
                    >
                      <span
                        className="text-primary"
                        style={{ fontSize: "12px", fontWeight: "500" }}
                      >
                        Generate
                      </span>
                    </button>

                    {/* Invoice generate popup*/}
                    <div
                      className="modal fade"
                      id="generatePop"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header d-flex align-items-center pb-2 pt-2">
                            <h5
                              className="modal-title fs-5"
                              id="generateinvoiceHead"
                            >
                              Purchase Order Number
                            </h5>
                            <button
                              type="button"
                              className="btn pr-0"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <ion-icon
                                name="close-circle-outline"
                                size="large"
                                style={{ width: "28px" }}
                              ></ion-icon>
                            </button>
                          </div>
                          <div className="modal-body pt-1 pb-1">
                            <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                              <div className="row headline">
                                <div className="customer-headline-label">
                                  <label className="form-label">
                                    <span className="headline">
                                      Your purchase order numbers are set on
                                      auto-generate mode to save your time.
                                      <br /> Are you sure about changing this
                                      setting?
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <label className="customer-rdiobox-label d-flex align-items-center">
                                  <input
                                    className="mr-2"
                                    type="radio"
                                    name="cus_type"
                                    id="cus_bus"
                                    value="Business"
                                    checked={show1 === true ? true : false}
                                    onClick={setBill}
                                  />
                                  <span className="customer-option">
                                    Continue auto-generating purchase order
                                    numbers
                                  </span>
                                </label>
                              </div>
                              {show1 && (
                                <div className="row ml-3 mb-3">
                                  <div className="customer-generate-inputfield">
                                    <span>Prefix</span>
                                    <input
                                      className="form-control mt-1"
                                      placeholder="Prefix"
                                      value={invoiceNumberPrefix}
                                      onChange={(e) =>
                                        setInvoiceNumberPrefix(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="customer-generate-inputfield2">
                                    <span>Next Number</span>
                                    <input
                                      className="form-control mt-1"
                                      placeholder="Next Number"
                                      value={invoiceNumberNext}
                                      onChange={(e) =>
                                        setInvoiceNumberNext(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row mt-1">
                                <label className="customer-rdiobox-label d-flex align-items-center">
                                  <input
                                    className="mr-2"
                                    type="radio"
                                    name="cus_type"
                                    id="cus_bus"
                                    value="Business"
                                    checked={show1 === false ? true : false}
                                    onClick={() => {
                                      setShow1(false);
                                    }}
                                  />
                                  <span className="customer-option">
                                    I will add them manually each time
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <div className="modal-bottombtns">
                              <button
                                type="button"
                                class="modalbtn-1 btn mt-0"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="modalbtn-3 btn btn-primary"
                                onClick={() => {
                                  copyAdd();
                                }}
                                data-bs-dismiss="modal"
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Reference
                    </label>
                    <Form.Control
                      placeholder="Reference"
                      name="reference"
                      value={formData.reference}
                      onChange={handleInputChange}
                      type="text"
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        color: "black",
                        height: "38px",
                        "::placeholder": {
                          color: "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                        },
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Purchase Date <span className="valid"> *</span>
                    </label>
                    {showInvDate ? (
                      <input
                        className="form-control"
                        type="text"
                        value={formData.purchaseDate}
                        onClick={(e, date) => {
                          setShowInvDate(false);
                          setFormData({
                            ...formData,
                            purchaseDate: normalDateFormat(startDate),
                          });
                          handleDate(startDate);
                          setShowDuePerTerm(true);
                        }}
                        style={{
                          padding: "12px",
                          backgroundColor: "#F6F6F6",
                          borderRadius: "10px",
                          color: "black",
                        }}
                        readOnly
                      />
                    ) : (
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => handleDate(date)}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            borderRadius: "10px",
                            color: "black",
                          }}
                        />
                      </div>
                    )}
                    {/* </div> */}
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Payment Terms
                    </label>
                    <Select
                      // className="dropdown-field"
                      options={optionPaymentTerms}
                      value={optionPaymentTerms.find(
                        (option) => option.value === fetchedPaymentTerms
                      )}
                      onChange={handleChange}
                      placeholder="Select Terms"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      Due Date <span className="valid"> *</span>
                    </label>

                    {showDueDate ? (
                      <input
                        className="form-control mt-0"
                        type="text"
                        value={dueDate || formData.dueDate}
                        onClick={(e) => {
                          setShowDueDate(false);
                          setFetchedPaymentTerms("Custom");
                          setFormData({ ...formData, terms: "Custom" });
                        }}
                        style={{
                          padding: "12px",
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          color: "black",
                          height: "38px",
                          "::placeholder": {
                            color:
                              "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                          },
                        }}
                        readOnly
                      />
                    ) : (
                      <div className="customDatePickerWidth">
                        <DatePicker
                          className="form-control"
                          selected={startDate1}
                          dateFormat="dd-MM-yyyy"
                          onChange={(date) => handleDate1(date)}
                          placeholderText="Select due date"
                          showIcon
                          toggleCalendarOnIconClick
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="mt-3" md="3">
                  <Form.Group>
                    <label
                      className="text-primary"
                      style={{ fontSize: 14, fontWeight: "600" }}
                    >
                      ITEM INFO
                    </label>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="mb-3">
                    {/* <div className="table-responsive"> */}
                    <table className="table table-bordered mb-0">
                      <thead className="text-center">
                        <tr>
                          <th scope="col" style={{ color: "grey" }}>
                            ITEM DETAILS
                          </th>
                          <th scope="col" style={{ color: "grey" }}>
                            ACCOUNT
                          </th>
                          <th scope="col" style={{ color: "grey" }}>
                            SIZE
                          </th>
                          <th scope="col" style={{ color: "grey" }}>
                            QUANTITY
                          </th>
                          <th scope="col" style={{ color: "grey" }}>
                            RATE
                          </th>
                          {discountCheck === "YES" && (
                            <th scope="col" style={{ color: "grey" }}>
                              DISCOUNT
                            </th>
                          )}{" "}
                          {bussinessRegistered === "YES" && (
                            <th scope="col" style={{ color: "grey" }}>
                              TAX
                            </th>
                          )}
                          <th scope="col" style={{ color: "grey" }}>
                            AMOUNT
                          </th>
                          <th scope="col" style={{ color: "grey" }}>
                            ACTION
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userDetails.map((data, i) => {
                          const amount = calculateAmount(data, data.tax);
                          const totalAmountWithTax = parseFloat(amount);
                          const itemDescription = itemDescriptions.find(
                            (desc) => desc.itemDetails === data.itemDetails
                          );
                          return (
                            <tr key={i}>
                              <td style={{ width: "35%" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <input
                                    className="form-control noBorder"
                                    placeholder="Search items..."
                                    value={data.itemDetails}
                                    onChange={(e) => handleItemDetailsChange(e, i)}
                                    style={{ flex: 1 }}
                                  />
                                  {data.itemDetails && (
                                    <button
                                      type="button"
                                      className="btn p-0"
                                      onClick={() => handleItemShow(data.itemDetails)}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <span
                                        className="text-primary"
                                        style={{ fontSize: "12px", fontWeight: "500" }}
                                      >
                                        Edit
                                      </span>
                                    </button>
                                  )}
                                </div>
                                {itemDescription && (
                                  <div style={{ marginTop: "10px", color: "#6c757d",whiteSpace: "pre-line" }}>
                                    {itemDescription.description}
                                  </div>
                                )}
                                {searchPerformed &&
                                  data.itemDetails &&
                                  i === searchIndex && (
                                    <div
                                      style={{
                                        position: "relative",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {searchResults.length === 0 && ""}
                                      {searchResults.length > 0 && (
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            padding: 0,
                                            position: "absolute",
                                            top: "calc(100% + 10px)",
                                            left: 0,
                                            zIndex: 999,
                                            backgroundColor: "#FFF",
                                            boxShadow:
                                              "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                            borderRadius: "5px",
                                            width: "100%",
                                          }}
                                        >
                                          {searchResults.map(
                                            (result, index) => (
                                              <li
                                                key={index}
                                                onClick={() =>
                                                  handleItemSelect(
                                                    result.name,
                                                    result.id,
                                                    i
                                                  )
                                                }
                                                onMouseEnter={(e) => {
                                                  e.target.style.backgroundColor =
                                                    "#408dfb";
                                                  e.target.style.color =
                                                    "#FFF";
                                                  e.target.style.borderRadius =
                                                    "5px";
                                                }}
                                                onMouseLeave={(e) => {
                                                  e.target.style.backgroundColor =
                                                    "";
                                                  e.target.style.color =
                                                    "#000";
                                                  e.target.style.borderRadius =
                                                    "";
                                                }}
                                                style={{
                                                  padding: "12px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {result.name}
                                                <br />
                                                Rate : ₹{result.salesPrice}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  )}
                              </td>

                              <Modal
                                show={showItemModal}
                                onHide={handleItemClose}
                              >
                                <div class="modal-header d-flex align-items-center pb-2 pt-2">
                                  <h5
                                    class="modal-title fs-5"
                                    id="salespersonHead"
                                  >
                                    Add Item Description
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn pr-0"
                                    onClick={handleItemClose}
                                  >
                                    <ion-icon
                                      name="close-circle-outline"
                                      size="large"
                                      style={{ width: "28px" }}
                                    ></ion-icon>
                                  </button>
                                </div>
                                <Modal.Body>
                                    <Row>
                                          <Col md="12">
                                            <div className="row pt-2">
                                              <div className="col-lg-6">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "grey",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  Item
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 pl-0 pr-0">
                                              <input
                                                className="form-control"
                                                placeholder="Name"
                                                id="name"
                                                name="name"
                                                type="text"
                                                value={itemName}
                                                readOnly
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <div className="row pt-2">
                                              <div className="col-lg-6">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "grey",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  Description
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 pl-0 pr-0">
                                              <textarea
                                                className="cn-textarea"
                                                type="text"
                                                placeholder="Item Description"
                                                name="description"
                                                value={itemData.description}
                                                onChange={handleItemChange}
                                                rows={2}
                                                style={{
                                                  backgroundColor: "#F6F6F6",
                                                  borderRadius: "9px",
                                                  padding: "12px",
                                                  width: '100%'
                                                }}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                  </Modal.Body>
                                <Modal.Footer className="p-2">
                                  <div className="modal-bottombtns">
                                    <button
                                      type="button"
                                      class="modalbtn-1 btn mt-0"
                                      onClick={handleItemClose}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      class="modalbtn-3 btn btn-primary"
                                      onClick={handleItem}
                                      data-bs-dismiss="modal"
                                    >
                                      Add Description
                                    </button>
                                  </div>
                                </Modal.Footer>
                              </Modal>

                              <td>
                                <select
                                  className="form-control"
                                  name="account"
                                  value={data.account}
                                  onChange={(e) => handleInputChangess(e, i)}
                                >
                                  <option
                                    disabled="true"
                                    style={{ color: "black" }}
                                  >
                                    Other Current Assets
                                  </option>
                                  <option value="Advance Tax">
                                    Advance Tax
                                  </option>
                                  <option value="Employee Advance">
                                    Employee Advance
                                  </option>
                                  <option value="Input Tax Credits">
                                    Input Tax Credits
                                  </option>
                                  <option value="Input CGST">
                                    {" "}
                                    - Input CGST
                                  </option>
                                  <option value="Input IGST">
                                    {" "}
                                    - Input IGST
                                  </option>
                                  <option value="Input SGST">
                                    {" "}
                                    - Input SGST
                                  </option>
                                  <option value="Prepaid Expenses">
                                    Prepaid Expenses
                                  </option>
                                  <option value="Reverse Charge Tax Input but not due">
                                    Reverse Charge Tax Input but not due
                                  </option>
                                  <option value="TDS Receivable">
                                    TDS Receivable
                                  </option>
                                  <option
                                    disabled="true"
                                    style={{ color: "black" }}
                                  >
                                    Fixed Assets
                                  </option>
                                  <option value="Furniture and Equipment">
                                    Furniture and Equipment
                                  </option>
                                  <option
                                    disabled="true"
                                    style={{ color: "black" }}
                                  >
                                    Other Current Liability
                                  </option>
                                  <option value="Employee Reimbursements">
                                    Employee Reimbursements
                                  </option>
                                  <option value="GST Payable">
                                    GST Payable
                                  </option>
                                  <option value="Output CGST">
                                    {" "}
                                    - Output CGST
                                  </option>
                                  <option value="Output IGST">
                                    {" "}
                                    - Output IGST
                                  </option>
                                  <option value="Output SGST">
                                    {" "}
                                    - Output SGST
                                  </option>
                                  <option value="Opening Balance Adjustments">
                                    Opening Balance Adjustments
                                  </option>
                                  <option value="Tax Payable">
                                    Tax Payable
                                  </option>
                                  <option value="TDS Payable">
                                    TDS Payable
                                  </option>
                                  <option value="Unearned Revenue">
                                    Unearned Revenue
                                  </option>
                                  <option
                                    disabled="true"
                                    style={{ color: "black" }}
                                  >
                                    Expense
                                  </option>
                                  <option value="Advertising And Marketing">
                                    Advertising And Marketing
                                  </option>
                                  <option value="Automobile Expense">
                                    Automobile Expense
                                  </option>
                                  <option value="Bad Debt">Bad Debt</option>
                                  <option value="Bank Fees and Charges">
                                    Bank Fees and Charges
                                  </option>
                                  <option value="Consultant Expense">
                                    Consultant Expense
                                  </option>
                                  <option value="Contract Assets">
                                    Contract Assets
                                  </option>
                                  <option value="Credit Card Charges">
                                    Credit Card Charges
                                  </option>
                                  <option value="Depreciation And Amortisation">
                                    Depreciation And Amortisation
                                  </option>
                                  <option value="Depreciation Expense">
                                    Depreciation Expense
                                  </option>
                                  <option value="IT and Internet Expenses">
                                    IT and Internet Expenses
                                  </option>
                                  <option value="Janitorial Expense">
                                    Janitorial Expense
                                  </option>
                                  <option value="Lodging">Lodging</option>
                                  <option value="Meals and Entertainment">
                                    Meals and Entertainment
                                  </option>
                                  <option value="Merchandise">
                                    Merchandise
                                  </option>
                                  <option value="Office Supplies">
                                    Office Supplies
                                  </option>
                                  <option value="Other Expenses">
                                    Other Expenses
                                  </option>
                                  <option value="Postage">Postage</option>
                                  <option value="Printing and Stationery">
                                    Printing and Stationery
                                  </option>
                                  <option value="Raw Materials And Consumables">
                                    Raw Materials And Consumables
                                  </option>
                                  <option value="Rent Expense">
                                    Rent Expense
                                  </option>
                                  <option value="Repairs and Maintenance">
                                    Repairs and Maintenance
                                  </option>
                                  <option value="Salaries and Employee Wages">
                                    Salaries and Employee Wages
                                  </option>
                                  <option value="Telephone Expense">
                                    Telephone Expense
                                  </option>
                                  <option value="Transportation Expense">
                                    Transportation Expense
                                  </option>
                                  <option value="Travel Expense">
                                    Travel Expense
                                  </option>
                                  <option value="Uncategorized">
                                    Uncategorized
                                  </option>
                                  <option
                                    value=""
                                    disabled=""
                                    style={{ color: "black" }}
                                  >
                                    Cost Of Good Sold
                                  </option>
                                  <option value="Cost of Goods Sold">
                                    Cost of Goods Sold
                                  </option>
                                  <option value="Job Costing">
                                    Job Costing
                                  </option>
                                  <option value="Labor">Labor</option>
                                  <option value="Materials">Materials</option>
                                  <option value="Subcontractor">
                                    Subcontractor
                                  </option>
                                </select>
                              </td>

                              <td style={{ width: "10%" }}>
                                <input
                                  className="form-control noArrowNumber noBorder"
                                  value={data.size || ""}
                                  type="number"
                                  name="size"
                                  onChange={(e) => handleInputChangess(e, i)}
                                />
                              </td>

                              <td style={{ width: "10%" }}>
                                <input
                                  className="form-control noArrowNumber noBorder"
                                  value={data.quantity || ""}
                                  type="number"
                                  name="quantity"
                                  onChange={(e) => handleInputChangess(e, i)}
                                />
                              </td>

                              <td style={{ width: "10%" }}>
                                <input
                                  className="form-control noArrowNumber noBorder"
                                  value={data.rate}
                                  name="rate"
                                  type="number"
                                  onChange={(e) => handleInputChangess(e, i)}
                                />
                              </td>
                              {discountCheck === "YES" && (
                                <td style={{ width: "10%", padding: "0px" }}>
                                  <div className="d-flex justify-content-center">
                                    <input
                                      className="form-control col-md-6 noBorder"
                                      value={data.discount || ""}
                                      type="number"
                                      onChange={(e) =>
                                        handleInputChangess(e, i)
                                      }
                                      name="discount"
                                      style={{
                                        textAlign: "center",
                                        padding: "0px",
                                        marginRight: "5px",
                                      }}
                                    />
                                    <select
                                      className="border-0 noBorder"
                                      value={data.discountType}
                                      onChange={(e) =>
                                        handleInputChangess(e, i)
                                      }
                                      name="discountType"
                                    >
                                      <option value="₹">₹</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </td>
                              )}
                              {bussinessRegistered === "YES" && (
                                <td style={{ width: "10%" }}>
                                  <select
                                    className="form-control"
                                    value={data.tax}
                                    onChange={(e) => handleTaxChange(e, i)}
                                  >
                                    <option value="0">Not Applicable</option>
                                    <option value="5">5 GST [5%]</option>
                                    <option value="12">12 GST [12%]</option>
                                    <option value="18">18 GST [18%]</option>
                                    <option value="28">28 GST [28%]</option>
                                  </select>
                                </td>
                              )}
                              <td style={{ width: "10%" }}>
                                <span>
                                  {parseFloat(totalAmountWithTax).toFixed(2)}
                                </span>
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "7%" }}
                              >
                                <button
                                  type="button"
                                  className="btn px-2 btn-light icon-border btn-sm"
                                  onClick={() => handleDeleteRow(i)}
                                >
                                  <i className="fa fa-trash text-danger"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* </div> */}
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <button
                    className="btn addline-btn"
                    type="button"
                    onClick={handleAdd}
                  >
                    + Add New Row
                  </button>
                </Col>
              </Row>

              {/* endtable */}

              <div className="grid-container">
                <div className="flex-item d-flex flex-column-reverse p-0">
                  <div className="row pt-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Notes
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <textarea
                        className="cn-textarea"
                        placeholder="Customer Notes..."
                        name="customerNote"
                        value={formData.customerNote}
                        onChange={handleInputChange}
                        type="text"
                        rows={1}
                        style={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          padding: "12px",
                        }}
                      />
                      <i className="text-muted">
                        This note will be displayed on the invoice
                      </i>
                    </div>
                  </div>
                </div>

                <div className="customer-subtotal-box p-2">
                  <div className="customer-flex-item">
                    <div className="row">
                      <div className="col-lg-8">
                        <label className="form-label">Sub Total</label>
                      </div>
                      <div className="col-lg-4 text-end pl-0">
                        <label className="form-label">
                          {calculateSubTotal()}
                        </label>
                      </div>
                    </div>

                    {discountCheck === "YES" && (
                      <div className="row d-flex align-items-baseline mt-1">
                        <div className="col-lg-5 pr-0">
                          <label>Discount</label>
                        </div>
                        <div className="col-lg-3 pl-0 d-flex">
                          <input
                            className="form-control"
                            value={disAllValue || "0"}
                            type="number"
                            name="discount"
                            onChange={handleDisAllValueChange}
                            style={{
                              height: "36px",
                              backgroundColor: "#F6F6F6",
                            }}
                          />
                          <select
                            className="form-control ml-1 pl-0 rounded"
                            name="discountType"
                            value={discountType}
                            onChange={handleDiscountTypeChange}
                            style={{
                              height: "44px",
                              width: "40px",
                              padding: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          >
                            <option disabled>Select</option>
                            <option value="₹">₹</option>
                            <option value="%">%</option>
                          </select>
                        </div>
                        <div className="col-lg-4 text-end pl-0">
                          <label className="form-label textColor-red">
                            {isNaN(parseFloat(disValue))
                              ? "0.00"
                              : -parseFloat(disValue).toFixed(2)}
                          </label>
                        </div>
                      </div>
                    )}
                    {bussinessRegistered === "YES" && (
                      <div className="row mt-2">

                        {userState.state !== customerState && (
                          <>
                            {calculateIGSTForTax().map((taxResult, index) => (
                              <React.Fragment key={index}>
                                <div className="col-lg-8">
                                  <label className="form-label required">
                                    IGST ({(taxResult.taxRate * 100).toFixed(0)}
                                    %)
                                  </label>
                                </div>
                                <div className="col-lg-4 text-end pl-0">
                                  <label className="form-label required">
                                    {taxResult.totalIGST.toFixed(2)}
                                  </label>
                                </div>
                              </React.Fragment>
                            ))}
                          </>
                        )}

                        {userState.state === customerState && (
                          <>
                            {Object.entries(calculateGroupedSGSTandCGST()).map(
                              ([taxRate, taxData], index) => (
                                <React.Fragment key={index}>
                                  <div className="col-lg-8">
                                    <label className="form-label required">
                                      CGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0+$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0">
                                    <label className="form-label required">
                                      {taxData.totalCGST.toFixed(2)}
                                    </label>
                                  </div>
                                  <div className="col-lg-8 mt-2">
                                    <label className="form-label required">
                                      SGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0+$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0 mt-2">
                                    <label className="form-label required">
                                      {taxData.totalSGST.toFixed(2)}
                                    </label>
                                  </div>
                                </React.Fragment>
                              )
                            )}
                          </>
                        )}

                      </div>
                    )}
                    <div className="row mt-3 mb-3">
                      <div className="col-lg-5 pr-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Adjustment" // Provide a placeholder for user input
                          value={formData.adjustmentLabel}
                          onChange={handleInputChange}
                          name="adjustmentLabel"
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            color: "black",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-5 pl-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Adjustment Value"
                          name="adjustment"
                          value={formData.adjustment}
                          onChange={handleInputChange}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "10px",
                            color: "black",
                            "::placeholder": {
                              color:
                                "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-2 text-end pl-0">
                        <label className="form-label mt-2">
                          {oppositeValue || formData.adjustment}
                        </label>
                      </div>
                    </div>

                    <div className="my-4 d-flex justify-content-between">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ width: "70%" }}
                      >
                        <div className="col-lg-7 pl-0">
                          <label className="rdiobox">
                            <span>TCS</span>
                          </label>
                        </div>
                        <div
                          className="col-lg-6 p-0"
                          style={{ width: "190px" }}
                        >
                          <Select
                            options={tcs}
                            value={tcs.find(
                              (option) => option.value === fetchedTcsTax
                            )}
                            onChange={handleTcsOptionChange}
                            placeholder="Select TCS..."
                            styles={customStyles2}
                          />
                        </div>
                      </div>
                      <label className="form-label">{discountAmountTcs}</label>
                    </div>

                    <hr />
                    <div className="d-flex justify-content-between">
                      <label className="form-label required ">
                        Total (in Rupees) :
                      </label>
                      <label className="form-label required">
                        {calculateTotal()}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-4" />

              <Row>
                <Col md="12">
                  <div className="row pt-2">
                    <div className="col-lg-6">
                      <label
                        className="form-label required mg-b-0"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Terms & Conditions
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 pr-0">
                    <textarea
                      className="invoicetextarea"
                      placeholder="Add your terms & condition"
                      name="termsAndConditions"
                      value={formData.termsAndConditions || ""}
                      onChange={handleInputChange}
                      rows={4}
                      style={{
                        backgroundColor: "#F6F6F6",
                        borderRadius: "9px",
                        padding: "12px",
                        height: "100%",
                      }}
                    />
                     <i className="text-muted">
                        This Terms and Condition will be displayed on the purchase order
                      </i>
                  </div>
                </Col>
              </Row>

              <div className="text-right invoice-bottombtns">
                <button
                  type="button"
                  className="btn-1 btn mt-0"
                  onClick={handleCancleClick}
                >
                  Cancel
                </button>
                {props.fetchedStatus === "Draft" && (
                  <button
                    type="button"
                    className="btn-1 btn mt-0"
                    onClick={insertDataIntoDatabaseAsDraft}
                  >
                    {isLoading2 ? "Saving..." : "Save as Draft"}
                  </button>)}
                <button
                  type="button"
                  className="btn-3 btn btn-primary"
                  onClick={insertDataIntoDatabase}
                >
                  {isLoading ? "Updating..." : "Update Order"}
                </button>
              </div>
              <div className="clearfix"></div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PurchaseOrdersUpdate;
