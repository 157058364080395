import React ,{useEffect ,useState,useRef}from 'react'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./VendorBalancesSummary.css";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ReactToPrint from "react-to-print";
import Select, { components } from "react-select"; // Import components from react-select
import DatePicker from "react-datepicker"; // Import DatePicker for manual date selection
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles

export default function VendorBalancesSummary({navigation}) {
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancelClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
    handleNavigation("reports")
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const [itemData, setItemData] = useState([]);
  const [totalBalance ,setTotalBalance] = useState("")
  const [totalAmount , setTotalAmount] = useState("")
  const [totalAmountPaid , setTotalAmountPaid] = useState("")
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const jwtToken = localStorage.getItem("jwtToken");
  const decryptedUniqueId = AES.decrypt(encryptedUniqueId, "1234567812345678").toString(enc.Utf8);
  const [loading, setLoading] = useState(true);
  const componentRef = useRef();



  const [userData, setUserData] = useState({
    organizationName: "",
    companyName: "",
  });
 
  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          // const fetchid = portfolioItem.id;
          // setId(fetchid);

          setUserData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            name: portfolioItem.name,
            role: portfolioItem.role,

            uniqueId: portfolioItem.uniqueId,
          });

          console.log("itemDaata::", itemData);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const options = [
    { value: "today", label: "Today" },
    { value: "this_week", label: "This week" },
    { value: "this_month", label: "This month" },
    { value: "this_year", label: "This year" },
    { value: "this_quarter", label: "This Quarter" },
    { value: "yesterday", label: "Yesterday" },
    { value: "previous_week", label: "Previous Week" },
    { value: "previous_month", label: "Previous Month" },
    { value: "previous_quarter", label: "Previous Quarter" },
    { value: "previous_year", label: "Previous Year" },
    { value: "custom", label: "Custom" },
  ];
  
  const formatDate1 = (date) => {
    return date.toLocaleDateString("en-GB").replace(/\//g, "-"); // DD-MM-YYYY format
  };
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
  };
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    if (selectedOption) {
      const today = new Date();
      switch (selectedOption.value) {
        case "today":
          setFromDate(today);
          setToDate(today);
          break;
        case "yesterday":
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          setFromDate(yesterday);
          setToDate(yesterday);
          break;
        case "this_week":
          const sunday = new Date(today);
          sunday.setDate(today.getDate() - today.getDay());
          const saturday = new Date(today);
          saturday.setDate(today.getDate() + (6 - today.getDay()));
          setFromDate(sunday);
          setToDate(saturday);
          break;
        case "previous_week":
          const prevSunday = new Date(today);
          prevSunday.setDate(today.getDate() - today.getDay() - 7);
          const prevSaturday = new Date(today);
          prevSaturday.setDate(today.getDate() - today.getDay() - 1);
          setFromDate(prevSunday);
          setToDate(prevSaturday);
          break;
        case "this_month":
          const firstDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          const lastDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );
          setFromDate(firstDayOfMonth);
          setToDate(lastDayOfMonth);
          break;
        case "previous_month":
          const prevMonthStart = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
          );
          const prevMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          );
          setFromDate(prevMonthStart);
          setToDate(prevMonthEnd);
          break;
        case "this_quarter":
          const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
          const quarterStartDate = new Date(
            today.getFullYear(),
            quarterStartMonth,
            1
          );
          const quarterEndDate = new Date(
            today.getFullYear(),
            quarterStartMonth + 3,
            0
          );
          setFromDate(quarterStartDate);
          setToDate(quarterEndDate);
          break;
        case "previous_quarter":
          const prevQuarterStartMonth =
            Math.floor((today.getMonth() - 3) / 3) * 3;
          const prevQuarterStartDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth,
            1
          );
          const prevQuarterEndDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth + 3,
            0
          );
          setFromDate(prevQuarterStartDate);
          setToDate(prevQuarterEndDate);
          break;
        case "this_year":
          const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
          const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
          setFromDate(firstDayOfYear);
          setToDate(lastDayOfYear);
          break;
        case "previous_year":
          const prevYearStart = new Date(today.getFullYear() - 1, 0, 1);
          const prevYearEnd = new Date(today.getFullYear() - 1, 11, 31);
          setFromDate(prevYearStart);
          setToDate(prevYearEnd);
          break;
          case "custom":
          setFromDate(today);
          setToDate(today);
          break;
        default:
          setFromDate(null);
          setToDate(null);
          break;
      }

      // Call fetchStatement1 with the appropriate arguments
      // fetchStatement1(custID, fromDate, toDate);
    }
  }, [selectedOption]); // This effect runs when selectedOption state changes

  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      fetchStatement1( fromDate, toDate);
    }
  }, [fromDate, toDate ]); // This effect runs when either fromDate or toDate changes

 
  const [dataPost, setDataPost] = useState({
    uniqueId: decryptedUniqueId,
    fromDate: formatDate1(fromDate),
    toDate: formatDate1(toDate),
  });

  useEffect(() => {
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchVendorBalanceSummary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(dataPost), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMesage;
          setItemData(Data);
          
          const total = data.totalClosingBalance;
          setTotalBalance(total)
          setTotalAmount(data.totalBilledAmount)
          setTotalAmountPaid(data.totalAmountPaid)
          setLoading(false)
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false)
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false)
      });
  }, []);

  const fetchStatement1 = (fromDate,toDate) => {
    setLoading(true)
    fetch(BASE_URL + `/fetchVendorBalanceSummary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({uniqueId: decryptedUniqueId,fromDate: formatDate1(fromDate), toDate: formatDate1(toDate),}), // Send uniqueId in the request body
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "success") {
        const Data = data.responseMesage;
          setItemData(Data);
          
          const total = data.totalClosingBalance;
          setTotalBalance(total)
          setTotalAmount(data.totalBilledAmount)
          setTotalAmountPaid(data.totalAmountPaid)
          setLoading(false)
      } else {
        console.error("Failed to fetch invoices:", data.responseMessage);
        setLoading(false);
      }
      setLoading(false);
    })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      border: "1px solid #ccc",
      borderRadius: "4px",
      paddingLeft: "6px",
      width: "100%",
      maxWidth: "400px", // Ensure responsiveness
      boxSizing: "border-box",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: "400px",
    }),
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <span className="custom-control-icon">📅</span>
      {children}
    </components.Control>
  )


  return (
    <>
     <style>
        {`
          .custom-datepicker-wrapper {
            position: relative;
            display: inline-block;
            width: 200px;
          }

          .custom-datepicker-calendar {
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          .react-datepicker__input-container {
            width: 100%;
          }

          .react-datepicker__input-container input {
            width: 100%;
            height: 40px;
            padding: 0 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }

          .custom-label {
            display: block;
            margin-bottom: 5px;
            font-size: 16px;
            color: #333;
          }

          .custom-date-picker {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        `}
      </style>

      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={handleCancelClick}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5 style={{ letterSpacing: "1px", color: "#086bd5de" }}>
            Vendor Balances Summary
          </h5>
        </div>
      </div>
      <div className="my-3 mx-0" style={{height: "100%", padding: "30px", backgroundColor: "#fff"}}>
      <div className="d-flex justify-content-between mt-1 mb-1 ml-3">
          <div className="d-flex align-items-center">
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={options}
            styles={customStyles}
            components={{ Control: CustomControl }}
          />
          </div>
          {selectedOption.value === "custom" && (
            <div className="d-flex align-items-center ml-auto">
             <div className="custom-date-picker">
                <label htmlFor="from-date" className="custom-label mr-2">
                  From Date:
                </label>{" "}
                <DatePicker
                  id="from-date"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                  style={{backgroundColor:"white"}}
                />
              </div>
              <div className="custom-date-picker">
                <label htmlFor="to-date" className="custom-label mr-2">
                  To Date:
                </label>
                <DatePicker
                  id="to-date"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                />
              </div>
            </div>
          )}
          <div className="d-flex align-items-center">
            <ReactToPrint
              trigger={() => (
                <span
                  className="input-group-text"
                  id="pdf-icon"
                  style={{
                    backgroundColor: "#F6F6F6",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  📑
                </span>
              )}
              content={() => componentRef.current}
            />{" "}
          </div>
        </div>
      <div ref={componentRef} className="head-container mb-5 d-flex flex-column justify-content-center align-items-center">
        <h5 style={{ fontWeight: "normal" }}>{userData.companyName}</h5>
        <h4 style={{ fontWeight: "normal" }}>Vendor Balances Summary</h4>
        <p className="mb-1">Basis: Accrual</p>
        <p style={{ fontWeight: "normal" }}>FromTo {formatDate(fromDate)} - ToDate {formatDate(toDate)}</p>        
        <div className="container mt-2">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th> Venodr Name</th>
                  <th>Bill Amount</th>
                  <th>Amount Paid</th>
                  <th>Closing Balance</th>
                </tr>
              </thead>
              <tbody>
                {itemData && itemData.length > 0 ? (
                  itemData.map((data) => {
                                        return (
                      <tr key={data.itemData}>
                        <td>
                          <a href="#">{data.vendorName}</a>
                        </td>
                        <td>₹ {data.billedAmount}</td>
                        <td>₹ {data.amountPaid}</td>
                        <td>₹ {data.closingBalance}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="4">No data available</td>
                  </tr>
                )}
              </tbody>
              <thead>
                <tr>
                  <th className="text-left">
                    Total
                  </th>
                  <th>₹ {totalAmount}</th>
                  <th>₹ {totalAmountPaid}</th>
                  <th>₹ {totalBalance}</th>
                </tr>
              </thead>
            </table>
          </div>
              </div>
     
      <div>
        <p style={{
          marginTop: "20px",
          display: "inline-block",
          marginLeft: "8px",
        }}>**Amount is displayed in your base currency <span style={{backgroundColor: "rgb(56,138,16)",padding: "2px 4px",fontWeight: "600", fontSize: "12px"}}>INR</span></p>
      </div>
      </div>
      </>
  );
}
