import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

class TimeSheetLogCard extends React.Component {
    render() {
        const { navigation, onCompose } = this.props;
        return (
            <div className="col-lg-12">
                {/* <div className="card"> */}
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <div className="table-responsive">
                                    <table class="table table-bordered table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">SR NO</th>
                                                 <th scope="col">NAME</th>
                                                <th scope="col">EMAIL</th>
                                                <th scope="col">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>yash dudhankar</td>
                                                <td>ysh@gmail.com</td>
                                                <td><button className="btn btn-primary">View Log</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>                               
                            </Card>
                        </Col>
                    </Row>
                    {/* Previous and Next buttons */}
                    <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-primary mx-1" onClick={this.handlePrevious}>
                            Previous
                        </button>
                        <button className="btn btn-primary mx-1" onClick={this.handleNo}>
                            1
                        </button>
                        <button className="btn btn-primary mx-1" onClick={this.handleNext}>
                            Next
                        </button>
                    </div>
                </Container>
            </div>
        );
    }
}
export default TimeSheetLogCard
