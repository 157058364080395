import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Modal } from "react-bootstrap";
import "../../../asset/css/inbox.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import Alert from "@mui/material/Alert";

function SalesPerson() {
  const [itemCards, setItemCard] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [validationMessage, setValidationMessage] = useState("");
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [showSalesUpdateModal, setShowSalesUpdateModal] = useState(false);
  const [validationMessage2, setValidationMessage2] = useState("");

  const jwtToken = localStorage.getItem("jwtToken");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(encryptedUniqueId, "1234567812345678").toString(enc.Utf8);
  const [salesData, setSalesData] = useState({
    uniqueId: decryptedUniqueId,
    name: "",
    email: "",
  });
  useEffect(() => {
    setSalesData(prevState => ({ ...prevState, uniqueId: decryptedUniqueId }));
    fetchSalesData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSalesData({ ...salesData, [name]: value });
  };
  const handleSalesShow = () => {
    setSalesData({
      uniqueId: decryptedUniqueId,
      name: "",
      email: "",
    });
    setShowSalesModal(true);
  };
  const fetchSalesData = () => {
    const postApiUrl = BASE_URL + `/getAllSalesPersonByUniqueId`;
    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const filteredData = data.responseMessage.filter(
            (item) => item.name !== "Others"
          );
          setItemCard(filteredData);
        } else {
          console.error("Failed to fetch salespersons:", data.responseMessage);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  const handleSalesPerson = (event) => {
    event.preventDefault();
    if (!salesData.name) {
      setValidationMessage("Please enter a name.");
      return;
    }

    fetch(BASE_URL + "/createSalesperson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal("Sales Person Created", "", "success");
          setShowSalesModal(false);
          fetchSalesData();
        } else {
          swal("Failed To Create Sales Person!", "", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal("An Error Occurred While Creating Sales Person", "", "error");
      });
  };

  const handleSalesUpdateShow = (salesPerson) => {
    setSalesData(salesPerson);
    setShowSalesUpdateModal(true);
  };

  const handleUpdateSalesPerson = (event) => {
    event.preventDefault();
    if (!salesData.name) {
      setValidationMessage2("Please enter a name.");
      return;
    }

    fetch(BASE_URL + `/updateSalesperson/${salesData.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal("Sales Person Updated Successfully", "", "success");
          setShowSalesUpdateModal(false);
          fetchSalesData();
        } else {
          swal("Failed To Update Sales Person!", "", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal("An Error Occurred While Updating Sales Person", "", "error");
      });
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this salesperson?")) {
      fetch(BASE_URL + `/deleteSalesperson/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setItemCard(itemCards.filter((item) => item.id !== id));
            swal("Sales Person Deleted Successfully", "", "success");
          } else {
            swal("Failed to Delete Sales Person", "", "error");
          }
        })
        .catch((error) => {
          console.error("Error deleting salesperson:", error);
        });
    }
  };

  if (loading) {
    return (
      <div className="container-fluid ">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  let i =1;
  return (
    <div className="container-fluid">
      <div className="item-page">
        <div className="d-flex justify-content-between">
          <div
            className="mt-2"
            style={{
              color: "#3C7BD4",
              fontSize: 20,
              fontWeight: "600",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            Manage Sales Person
          </div>
          <div className="button-container d-flex">
            <button
              className="btn btn-primary px-1 py-2 new-button"
              onClick={() => handleSalesShow()}
            >
              + SalesPerson
            </button>
          </div>
        </div>
      </div>
      <Modal show={showSalesModal} onHide={() => setShowSalesModal(false)}>
        <div className="modal-header d-flex align-items-center pb-2 pt-2">
          <h5 className="modal-title fs-5">Add Sales Person</h5>
          <button
            type="button"
            className="btn pr-0"
            onClick={() => setShowSalesModal(false)}
          >
            <ion-icon
              name="close-circle-outline"
              size="large"
              style={{ width: "28px" }}
            ></ion-icon>
          </button>
        </div>
        <Modal.Body>
          {validationMessage && (
            <Alert severity="error" className="mt-0">
              {validationMessage}
            </Alert>
          )}
          <div className="row pt-2">
            <div className="modalsalesinput">
              <label className="form-label">
                <span style={{ color: "red" }}>Name *</span>
              </label>
              <input
                className="form-control"
                placeholder="Name"
                id="name"
                name="name"
                type="text"
                value={salesData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row pt-4">
            <div className="modalsalesinput">
              <label className="form-label">
                <span>Email </span>
              </label>
              <input
                className="form-control"
                placeholder="Email"
                id="email"
                name="email"
                type="text"
                value={salesData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-2">
          <div className="modal-bottombtns">
            <button
              type="button"
              className="modalbtn-1 btn mt-0"
              onClick={() => setShowSalesModal(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="modalbtn-3 btn btn-primary"
              onClick={handleSalesPerson}
            >
              Create Sales Person
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Update Salesperson Modal */}
      <Modal show={showSalesUpdateModal} onHide={() => setShowSalesUpdateModal(false)}>
        <div className="modal-header d-flex align-items-center pb-2 pt-2">
          <h5 className="modal-title fs-5">Update Sales Person</h5>
          <button
            type="button"
            className="btn pr-0"
            onClick={() => setShowSalesUpdateModal(false)}
          >
            <ion-icon
              name="close-circle-outline"
              size="large"
              style={{ width: "28px" }}
            ></ion-icon>
          </button>
        </div>
        <Modal.Body>
          {validationMessage2 && (
            <Alert severity="error" className="mt-0">
              {validationMessage2}
            </Alert>
          )}
          <div className="row pt-2">
            <div className="modalsalesinput">
              <label className="form-label">
                <span style={{ color: "red" }}>Name *</span>
              </label>
              <input
                className="form-control"
                placeholder="Name"
                id="name"
                name="name"
                type="text"
                value={salesData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row pt-4">
            <div className="modalsalesinput">
              <label className="form-label">
                <span>Email </span>
              </label>
              <input
                className="form-control"
                placeholder="Email"
                id="email"
                name="email"
                type="text"
                value={salesData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-2">
          <div className="modal-bottombtns">
            <button
              type="button"
              className="modalbtn-1 btn mt-0"
              onClick={() => setShowSalesUpdateModal(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="modalbtn-3 btn btn-primary"
              onClick={handleUpdateSalesPerson}
            >
              Update Sales Person
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <div
        className="container-fluid"
        style={{ border: "none", borderRadius: "7px" }}
      >
        <div className="page-content ">
          <Container fluid={true} className="">
            <Row>
              <Col>
                <Card>
                  {itemCards && itemCards.length > 0 ? (
                    <>
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Serial no.
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Email
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody className="text1">
                                    {itemCards.map((item) => (
                                      <tr
                                        role="row"
                                        className="odd"
                                        key={item.id}
                                      >
                                        <td>{i++}</td>
                                        <td className="sorting_1">
                                          {" "}
                                          {item.name}
                                        </td>
                                        <td>{item.email}</td>

                                        <td>
                                          <button className="btn icon-border btn-sm mr-2"  onClick={() => handleSalesUpdateShow(item)}                                          >
                                            <i className="fa fa-edit text1"></i>
                                          </button>
                                          <button
                                            className="btn icon-border btn-sm"
                                            onClick={() =>
                                              handleDelete(item.id)
                                            }
                                          >
                                            <i className="fa fa-trash text1"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="heading-text">
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-con
                                      trols="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                      aria-label="Date: activate to sort column descending"
                                    >
                                      Serial no.
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Type: activate to sort column ascending"
                                    >
                                      name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Amount BTC: activate to sort column ascending"
                                    >
                                      Email
                                    </th>

                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default SalesPerson;
