import React, { useState } from "react";
import { XCircle } from "phosphor-react";
import axios from "axios";
import swal from "sweetalert";
import { BASE_URL } from "../CommonApi";

function ManageServerNew(props) {
  const handleCancleClick = () => {
    props.handleCancle();
  };

  const [serverData, setServerData] = useState({
    name: "",
    link: "",
    loginId: "",
    password: "",
    purchaseAcccount: "",
    purchaseDate: "",
    expiryDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServerData({
      ...serverData,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    // Define the URL of your API endpoint
    const apiUrl = BASE_URL + "/insertServer";

    // Define the request headers
    const headers = {
      "Content-Type": "application/json",
    };

    // Create the request body by converting the serverData object to JSON
    const requestBody = JSON.stringify(serverData);

    // Send a POST request to the API
    fetch(apiUrl, {
      method: "POST",
      headers,
      body: requestBody,
    })
      .then((response) => {
        if (response.ok) {
          console.log("Server added successfully");
          swal({
            icon: "success",
            title: "Estimate Data Inserted Successfully",
          });
        } else {
          console.error("Error adding server");
          swal({
            icon: "error",
            title: "Failed to insert data",
          });
        }
      })
      .catch((error) => {
        console.error("Error adding server", error);
      });
  };

  return (
    <div className="container my-3">
      <div className="border border-2 p-2">
        <div className="px-2 d-flex justify-content-between">
          <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}>
            Add New Server
          </h4>
          <button className="btn" onClick={handleCancleClick}>
            <XCircle size={32} weight="fill" />
          </button>
        </div>
        <hr />
        <div className="container">
          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Name </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="Name"
                value={serverData.name}
                onChange={handleInputChange}
                name="name"
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Link</label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="Link"
                value={serverData.link}
                onChange={handleInputChange}
                name="link"
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Login Id </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="Login Id"
                value={serverData.loginId}
                onChange={handleInputChange}
                name="loginId"
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Password </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="Password"
                value={serverData.password}
                onChange={handleInputChange}
                name="password"
                type="password"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                Purchase Amount{" "}
              </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                placeholder="Purchase Amount"
                value={serverData.purchaseAcccount}
                onChange={handleInputChange}
                name="purchaseAcccount"
                type="text"
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                Purchase Date{" "}
              </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                name="purchaseDate"
                type="date"
                value={serverData.purchaseDate}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">Expiry Date </label>
            </div>
            <div className="col-md-3 pl-0">
              <input
                className="form-control"
                name="expiryDate"
                type="date"
                value={serverData.expiryDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="my-2 text-right">
            <button
              className="btn btn-primary px-2 py-1"
              style={{ backgroundColor: "var(--primary-color)" }}
            >
              Save
              {"  "}
              <i className="fa fa-save"></i>
            </button>
            <button
              className="btn btn-secondary mx-2 px-2 py-1"
              onClick={handleCancleClick}
            >
              Cancel <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageServerNew;
