import React, { useState, useEffect } from "react";
import Select from "react-select";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import Alert from "@mui/material/Alert";

function ExpensesUpdate(props) {
  const [showInvDate, setShowInvDate] = useState(true);

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      props.handleCancel();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };
  const jwtToken = localStorage.getItem("jwtToken");

  const expenseAccount = [
    { value: "Cost of Goods Sold", label: "Cost of Goods Sold" },
    { value: "Job Costing", label: "Job Costing" },
    { value: "Labor", label: "Labor" },
    { value: "Materials", label: "Materials" },
    { value: "Subcontractor", label: "Subcontractor" },
    { value: "Addance Tax", label: "Addance Tax" },
    { value: "Employee Advance", label: "Employee Advance" },
    { value: "Input Tax Credit", label: "Input Tax Credit" },
    { value: "Input CSGT", label: "- Input CSGT" },
    { value: "Input IGST", label: "- Input IGST" },
    { value: "Input SGST", label: "- Input SGST" },
    { value: "Prepaid Expenses", label: "Prepaid Expenses" },
    {
      value: "Reverse charge tax input but not due",
      label: "Reverse charge tax input but not due",
    },
    { value: "TDS receivable", label: "TDS receivable" },
    { value: "Furniture and quipment", label: "Furniture and quipment" },
    { value: "Employee Reimbursement", label: "Employee Reimbursement" },
    { value: "GST payable", label: "GST payable" },
    { value: "Output CGST", label: "- Output CGST" },
    { value: "Output IGST", label: "- Output IGST" },
    { value: "Output SGST", label: "- Output SGST" },
    { value: "Opening Balance Adjusment", label: "GOpening Balance Adjusment" },
    { value: "Tax payable", label: "Tax payable" },
    { value: "TDS payable", label: "TDS payable" },
    { value: "Unearned revenue", label: "Unearned revenue" },
    { value: "Advertising and marketing", label: "Advertising and marketing" },
    { value: "Automobile expense", label: "Automobile expense" },
    { value: "Bad debt", label: "Bad debt" },
    { value: "Bank fees and charges", label: "Bank fees and charges" },
    { value: "Consultant Expense", label: "Consultant Expense" },
    { value: "Contract assets", label: "Contract assets" },
    { value: "Credit card charges", label: "Credit card charges" },
    {
      value: "Depreciation and amortisation",
      label: "Depreciation and amortisation",
    },
    { value: "Depreciation expense", label: "Depreciation expense" },
    { value: "IT and Internet expenses", label: "IT and Internet expenses" },
    { value: "Janitorial expense", label: "Janitorial expense" },
    { value: "Lodging", label: "Lodging" },
    { value: "Meals and entertainment", label: "Meals and entertainment" },
    { value: "Merchandise", label: "Merchandise" },
    { value: "Office Supplies", label: "Office Supplies" },
    { value: "Other expenses", label: "Other expenses" },
    { value: "Postage", label: "Postage" },
    { value: "Printing Stationery", label: "Printing Stationery" },
    {
      value: "Raw materials and consumables",
      label: "Raw materials and consumables",
    },
    { value: "Rent expense", label: "Rent expense" },
    { value: "Repairs and maintainance", label: "Repairs and maintainance" },
    {
      value: "Salareis and employee wages",
      label: "Salareis and employee wages",
    },
    { value: "Telephone expense", label: "Telephone expense" },
    { value: "Transportation expense", label: "Transportation expense" },
    { value: "Travel expense", label: "Travel expense" },
    { value: "Uncategorized", label: "Uncategorized" },
  ];

  const paidThrough = [
    { value: "Bank account", label: "bank account" },
    { value: "Invoice", label: "Invoice" },
    { value: "Petty Cash", label: "Petty Cash" },
    { value: "Undeposited funds", label: "Undeposited funds" },
    { value: "Advance Tax", label: "Advance Tax" },
    { value: "Employee Advance", label: "Employee Advance" },
    { value: "Prepaid Expenses", label: "Prepaid Expenses" },
    { value: "TDS Receivables", label: "TDS Receivables" },
    { value: "Employee reimbursements", label: "Employee reimbursements" },
    {
      value: "Opening balance adjustment",
      label: "Opening balance adjustment",
    },
    { value: "TDS payable", label: "TDS payable" },
  ];

  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID
  const [vendorNames, setVendorNames] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [selectedExpenseAccount, setSelectedExpenseAccount] = useState("");
  const [selectedPaidThrough, setSelectedPaidThrough] = useState("");
  const [loading, setLoading] = useState(true);
  const [expId, setExpense] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [imageChanged, setImageChanged] = useState(false);

  const [dateValue, setDateValue] = useState("");

  useEffect(() => {
    setStartDate(new Date());
  }, []);

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };
  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
  };
  const [serverData, setServerData] = useState({
    date: "",
    expenseAccount: "", // Initialize to an empty string
    amount: "",
    paidThrough: "", // Initialize to an empty string
    vendor: "",
    invoice: "",
    notes: "",
    customerName: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServerData({
      ...serverData,
      [name]: value,
    });
  };
  const { expenseId } = props;
  const [image, setImage] = useState(null);
  useEffect(() => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchExpensesById/${expenseId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          const ExpId = Data.id;
          setImage(
            `https://documents.zarp.software/zarp_images/${Data.attachReceipt}`
          );
          setExpense(ExpId);
          setServerData({
            date: Data.date,
            uniqueId: decryptedUniqueId,
            expenseAccount: Data.expenseAccount, // Use selected value from state
            amount: Data.amount,
            paidThrough: Data.paidThrough, // Use selected value from state
            vendor: Data.vendor,
            invoice: Data.invoice,
            notes: Data.notes,
            customerName: Data.customerName,
          });
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);
  // Handle the "expenseAccount" selection

  const handleExpenseAccountChange = (event) => {
    const selectedValue = event.value;
    setSelectedExpenseAccount(selectedValue);
    setServerData({
      ...serverData,
      expenseAccount: selectedValue,
    });
  };

  // Handle the "paidThrough" selection
  const handlePaidThroughChange = (event) => {
    const selectedValue = event.value;
    setSelectedPaidThrough(selectedValue);
    setServerData({
      ...serverData,
      paidThrough: selectedValue,
    });
  };

  const [validationMessage1, setValidationMessage1] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveClick = () => {
    const jwtToken = localStorage.getItem("jwtToken");
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const titleAndDescriptionData = {
      date: serverData.date,
      uniqueId: decryptedUniqueId,
      expenseAccount: serverData.expenseAccount,
      amount: serverData.amount,
      paidThrough: serverData.paidThrough,
      vendor: serverData.vendor,
      customerName: serverData.customerName,
      invoice: serverData.invoice,
      notes: serverData.notes,
    };

    let newValidationMessage = "";
    if (!titleAndDescriptionData.date) {
      newValidationMessage += "Please insert date \n";
    } else if (!titleAndDescriptionData.expenseAccount) {
      newValidationMessage += "Please select the expense account\n";
    } else if (!titleAndDescriptionData.amount) {
      newValidationMessage += "Please insert the amount \n";
    } else if (!titleAndDescriptionData.paidThrough) {
      newValidationMessage += "Please select paid through\n";
    } else if (!titleAndDescriptionData.invoice) {
      newValidationMessage += "Please insert the invoice\n";
    }
    setValidationMessage1(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0);
      return;
    }
    setIsLoading(true)

    const apiUrl = `${BASE_URL}/updateExpenses/${expId}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    const submitFormData = (formData) => {
      requestOptions.body = formData;

      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Response was non-200");
          }
        })
        .then((data) => {
          if (data.status === "success") {
            props.handleCancel();
            swal({
              icon: "success",
              title: "Expense Updated",
            });
            setIsLoading(false)
          } else {
            console.error("Error updating expenses");
            swal({
              icon: "error",
              title: "Failed to update expense",
            });
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.error("Error during API call:", error);
          swal({
            icon: "error",
            title: "Error during API call",
          });
          setIsLoading(false)
        });
    };

    const formData = new FormData();

    if (imageChanged && image) {
      fetch(image)
        .then((res) => res.blob())
        .then((blob) => {
          formData.append("image", blob);
          Object.keys(titleAndDescriptionData).forEach((key) => {
            formData.append(key, titleAndDescriptionData[key]);
          });
          submitFormData(formData);
        })
        .catch((error) => {
          console.error("Error converting image to blob:", error);
        });
    } else {
      const emptyFile = new Blob([], { type: "image/jpeg" });
      formData.append("image", emptyFile, "empty.jpg");
      Object.keys(titleAndDescriptionData).forEach((key) => {
        formData.append(key, titleAndDescriptionData[key]);
      });
      submitFormData(formData);
    }
  };

  // Fetch customer data
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  // Handle customer selection
  const handleCustomerChange = (event) => {
    const selectedValue = event.value;
    setSelectedCustomer(selectedValue);
    setServerData({
      ...serverData,
      customerName: selectedValue,
    });
    // Find the selected customer object in the fetched data
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    setServerData({
      ...serverData,
      customerName: selectedValue,
    });

    if (selectedCustomerObject) {
    
      // Set the selected customer's ID in state
      setSelectedCustomerId(selectedCustomerObject.id);
    } else {
      console.log("Selected Customer: (Not found)");
    }
  };

  useEffect(() => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch vendor names for the decrypted uniqueId
    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setVendorNames(data.responseMessage);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  }, []);

  const handleVendorChange = (event) => {
    const selectedValue = event.value;
    setSelectedVendor(selectedValue); // Store the selected vendor's display name
    setServerData({
      ...serverData,
      vendor: selectedValue,
    });
    // Find the selected vendor object based on the display name
    const selectedVendorObject = vendorNames.find(
      (vendor) => vendor.vendorDisplayName === selectedValue
    );

    setServerData({
      ...serverData,
      vendor: selectedValue,
    });
    // Debugging: log the selected vendor object and its ID
    // console.log("Selected Vendor Object:", selectedVendorObject);

    if (selectedVendorObject) {
     
      setSelectedVendorId(selectedVendorObject.id);
      //   var selectedVendorId=selectedVendorObject.id;
      //   console.log("selectedVendorId : ",selectedVendorId);
    } else {
      // If the selected vendor object is not found, set the ID to an empty string
      console.log("Selected Vendor ID: (Not found)");
    }
  };

  const vendorOptions = vendorNames.map((names) => ({
    label: names.vendorDisplayName,
    value: names.vendorDisplayName,
  }));

  const options = custNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));

  const handleDrop1 = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImage(file);
  };

  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    handleImage(file);
  };

  const handleImage = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setImageChanged(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  return (
    <>
      <Container fluid className="">
        <Row>
          <Col>
            <div className="my-3 mt-4">
              <div className="px-2 d-flex">
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "35px",
                    width: "38px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIosIcon
                    width={2}
                    onClick={handleCancleClick}
                    className="arrow-child"
                  />
                </div>
                <h4
                  className="mt-0 mx-2 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  Update Expense
                </h4>
              </div>
            </div>
            {validationMessage1 && (
              <Alert severity="error" className="mt-0">
                {validationMessage1.split("\n").map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </Alert>
            )}{" "}
            <Card className="card-style">
              <Card.Body>
                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Date</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      {showInvDate ? (
                        <input
                          className="form-control"
                          type="text"
                          value={serverData.date}
                          onClick={(e, date) => {
                            setShowInvDate(false);
                            setServerData({
                              ...serverData,
                              date: normalDateFormat(startDate),
                            });
                            handleDate(startDate);
                          }}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            color: "black",
                          }}
                          readOnly
                        />
                      ) : (
                        <div className="customDatePickerWidth">
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => handleDate(date)}
                            style={{
                              padding: "12px",
                              backgroundColor: "#F6F6F6",
                              borderRadius: "7px",
                              color: "black",
                            }}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Expense Account</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Select
                        options={expenseAccount}
                        value={expenseAccount.find(
                          (option) => option.value === serverData.expenseAccount
                        )}
                        onChange={handleExpenseAccountChange}
                        placeholder="Select..."
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="2" className="">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Amount</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <div>
                        <div className="wrapper" style={{ borderRight: "5px" }}>

                          <Form.Control
                            className=""
                            placeholder="Amount"
                            value={serverData.amount}
                            onChange={handleInputChange}
                            name="amount"
                            type="text"
                            style={{
                              backgroundColor: "#F6F6F6",
                              padding: "12px",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="2" className="">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Paid Through</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Select
                        options={paidThrough}
                        onChange={handlePaidThroughChange}
                        value={paidThrough.find(
                          (option) => option.value === serverData.paidThrough
                        )}
                        placeholder="Select..."
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span>Vendor</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Select
                        value={vendorOptions.find(
                          (option) => option.value === serverData.vendor
                        )}
                        onChange={handleVendorChange}
                        options={vendorOptions}
                        placeholder="Select a Vendor"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span style={{ color: "red" }}>Invoice</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Form.Control
                        className=""
                        placeholder="Invoice"
                        name="invoice"
                        type="text"
                        value={serverData.invoice}
                        onChange={handleInputChange}
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "12px",
                          borderRadius: "5px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span>Notes</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Form.Control
                        className=""
                        placeholder="Notes..."
                        name="notes"
                        value={serverData.notes}
                        onChange={handleInputChange}
                        type="text"
                        style={{
                          backgroundColor: "#F6F6F6",
                          padding: "12px",
                          borderRadius: "5px",
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span>Customer Name</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Select
                        className=""
                        // className="dropdown-field"
                        options={options}
                        value={options.find(
                          (option) => option.value === serverData.customerName
                        )}
                        onChange={handleCustomerChange}
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "6px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="2" className="mt-2">
                    <label style={{ fontSize: "14px", color: "grey" }}>
                      <span>Attach Receipt</span>
                    </label>
                  </Col>
                  <Col className="" md="3">
                    <Form.Group>
                      <Col md="3">
                        <div
                          onDrop={handleDrop1}
                          onDragOver={(e) => e.preventDefault()}
                          style={{
                            border: "2px dashed #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                            textAlign: "center",
                            width: "200px",
                            height: "200px",
                            position: "relative",
                            marginRight: "20px", // Added margin for spacing
                          }}
                        >
                          {image ? (
                            <>
                              <img
                                src={image}
                                alt="Uploaded"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  borderRadius: "5px",
                                }}
                              />
                              <button
                                onClick={handleRemoveImage}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  padding: "5px 10px",
                                  backgroundColor: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <>
                              <p>Drag & Drop or Click to Browse Image</p>
                              <input
                                type="file"
                                onChange={handleImageInputChange}
                                accept="image/*"
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  opacity: "0",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          )}
                        </div>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

              
                <div className="text-right">
                  <button className="btn-1 mt-0" onClick={handleCancleClick}>
                    Cancel{" "}
                  </button>
                  <button
                    className="btn-3 btn btn-primary mt-0"
                    onClick={handleSaveClick}
                  >
                    {isLoading ? "Updating..." : "Update Expense"}
                    
                  </button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ExpensesUpdate;
