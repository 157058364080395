import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Container, Row, Col, Card } from "react-bootstrap";
// import Select from "react-select";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import DatePicker from "react-datepicker";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./PaymentReceivedNew.css";
import Alert from "@mui/material/Alert";

function PaymentRecivedNew({ navigation }) {
  // const [row, setRow] = useState([1]);

  // const [show, setShow] = useState(false);

  const handleCancleClick = () => {
    handlePaymentClick();
  };
  const jwtToken = localStorage.getItem("jwtToken");

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const labelStyle = {
    backgroundColor: "rgba(169, 169, 169, 0.5)", // Adjust the alpha value for transparency
    // Add any other styles as needed
    width: "250px",
    borderRadius: "9px",
  };
  const [show, setShow] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [selectedDepositToOption, setSelectedDepositToOption] = useState("");
  const [selectedCustomerType, setSelectedCustomerType] = useState("");
  const [custNames, setCustNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [failed, setFaild] = useState("");
  const [invoices, setInvoice] = useState([]);
  const [customerId, setCustomerId] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [customerNameChange, setCustomerNameChaneg] = useState(false);
  const [amountReceived, setAmountReceived] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const [amountUsedForPayment, setAmountUsedForPayment] = useState(0);
  const [amountInExcess, setAmountInExcess] = useState(0);
  const [invoicePayments, setInvoicePayments] = useState([]);
  // const [paymentMode, setPaymentMode] = useState(""); // Added
  const [depositTo, setDepositTo] = useState(""); // Added
  const [customerType, setCustomerType] = useState(""); // Added

  const [excessAmount, setExcessAmount] = useState("0"); // Added
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const optionsCustomers = custNames.map((customer) => ({
    value: customer.id,
    label: customer.customerName,
  }));

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handlePaymentClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleNavigation("Payment");
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };
  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);

    if (isCheckboxChecked) {
      setAmountReceived(0);
    }
  };

  // const handlePaymentModeChange = (event) => {
  //   setPaymentMode(event.target.value);
  // };

  const handleDepositToChange = (event) => {
    setDepositTo(event.target.value);
  };

  const handleCustomerTypeChange = (event) => {
    setCustomerType(event.target.value);
  };

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Customer Data:", data);

        if (data.responseCode === "200") {
          console.log("Customer Name Response Message:", data.responseMessage);
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const getCustomerData = (id) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    console.log(id, ":::::::::custoemr ID::::::::::::::::::::");

    fetch(BASE_URL + `/fetchExcessAmount/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log(
            "onclick fetch Excess Amount Data::::",
            data.responseMessage
          );
          const excessAmount = data.responseMessage.excessAmount;
          console.log("========excessAmount============================");
          console.log(excessAmount);
          console.log("====================================");
          setExcessAmount(excessAmount);
        } else {
          console.error(
            "Excess Amount not fetched  by id:",
            data.responseMessage
          );
          // swal("Error!", "Excess Amount Not Found.", "error");
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  // Handle customer selection
  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    const selectedValueId = id;
    console.log("selectedValue : ", selectedValue);
    setSelectedCustomer(selectedValue);
    setSelectedCustomerId(selectedValueId);

    // Find the selected customer object in the fetched data
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      const customerState = selectedCustomerObject.billState;
      setCustomerNameChaneg(true);
      handleInvoiceClick(selectedCustomerObject.id);
      getCustomerData(selectedCustomerObject.id);
      setSelectedCustomerId(selectedCustomerObject.id);
    } else {
      console.log("Selected Customer: (Not found)");
    }
  };

  console.log("paymendt id give", selectedCustomerId);

  const handleInvoiceClick = (id) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchInvoiceForPaymentReceived/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const invoice = data.responseMessage;
          setInvoice(invoice);
          setFaild("success");

          // Initialize payments array with default values
          const initialPayments = invoice.map(() => 0);
          setInvoicePayments(initialPayments);
        } else if (data.status === "error") {
          setFaild(data.status);
          console.log("invoice not available for you");
        } else {
          console.error("Failed to fetch SalesOrder:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching SalesOrder:", error);
      });
  };

  let [totalPayment, setTotalPayment] = useState(0);
  const handleAmountReceivedChange = (event) => {
    let receivedAmount = parseFloat(event.target.value) || 0;
    setAmountReceived(receivedAmount);
    let totalPayment2 = 0;

    const newInvoicePayments = invoices.map((invoice) => {
      const payment =
        receivedAmount >= invoice.receivables
          ? invoice.receivables
          : receivedAmount;
      receivedAmount -= payment;
      console.log("hello", totalPayment);
      console.log("hello2", payment);

      return payment;
    });
    const totalPayments = newInvoicePayments.reduce(
      (total, payment) => total + parseFloat(payment),
      0
    );

    console.log("Total Payment:", totalPayments);
    setAmountUsedForPayment(totalPayments);

    setInvoicePayments(newInvoicePayments);

    const totalAmountDue = invoices.reduce(
      (total, invoice) => total + parseInt(invoice.receivables),
      0
    );
    const newAmountDue =
      receivedAmount >= totalAmountDue ? totalAmountDue : receivedAmount;
    setAmountDue(newAmountDue);
    const remainingAmount = amountReceived - amountUsedForPayment;
    // setAmountInExcess(remainingAmount);

    // setAmountInExcess(receivedAmount - newAmountDue);
    console.log("excess amount:::---", receivedAmount);
    setAmountInExcess(receivedAmount);

    // setTotalPayment(receivedAmount + amountReceived);
  };

  const handlePrintExcessAmount = () => {
    // Logic to print excessAmount when checkbox is clicked
    console.log("Excess Amount:", excessAmount);
    setAmountReceived(excessAmount);
  };

  const [notes, setNotes] = useState("");

  const itemDetails1 = invoices.map((invoice, index) => {
    return {
      invoiceNumber: invoice.invoiceNumber || "",
      invoiceAmount: invoice.total || "",
      date: invoice.invoiceDate || "",
      amountDue: invoice.receivables || "",
      payment: invoicePayments[index] || "0", // Use invoicePayments state
    };
  });
  const [validationMessage1, setValidationMessage1] = useState("");

  const handleSaveClick = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    // Construct payment data
    const paymentData = {
      customerName: selectedCustomer,
      customerId: selectedCustomerId,
      bankCharges: "20.00", // Replace with actual value
      paymentDate: dateValue || normalDateFormat(startDate),
      paymentMode: selectedPaymentMode, // Replace with actual value
      depositeTo: selectedDepositToOption, // Replace with actual value
      reference: "REF123", // Replace with actual value
      amountUsedForPayments: amountUsedForPayment,
      taxDeducted:
        customerType === "No Tax deducted" ? "No Tax deducted" : customerType,
      amountReceived: amountReceived,
      excessCheck: isCheckboxChecked ? "use" : "No",
      // amountInExcess: amountInExcess,
      notes: notes,
      items: itemDetails1,
      uniqueId: decryptedUniqueId,
    };

    let newValidationMessage = "";
    if (!paymentData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!paymentData.amountReceived) {
      newValidationMessage += "Please enter amount.\n";
    }

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    // Make a fetch request to save payment data
    fetch(BASE_URL + "/insertPaymentReceived", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(paymentData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal("Success!", "Payment saved successfully.", "success");
          handleNavigation("Payment");
        } else {
          swal("Error!", "Failed to save payment.", "error");
        }
      })
      .catch((error) => {
        console.error("Error saving payment:", error);
        swal("Error!", "Failed to save payment.", "error");
      });
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
  });
  const [paymentCount, setPaymentCount] = useState();
  const fetchDataAndInitializeDataTable = () => {
    const jwtToken = localStorage.getItem("jwtToken");

    const apiUrl = BASE_URL + "/fetchPaymentReceived2";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("POST request failed");
        }
      })
      .then((responseData) => {
        console.log("POST request successful");
        const count = responseData.responseMessage1;
        setPaymentCount(count + 1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Call the function wherever needed
  // For example, you can call it inside a useEffect in a component
  useEffect(() => {
    fetchDataAndInitializeDataTable();
  }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [dateValue, setDateValue] = useState("");
  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    console.log("normalFormat : ", normalFormat);
    setDateValue(normalFormat);
  };
  useEffect(() => {
    setStartDate(new Date());
  }, []);

  const paymentModeOptions = [
    { value: "Bank Remittance", label: "Bank Remittance" },
    { value: "Bank Transfer", label: "Bank Transfer" },
    { value: "Cash", label: "Cash" },
    { value: "Checque", label: "Checque" },
    { value: "Credit Card/Debit Card", label: "Credit Card/Debit Card" },
  ];
  const handlePaymentMode = (selectedOption) => {
    setSelectedPaymentMode(selectedOption.value);
  };

  const depsoitToOptions = [
    { value: "Bank Account", label: "Bank Account" },
    { value: "Petty Cash", label: "Petty Cash" },
    { value: "Undeposited Funds", label: "Undeposited Funds" },
    { value: "Employee Reimbursenments", label: "Employee Reimbursenments" },
    {
      value: "Opening Balance Adjustments",
      label: "Opening Balance Adjustments",
    },
    { value: "TDS Payable", label: "TDS Payable" },
  ];
  const handleDepositTo = (selectedOption) => {
    setSelectedDepositToOption(selectedOption.value);
  };

  const customerTypeOptions = [
    { value: "Kunal", label: "Kunal" },
    { value: "Patil", label: "Patil" },
    { value: "sagar", label: "sagar" },
  ];
  const handleCustomerType = (selectedOption) => {
    setSelectedCustomerType(selectedOption.value);
  };
  return (
    <>
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
              cursor: "pointer",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={handleCancleClick}
              className="arrow-child"
            />
          </div>
          <div className="headitems ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              Record Payment
            </h5>
          </div>
        </div>
      </div>
      {validationMessage1 && (
        <Alert severity="error" className="mt-0">
          {validationMessage1.split("\n").map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </Alert>
      )}{" "}
      <div className="card border-2 mt-3">
        <div className="pl-4 pr-4">
          <div className="row pt-3 pb-3">
            <div className="col-md-2">
              <div className="text-style">
                Customer Name<span className="valid">*</span>
              </div>
            </div>
            <div className="salespersonbox">
              <Select
                options={optionsCustomers}
                value={optionsCustomers.find(
                  (option) => option.value === selectedCustomer
                )}
                onChange={(selectedOption) =>
                  handleCustomerChange(
                    selectedOption.label,
                    selectedOption.value
                  )
                }
                placeholder="Select Customer"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: "10px",
                    width: "250px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#F6F6F6",
                    borderRadius: "9px",
                    height: "46px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "19px",
                  }),
                }}
              />
            </div>
          </div>
          {customerNameChange && (
            <>
              <div className="row pt-3">
                <div className="col-md-2">
                  <div className="text-style">Un-Used Credit</div>
                </div>
                <div className="unusedcreditbox d-flex align-items-center">
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    className="mr-2"
                    onChange={handleCheckboxChange}
                    checked={isCheckboxChecked}
                    // onClick={handlePrintExcessAmount}
                  />
                  <label className="mb-0 form-control" style={labelStyle}>
                    <span className="bg-red">{excessAmount}</span>
                  </label>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-2">
                  <div className="text-style">
                    Amount Received <span className="valid"> *</span>
                  </div>
                </div>
                <div className="inputchild">
                  <input
                    className="form-control"
                    type="text"
                    value={amountReceived}
                    onChange={handleAmountReceivedChange}
                    required
                  />
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-2">
                  <div className="text-style">Bank Charges (if any)</div>
                </div>
                <div className="inputchild">
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-2">
                  <div className="text-style">Payment Date</div>
                </div>
                <div className="inputchild">
                  <div className="customDatePickerWidth">
                    {/* <input className="form-control" type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} /> */}
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => handleDate(date)}
                      style={{
                        padding: "12px",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "10px",
                        color: "black",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-2">
                  <div className="text-style">Payment</div>
                </div>
                <div className="inputchild">
                  <input
                    type="text"
                    className="form-control"
                    value={paymentCount}
                    readOnly
                  />
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-2">
                  <div className="text-style">Payment Mode</div>
                </div>
                <div className="salespersonbox">
                  {/* <select id="taxOption" className="form-control" value={paymentMode} onChange={handlePaymentModeChange}>
                    <option value="">Select an option</option>
                    <option value="Bank Remittance">Bank Remittance</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="Cash">Cash</option>
                    <option value="Checque">Checque</option>
                    <option value="Credit Card/Debit Card">Credit Card/Debit Card</option>
                  </select> */}
                  <Select
                    // className="dropdown-field"
                    options={paymentModeOptions}
                    value={paymentModeOptions.find(
                      (option) => option.value === selectedPaymentMode
                    )}
                    onChange={handlePaymentMode}
                    placeholder="Select Payment"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: "250px",
                        marginTop: "10px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "9px",
                        height: "46px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        height: "19px",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-2">
                  <div className="text-style">Deposit To</div>
                </div>
                <div className="salespersonbox">
                  {/* <select id="taxOption" className="form-control" value={depositTo} onChange={handleDepositToChange}>
                    <option value="">Select an option</option>
                    <option value="Bank Account">Bank Account</option>
                    <option value="Petty Cash">Petty Cash</option>
                    <option value="Undeposited Funds">Undeposited Funds</option>
                    <option value="Employee Reimbursenments">Employee Reimbursenments</option>
                    <option value="Opening Balance Adjustments">Opening Balance Adjustments</option>
                    <option value="TDS Payable">TDS Payable</option>
                  </select> */}
                  <Select
                    // className="dropdown-field"
                    options={depsoitToOptions}
                    value={depsoitToOptions.find(
                      (option) => option.value === selectedDepositToOption
                    )}
                    onChange={handleDepositTo}
                    placeholder="Select Deposit To"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: "250px",
                        marginTop: "10px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "9px",
                        height: "46px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        height: "19px",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-2">
                  <div className="text-style">Customer Type</div>
                </div>
                <div className="d-flex" style={{ marginTop: "15px" }}>
                  <div className="rdiobox ml-3 d-flex align-items-center">
                    <input
                      className="mx-1"
                      type="radio"
                      name="customerType"
                      id="cus_bus"
                      value="No Tax deducted"
                      onChange={handleCustomerTypeChange}
                      onClick={() => {
                        setShow(false);
                      }}
                    />
                    <span>No Tax deducted</span>
                  </div>

                  <div className="rdiobox ml-3 d-flex align-items-center">
                    <input
                      className="mx-1"
                      type="radio"
                      name="customerType"
                      id="cus_indivi"
                      value="customerType"
                      onClick={() => {
                        setShow(true);
                      }}
                    />
                    <span>Yes, TDS (Income Tax)</span>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-2"></div>
                <div className="salespersonbox">
                  {show && (
                    // <select id="taxOption" className="form-control" value={customerType} onChange={handleCustomerTypeChange}>
                    //   <option value="">Select an option</option>
                    //   <option value="Kunal">Kunal</option>
                    //   <option value="Patil">Patil</option>
                    //   <option value="sagar">sagar</option>
                    // </select>
                    <Select
                      // className="dropdown-field"
                      options={customerTypeOptions}
                      value={customerTypeOptions.find(
                        (option) => option.value === selectedCustomerType
                      )}
                      onChange={handleCustomerType}
                      placeholder="Select Customer Type"
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: "250px",
                          marginTop: "10px",
                        }),
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#F6F6F6",
                          borderRadius: "9px",
                          height: "46px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          height: "19px",
                        }),
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="row mg-t-50 pt-3  align-items-center mg-b-20">
                <div className="container-fluid">
                  <div className="page-content">
                    <Container fluid={true}>
                      <Row className="pl-3 pr-3">
                        <Col>
                          <Card>
                            <div className="table-responsive">
                              <table className="table table-bordered mb-0">
                                <thead className="text-center">
                                  <tr>
                                    <th scope="col">DATE</th>
                                    <th scope="col">INVOICE NUMBER</th>
                                    <th scope="col">INVOICE AMOUNT </th>
                                    <th scope="col">AMOUNT DUE </th>
                                    <th scope="col">PAYMENT</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {failed === "error" ? (
                                    <tr>
                                      <td colSpan="5">
                                        There are no unpaid Invoices for this
                                        customer, that can be applied for this
                                        payment
                                      </td>
                                    </tr>
                                  ) : invoices.length > 0 ? (
                                    invoices.map((invoice, index) => (
                                      <tr key={index}>
                                        <th>{invoice.invoiceDate || " "}</th>
                                        <th>{invoice.invoiceNumber}</th>
                                        <th>{invoice.total}</th>
                                        <th>{invoice.receivables}</th>
                                        <th>{invoicePayments[index]}</th>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="5">
                                        There are no unpaid Invoices for this
                                        customer, that can be applied for this
                                        payment
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                      {/* Previous and Next buttons */}
                    </Container>
                  </div>
                </div>
              </div>

              <div className="row mg-t-0 align-items-center mg-b-20">
                {/* Left Side Content */}
                <div className="col-md-7">{/* ... (existing code) */}</div>

                {/* Right Side Card */}
                <div className="col-md-5">
                  <Card>
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="text-center">
                          <label>Amount Received </label>
                        </div>
                        <div>
                          <span>{amountReceived}</span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="text-center">
                          <label>Amount used for payments: </label>
                        </div>
                        <div>
                          <span>{amountUsedForPayment} </span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="text-center">
                          <label>Amount Refunded </label>
                        </div>
                        <div>
                          <span>00.00</span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="text-center">
                          <label>Amount in excess </label>
                        </div>
                        <div>
                          <span>{amountInExcess}</span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="row pt-3 align-items-top">
                <div className="col-md-6">
                  <div className="text-style">
                    Notes (Internal use. Not visible to customer)
                  </div>
                </div>
                <div className="col-md-12">
                  <textarea
                    className="custom-textarea-payment"
                    type="text"
                    value={notes}
                    onChange={handleNotesChange}
                    style={{
                      backgroundColor: "#F6F6F6",
                    }}
                  />
                </div>
              </div>

              {/* <div className="my-2 text-right  mt-4">
                <button className="btn " style={{ backgroundColor: "#06699d", color: "white" }} onClick={handleSaveClick}>
                  Save{"  "}
                  <i className="fa fa-save" style={{ color: "white" }}></i>
                </button>
                <button className="btn btn-secondary mx-2" onClick={handleCancleClick}>
                  Cancel <i className="fa fa-times"></i>
                </button>
              </div> */}
              <div className="text-right invoice-bottombtns mb-3">
                <button
                  className="payments-btn-1 btn mt-0"
                  onClick={handleCancleClick}
                >
                  Cancel
                </button>
                <button
                  className="payments-btn-3 btn btn-primary"
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PaymentRecivedNew;
