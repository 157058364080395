import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import numberToWords from "number-to-words";
import PaymentsMadeUpdate from "./PaymentsMadeUpdate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CiEdit ,CiTrash } from "react-icons/ci";
import { MdOutlinePrint } from "react-icons/md";
import swal from "sweetalert"; // import { OptionsManager } from "@fullcalendar/core/OptionsManager";

function PaymentsMadeDetails(props) {
  const [invoices, setInvoices] = useState([]);
  const [paymentTabel, setPaymentTabel] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentMadeEdit, setPaymentMadeEdit] = useState(false);
  const [detailsPage, setDetailsPage] = useState(true);
  const [vendorId, setVendorId] = useState("");
  const [paymentCount, setPaymentCount] = useState("");
  const [companyName, setCompanyName] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const [amountReceivedWords, setAmountReceivedWords] = useState(0);

  const jwtToken = localStorage.getItem("jwtToken");

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchForUpdatePurchaseRecords`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        vendorId: props.vendorId, // Add custId to the body
        paymentCount: props.paymentCount,
      }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "sucess") {
          const Data = data.responseMessage[0];
          const tabelData = data.responseMessage;
          setPaymentTabel(tabelData);
          setInvoices(Data);
          setAmountReceivedWords(Data.amountReceived);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchPurchaseRecords`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "sucess") {
          const mergedData = mergeArrays(data.responseMessage);
          setPaymentData(mergedData);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const userId = localStorage.getItem("userId");

    fetch(BASE_URL + `/fetchUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userDetails = data.responseMessage;
          setUserDetails(userDetails);
          const companyName = data.responseMessage.companyName;
          setCompanyName(companyName);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, []);
  const mergeArrays = (data) => {
    const mergedData = [];
    const uniquePaymentCounts = new Set();
    data.forEach((innerArray) => {
      innerArray.forEach((item) => {
        const existingRow = mergedData.find(
          (row) => row.paymentCount === item.paymentCount
        );

        if (!existingRow) {
          uniquePaymentCounts.add(item.paymentCount);
          mergedData.push({ ...item, invoices: [item.invoiceNumber] });
        } else {
          existingRow.invoices.push(item.invoiceNumber);
        }
      });
    });

    return mergedData;
  };

  const handleCancelUpdate = () => {
    setPaymentMadeEdit(false);
    setDetailsPage(true);
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleInvoiceClick = (paymentCount, vendorId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchForUpdatePurchaseRecords`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        paymentCount: paymentCount,
        vendorId: vendorId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "sucess") {
          const Data = data.responseMessage[0];
          const tabelData = data.responseMessage;
          setPaymentTabel(tabelData);
          setInvoices(Data);
          setAmountReceivedWords(Data.amountReceived);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };
  const handleEditClick = (paymentCount, vendorId) => {
    setVendorId(vendorId);
    setPaymentCount(paymentCount);
    setPaymentMadeEdit(true);
    setDetailsPage(false);
  };
  let i = 1;
  const handleCancel = () => {
    props.handleCancel();
  };
  const formatAmountInWords = (amount) => {
    const [integerPart, fractionalPart] = amount.toString().split(".");
    let amountInWords = numberToWords.toWords(integerPart);
  
    if (fractionalPart) {
      const fractionalInWords = numberToWords.toWords(fractionalPart);
      amountInWords += ` and ${fractionalInWords} paisa`;
    }
  
    // Capitalize the first letter
    amountInWords = amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1);
  
    return amountInWords;
  };
  const amountInWords = formatAmountInWords(invoices.amountReceived);

  const handleDelete = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this item!"
    );
    const jwtToken = localStorage.getItem("jwtToken");
  
    if (userConfirmed) {
      fetch(BASE_URL + `/deletePurchaseRecords`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          paymentCount: invoices.paymentCount,
          uniqueId: decryptedUniqueId,
        }),
      })
      .then((response)=>response.json())
        .then((data) => {
          if (data.status==="sucess") {
            swal({
              icon: "success",
              title: "Payment Deleted!",
            });
            handleCancel()
          } else {
            console.error("Failed to delete Item");
            alert("Failed to delete Item");
            // handleCancel();
          }
        })
        .catch((error) => {
          console.error("Error deleting Item:", error);
        });
    } else {
      // If the user clicks "Cancel", do nothing
      alert("Item deletion canceled!");
    }
  };
  const handlePrint = () => {
    const printableContent = document.getElementById("printable-content");
    if (printableContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>PAYMENT RECEIPT</title>
            <style>
            *{
            -webkit-print-color-adjust:exact;
            print-color-adjust:exact;
            }
              body {
                font-family: Arial, sans-serif;
              }
              .head{
                width: 100%;
                display: flex;
                border-bottom: 1px solid #52504f;
              }
              p{
                padding: 0px;
                margin: 0px;
              }
              .title{
                width: 100%;
                height: 50%;
                text-align: left;
              }
              .invoice-box {
                max-width: 800px;
                max-height:880px;
                 margin-top:-10px;
                 margin: auto;
                padding: 30px;
                border: 1px solid #52504f;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
                font-size: 16px;
                line-height: 24px;
              }
              .notes {
               /* border-left:1px solid black; */
               border-bottom: 1px solid black;
                display: flex;
                width: 100%;
                height: 235px;
              }
              .invoice {
                border-bottom: 1px solid black;
                display: flex;
                height: 140px;
              }
              .invoice-no {
                width: 30%;
                padding-left: 5px;
              }
              .inv {
                width: 40%;
              
              }
              .inv-three{
                width: 30%;
              }
              .insert{
                height: 70px;
                text-align: left;
              }
              .amount{
                text-align: center;
                align-content: center;
                height: 90%;
                width: 90%;
                margin-top: 5px;
                margin-left: 10px;
                background-color: rgb(122, 235, 122);
              }
            .bill{
              margin-top: 20px;
              border-bottom: 1px solid black;
            }
            .for{
              text-align: center;
              margin-bottom: 5px;
              margin-top: 20px;
            }
            .table-container {
                    width: 100%;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                table, th, td {
                    border: 1px solid #ccc;
                }
                 td {
                    padding: 10px;
                    text-align: left;
                 }
                 th{
                      padding: 10px;
                    text-align: left;
                    background-color: #eee;
                    }
             .receipt{
              text-align: center;
              margin-top:15px;
             }
            </style>
          </head>
          <body>
            <div class="invoice-box">
                        <div class="head">
                        <div class="title">
                          <div class="shop-info">
                                        <h3 style="margin: 14px 0px 5px 0px;">${companyName || ""
        }</h3>
                                         <address>
                                         ${userDetails.address1 || ""} ${userDetails.address2 || ""}<br>${userDetails.zipcode || ""} ${userDetails.city || ""}
                                         <br>${userDetails.state || ""} <br>
                                         ${userDetails.contact ? `MOB:${userDetails.contact || ""}<br>`:""} 
                                          ${userDetails.gstNumber ? `GST NO: ${userDetails.gstNumber}` : ""}
                                         </address>
                                    </div>
                          </div>
                        </div>
              <div class="receipt">
                <b> PAYMENT RECEIPT</b>
              </div>
              <td>
                <div class="invoice">
                  <div class="invoice-no">
                    <b>Payment Date</b><br />
                    <b>Reference Number</b> <br />
                    <b>Payment Mode</b><br />
                    <b>Amount Received In Words</b>
                  </div>
                  <div class="inv">
                    : ${invoices.paymentDate}<br/>
                    : ${invoices.reference}<br/>
                    : ${invoices.paymentMode}<br/>
                    :Indian Rupee ${amountInWords} Only<br/>
                </div>
                  <div class="inv-three">
                    <div class="amount">
                    <b>Amount Received</b><br/>
                     <b>₹${invoices.amountReceived}</b><br/>
                    </div>
                  </div>
                </div>
            </td>
            <div class="bill">
              <b> Bill To</b><br/>
             ${invoices.vendorName}<br/>

        
            </div>
            <div class="for">
              <b>PAYMENT FOR</b>
            </div>
            <div class="table-container">
              <table>
                  <thead>
                      <tr>
                          <th>Bill Number</th>
                          <th>Bill Date</th>
                          <th>Bill Amount</th>
                          <th>Payment Amount</th>
                          
                      </tr>
                  </thead>
                  <tbody>
                  ${paymentTabel
                    .map(
                      (data) => `
                      <tr>
                          <td>${data.billsNumber || ""}</td>
                          <td>${data.date || ""}</td>
                          <td>₹ ${data.billsAmount || ""}</td>
                          <td>₹ ${data.amountReceived || ""}</td>
                      </tr>
                      `
                    )
                    .join("")}
                  </tbody>
              </table>
          </div>
            </div>
          </body>
        </html>`
      );
      printWindow.document.close();
      printWindow.onafterprint = () => {
        printWindow.close();
        printWindow.onafterprint = null;
      };
      printWindow.print();
    }
  };
  return (
    <>
      {detailsPage && (
        <>
          <div
            className="d-flex justify-content-between mt-3"
            style={{ padding: "5px 5px 0px 5px" }}
          >
            <div className="d-flex align-items-center ">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancel}
                  className="arrow-child"
                />
              </div>
              <div className="headinvoice ml-2">
                <h5
                  className="ml-1 mb-0 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  All Details
                </h5>
                <p className="ml-1 mb-0 text-muted">
                  {paymentData.length} Payments
                </p>
              </div>
            </div>
            <div className="add-invoice pr-2 d-flex align-items-baseline">
            </div>
          </div>
          <div
            className="card border-2 invoice-border mt-3"
            style={{
              borderRadius: "20px",
              height: "auto",
              marginBottom: "0px",
            }}
          >
            <div className="custom-templates-container">
              <div className="left-invoice custom-scrollbar">
                <div>
                  <div
                    className="text-left custom-scrollbar"
                    style={{ cursor: "pointer" }}
                  >
                    {paymentData.map((data, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="invoice-row"
                          onClick={() => {
                            handleInvoiceClick(
                              data.paymentCount,
                              data.vendorId
                            );
                            setShowDetails(true);
                          }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "1px",
                            }}
                          >
                            {data.vendorName}
                          </p>

                          <p
                            className="text-muted"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            {data.billsNumber}
                          </p>
                          <div className="d-flex justify-content-between mt-1">
                            <p
                              className=""
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginLeft: "10px",
                                marginBottom: "0px",
                              }}
                            >
                              {`₹ ${data.payment}`}
                            </p>
                            <span className="fs-1 text-muted">
                              {data.paymentDate}
                            </span>
                          </div>
                        </div>
                        <div className="horizontal-line"></div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className="middle-line"></div>
              {showDetails && (
                <div className="right-invoice">
                  <ul
                    className="nav nav-tabs-new d-flex"
                    style={{ padding: "0px 0px 10px 0px" }}
                  >
                    <h5
                      className="flex-grow-1 mb-0 ml-1"
                      style={{ color: "#3C7BD4" }}
                    >
                      {invoices.paymentCount}
                    </h5>
                    <li className="nav-item mr-1 cursor-pointer">
                      <a
                        className="nav-link"
                        href="#"
                        role="button"
                        onClick={() =>
                          handleEditClick(
                            invoices.paymentCount,
                            invoices.vendorId
                          )
                        }
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiEdit  size={18} />{" "}
                        <span >Edit</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                      <a
                        className="nav-link "
                        onClick={handlePrint}
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <MdOutlinePrint size={18} /> <span>Print</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item ml-1 cursor-pointer">
                      <a
                        className="nav-link "
                        onClick={handleDelete}
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiTrash size={18} /> <span>Delete</span>{" "}
                      </a>
                    </li>
                  </ul>

                  <div className="horizontal-line"></div>

                  <div
                    className="container  custom-scrollbar"
                    id="printable-content"
                    style={{ overflowY: "auto", maxHeight: "500px" }}
                  >
                    <div className="section1  pt-2">
                      <div className="container my-3 border border-2">
                        <div className="row ">
                          {userDetails && (
                            <div className="col-md-6">
                              <h5>{companyName}</h5>
                              <ul className="text-start mb-0">
                                  {userDetails.address1 && (
                                    <li>{userDetails.address1}</li>
                                  )}
                                  {userDetails.address2 && (
                                    <li>{userDetails.address2}</li>
                                  )}
                                  {userDetails.zipcode && (
                                    <li>{userDetails.zipcode}</li>
                                  )}
                                  {userDetails.city && (
                                    <li>{userDetails.city}</li>
                                  )}
                                  {userDetails.state && (
                                    <li>{userDetails.state}</li>
                                  )}
                                  {userDetails.businessLocation && (
                                    <li>{userDetails.businessLocation}</li>
                                  )}
                                  {userDetails.contact && (
                                    <li><span className="text-bold">MOB :</span>{" "}{userDetails.contact}</li>
                                  )}
                                  {userDetails.gstNumber && (
                                    <li>
                                      <span className="text-bold">GST NO:</span>{" "}
                                      {userDetails.gstNumber}
                                    </li>
                                  )}
                                </ul>
                            </div>
                          )}
                          
                        </div>
                        <hr />
                        <div className="text-center pt-2 pb-4">
                          <h4>PAYMENT RECEIPT</h4>
                        </div>

                        <div class="row">
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-6">
                                <b>Payment Date </b><br />
                                <b>Reference Number</b> <br />
                                <b>Payment Mode</b> <br />
                                <b>Amount Received In Words </b><br />
                                {/* P.O.# */}
                              </div>
                              <div className="col-md-6">
                                : {invoices.paymentDate}
                                <br />: {invoices.reference}
                                <br />: {invoices.paymentMode}
                                <br />:Indian Rupee {amountInWords} Only
                                <br />
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#BCE954",
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                padding: "0px",
                              }}
                            >
                              <div style={{ fontSize: "20px", padding: "2px" }}>
                                Amount Received
                              </div>
                              <br />
                              <div
                                className="pb-2"
                                style={{ fontSize: "20px" }}
                              >
                                ₹ {invoices.amountReceived}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="bill">
                          {" "}
                          <span
                            className="pt-2 mt-10"
                            style={{ marginTop: "4px" }}
                          >
                            <p className="ml-2 color-black mt-2">Paid To</p>
                          </span>
                        </div>
                        <div class="row ">
                          <div className="col-md-6 ">
                            <div className="row">
                              <div className="col-md-6 ">
                                <br />
                                {invoices.vendorName}
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 ">
                          </div>
                        </div>

                        <hr />
                        <div class="row">
                          <h5 className="pl-4 text-center">Payment For</h5>
                          <table
                            class="table table-bordered col-md-11"
                            style={{ marginLeft: "20px" }}
                          >
                            <thead>
                              <tr>
                                <th>Bill Number</th>
                                <th>Bill Date</th>
                                <th>Bill Amount</th>
                                <th>Payment Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {paymentTabel.map((data) => (
                                <tr>
                                  <td>{data.billsNumber}</td>
                                  <td>{data.date}</td>
                                  <td>₹ {data.billsAmount}</td>
                                  <td>₹ {data.amountReceived}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </>
      )}

      {paymentMadeEdit && (
        <PaymentsMadeUpdate
          vendorId={vendorId}
          paymentCount={paymentCount}
          handleCancel={handleCancelUpdate}
        />
      )}

    </>
  );
}

export default PaymentsMadeDetails;
