import React, { useState } from "react";
import { XCircle, User } from "phosphor-react"
import Select from "react-select";
function ManageRecipeNew(props) {
    
    const handleCancleClick = () => {
        props.handleCancle();
    }

    const [row, setRow] = useState([]);

    const handleAdd = () => {
        const value1 = [...row, []]
        setRow(value1);
    }

    const handleDelete = (index) => {
        const newRow = [...row];
        newRow.splice(index, 1);
        setRow(newRow);
    }

    const productName = [];
   
    return (
        <div className="container my-3">
            <div className="border border-2 p-2" >
                <div className="px-2 d-flex justify-content-between">
                    <h4 className="mt-3 mx-2"><b>   New Item Recipe</b></h4>
                    <button className="btn" onClick={handleCancleClick}><XCircle size={32} weight="fill" /></button>
                </div>
                <hr />
                <div className="container">

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2" style={{ color: "#E54643" }}>
                            <label className="form-label required mg-b-0"><b>Product Name* : </b></label>
                        </div>
                        <div className="" style={{width:"190px"}}>
                            <Select options={productName} placeholder="Select..." />
                        </div>
                    </div>                                    

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label required mg-b-0"><b>Product Quantity :</b></label>
                        </div>
                        <div className="">
                            <input className="form-control" placeholder="1" name="email" type="text" />
                        </div>
                    </div>
                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label required mg-b-0"><b>Product Unit : </b></label>
                        </div>
                        <div className="">
                            <input className="form-control" placeholder="KG" name="email" type="text" />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div class="col-md-12 col-lg-4 col-xl-2 mt-4 mt-lg-0">
                            <select class="form-control" id="other[]" name="other[]" fdprocessedid="yca2vq">
                                <option value="26">Hemesh</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <input className="form-control" placeholder="Row Quantity " name="displayname" type="text" />
                        </div>
                        <div className="col-md-3">
                            <select class="form-control" id="unit" name="unit1">
                                <option value="pcs">pcs </option>

                                <option value="box">box </option>

                                <option value="cm">cm </option>

                                <option value="dz">dz </option>

                                <option value="ft">ft </option>

                                <option value="g">g </option>

                                <option value="in">in </option>

                                <option value="kg">kg </option>

                                <option value="km">km </option>

                                <option value="lb">lb </option>

                                <option value="mg">mg </option>

                                <option value="m">m </option>
                            </select>
                        </div>
                        <button class="btn btn-success btn-icon" onClick={handleAdd} type="button" id="del" name="del" fdprocessedid="70svb">+</button>
                    </div>

                    {row.map((_, index) => {
                        return (
                            <div key={index} className="row mg-t-15 pt-3 align-items-center mg-b-4">
                                <div class="col-md-12 col-lg-4 col-xl-2 mt-4 mt-lg-0">
                                    <select class="form-control" id={`other[${index}]`} name={`other[${index}]`} fdprocessedid="yca2vq">
                                        <option value="26">Hemesh</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <input className="form-control" placeholder="Row Quantity " name="displayname" type="text" />
                                </div>
                                <div className="col-md-3">
                                    <select class="form-control" id="unit" name={`unit${index}`} >
                                        <option value="pcs">pcs </option>

                                        <option value="box">box </option>

                                        <option value="cm">cm </option>

                                        <option value="dz">dz </option>

                                        <option value="ft">ft </option>

                                        <option value="g">g </option>

                                        <option value="in">in </option>

                                        <option value="kg">kg </option>

                                        <option value="km">km </option>

                                        <option value="lb">lb </option>

                                        <option value="mg">mg </option>

                                        <option value="m">m </option>
                                    </select>
                                </div>
                                <button class="btn btn-danger btn-icon" onClick={() => handleDelete(index)} type="button" id="del" name="del" fdprocessedid="70svb">-</button>
                            </div>
                        )
                    })
                    }
                    {/* <button class="btn btn-success btn-icon my-2" onClick={handleAdd} type="button" id="add" name="add" fdprocessedid="70svb">+ Add New</button> */}
                </div>
            </div>
            <div className="my-2">
                <button className="btn btn-success">Save</button>
                <button className="btn btn-dark mx-2" onClick={handleCancleClick}>Cancle</button>
            </div>

        </div>
    )
}

export default ManageRecipeNew;