import React from "react";
import "./header.css";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";
import { AES, enc } from "crypto-js";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleValue: false, // Set the initial toggle value
    };
  }

  handleSwitchToggle = (event) => {
    const isChecked = event.target.checked;
    console.log(isChecked ? "YES" : "NO");
    this.setState({ toggleValue: isChecked });

    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const bodyData = {
      uniqueId: decryptedUniqueId,
      businessRegistered: isChecked ? "YES" : "NO", // Updated line
    };

    fetch(BASE_URL + `/OnOff`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log("Tax updated successfully:", data.responseMessage);
        } else {
          console.error("Failed to update tax:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
      });
  };

  componentDidMount() {
    this.fetchAdditionalData();
  }

  fetchAdditionalData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("GST DATA Data:", data);

        if (data.responseCode === "200") {
          console.log("GST DATA  Response Message:", data.responseMessage[0]);
          const businessRegistered = data.responseMessage[0].businessRegistered;
          console.log(businessRegistered, " businessRegistered");
          this.setState({
            toggleValue: businessRegistered === "YES",
          });
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  render() {
    const {
      headerText,

    } = this.props;
    return (
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h2>{headerText}</h2>
             
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="d-flex flex-row-reverse">
              
              <div className="p-2 d-flex"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Header;
