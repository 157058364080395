import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Container, Row, Col, Card } from "react-bootstrap";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./PaymentReceivedNew.css";
import Alert from "@mui/material/Alert";

function PaymentRecivedUpdate(props) {


  const handleCancleClick = () => {
    const confirmCancel = window.confirm('Are you sure Leave this page?');
    if (confirmCancel) {
      props.handleCancel();
    } else {
      console.log('Cancellation not confirmed');
    }

  };

  const jwtToken = localStorage.getItem("jwtToken");

  const labelStyle = {
    backgroundColor: "rgba(169, 169, 169, 0.5)", // Adjust the alpha value for transparency
    // Add any other styles as needed
  };
  const [show, setShow] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().substr(0, 10));
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [selectedDepositToOption, setSelectedDepositToOption] = useState("");
  const [selectedCustomerType, setSelectedCustomerType] = useState("");
  const [custNames, setCustNames] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [failed, setFaild] = useState("");
  const [invoices, setInvoice] = useState([]);
  const [customerId, setCustomerId] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [customerNameChange, setCustomerNameChaneg] = useState(false);
  const [amountReceived, setAmountReceived] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const [amountUsedForPayment, setAmountUsedForPayment] = useState(0);
  const [amountInExcess, setAmountInExcess] = useState(0);
  const [invoicePayments, setInvoicePayments] = useState([]);
  const [paymentMode, setPaymentMode] = useState(""); // Added
  const [depositTo, setDepositTo] = useState(""); // Added
  const [customerType, setCustomerType] = useState(""); // Added
  const [paymentDate, setPaymentDate] = useState("");
  const [excessAmount, setExcessAmount] = useState("0"); // Added
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { custId } = props;
  const { paymentCount } = props;
  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);

    if (isCheckboxChecked) {
      setAmountReceived(0);
    }
  };


  const handleCustomerTypeChange = (event) => {
    setCustomerType(event.target.value);
  };

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(encryptedUniqueId, "1234567812345678").toString(enc.Utf8);

    fetch(BASE_URL + `/fetchForUpdate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        customerId: props.customerId, // Add custId to the body
        paymentCount: props.paymentCount,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Customer Data:", data);

        if (data.status === "sucess") {
          // console.log("Customer Name Response Message:", data.responseMessage);
          const invoice = data.responseMessage;
          const extraData = data.responseMessage[0];
          setInvoice(invoice);
          setFaild("success");
          setAmountReceived(extraData.amountReceived);
          setPaymentDate(extraData.paymentDate);
          setPaymentMode(extraData.paymentMode);
          setDepositTo(extraData.depositeTo);
          setAmountReceived(extraData.amountReceived);
          setAmountUsedForPayment(extraData.amountUsedForPayments);
          setAmountInExcess(extraData.amountInExcess);
          // Initialize payments array with default values
          const initialPayments = invoice.map(() => 0);
          setInvoicePayments(initialPayments);
          setCustNames(extraData.customerName);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const getCustomerData = (id) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(encryptedUniqueId, "1234567812345678").toString(enc.Utf8);

    fetch(BASE_URL + `/fetchExcessAmount/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log("onclick fetch Excess Amount Data::::", data.responseMessage);
          const excessAmount = data.responseMessage.excessAmount;

          setExcessAmount(excessAmount);
        } else {
          console.error("Excess Amount not fetched  by id:", data.responseMessage);
          // swal("Error!", "Excess Amount Not Found.", "error");
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };



  let [totalPayment, setTotalPayment] = useState(0);
  const handleAmountReceivedChange = (event) => {
    let receivedAmount = parseFloat(event.target.value) || 0;
    setAmountReceived(receivedAmount);

    const newInvoicePayments = invoices.map((invoice) => {
      const payment = receivedAmount >= parseFloat(invoice.amountDue) + parseFloat(invoice.payment) ? parseFloat(invoice.amountDue) + parseFloat(invoice.payment) : receivedAmount;
      receivedAmount -= payment;
      console.log("hello", totalPayment);
      console.log("hello2", payment);

      return payment;
    });
    const totalPayments = newInvoicePayments.reduce((total, payment) => total + parseFloat(payment), 0);

    console.log("Total Payment:", totalPayments);
    setAmountUsedForPayment(totalPayments);

    setInvoicePayments(newInvoicePayments);

    const totalAmountDue = invoices.reduce((total, invoice) => total + parseInt(parseFloat(invoice.amountDue) + parseFloat(invoice.payment)), 0);
    const newAmountDue = receivedAmount >= totalAmountDue ? totalAmountDue : receivedAmount;
    setAmountDue(newAmountDue);
    const remainingAmount = amountReceived - amountUsedForPayment;
    // setAmountInExcess(receivedAmount - newAmountDue);
    console.log("excess amount:::---", receivedAmount);
    setAmountInExcess(receivedAmount);

    // setTotalPayment(receivedAmount + amountReceived);
  };


  const [notes, setNotes] = useState("");

  const itemDetails1 = invoices.map((invoice, index) => {
    return {
      invoiceNumber: invoice.invoiceNumber || "",
      invoiceAmount: invoice.total || "",
      date: invoice.invoiceDate || "",
      amountDue: invoice.receivables || "",
      payment: invoicePayments[index] || "0", // Use invoicePayments state
    };
  });

  const [validationMessage1, setValidationMessage1] = useState("");

  const handleSaveClick = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(encryptedUniqueId, "1234567812345678").toString(enc.Utf8);

    const updatedPayments = invoices.map((invoice, index) => {
      return {
        id: invoice.id,
        customerName: custNames,
        invoiceNumber: invoice.invoiceNumber,
        invoiceAmount: invoice.invoiceAmount,
        date: invoice.date,
        amountDue: parseFloat(invoice.amountDue) + parseFloat(invoice.payment),
        payment: invoicePayments[index] || invoice.payment, // Use invoicePayments state
        // amountReceived: invoice.amountReceived,
        // customerName: custNames,
        bankCharges: "20.00", // Replace with actual value
        paymentDate: dateValue || normalDateFormat(startDate),
        paymentMode: selectedPaymentMode !== "" ? selectedPaymentMode : paymentMode,
        depositeTo: selectedDepositToOption !== "" ? selectedDepositToOption : depositTo,
        reference: "REF123", // Replace with actual value
        taxDeducted: customerType === "No Tax deducted" ? "No Tax deducted" : customerType,
        withHoldingTax: "5.00", // Replace with actual value
        amountReceived: amountReceived,
        amountUsedForPayments: amountUsedForPayment,
        amountRefunded: "100.00", // Replace with actual value
        amountInExcess: amountInExcess,
        notes: notes,
        uniqueId: decryptedUniqueId,
        // Add other fields as needed
      };
    });

    let newValidationMessage = "";
     if (!amountReceived) {
      newValidationMessage += "Please enter amount.\n";
    }

    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    // Make a fetch request to save payment data
    fetch(BASE_URL + "/updatePaymentReceived", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(updatedPayments),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "sucess") {
          swal("Success!", "Payment Updated successfully.", "success");
          props.handleCancel();
        } else {
          swal("Error!", "Failed to Update payment.", "error");
        }
      })
      .catch((error) => {
        console.error("Error saving payment:", error);
        swal("Error!", "Failed to save payment.", "error");
      });
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(encryptedUniqueId, "1234567812345678").toString(enc.Utf8);

  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
  });
  // const [paymentCount, setPaymentCount] = useState();
  const fetchDataAndInitializeDataTable = () => {
    const jwtToken = localStorage.getItem("jwtToken");

    const apiUrl = BASE_URL + "/fetchPaymentReceived2";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("POST request failed");
        }
      })
      .then((responseData) => {
        console.log("POST request successful");
        const count = responseData.responseMessage1;
        // setPaymentCount(count+1)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Call the function wherever needed
  // For example, you can call it inside a useEffect in a component
  useEffect(() => {
    fetchDataAndInitializeDataTable();
  }, []);

  const [startDate, setStartDate] = useState("");
  const [dateValue, setDateValue] = useState("");
  const normalDateFormat = (date) => {
    if (date) {
      return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (Number(date.getMonth()) + 1)).slice(-2) + "-" + date.getFullYear();
    }
    return date;
  };

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    console.log("normalFormat : ", normalFormat);
    setDateValue(normalFormat);
  };
  useEffect(() => {
    setStartDate(new Date());
  }, []);

  const paymentModeOptions = [
    { value: "Bank Remittance", label: "Bank Remittance" },
    { value: "Bank Transfer", label: "Bank Transfer" },
    { value: "Cash", label: "Cash" },
    { value: "Checque", label: "Checque" },
    { value: "Credit Card/Debit Card", label: "Credit Card/Debit Card" },
  ];
  const handlePaymentMode = (selectedOption) => {
    setSelectedPaymentMode(selectedOption.value);
  };

  const depsoitToOptions = [
    { value: "Bank Account", label: "Bank Account" },
    { value: "Petty Cash", label: "Petty Cash" },
    { value: "Undeposited Funds", label: "Undeposited Funds" },
    { value: "Employee Reimbursenments", label: "Employee Reimbursenments" },
    { value: "Opening Balance Adjustments", label: "Opening Balance Adjustments" },
    { value: "TDS Payable", label: "TDS Payable" },
  ];
  const handleDepositTo = (selectedOption) => {
    setSelectedDepositToOption(selectedOption.value);
  };

  const customerTypeOptions = [
    { value: "Kunal", label: "Kunal" },
    { value: "Patil", label: "Patil" },
    { value: "sagar", label: "sagar" },
  ];
  const handleCustomerType = (selectedOption) => {
    setSelectedCustomerType(selectedOption.value);
  };

  return (
    <>
    <div className="my-3">
      <div className="d-flex align-items-center ml-1 mt-4">
        <div className="cursor-pointer d-flex justify-content-center align-items-center" style={{ backgroundColor: "white", borderRadius: "50%", height: "35px", width: "38px" ,cursor:"pointer" }}>
          <ArrowBackIosIcon width={2} onClick={handleCancleClick} className="arrow-child" />
        </div>
        <div className="headitems ml-2">
          <h5 className="ml-1 mb-0" style={{ letterSpacing: "1px", color: "#086bd5de" }}>
            Update Payment
          </h5>
        </div>
      </div>
      </div>

      {validationMessage1 && (
        <Alert severity="error" className="mt-0">
          {validationMessage1.split("\n").map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </Alert>
      )}{" "}
      <div className="card border-2 mt-3">
        <div className="pl-4 pr-4">
          <div className="row pt-3">
            <div className="col-md-2">
              <div className="text-style">
                Customer Name<span className="valid"> *</span>
              </div>
            </div>
            <div className="inputchild">
              <input type="text" className="form-control" value={custNames} required readOnly />
            </div>
            {customerNameChange && (
              <div className="col-md-2">
                <div className="text-style">Un-Used Credit</div>
              </div>
            )}
            {customerNameChange && (
              <div className="unusedcreditbox d-flex align-items-center">
                <input
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                  className="mr-2"
                  onChange={handleCheckboxChange}
                  checked={isCheckboxChecked}
                  // onClick={handlePrintExcessAmount}
                />
                <label className="mb-0 form-control" style={labelStyle}>
                  <span className="bg-red">{excessAmount}</span>
                </label>
              </div>
            )}
          </div>
          <>
            <div className="row pt-3">
              <div className="col-md-2">
                <div className="text-style">Amount Received <span className="valid"> *</span></div>
              </div>
              <div className="inputchild">
                <input type="text" className="form-control" value={amountReceived} onChange={handleAmountReceivedChange} required />
              </div>
              {/* <div className="col-md-2">
                <label className="form-label   mg-b-0">
                  <span className="text-dashed-underline">Bank Charges (if any)</span>
                </label>{" "}
              </div>
              <div className="col-md-3">
                <input className="form-control" />
              </div> */}
            </div>

            <div className="row pt-3">
              <div className="col-md-2">
                <div className="text-style">Bank Charges (if any)</div>
              </div>
              <div className="inputchild">
                <input type="text" className="form-control" />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-2">
                <div className="text-style">Payment Date</div>
              </div>
              <div className="inputchild">
                {/* <input ty className="form-control" type="date" value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)} /> */}
                <div className="customDatePickerWidth">
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleDate(date)}
                    style={{
                      padding: "12px",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "10px",
                      color: "black",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-2">
                <div className="text-style">Payment</div>
              </div>
              <div className="inputchild">
                <input type="text" className="form-control" value={paymentCount} readOnly />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-2">
                <div className="text-style">Payment Mode</div>
              </div>
              <div className="salespersonbox">
                <Select
                  // className="dropdown-field"
                  options={paymentModeOptions}
                  value={paymentModeOptions.find((option) => option.value === selectedPaymentMode)}
                  onChange={handlePaymentMode}
                  placeholder="Select Payment"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "250px",
                      marginTop: "10px",
                    }),
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#F6F6F6",
                      borderRadius: "9px",
                      height: "46px",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      height: "19px",
                    }),
                  }}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-2">
                <div className="text-style">Deposit To</div>
              </div>
              <div className="salespersonbox">
                <Select
                  // className="dropdown-field"
                  options={depsoitToOptions}
                  value={depsoitToOptions.find((option) => option.value === selectedDepositToOption)}
                  onChange={handleDepositTo}
                  placeholder="Select Deposit To"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "250px",
                      marginTop: "10px",
                    }),
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#F6F6F6",
                      borderRadius: "9px",
                      height: "46px",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      height: "19px",
                    }),
                  }}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-2">
                <div className="text-style">Customer Type</div>
              </div>
              <div className="d-flex" style={{ marginTop: "15px" }}>
                <div className="rdiobox ml-3 d-flex align-items-center">
                  <input
                    className="mx-1"
                    type="radio"
                    name="customerType"
                    id="cus_bus"
                    value="No Tax deducted"
                    onChange={handleCustomerTypeChange}
                    onClick={() => {
                      setShow(false);
                    }}
                  />
                  <span>No Tax deducted</span>
                </div>

                <div className="rdiobox ml-3 d-flex align-items-center">
                  <input
                    className="mx-1"
                    type="radio"
                    name="customerType"
                    id="cus_indivi"
                    value="customerType"
                    onClick={() => {
                      setShow(true);
                    }}
                  />
                  <span>Yes, TDS (Income Tax)</span>
                </div>
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-2"></div>
              <div className="salespersonbox">
                {show && (
                  // <select id="taxOption" className="form-control" value={customerType} onChange={handleCustomerTypeChange}>
                  //   <option value="">Select an option</option>
                  //   <option value="Kunal">Kunal</option>
                  //   <option value="Patil">Patil</option>
                  //   <option value="sagar">sagar</option>
                  // </select>
                  <Select
                    // className="dropdown-field"
                    options={customerTypeOptions}
                    value={customerTypeOptions.find((option) => option.value === selectedCustomerType)}
                    onChange={handleCustomerType}
                    placeholder="Select Customer Type"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: "250px",
                        marginTop: "10px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "9px",
                        height: "46px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        height: "19px",
                      }),
                    }}
                  />
                )}
              </div>
            </div>

            <div className="row mg-t-50 pt-3  align-items-center mg-b-20">
              <div className="container-fluid">
                <div className="page-content">
                  <Container fluid={true}>
                    <Row>
                      <Col>
                        <Card>
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0">
                              <thead className="text-center">
                                <tr>
                                  <th scope="col">DATE</th>
                                  <th scope="col">INVOICE NUMBER</th>
                                  <th scope="col">INVOICE AMOUNT </th>
                                  <th scope="col">AMOUNT DUE </th>
                                  <th scope="col">PAYMENT</th>
                                </tr>
                              </thead>
                              <tbody>
                                {failed === "error" ? (
                                  <tr>
                                    <td colSpan="5">There are no unpaid Invoices for this customer, that can be applied for this payment</td>
                                  </tr>
                                ) : invoices.length > 0 ? (
                                  invoices.map((invoice, index) => (
                                    <tr key={index}>
                                      <th>{invoice.date || " "}</th>
                                      <th>{invoice.invoiceNumber}</th>
                                      <th>{invoice.invoiceAmount}</th>
                                      <td>{parseFloat(invoice.amountDue) + parseFloat(invoice.payment)}</td>
                                      <th>{invoicePayments[index] || invoice.payment}</th>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="5">There are no unpaid Invoices for this customer, that can be applied for this payment</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    {/* Previous and Next buttons */}
                  </Container>
                </div>
              </div>
            </div>

            <div className="row mg-t-0 align-items-center mg-b-20">
              {/* Left Side Content */}
              <div className="col-md-7">{/* ... (existing code) */}</div>

              {/* Right Side Card */}
              <div className="col-md-5">
                <Card>
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <div className="text-center">
                        <label>Amount Received </label>
                      </div>
                      <div>
                        <span>{amountReceived}</span>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="text-center">
                        <label>Amount used for payments </label>
                      </div>
                      <div>
                        <span>{amountUsedForPayment} </span>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="text-center">
                        <label>Amount Refunded </label>
                      </div>
                      <div>
                        <span>00.00</span>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="text-center">
                        <label>Amount in excess </label>
                      </div>
                      <div>
                        <span>{amountInExcess}</span>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="row pt-3 align-items-top">
              <div className="col-md-6">
                <div className="text-style">Notes (Internal use. Not visible to customer)</div>
              </div>
              <div className="col-md-12 pr-0">
                <textarea
                  className="custom-textarea-payment"
                  type="text"
                  value={notes}
                  onChange={handleNotesChange}
                  style={{
                    backgroundColor: "#F6F6F6",
                  }}
                />
              </div>
            </div>

            <div className="text-right invoice-bottombtns mb-3">
              <button className="btn-1 btn mt-0" onClick={handleCancleClick}>
                Cancel
              </button>
              <button className="btn-3 btn btn-primary" onClick={handleSaveClick}>
                Save
              </button>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default PaymentRecivedUpdate;
