import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import Select from "react-select";
import "./popup.css";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import id from "date-fns/locale/id";
import { BASE_URL } from "../CommonApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";

function CreditNotesUpdate(props) {
  const [row, setRow] = useState([
    {
      itemDetails: "",
      account: "",
      hsnSac: "",
      quantity: "1",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
    },
  ]);

  const handleAdd = () => {
    setUserDetails([
      ...userDetails,
      {
        itemsDetails: "",
        hsnSac: "",
        size: "",
        quantity: "1",
        rate: "0.0",
        discount: "0.0",
        discountType: "₹",
        tax: "0",
      },
    ]);
  };
  const [manualCheck, setManualCheck] = useState("No");

  const [fetchedCustomerName, setFetchedCustomerName] = useState("");
  const [fetchedOgInvoiceNumber, setFetchedOgInvoiceNumber] = useState("");
  const [fetchedOgInvoiceDate, setFetchedOgInvoiceDate] = useState("");
  const [fetchedSalesPerson, setFetchedSalesPerson] = useState("");
  const [fetchedCustomerId, setFetchedCustomerId] = useState("");
  const jwtToken = localStorage.getItem("jwtToken");

  const handleDeleteRow = (i) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this estimate!"
    );

    if (userConfirmed) {
      const updatedUserDetails = [...userDetails];
      if (updatedUserDetails.length === 1) {
        alert("Cannot delete the last remaining row Data.");
        return;
      }
      updatedUserDetails.splice(i, 1);
      setUserDetails(updatedUserDetails); // Update the state with the modified array
    } else {
      alert("Credit Notes deletion canceled!");
    }
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      props.handleCancel();
    } else {
      console.log("Cancellation not confirmed");
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const [selectedTerms, setSelectedTerms] = useState("");

  const handleDiscountChange = (e, i) => {
    const { name, value } = e.target;
    const newValues =
      name === "discount" ? { discount: value } : { discountType: value };
    const newRow = [...row];
    newRow[i] = { ...newRow[i], ...newValues };
    setRow(newRow);
    updateTotal(newRow);
  };

  const handleQuantityChange = (e, i) => {
    const newQuantity = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], quantity: newQuantity };
    setRow(newRow);
    updateTotal(row);
  };

  const handleRateChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate: newRate };
    setRow(newRow);
    updateTotal(row);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
    setTotal(calculatedTotal.toFixed(2));
  };

  const [total, setTotal] = useState(0);

  const calculateAmount = (data, tax) => {
    const { quantity, rate, discount, discountType } = data;
    let amount = quantity * rate;
    if (discount) {
      amount -= discountType === "%" ? (amount * discount) / 100 : discount;
    }
    return amount;
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST() || userDetails.igst);
    const adjustment = parseFloat(adjustmentValue || formData.adjustment) || 0;
    const total1 = subTotal + igst + adjustment;
    const total2 = total1 - disValue;
    const totalFinal = total2 + discountAmountTcs ;
    return totalFinal.toFixed(2);
  };

  const calculateSubTotal = () => {
    let subTotal = 0;
    userDetails.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const [adjustmentValue, setAdjustmentValue] = useState(); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value

  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      // Calculate the opposite value only if the adjustmentValue is a valid number
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      // If adjustmentValue is not a valid number, set the opposite value to an empty string
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  const { creditNoteNumber } = props;

  const [originalInvoiceNumber, setOriginalInvoiceNumber] = useState("");
  const [originalInvoiceDate, setOriginalInvoiceDate] = useState(""); // Step 1: State for Original Invoice Date
  const [salesPersonData, setSalesPersonData] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [custNames, setCustNames] = useState([]);
  const [invs, setInvs] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID
  const [formData, setFormData] = useState([]);
  const [ids, setIds] = useState([]);
  const [userDetails, setUserDetails] = useState([
    {
      itemDetails: "",
      hsnSac: "",
      quantity: "",
      rate: "",
      discount: "",
      discountType: "%",
      tax: "",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [oldCreditNumber, setOldCreditNumber] = useState("");
  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState("");
  const [discountCheck, setDiscountCheck] = useState("");
  const [hsnCheck ,setHsnCheck] = useState("")
  const [sizeCheck ,setSizeCheck]=useState("")

  const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState([]);
  const [invoiceNumberNext, setInvoiceNumberNext] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [show1, setShow1] = useState(false);

  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const copyAdd = () => {
    if (show1) {
      setInvoiceNumber(invoiceNumberPrefix + invoiceNumberNext);
      setFormData({
        ...formData,
        creditNoteNumber: invoiceNumberPrefix + invoiceNumberNext,
      });
      setManualCheck("No");
    } else {
      setManualCheck("Yes");
      setInvoiceNumber("");
      setFormData({
        ...formData,
        creditNoteNumber: "",
      });
    }
  };



  const fetchBill = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const requestData = {
      uniqueId: decryptedUniqueId,
      type: "creditnotenumber",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setInvoiceNumberPrefix(data.responseMessage.prefix);
        setInvoiceNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setUserState(data.responseMessage);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fetchItemData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/getAllItemData`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const [searchIndex, setSearchIndex] = useState(0);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setRow(newRow);
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...userDetails];
    newRow[index].itemDetails = value;
    setUserDetails(newRow);
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setSearchIndex(index);
    if (value) {
      fetchItemRate(value, index);
    } else {
      handleSearchChange(value, index);
    }
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...userDetails];
    newRow[index].itemDetails = selectedItem;
    setRow(newRow);
    setUserDetails(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const jwtToken = localStorage.getItem("jwtToken");
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const rate = data.data.taxableAmount;
        const hsnSac = data.hsnCode;
        const description1 = data.data.description;
        const itemName = data.data.name;
        const tax = data.data.intraStateTaxRate;
        const updatedRow = [...userDetails];
        updatedRow[index].rate = rate;
        updatedRow[index].hsnSac = hsnSac;
        updatedRow[index].description1 = description1;
        updatedRow[index].itemDetails = itemName;
        updatedRow[index].tax = tax;
        setUserDetails(updatedRow);
        setSearchResults([]);
        handleAdd();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const calculateIGST = () => {
    let totalIGST = 0;

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      if (taxRate > 0) {
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = (parseFloat(amount) * taxRate) / 100;
        totalIGST += increasedTaxAmount;
      }
    });
    return totalIGST.toFixed(2);
  };

  const calculateIGSTForTax = () => {
    const taxResults = [];

    userDetails.forEach((data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate > 0) {
        let existingTaxResult = taxResults.find(
          (result) => result.taxRate === taxRate
        );
        if (!existingTaxResult) {
          existingTaxResult = { taxRate, totalIGST: 0 };
          taxResults.push(existingTaxResult);
        }
        const amount = calculateAmount(data, taxRate);
        const increasedTaxAmount = amount * taxRate;
        existingTaxResult.totalIGST += increasedTaxAmount;
      }
    });
    return taxResults;
  };

  const calculateGroupedSGSTandCGST = () => {
    const taxGroups = userDetails.reduce((acc, data) => {
      const taxRate = parseFloat(data.tax / 100 || 0);
      if (taxRate === 0) return acc; // Skip 0% GST
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = amount * taxRate;
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;

      if (!acc[taxRate]) {
        acc[taxRate] = { totalSGST: 0, totalCGST: 0 };
      }
      acc[taxRate].totalSGST += sgst;
      acc[taxRate].totalCGST += cgst;
      return acc;
    }, {});

    return taxGroups;
  };

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const filteredData = data.responseMessage.filter(
            (item) => item.name !== "Others"
          );
          setSalesPersonData(filteredData);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  }, []);
  const customerOptions = custNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));
  const handleSalesPersonChange = (value, id) => {
    const selectedValue = value.value;
    setSelectedSalesPerson(selectedValue);
    setFetchedSalesPerson(value);
    const selectedSalesPersonObject = salesPersonData.find(
      (person) => person.name === selectedValue
    );
    if (selectedSalesPersonObject) {
    } else {
      console.log("Selected Sales Person : (Not found)");
    }
  };


  const [taxValues, setTaxValues] = useState([]);
  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    fetch(BASE_URL + `/fetchByCreditNumber/${creditNoteNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setUserDetails(data.responseMessage);
          const itemIds = data.responseMessage.map((item) => item.id);
          setIds(itemIds);
          const initialTaxValues = data.responseMessage.map((item) => ({
            id: item.id,
            tax: item.tax || "",
          }));
          setTaxValues(initialTaxValues);
          setItemDescriptions(
            data.responseMessage.map((item) => ({
              itemDetails: item.itemDetails,
              description: item.description2 || "",
            }))
          );
          const Data = data.responseMessage[0];
          setOldCreditNumber(Data.creditNoteNumber);
          setBussinessRegistered(Data.taxCheck);
          setDiscountCheck(Data.discountCheck);
          setHsnCheck(Data.hsnCheck)
          setSizeCheck(Data.sizeCheck)
          setFormData({
            customerName: Data.customerName,
            customerId: Data.customerId,
            creditNoteNumber: Data.creditNoteNumber,
            reference: Data.reference,
            creditNoteDate: Data.creditNoteDate,
            salesPerson: Data.salesPerson,
            subject: Data.subject,
            itemDetails: Data.itemDetails,
            account: Data.account,
            quantity: Data.quantity,
            rate: Data.rate,
            discount: Data.discount,
            discountType: Data.discountType,
            tax: Data.tax,
            amount: Data.amount,
            subtotal: Data.subtotal,
            adjustment: Data.adjustment,
            adjustmentLabel: Data.adjustmentLabel,
            total: Data.total,
            igst: Data.igst,
            cgst: Data.cgst,
            sgst: Data.sgst,
            value: Data.value,
            customerNotes: Data.customerNotes,
            termsAndCondition: Data.termsAndCondition,
            originalInvoiceNumber: Data.originalInvoiceNumber,
            originalInvoiceDate: Data.originalInvoiceDate,
          });
          setFetchedCustomerId(Data.customerId);
          setFetchedOgInvoiceNumber(Data.originalInvoiceNumber);
          setFetchedOgInvoiceDate(Data.originalInvoiceDate);
          setOriginalInvoiceDate(Data.originalInvoiceDate);
          setFetchedSalesPerson(Data.salesPerson);
          setFetchedCustomerName(Data.customerName);
          setDisAllValue(Data.overallDiscount || ""); // Assuming the discount value is in Data.discount
          setDiscountType(Data.overallDiscountType || "₹");
          setDiscountAmountTcs(parseFloat(Data.value));
          const selectedTcsOption = tcs.find(
            (option) => option.value === Data.tcsTax
          );
          setSelectedTcsOption(selectedTcsOption);
        } else {
          console.error(
            "Failed to fetch credit note by id:",
            data.responseMessage
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching creedit note:", error);
        setLoading(false);
      });
  }, []);

  const [validationMessage1, setValidationMessage1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const insertDataIntoDatabase = async () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const formDataObject = {
      uniqueId: decryptedUniqueId,
      items: userDetails.map((userDetail) => ({
        itemDetails: userDetail.itemDetails || "",
        account: userDetail.account || "",
        quantity: userDetail.quantity || "",
        rate: userDetail.rate || "",
        discount: userDetail.discount || "",
        discountType: userDetail.discountType || "",
        tax: userDetail.tax || "",
        amount: calculateAmount(userDetail),
        rowDiscount: userDetail.discount || "",
        description1: userDetail.description1 || "",
        description2: userDetail.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100
            : 0,
      })),
      ids: ids.map((id) => ({ id })),
      customerName: formData.customerName,
      customerId: selectedCustomerId || formData.customerId,
      creditNoteNumber: formData.creditNoteNumber,
      oldCreditNote: oldCreditNumber,
      reference: formData.reference,
      creditNoteDate: dateValue || formData.creditNoteDate,
      salesPerson: fetchedSalesPerson || formData.salesPerson,
      subject: formData.subject,
      subtotal: calculateSubTotal(),
      adjustment: formData.adjustment,
      adjustmentLabel: formData.adjustmentLabel,
      total: calculateTotal(),
      customerNotes: formData.customerNotes,
      termsAndCondition: formData.termsAndCondition,
      originalInvoiceDate: originalInvoiceDate || formData.originalInvoiceDate,
      originalInvoiceNumber:
        originalInvoiceNumber || formData.originalInvoiceNumber,
      overallDiscount: disAllValue || 0,
      overallDiscountType: discountType || 0,
      totalDiscount: disValue || 0,
      value: discountAmountTcs,
      tcsTax: selectedTcsOption.value,
      taxCheck: bussinessRegistered,
      discountCheck: discountCheck,
      status: "Sent",
      manualCheck: manualCheck,
    };

    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!formData.creditNoteNumber) {
      newValidationMessage += "Please enter credit note number.\n";
    } else if (!formData.creditNoteDate) {
      newValidationMessage += "Please select a credit date \n";
    }
    setValidationMessage1(newValidationMessage);
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
    setIsLoading(true)
    try {
      const response = await fetch(BASE_URL + "/updateCreditNotes", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        swal({
          icon: "success",
          title: `Updated Credit Note`,
        });
        setIsLoading(false)
        props.handleCancel();
      } else {
        swal({
          icon: "error",
          title: `Failed to Update Credit Note`,
        });
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const insertDataIntoDatabaseAsDraft = async () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const formDataObject = {
      uniqueId: decryptedUniqueId,
      items: userDetails.map((userDetail) => ({
        itemDetails: userDetail.itemDetails || "",
        hsnSac: userDetail.hsnSac || "",
        size: userDetail.size || "",
        quantity: userDetail.quantity || "",
        rate: userDetail.rate || "",
        discount: userDetail.discount || "",
        discountType: userDetail.discountType || "",
        amount: calculateAmount(userDetail),
        rowDiscount: userDetail.discount || "",
        description1: userDetail.description1 || "",
        description2: userDetail.description2 || "",
        customerTaxType: userState.state === customerState ? 1 : 2,
        cgst:
          userState.state === customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100 / 2
            : 0,
        sgst:
          userState.state === customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100 / 2
            : 0,
        igst:
          userState.state !== customerState
            ? (calculateAmount(userDetail) * userDetail.tax) / 100
            : 0,
      })),
      ids: ids.map((id) => ({ id })),
      customerName: formData.customerName,
      customerId: selectedCustomerId || formData.customerId,
      creditNoteNumber: formData.creditNoteNumber,
      oldCreditNote: oldCreditNumber,
      reference: formData.reference,
      creditNoteDate: dateValue || formData.creditNoteDate,
      salesPerson: fetchedSalesPerson || formData.salesPerson,
      subject: formData.subject,
      subtotal: calculateSubTotal(),
      adjustment: formData.adjustment,
      adjustmentLabel: formData.adjustmentLabel,
      total: calculateTotal(),
      customerNotes: formData.customerNotes,
      termsAndCondition: formData.termsAndCondition,
      originalInvoiceDate: dateValue1 || formData.originalInvoiceDate,
      originalInvoiceNumber: formData.originalInvoiceNumber,
      overallDiscount: disAllValue || 0,
      overallDiscountType: discountType || 0,
      totalDiscount: disValue || 0,
      value: discountAmountTcs,
      tcsTax: selectedTcsOption.value,
      taxCheck: bussinessRegistered,
      discountCheck: discountCheck,
      status: "Draft",
      manualCheck: manualCheck,
    };

    let newValidationMessage = "";
    if (!formData.customerName) {
      newValidationMessage += "Please select a customer \n";
    } else if (!formData.creditNoteNumber) {
      newValidationMessage += "Please enter credit note number.\n";
    } else if (!formData.creditNoteDate) {
      newValidationMessage += "Please select a credit date \n";
    }
    setValidationMessage1(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }
      setIsLoading2(true)
    try {
      const response = await fetch(BASE_URL + "/updateCreditNotes", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        swal({
          icon: "success",
          title: `Updated Credit Note`,
        });
        setIsLoading2(false)
        props.handleCancel();
      } else {
        swal({
          icon: "error",
          title: `Failed to Update Credit Note`,
        });
        setIsLoading2(false)
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setManualCheck("Yes");
    if (name === "adjustment") {
      setAdjustmentValue(value);
    }
  };

  const handleInputChangess = (e, i) => {
    handleDiscountChange(e, i);
    handleRateChange(e, i);
    handleQuantityChange(e, i);
    const { name, value } = e.target;
    const updatedUserDetails = [...userDetails];
    updatedUserDetails[i][name] = value;

    setUserDetails(updatedUserDetails);

    if (name === "tax") {
      const taxRate = parseFloat(value);
      setSelectedTaxRate(taxRate);
    }
  };

  const handleCustomerChange = (selectedOption) => {
    const selectedValue = selectedOption.value; // Assuming selectedOption has a 'value' property
    setSelectedCustomer(selectedValue);
    setFetchedCustomerName(selectedValue);
    setOriginalInvoiceDate("");
    setFormData({ ...formData, customerName: selectedValue });
    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    const fetchId = formData.customerId;
    const selectId = selectedCustomerObject.id;
    if (fetchId == selectId) {
      setCustomerState(formData.state);
      setSelectedCustomerId(selectedCustomerObject.id);
      setSelectedTerms(selectedCustomerObject.paymentTerms);
    } else if (selectedCustomerObject) {
      setSelectedCustomerId(selectedCustomerObject.id);
      const customerState = selectedCustomerObject.billState;
      setCustomerState(customerState);
    } else {
      console.log("Selected Customer: (Not found)");
    }

    fetch(BASE_URL + "/fetchOriginalInvoiceNumber", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        customerId: selectedCustomerObject.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvs(data.responseMessage);
        } else {
          console.log("Data Not Exist");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleInvoiceChange = (selectedOption) => {
    setOriginalInvoiceNumber(selectedOption.value);
    setFetchedOgInvoiceNumber(selectedOption);
    fetch(BASE_URL + "/fetchOriginalInvoiceDate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        invoiceNumber: selectedOption.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setOriginalInvoiceDate(data.responseMessage[0].invoiceDate);
        } else {
          console.log("Data Not Exist");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const originalInvioceOption = invs.map((inv) => ({
    value: inv.invoiceNumber,
    label: inv.invoiceNumber,
  }));

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...userDetails];
    updatedRow[index].tax = newTaxRate;
    setUserDetails(updatedRow);
    calculateTotal();
  };

  const [itemDescriptions, setItemDescriptions] = useState([]);
  const [itemName, setItemName] = useState("");
  const [showItemModal, setShowItemModal] = useState(false);

  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    description: "",
  });

  const handleItemClose = () => {
    setShowItemModal(false);
    setItemData({
      ...itemData,
      description: "",
    });
  };

  const handleItemChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
  };

  const handleItem = (event) => {
    event.preventDefault();
    const newItemDescription = {
      itemDetails: itemName,
      description: itemData.description,
    };

    setItemDescriptions((prevDescriptions) => {
      const existingIndex = prevDescriptions.findIndex(
        (desc) => desc.itemDetails === itemName
      );
      if (existingIndex !== -1) {
        // Update existing description
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[existingIndex] = newItemDescription;
        return updatedDescriptions;
      } else {
        // Add new description
        return [...prevDescriptions, newItemDescription];
      }
    });
    setUserDetails((prevRows) =>
      prevRows.map((row) =>
        row.itemDetails === itemName
          ? { ...row, description2: itemData.description }
          : row
      )
    );
    setShowItemModal(false);
  };

  const handleItemShow = (itemDetails) => {
    const itemDescription = itemDescriptions.find(
      (desc) => desc.itemDetails === itemDetails
    );
    if (itemDescription) {
      setItemData({ description: itemDescription.description });
    } else {
      setItemData({ description: "" });
    }
    setItemName(itemDetails);
    setShowItemModal(true);
  };

  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);
    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);

  const [selectedTcsOption, setSelectedTcsOption] = useState(null);

  const tcs = [
    { value: "5 GST [5%]", label: "5 GST [5%]", discount: 5 },
    { value: "12 GST [12%]", label: "12 GST [12%]", discount: 12 },
    { value: "18 GST [18%]", label: "18 GST [18%]", discount: 18 },
    { value: "28 GST [28%]", label: "28 GST [28%]", discount: 28 },
  ];

  const [discountAmountTcs, setDiscountAmountTcs] = useState(0.0);
  const applyDiscountTcs = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tcsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTcs(parseFloat(discountAmount.toFixed(2))); // Set discountAmount in state
    return tcsDis;
  };
  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
    const subtotal = calculateSubTotal();
    // Check if a TDS option is selected and has a discount
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedOption.discount
      );
      console.log(
        `Subtotal: ${subtotal}, Discounted Total: ${discountedTotal}`
      );
    }
  };

  const [showDueDate, setShowDueDate] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");
  const [dateValue1, setDateValue1] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    setFormData({ ...formData, terms: selectedTerms || formData.terms });
    let normalFormat = date === null ? "" : normalDateFormat(date);
    setDateValue(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const optionsSalesPerson = salesPersonData.map((person) => ({
    value: person.name,
    label: person.name,
  }));

  const [customerFormData, setCustomerFormData] = useState({
    uniqueId: decryptedUniqueId,
    customerType: "Business",
    status: "Active",
    someName: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "",
    customerName: "",
    email: "",
    workphone: "",
    mobile: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "0",
    paymentTerms: "",
    facebook: "",
    twitter: "",
    billAttention: "",
    billCountry: "India",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "Maharashtra",
    billZipcode: "",
    billPhone: "",
    billFax: "",
    shipAttention: "",
    shipCountry: "India",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "Maharashtra",
    shipZipcode: "",
    shipPhone: "",
    shipFax: "",
    gstin: "",
    pan: "",
    placeOfSupply: "[MH] - Maharashtra",
    taxable: "",
    reason: "N/A",
    activeStatus: "Active",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        otherFirstname: "",
        otherLastname: "",
        otherEmail: "",
        otherWorkphone: "",
        otherMobile: "",
      },
    ],
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCustomerFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleSaveClick = () => {
    const jwtToken = localStorage.getItem("jwtToken");
    let newValidationMessage = "";
    if (!customerFormData.customerName) {
      newValidationMessage += "Please enter a customer display name.\n";
    } else if (
      customerFormData.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerFormData.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }
    setValidationMessage(newValidationMessage);
    if (newValidationMessage) {
      return; // Exit function if validation fails
    }
    fetch(BASE_URL + "/createCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(customerFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          swal({
            icon: "success",
            title: "Customer Created",
            text: "",
          });
          setShow(false);
          fetchAllCustomers();
        } else if (data.responseCode === "400") {
          swal({
            icon: "error",
            title: "This email already exists, please insert another email.",
            text: "",
          });
          setShow(true);
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Customer!",
            text: "",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShow(true);
      });
  };

  const customStyles3 = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#F6F6F6",
      borderRadius: "7px",
      height: "20px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: "8px",
      height: "20px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };

  useEffect(() => {
    if (selectedTcsOption && selectedTcsOption.discount) {
      const subtotal = calculateSubTotal();
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedTcsOption.discount
      );
    }
  }, [userDetails]);

  const fetchSalesData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSalesPersonData(data.responseMessage);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  };
  useEffect(() => {
    fetchSalesData();
  }, []);

  const [showSalesModal, setShowSalesModal] = useState(false);
  const [salesData, setSalesData] = useState({
    uniqueId: decryptedUniqueId,
    name: "",
    email: "",
  });

  const handleSalesClose = () => setShowSalesModal(false);
  const handleSalesShow = () => setShowSalesModal(true);
  const [validationMessage2, setValidationMessage2] = useState("");
  const handleSalesPerson = (event) => {
    let newValidationMessage = "";
    if (!salesData.name) {
      newValidationMessage += "Please enter a name.\n";
    }
    setValidationMessage2(newValidationMessage);
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    event.preventDefault();

    fetch(BASE_URL + "/createSalesperson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Sales Person Created",
          });
          setShowSalesModal(false);
          fetchSalesData();
        } else {
          swal({
            icon: "error",
            title: "Failed To Create Sales Person!",
          });
          setShowSalesModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occured While Creating",
        });
        setShowSalesModal(true);
      });
  };

  const generateNamePairs = () => {
    const { someName, firstName, lastName, companyName } = customerFormData;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${someName} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };

  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  useEffect(() => {
    if (fetchedCustomerName) {
      const selectedCustomerObject = custNames.find(
        (person) => person.customerName === fetchedCustomerName
      );

      if (selectedCustomerObject) {
        const customerState = selectedCustomerObject.billState;
        setCustomerState(customerState);
      }
    }
  }, [fetchedCustomerName, custNames]);

  useEffect(() => {
    fetch(BASE_URL + "/fetchOriginalInvoiceNumber", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        customerId: fetchedCustomerId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvs(data.responseMessage);
        } else {
          console.log("Data Not Exist");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [fetchedCustomerId]);

  return (
    <>
      <div className="my-3">
        <div className="d-flex align-items-center ml-1 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={handleCancleClick}
              className="arrow-child"
            />
          </div>
          <div className="newcustomer-head ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              Update Credit Note
            </h5>
          </div>
        </div>
      </div>
      {validationMessage1 && (
        <Alert severity="error" className="mt-0">
          {validationMessage1.split("\n").map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </Alert>
      )}{" "}
      <div className="card border-0 my-3">
        <div className="pl-4 pr-4">
          <div className="row d-inline-flex pt-3">
            <div className="salespersonbox">
              <div className="cntext-style">
                Customer Name <span className="valid"> *</span>
              </div>              
              <Select
                value={customerOptions.find(
                  (option) => option.value === fetchedCustomerName
                )}
                options={customerOptions}
                onChange={(selectedOption) =>
                  handleCustomerChange(selectedOption)
                }
                placeholder="Select Customer"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: "10px",
                    width: "260.8px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    height: "46px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "19px",
                  }),
                }}
              />
              <button type="button" class="btn p-0" onClick={handleShow}>
                <span
                  class="text-primary"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  New Customer
                </span>
              </button>

              <Modal size="lg" show={show} onHide={handleClose}>
                <div class="modal-header d-flex align-items-center pb-2 pt-2">
                  <h5 class="modal-title fs-5" id="newcustomerPopup">
                    New Customer
                  </h5>
                  <button
                    type="button"
                    className="btn pr-0"
                    onClick={handleClose}
                  >
                    <ion-icon
                      name="close-circle-outline"
                      size="large"
                      style={{ width: "28px" }}
                    ></ion-icon>
                  </button>
                </div>
                <Modal.Body className="p-0 pb-3">
                  {validationMessage && (
                    <Alert severity="error" className="mt-0">
                      {validationMessage.split("\n").map((message, index) => (
                        <div key={index}>{message}</div>
                      ))}
                    </Alert>
                  )}{" "}
                  <div className="container">
                    <div className="row d-inline-flex">
                      <div className="modalinputchild">
                        <div className="text-style"> Salutation </div>
                        <select
                          className="form-control"
                          name="someName"
                          value={customerFormData.someName}
                          onChange={handleChange}
                          style={{
                            borderRadius: "9px",
                            backgroundColor: "#f5f5f5",
                            width: "200px",
                            height: "35px",
                            fontSize: "13px",
                            marginTop: "10px",
                          }}
                        >
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Ms.">Ms.</option>
                          <option value="Miss.">Miss.</option>
                          <option value="Dr.">Dr.</option>
                        </select>
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">First Name</div>
                        <input
                          className="form-control"
                          placeholder="First Name"
                          name="firstName"
                          id="firstName"
                          value={customerFormData.firstName}
                          onChange={handleChange}
                          type="text"
                        />
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">Last Name</div>
                        <input
                          className="form-control"
                          placeholder="Last Name"
                          id="lastName"
                          name="lastName"
                          value={customerFormData.lastName}
                          onChange={handleChange}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="row d-inline-flex pt-2">
                      <div className="modalinputchild">
                        <div className="text-style">
                          {" "}
                          Customer Email{" "}
                          <span style={{ color: "red" }}> *</span>
                        </div>
                        <input
                          className="form-control"
                          placeholder="Customer Email"
                          name="email"
                          type="text"
                          value={customerFormData.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="modalinputchild">
                        <div className="text-style">
                          {" "}
                          Customer Display Name{" "}
                          <span style={{ color: "red" }}> *</span>
                        </div>
                        <input
                          className="form-control"
                          placeholder="Customer Display Name"
                          list="customerNames"
                          name="customerName"
                          value={customerFormData.customerName}
                          onChange={handleChange}
                          style={{
                            borderRadius: "7px",
                            backgroundColor: "#f5f5f5",
                            width: "200px",
                            height: "40px",
                            fontSize: "13px",
                            marginTop: "10px",
                          }}
                        />
                        <datalist id="customerNames">
                          {generateNamePairs().map((pair, index) => (
                            <option key={index} value={pair} />
                          ))}
                        </datalist>{" "}
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">Company Name</div>
                        <input
                          className="form-control"
                          placeholder="Add company name"
                          name="companyName"
                          type="text"
                          value={customerFormData.companyName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row d-inline-flex pt-2">
                      <div className="modalinputchild">
                        <div className="text-style">Customer Phone</div>
                        <input
                          className="form-control"
                          placeholder="Work Phone"
                          name="workphone"
                          type="text"
                          value={customerFormData.workphone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="modalinputchild">
                        <div className="text-style">Mobile</div>
                        <input
                          className="form-control"
                          placeholder="Mobile"
                          name="mobile"
                          type="text"
                          value={customerFormData.mobile}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="p-2">
                  <div className="modal-bottombtns">
                    <button
                      type="button"
                      class="modalbtn-1 btn mt-0"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="modalbtn-3 btn btn-primary"
                      onClick={handleSaveClick}
                    >
                      Create Customer
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
            {fetchedCustomerName && (
              <>
                <div className="salespersonbox">
                  <div className="cntext-style">Original Invoice Number</div>
                  <Select
                    value={originalInvioceOption.find(
                      (option) => option.value === fetchedOgInvoiceNumber
                    )}
                    options={originalInvioceOption}
                    isSearchable                   
                    onChange={(selectedOption) => {
                      handleInvoiceChange(selectedOption);
                    }}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        marginTop: "10px",
                        width: "260.8px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "#F6F6F6",
                        borderRadius: "7px",
                        height: "46px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        height: "19px",
                      }),
                    }}
                  />
                </div>
              </>
            )}
            <div className="cninputchild">
              <div className="cntext-style">Original Invoice Date</div>
              <input
                className="form-control"
                type="text"
                value={originalInvoiceDate}
                style={{
                  padding: "12px",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "7px",
                  color: "black",
                }}
                readOnly
              />
            </div>
          </div>

          <div className="row pt-2">
            <div className="cninputchild">
              <div className="cntext-style">
                Credit Note <span className="valid"> *</span>
              </div>
              <input
                className="form-control"
                placeholder="Credit Note Number"
                id=""
                name="creditNoteNumber"
                value={formData.creditNoteNumber}
                onChange={handleInputChange}
                type="text"
                readOnly
              />
              <button
                type="button"
                className="btn p-0"
                data-bs-toggle="modal"
                data-bs-target="#generatePop"
              >
                <span
                  className="text-primary"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  Generate
                </span>
              </button>

              <div
                className="modal fade"
                id="generatePop"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header d-flex align-items-center pb-2 pt-2">
                      <h5 className="modal-title fs-5" id="generateinvoiceHead">
                        Credit Note Number
                      </h5>
                      <button
                        type="button"
                        className="btn pr-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <ion-icon
                          name="close-circle-outline"
                          size="large"
                          style={{ width: "28px" }}
                        ></ion-icon>
                      </button>
                    </div>
                    <div className="modal-body pt-1 pb-1">
                      <div className="popupinvoice-container ml-2 mt-2 mr-2 mb-0">
                        <div className="row headline">
                          <div className="customer-headline-label">
                            <label className="form-label">
                              <span className="headline">
                                Your credit note numbers are set on
                                auto-generate mode to save your time.
                                <br /> Are you sure about changing this setting?
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <label className="customer-rdiobox-label d-flex align-items-center">
                            <input
                              className="mr-2"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Business"
                              checked={show1 === true ? true : false}
                              onClick={setBill}
                            />
                            <span className="customer-option">
                              Continue auto-generating credit note numbers
                            </span>
                          </label>
                        </div>
                        {show1 && (
                          <div className="row ml-3 mb-3">
                            <div className="customer-generate-inputfield">
                              <span>Prefix</span>
                              <input
                                className="form-control mt-1"
                                placeholder="Prefix"
                                value={invoiceNumberPrefix}
                                onChange={(e) =>
                                  setInvoiceNumberPrefix(e.target.value)
                                }
                              />
                            </div>
                            <div className="customer-generate-inputfield2">
                              <span>Next Number</span>
                              <input
                                className="form-control mt-1"
                                placeholder="Next Number"
                                value={invoiceNumberNext}
                                onChange={(e) =>
                                  setInvoiceNumberNext(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="row mt-1">
                          <label className="customer-rdiobox-label d-flex align-items-center">
                            <input
                              className="mr-2"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Business"
                              checked={show1 === false ? true : false}
                              onClick={() => {
                                setShow1(false);
                              }}
                            />
                            <span className="customer-option">
                              I will add them manually each time
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="modal-bottombtns">
                        <button
                          type="button"
                          class="modalbtn-1 btn mt-0"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="modalbtn-3 btn btn-primary"
                          onClick={() => {
                            copyAdd();
                          }}
                          data-bs-dismiss="modal"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Reference */}
            <div className="cninputchild">
              <div className="cntext-style">Reference</div>
              <input
                className="form-control"
                placeholder="Reference"
                name="reference"
                value={formData.reference}
                onChange={handleInputChange}
                type="text"
              />
            </div>

            {/* date */}
            <div className="cninputchild">
              <div className="cntext-style">
                Credit Note Date <span className="valid"> *</span>
              </div>
              <div>
                {showDueDate ? (
                  <input
                    className="form-control"
                    type="text"
                    value={formData.creditNoteDate}
                    onClick={(e) => {
                      setShowDueDate(false);
                      setFormData({
                        ...formData,
                        creditNoteDate: normalDateFormat(startDate),
                      });
                    }}
                    readOnly
                  />
                ) : (
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => handleDate(date)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="salespersonbox">
              <label className="cntext-style">Sales Person</label>
              <Select
                options={optionsSalesPerson}
                value={optionsSalesPerson.find(
                  (option) => option.value === fetchedSalesPerson
                )}
                onChange={(selectedOption) =>
                  handleSalesPersonChange(
                    selectedOption.label,
                    selectedOption.value
                  )
                }
                placeholder="Select a SalesPerson"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "260.8px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#F6F6F6",
                    borderRadius: "7px",
                    height: "46px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    height: "19px",
                  }),
                }}
              />
              <button type="button" className="btn p-0" onClick={openPopup}>
                <span
                  class="text-primary"
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  New Sales Person
                </span>
              </button>
              <Modal show={showSalesModal} onHide={handleSalesClose}>
                <div class="modal-header d-flex align-items-center pb-2 pt-2">
                  <h5 class="modal-title fs-5" id="salespersonHead">
                    Add Sales Person
                  </h5>
                  <button
                    type="button"
                    className="btn pr-0"
                    onClick={handleSalesClose}
                  >
                    <ion-icon
                      name="close-circle-outline"
                      size="large"
                      style={{ width: "28px" }}
                    ></ion-icon>
                  </button>
                </div>
                <Modal.Body>
                  {validationMessage2 && (
                    <Alert severity="error" className="mt-0">
                      {validationMessage1.split("\n").map((message, index) => (
                        <div key={index}>{message}</div>
                      ))}
                    </Alert>
                  )}{" "}
                  <div className="row pt-2">
                    <div className="modalsalesinput">
                      <label className="form-label">
                        <span style={{ color: "red" }}>Name *</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Name"
                        id="name"
                        name="name"
                        type="text"
                        value={salesData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="modalsalesinput">
                      <label className="form-label">
                        <span>Email </span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Email"
                        id="email"
                        name="email"
                        type="text"
                        value={salesData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="p-2">
                  <div className="modal-bottombtns">
                    <button
                      type="button"
                      class="modalbtn-1 btn mt-0"
                      onClick={handleSalesClose}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="modalbtn-3 btn btn-primary"
                      onClick={handleSalesPerson}
                      data-bs-dismiss="modal"
                    >
                      Create Sales Person
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="subjectbox-cn">
              <label className="cntext-style">Subject</label>
              <input
                className="form-control "
                placeholder="Subject..."
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                type="text"
              />
            </div>
          </div>

          <div>
            <div>
              <Row className="mt-4">
                <Col>
                  <Card className="mb-3">
                    <div className="">
                      <table className="table table-bordered mb-0">
                        <thead className="text-center">
                          <tr>
                            <th scope="col" style={{ color: "grey" }}>
                              ITEM DETAILS
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              ACCOUNT
                            </th>
                            {hsnCheck === "YES" && (
                              <th scope="col" style={{ color: "grey" }}>
                                HSN/SAC
                              </th>
                            )}
                            {sizeCheck === "YES" && (
                              <th scope="col" style={{ color: "grey" }}>
                                SIZE
                              </th>
                            )}
                            <th scope="col" style={{ color: "grey" }}>
                              QUANTITY
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              RATE
                            </th>
                            {discountCheck === "YES" && (
                              <th scope="col" style={{ color: "grey" }}>
                                DISCOUNT
                              </th>
                            )}
                            {bussinessRegistered === "YES" && (
                              <th scope="col" style={{ color: "grey" }}>
                                TAX
                              </th>
                            )}
                            <th scope="col" style={{ color: "grey" }}>
                              AMOUNT
                            </th>
                            <th scope="col" style={{ color: "grey" }}>
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        {userDetails.map((data, i) => {
                          const amount = calculateAmount(data, data.tax);
                          const totalAmountWithTax = parseFloat(amount);
                          const itemDescription = itemDescriptions.find(
                            (desc) => desc.itemDetails === data.itemDetails
                          );
                          return (
                            <tbody key={i}>
                              <tr>
                                <td style={{ width: "35%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      className="form-control noBorder"
                                      placeholder="Search items..."
                                      value={data.itemDetails}
                                      onChange={(e) =>
                                        handleItemDetailsChange(e, i)
                                      }
                                      style={{ flex: 1 }}
                                    />
                                    {data.itemDetails && (
                                      <button
                                        type="button"
                                        className="btn p-0"
                                        onClick={() =>
                                          handleItemShow(data.itemDetails)
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <span
                                          className="text-primary"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Edit
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                  {itemDescription && (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        color: "#6c757d",
                                        whiteSpace: "pre-line"
                                      }}
                                    >
                                      {itemDescription.description}
                                    </div>
                                  )}
                                  {searchPerformed &&
                                    data.itemDetails &&
                                    i === searchIndex && (
                                      <div
                                        style={{
                                          position: "relative",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {searchResults.length === 0 && ""}
                                        {searchResults.length > 0 && (
                                          <ul
                                            style={{
                                              listStyleType: "none",
                                              padding: 0,
                                              position: "absolute",
                                              top: "calc(100% + 10px)",
                                              left: 0,
                                              zIndex: 999,
                                              backgroundColor: "#FFF",
                                              boxShadow:
                                                "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                              borderRadius: "5px",
                                              width: "100%",
                                            }}
                                          >
                                            {searchResults.map(
                                              (result, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() =>
                                                    handleItemSelect(
                                                      result.name,
                                                      result.id,
                                                      i
                                                    )
                                                  }
                                                  onMouseEnter={(e) => {
                                                    e.target.style.backgroundColor =
                                                      "#408dfb";
                                                    e.target.style.color =
                                                      "#FFF";
                                                    e.target.style.borderRadius =
                                                      "5px";
                                                  }}
                                                  onMouseLeave={(e) => {
                                                    e.target.style.backgroundColor =
                                                      "";
                                                    e.target.style.color =
                                                      "#000";
                                                    e.target.style.borderRadius =
                                                      "";
                                                  }}
                                                  style={{
                                                    padding: "12px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {result.name}
                                                  <br />
                                                  Rate : ₹{result.taxableAmount}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                    )}
                                  <Modal
                                    show={showItemModal}
                                    onHide={handleItemClose}
                                  >
                                    <div className="modal-header d-flex align-items-center pb-2 pt-2">
                                      <h5
                                        className="modal-title fs-5"
                                        id="salespersonHead"
                                      >
                                        Add Item Description
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn pr-0"
                                        onClick={handleItemClose}
                                      >
                                        <ion-icon
                                          name="close-circle-outline"
                                          size="large"
                                          style={{ width: "28px" }}
                                        ></ion-icon>
                                      </button>
                                    </div>
                                    <Modal.Body>
                                    <Row>
                                          <Col md="12">
                                            <div className="row pt-2">
                                              <div className="col-lg-6">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "grey",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  Item
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 pl-0 pr-0">
                                              <input
                                                className="form-control"
                                                placeholder="Name"
                                                id="name"
                                                name="name"
                                                type="text"
                                                value={itemName}
                                                readOnly
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <div className="row pt-2">
                                              <div className="col-lg-6">
                                                <label
                                                  className="form-label"
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "grey",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  Description
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-lg-12 pl-0 pr-0">
                                              <textarea
                                                className="cn-textarea"
                                                type="text"
                                                placeholder="Item Description"
                                                name="description"
                                                value={itemData.description}
                                                onChange={handleItemChange}
                                                rows={2}
                                                style={{
                                                  backgroundColor: "#F6F6F6",
                                                  borderRadius: "9px",
                                                  padding: "12px",
                                                  width: '100%'
                                                }}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                  </Modal.Body>
                                    <Modal.Footer className="p-2">
                                      <div className="modal-bottombtns">
                                        <button
                                          type="button"
                                          className="modalbtn-1 btn mt-0"
                                          onClick={handleItemClose}
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="button"
                                          className="modalbtn-3 btn btn-primary"
                                          onClick={handleItem}
                                          data-bs-dismiss="modal"
                                        >
                                          Add Description
                                        </button>
                                      </div>
                                    </Modal.Footer>
                                  </Modal>
                                </td>

                                <td style={{ width: "10%" }}>
                                  <select
                                    className="form-control"
                                    style={{ width: "100px", padding: "0px" }}
                                    name="account"
                                    value={data.account}
                                    onChange={(e) => handleInputChangess(e, i)}
                                  >
                                    <option
                                      disabled="true"
                                      selected="true"
                                      style={{ color: "black" }}
                                    >
                                      Other Current Assets
                                    </option>
                                    <option value="Advance Tax">
                                      Advance Tax
                                    </option>
                                    <option value="Employee Advance">
                                      Employee Advance
                                    </option>
                                    <option value="Input Tax Credits">
                                      Input Tax Credits
                                    </option>
                                    <option value="Input CGST">
                                      {" "}
                                      - Input CGST
                                    </option>
                                    <option value="Input IGST">
                                      {" "}
                                      - Input IGST
                                    </option>
                                    <option value="Input SGST">
                                      {" "}
                                      - Input SGST
                                    </option>
                                    <option value="Prepaid Expenses">
                                      Prepaid Expenses
                                    </option>
                                    <option value="Reverse Charge Tax Input but not due">
                                      Reverse Charge Tax Input but not due
                                    </option>
                                    <option value="TDS Receivable">
                                      TDS Receivable
                                    </option>
                                    <option
                                      disabled="true"
                                      style={{ color: "black" }}
                                    >
                                      Fixed Assets
                                    </option>
                                    <option value="Furniture and Equipment">
                                      Furniture and Equipment
                                    </option>
                                    <option
                                      disabled="true"
                                      style={{ color: "black" }}
                                    >
                                      Other Current Liability
                                    </option>
                                    <option value="Employee Reimbursements">
                                      Employee Reimbursements
                                    </option>
                                    <option value="GST Payable">
                                      GST Payable
                                    </option>
                                    <option value="Output CGST">
                                      {" "}
                                      - Output CGST
                                    </option>
                                    <option value="Output IGST">
                                      {" "}
                                      - Output IGST
                                    </option>
                                    <option value="Output SGST">
                                      {" "}
                                      - Output SGST
                                    </option>
                                    <option value="Opening Balance Adjustments">
                                      Opening Balance Adjustments
                                    </option>
                                    <option value="Tax Payable">
                                      Tax Payable
                                    </option>
                                    <option value="TDS Payable">
                                      TDS Payable
                                    </option>
                                    <option value="Unearned Revenue">
                                      Unearned Revenue
                                    </option>
                                    <option
                                      disabled="true"
                                      style={{ color: "black" }}
                                    >
                                      Expense
                                    </option>
                                    <option value="Advertising And Marketing">
                                      Advertising And Marketing
                                    </option>
                                    <option value="Automobile Expense">
                                      Automobile Expense
                                    </option>
                                    <option value="Bad Debt">Bad Debt</option>
                                    <option value="Bank Fees and Charges">
                                      Bank Fees and Charges
                                    </option>
                                    <option value="Consultant Expense">
                                      Consultant Expense
                                    </option>
                                    <option value="Contract Assets">
                                      Contract Assets
                                    </option>
                                    <option value="Credit Card Charges">
                                      Credit Card Charges
                                    </option>
                                    <option value="Depreciation And Amortisation">
                                      Depreciation And Amortisation
                                    </option>
                                    <option value="Depreciation Expense">
                                      Depreciation Expense
                                    </option>
                                    <option value="IT and Internet Expenses">
                                      IT and Internet Expenses
                                    </option>
                                    <option value="Janitorial Expense">
                                      Janitorial Expense
                                    </option>
                                    <option value="Lodging">Lodging</option>
                                    <option value="Meals and Entertainment">
                                      Meals and Entertainment
                                    </option>
                                    <option value="Merchandise">
                                      Merchandise
                                    </option>
                                    <option value="Office Supplies">
                                      Office Supplies
                                    </option>
                                    <option value="Other Expenses">
                                      Other Expenses
                                    </option>
                                    <option value="Postage">Postage</option>
                                    <option value="Printing and Stationery">
                                      Printing and Stationery
                                    </option>
                                    <option value="Raw Materials And Consumables">
                                      Raw Materials And Consumables
                                    </option>
                                    <option value="Rent Expense">
                                      Rent Expense
                                    </option>
                                    <option value="Repairs and Maintenance">
                                      Repairs and Maintenance
                                    </option>
                                    <option value="Salaries and Employee Wages">
                                      Salaries and Employee Wages
                                    </option>
                                    <option value="Telephone Expense">
                                      Telephone Expense
                                    </option>
                                    <option value="Transportation Expense">
                                      Transportation Expense
                                    </option>
                                    <option value="Travel Expense">
                                      Travel Expense
                                    </option>
                                    <option value="Uncategorized">
                                      Uncategorized
                                    </option>
                                    <option
                                      value=""
                                      disabled=""
                                      style={{ color: "black" }}
                                    >
                                      Cost Of Good Sold
                                    </option>
                                    <option value="Cost of Goods Sold">
                                      Cost of Goods Sold
                                    </option>
                                    <option value="Job Costing">
                                      Job Costing
                                    </option>
                                    <option value="Labor">Labor</option>
                                    <option value="Materials">Materials</option>
                                    <option value="Subcontractor">
                                      Subcontractor
                                    </option>
                                  </select>
                                </td>
                                {hsnCheck === "YES" && (
                                  <td style={{ width: "10%" }}>
                                    <input
                                      className="form-control noArrowNumber noBorder bg-white"
                                      value={data.hsnSac || ""}
                                      type="number"
                                      name="hsnSac"
                                      onChange={(e) =>
                                        handleInputChangess(e, i)
                                      }
                                    />
                                  </td>
                                )}
                                {sizeCheck === "YES" && (
                                  <td style={{ width: "10%" }}>
                                    <input
                                      className="form-control noArrowNumber noBorder"
                                      value={data.size || ""}
                                      type="text"
                                      name="size"
                                      onChange={(e) =>
                                        handleInputChangess(e, i)
                                      }
                                    />
                                  </td>
                                )}
                                <td style={{ width: "10%" }}>
                                  <input
                                    className="form-control"
                                    value={data.quantity}
                                    onChange={(e) => handleInputChangess(e, i)}
                                    type="number"
                                    name="quantity"
                                    style={{ border: "none" }}
                                  />
                                </td>

                                <td style={{ width: "10%" }}>
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="rate"
                                    value={data.rate}
                                    onChange={(e) => handleInputChangess(e, i)}
                                    style={{ border: "none" }}
                                  />
                                </td>
                                {discountCheck === "YES" && (
                                  <td style={{ width: "10%", padding: "0px" }}>
                                    <div className="d-flex justify-content-center">
                                      <input
                                        className="form-control col-md-6 noBorder"
                                        name="discount"
                                        value={data.discount || ""}
                                        type="number"
                                        onChange={(e) =>
                                          handleInputChangess(e, i)
                                        }
                                        style={{
                                          textAlign: "center",
                                          padding: "0px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      <select
                                        className="border-0 noBorder"
                                        value={data.discountType}
                                        onChange={(e) =>
                                          handleInputChangess(e, i)
                                        }
                                        name="discountType"
                                        style={{
                                          textAlign: "center",
                                          padding: "0px",
                                          marginRight: "5px",
                                        }}
                                      >
                                        <option value="₹">₹</option>
                                        <option value="%">%</option>
                                      </select>
                                    </div>
                                  </td>
                                )}
                                {bussinessRegistered === "YES" && (
                                  <th style={{ width: "10%" }}>
                                    <select
                                      className="form-control col-md-18"
                                      name="tax"
                                      value={data.tax}
                                      onChange={(e) => handleTaxChange(e, i)}
                                    >
                                      <option value="0">N/A</option>
                                      <option value="5">5 GST [5%]</option>
                                      <option value="12">12 GST[12%]</option>
                                      <option value="18">18 GST [18%]</option>
                                      <option value="28">28 GST[28%]</option>
                                    </select>
                                  </th>
                                )}
                                <td style={{ width: "10%" }}>
                                  <span>
                                    {parseFloat(totalAmountWithTax).toFixed(2)}
                                  </span>
                                </td>

                                <td
                                  className="text-center"
                                  style={{ width: "10%" }}
                                >
                                  <button
                                    className="btn px-2 btn-light icon-border btn-sm"
                                    onClick={() => handleDeleteRow(i)}
                                  >
                                    <i className="fa fa-trash text-danger"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <button
                    className="btn addline-btn"
                    type="button"
                    onClick={handleAdd}
                  >
                    + Add New Row
                  </button>
                </Col>
              </Row>

              <hr />

              <div className="grid-container">
                <div className="flex-item p-0 d-flex flex-column-reverse">
                  <div className="row pt-3">
                    <div className="col-lg-6">
                      <label
                        className="form-label "
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Notes
                      </label>
                    </div>

                    <div className="col-lg-12">
                      <textarea
                        className="form-control cnotes-textarea"
                        placeholder="Customer Notes..."
                        name="customerNotes"
                        value={formData.customerNotes}
                        onChange={handleInputChange}
                        type="text"
                        rows={1}
                        style={{
                          backgroundColor: "#F6F6F6",
                          borderRadius: "7px",
                          padding: "12px",
                        }}
                      />                     
                    </div>
                  </div>
                </div>
                <div className="cnsubtotal-box p-2">
                  <div className="flex-item pb-1">
                    <div className="row">
                      <div className="col-lg-8">
                        <label className="form-label">Sub Total</label>
                      </div>
                      <div className="col-lg-4 text-end pl-0">
                        <label className="form-label">
                          {calculateSubTotal()}
                        </label>
                      </div>
                    </div>

                    {discountCheck === "YES" && (
                      <div className="row d-flex align-items-baseline mt-1">
                        <div className="col-lg-5 pr-0">
                          <label>Discount</label>
                        </div>
                        <div className="col-lg-3 pl-0 d-flex">
                          <input
                            className="form-control"
                            value={disAllValue || "0"}
                            type="number"
                            name="discount"
                            onChange={handleDisAllValueChange}
                            style={{
                              height: "36px",
                              padding: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          />

                          <select
                            className="form-control ml-1 pl-0 rounded"
                            name="discountType"
                            value={discountType}
                            onChange={handleDiscountTypeChange}
                            style={{
                              height: "36px",
                              width: "50px",
                              paddin: "3px 8px",
                              backgroundColor: "#F6F6F6",
                            }}
                          >
                            <option disabled>Select</option>
                            <option value="₹">₹</option>
                            <option value="%">%</option>
                          </select>
                        </div>
                        <div className="col-lg-4 text-end pl-0">
                          <label className="form-label textColor-red">
                            {" "}
                            - {parseFloat(disValue).toFixed(2)}
                          </label>
                        </div>
                      </div>
                    )}

                    {bussinessRegistered === "YES" && (
                      <div className="row mt-2">
                        {userState.state !== customerState && (
                          <>
                            {calculateIGSTForTax().map((taxResult, index) => (
                              <React.Fragment key={index}>
                                <div className="col-lg-8">
                                  <label className="form-label required">
                                    IGST ({(taxResult.taxRate * 100).toFixed(0)}
                                    %)
                                  </label>
                                </div>
                                <div className="col-lg-4 text-end pl-0">
                                  <label className="form-label required">
                                    {taxResult.totalIGST.toFixed(2)}
                                  </label>
                                </div>
                              </React.Fragment>
                            ))}
                          </>
                        )}

                        {userState.state === customerState && (
                          <>
                            {Object.entries(calculateGroupedSGSTandCGST()).map(
                              ([taxRate, taxData], index) => (
                                <React.Fragment key={index}>
                                  <div className="col-lg-8">
                                    <label className="form-label required">
                                      CGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0">
                                    <label className="form-label required">
                                      {taxData.totalCGST.toFixed(2)}
                                    </label>
                                  </div>
                                  <div className="col-lg-8 mt-2">
                                    <label className="form-label required">
                                      SGST (
                                      {((parseFloat(taxRate) * 100) / 2)
                                        .toFixed(1)
                                        .replace(/\.0$/, "")}
                                      %)
                                    </label>
                                  </div>
                                  <div className="col-lg-4 text-end pl-0 mt-2">
                                    <label className="form-label required">
                                      {taxData.totalSGST.toFixed(2)}
                                    </label>
                                  </div>
                                </React.Fragment>
                              )
                            )}
                          </>
                        )}
                      </div>
                    )}

                    <div className="row mt-3 mb-3">
                      <div className="col-lg-5 pr-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Adjustment Label" // Provide a placeholder for user input
                          value={formData.adjustmentLabel}
                          onChange={handleInputChange}
                          name="adjustmentLabel"
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            color: "black",
                          }}
                        />
                      </div>
                      <div className="col-lg-5 pl-0">
                        <input
                          className="form-control col-md-10"
                          type="text"
                          placeholder="Label"
                          name="adjustment"
                          value={formData.adjustment}
                          onChange={handleInputChange}
                          style={{
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            color: "black",
                          }}
                        />
                      </div>
                      <div className="col-lg-2 text-end pl-0">
                        <label className="form-label mt-2">
                          {formData.adjustment || oppositeValue}
                        </label>
                      </div>
                    </div>

                    <div className="row d-flex align-items-baseline mb-3">
                      <div className="col-lg-5 pr-0">
                        <label className="form-label">TCS</label>
                      </div>
                      <div className="col-lg-5 pl-0">
                        <Select
                          className="p-0 col-md-10"
                          options={tcs}
                          value={selectedTcsOption}
                          onChange={handleTcsOptionChange}
                          placeholder="Select TCS..."
                          styles={customStyles3}
                        />
                      </div>
                      <div className="col-lg-2 text-end pl-0">
                        <label className="form-label mt-2">
                          {discountAmountTcs || formData.value}
                        </label>
                      </div>
                    </div>

                    <hr className="mt-0" />
                    <div className="row mt-3">
                      <div className="col-lg-8">
                        <label className="form-label required ">
                          Total (₹) :
                        </label>
                      </div>
                      <div className="col-lg-4 text-end pl-0">
                        <label className="form-label required">
                          {calculateTotal()}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-4" />

              <div className="row pt-2 align-items-center">
                <div className="col-lg-6">
                  <label
                    className="form-label"
                    style={{ fontSize: "14px", color: "grey" }}
                  >
                    Terms & Condition
                  </label>
                </div>
                <div className="col-lg-12">
                  <textarea
                    className="invoicetextarea"
                    placeholder="Add your terms & condition"
                    name="termsAndCondition"
                    value={formData.termsAndCondition}
                    onChange={handleInputChange}
                    type="text"
                    rows={4}
                    style={{
                      backgroundColor: "#F6F6F6",
                      borderRadius: "9px",
                      padding: "12px",
                      height: "100%",
                    }}
                  />
                   <i className="text-muted">
                            This Terms and condition will be displayed on the credit note
                          </i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right cn-bottom-buttons">
          <button
            type="button"
            className="btn-1 btn mt-0"
            onClick={handleCancleClick}
          >
            Cancel
          </button>
          {props.fetchedStatus === "Draft" && (
            <button
              type="button"
              className="btn-1 btn mt-0"
              onClick={insertDataIntoDatabaseAsDraft}
            >
              {isLoading2 ? "Saving..." : "Save as Draft"}
            </button>
          )}
          <button
            type="button"
            className="btn-3 btn btn-primary"
            onClick={insertDataIntoDatabase}
          >
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </>
  );
}

export default CreditNotesUpdate;
