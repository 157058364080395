import React from "react";
import "../../asset/css/inbox.css";
import { Container, Card, Row, Col } from "react-bootstrap";
class ManageStockCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompose: false,
    };
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped mb-0">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">SERIAL NO</th>
                          <th scope="col">NAME</th>
                          <th scope="col">OLD STOCK</th>
                          <th scope="col">ENTER STOCK</th>
                          <th scope="col"> FINAL STOCK</th>
                          <th scope="col">UPDATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>1</th>
                          <td class="wd-5p text-center">
                            <a href="">
                              <b>Hemesh </b>
                            </a>
                          </td>
                          <td id="td-1" class="wd-5p text-center" >
                            <input
                              type="number"
                              name="oldStock"
                              readonly=""
                              id="oldStock1"
                              fdprocessedid="n4980o"
                              
                            />
                          </td>
                          <td id="td-1" class="wd-5p text-center">
                            <input
                              type="number"
                              name="stockValue"
                              onkeyup="calcValue1()"
                              id="stockValue1"
                              value=""
                              fdprocessedid="qurkwr"
                            />
                          </td>
                          <td id="td-1" class="wd-5p text-center">
                            <input
                              type="number"
                              name="newStock"
                              id="newStcok1"
                              readonly=""
                              fdprocessedid="dfrbs"
                            />
                            <input
                              type="hidden"
                              name="itemId"
                              value="26"
                              readonly=""
                            />
                          </td>
                          <th>
                          <button  class="btn btn-primary btn-block" fdprocessedid="51praa">Update</button>
                          </th>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
            {/* Previous and Next buttons */}
            <div className="d-flex justify-content-end mt-3">
              <button
                className="btn btn-primary mx-1"
                onClick={this.handlePrevious}
              >
                Previous
              </button>
              <button className="btn btn-primary mx-1" onClick={this.handleNo}>
                1
              </button>
              <button
                className="btn btn-primary mx-1"
                onClick={this.handleNext}
              >
                Next
              </button>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default ManageStockCard;
