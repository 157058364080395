import React from 'react';
import dotanimation from './dotanimation.gif';

function Loader() {
  return (
    <img src={dotanimation} alt="Loading..."  style={{ width: '70px' }} />
  );
}

export default Loader;
