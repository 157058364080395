import React, { useState, useEffect } from "react";
import "./popup.css";
import "./ItemsNew.css";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Select from "react-select";
import Loader from "../../../Loader";
import { Card, Form, Container, Row, Col, Modal } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import { Inventory, Warehouse } from "@mui/icons-material";

function ItemsNew({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [selectedType, setSelectedType] = useState("Goods");
  const [atLeastOnePriceFilled, setAtLeastOnePriceFilled] = useState(true);
  const jwtToken = localStorage.getItem("jwtToken");
  const [fetchOldItem, setFetchOldItem] = useState("");
  const [vendorNames, setVendorNames] = useState([]);
  const [wareHouseNames, setWareHouseNames] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState(null); // New state for vendor ID
  const [selectedWareHouse, setSelectedWareHouse] = useState("");
  const [loading, setLoading] = useState(false);
  const [originalPrice, setOriginalPrice] = useState(""); // To store the original price
  const [selectedWithTax, setSelectedWithTax] = useState("");
  const [isManualInput, setIsManualInput] = useState(false); // Track manual input
  const [selectedGst, setSelectedGst] = useState("");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("items");
  };

  // FetchVendor
  const fetchAllVendors = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchVendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setVendorNames(data.responseMessage);
        } else {
          console.error("Failed to fetch vendor names:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendor names:", error);
      });
  };

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchAllWareHouse`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setWareHouseNames(data.responseMessage);
        } else {
          console.error(
            "Failed to fetch warehouse names:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching warehouse names:", error);
      });
  }, []);

  useEffect(() => {
    fetchAllVendors();
    getTaxPreference();
  }, []);
  //handleVendorChange
  const handleVendorChange = (selectedOption) => {
    const selectedVendor = vendorNames.find(
      (vendor) => vendor.vendorDisplayName === selectedOption.value
    );
    setSelectedVendor(selectedOption);
    setSelectedVendorId(selectedVendor.id);
  };

  const handleWareHouseChange = (selectedOption) => {
    setSelectedWareHouse(selectedOption);
    console.log("selectedOption : ", selectedOption.id);
  };

  const handleTypeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedType(selectedValue);
    setItemData({
      ...itemData,
      type: selectedValue,
    });
  };
  const optionIntraStateTax = [
    { value: "0", label: "GST0 [0%]", rate: 0 },
    { value: "5", label: "GST5 [5%]", rate: 5 },
    { value: "12", label: "GST12 [12%]", rate: 12 },
    { value: "18", label: "GST18 [18%]", rate: 18 },
    { value: "28", label: "GST28 [28%]", rate: 28 },
  ];
  const [selectedTcsOption, setSelectedTcsOption] = useState(null);
  const [selectedPurchaseAccount, setSelectedPurchaseAccount] = useState(null);
  const [selectedSalesAccount, setSelectedSalesAccount] = useState(null);
  const [selectedIntraTax, setSelectedIntraTax] = useState("");
  const [selectedInterState, setSelectedInterState] = useState("");

  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
  };
  const handlePurchaseAccountOptionChange = (selectedOption) => {
    setSelectedPurchaseAccount(selectedOption);
  };
  const handleSalesAccountOptionChange = (selectedOption) => {
    setSelectedSalesAccount(selectedOption);
  };

  const [isSalesInformationChecked, setIsSalesInformationChecked] =
    useState(true);

  const [isInventory, setIsInventory] = useState(false);
  // purchase info
  const [isPurchaseInformationChecked, setIsPurchaseInformationChecked] =
    useState(true);

  const handleSalesInfoChange = () => {
    if (isSalesInformationChecked) {
      setIsSalesInformationChecked(false);
    } else {
      setIsSalesInformationChecked(true);
    }
  };

  const changePurchaseInfo = () => {
    if (isPurchaseInformationChecked) {
      setIsPurchaseInformationChecked(false);
    } else {
      setIsPurchaseInformationChecked(true);
    }
  };

  const changeInventory = () => {
    if (isInventory) {
      setIsInventory(false);
    } else {
      setIsInventory(true);
    }
  };
  const [selectedTax, setSelectedTax] = useState("");
  const [selectexemptionReason, setSelectexemptionReason] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [itemData, setItemData] = useState({
    uniqueId: decryptedUniqueId,
    type: selectedType,
    name: "",
    unit: "",
    hsnCode: "",
    sku: "",
    taxPreference: selectedTax.value,
    salesPrice: "",
    shopPrice: "",
    purchasePrice: "",
    account: "",
    accountP: "",
    description: "",
    descriptionP: "",
    intraStateTaxRate: selectedIntraTax,
    interStateTaxRate: selectedInterState,
    prefferedVendor: selectedVendor.value,
    wareHouseName: selectedWareHouse.value,
    wareHouseId: selectedWareHouse.id,
    inventoryAccount: "Inventory Asset",
    openingStock: "1",
    reorderPoint: "5",
  });

  const [validationMessage, setValidationMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiUrl = BASE_URL + "/createItem"; // Replace with your API URL
    const dataToSend = {
      ...itemData,
      name: itemData.name,
      oldItemName: fetchOldItem || itemData.name,
      unit: selectedTcsOption?.value,
      account: selectedSalesAccount?.value, // Include selected sales account
      accountP: selectedPurchaseAccount?.value, // Include selected purchase account
      prefferedVendor: selectedVendor?.value, // Include selected vendor name
      vendorId:selectedVendorId,
      wareHouseName: selectedWareHouse?.value,
      wareHouseId: selectedWareHouse.id,
      intraStateTaxRate: selectedIntraTax,
      interStateTaxRate: selectedInterState?.value || selectedInterState,
      taxPreference: selectedTax?.value || selectedTax,
      exemptionReason: selectexemptionReason?.value,
      taxType: selectedGst,
      taxableAmount: finalAmount || itemData.salesPrice,
      salesInformation: isSalesInformationChecked === true ? "YES" : "NO",
      purchaseInformation: isPurchaseInformationChecked===true?"YES":"NO",
      isInventory: isInventory === true ? "YES" : "NO",
    };
    let newValidationMessage = "";
    if (!itemData.name) {
      newValidationMessage += "Please enter a item name.\n";
    } else if (!dataToSend.unit) {
      newValidationMessage += "Please select the unit first.\n";
    } else if (isSalesInformationChecked && !itemData.salesPrice) {
      newValidationMessage += "Please enter a sales price.\n";
    } else if (isSalesInformationChecked && itemData.salesPrice) {
      setValidationMessage("");
    } else if (isPurchaseInformationChecked && !itemData.purchasePrice) {
      newValidationMessage += "Please enter a Purchase price.\n";
    } else if (isPurchaseInformationChecked && itemData.purchasePrice) {
      setValidationMessage("");
    }

    setValidationMessage(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(dataToSend),
    };

    setLoading(true);
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Item Inserted Successfully",
          });
          handleItemsClick();
        } else {
          swal({
            icon: "error",
            title: data.responseMessage || "Failed to insert item",
          });
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Failed to insert data",
        });
      });
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleItemsClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const unit = [
    // { value: "0 GST [0%]", label: "0 GST [0%]", discount: 0 },
    { value: "pcs", label: "pcs" },
    { value: "m", label: "m" },
    { value: "mg", label: "mg" },
    { value: "lb", label: "lb" },
    { value: "km", label: "km" },
    { value: "kg", label: "kg" },
    { value: "in", label: "in" },
    { value: "g", label: "g" },
    { value: "ft", label: "ft" },
    { value: "dz", label: "dz" },
    { value: "cm", label: "cm" },
    { value: "box", label: "box" },
  ];

  const purchaseAccount = [
    { value: "Advertising And Marketing", label: "Advertising And Marketing" },
    { value: "Automobile Expense", label: "Automobile Expense" },
    { value: "Bad Debt", label: "Bad Debt" },
    { value: "Bank Fees and Charges", label: "Bank Fees and Charges" },
    { value: "Consultant Expense", label: "Consultant Expense" },
    { value: "Contract Assets", label: "Contract Assets" },
    { value: "Credit Card Charges", label: "Credit Card Charges" },
    {
      value: "Deprecation And Amortisation",
      label: "Deprecation And Amortisation",
    },
    { value: "Depreciation Expense", label: "Depreciation Expense" },
    { value: "IT and Internet Expenses", label: "IT and Internet Expenses" },
    { value: "Janitor Expense", label: "Janitor Expense" },
    { value: "Lodging", label: "Lodging" },
    { value: "Meals and Entertainment", label: "Meals and Entertainment" },
    { value: "Merchandise", label: "Merchandise" },
    { value: "office Supplies", label: "office Supplies" },
    { value: "Other Expenses", label: "Other Expenses" },
    { value: "Postage", label: "Postage" },
    { value: "Printing and Stationery", label: "Printing and Stationery" },
    { value: "Purchase Discounts", label: "Purchase Discounts" },
    {
      value: "Raw Materials And Consumables",
      label: "Raw Materials And Consumables",
    },
    { value: "Rent Expense", label: "Rent Expense" },
    { value: "Repaires and Maintenance", label: "Repaires and Maintenance" },
    {
      value: "Salaries and Employee Wages ",
      label: "Salaries and Employee Wages",
    },
    { value: "Telephone Expense", label: "Telephone Expense" },
    { value: "Transport Expense", label: "Transport Expense" },
    { value: "Travel Expense", label: "Travel Expense" },
    { value: "Uncatagorized", label: "Uncatagorized" },
    { value: "Cost Of Goods Sold", label: "Cost Of Goods Sold" },
    { value: "Job Costing", label: "Job Costing" },
    { value: "Labor", label: "Labor" },
    { value: "Materials", label: "Materials" },
    { value: "Subcontractor", label: "Subcontractor" },
  ];

  const salesAccount = [
    { value: "Income", label: "Income" },
    { value: "Discount", label: "Discount" },
    { value: "General Income", label: "General Income" },
    { value: "Interest Income", label: "Interest Income" },
    { value: "Late Fee Income", label: "Late Fee Income" },
    { value: "Other Charges", label: "Other Charges" },
    { value: "Sales", label: "Sales" },
    { value: "Shiping Charges", label: "Shiping Charges" },
  ];

  const optionTax = [
    { value: "Taxable", label: "Taxable" },
    { value: "Non Taxable", label: "Non Taxable" },
    { value: "Out Of Scope", label: "Out Of Scope" },
    { value: "Non-GSt Supply", label: "Non-GSt Supply" },
  ];

  const optionExceptionReaon = [
    { value: "Consequuntur", label: "Consequuntur" },
    { value: "Veniam", label: "Veniam" },
    { value: "Perferendis", label: "Perferendis" },
    { value: "Minus", label: "Minus" },
  ];

  const optionInterStateTax = [
    { value: "0", label: "GST0 [0%]" },
    { value: "5", label: "GST5 [5%]" },
    { value: "12", label: "GST12 [12%]" },
    { value: "18", label: "GST18 [18%]" },
    { value: "28", label: "GST28 [28%]" },
  ];

  const calculateGST = () => {
    const salesPrice = parseFloat(itemData.salesPrice) || 0;
    const selectedTaxOption = optionIntraStateTax.find(
      (option) => option.value === selectedIntraTax
    );

    if (!selectedTaxOption || !selectedGst) return;

    const gstRate = selectedTaxOption.rate;
    let calculatedGstAmount, basePrice, totalPrice;

    if (selectedGst === "Including") {
      calculatedGstAmount = (salesPrice * gstRate) / (100 + gstRate);
      basePrice = salesPrice - calculatedGstAmount;
      totalPrice = basePrice;
    } else {
      calculatedGstAmount = (salesPrice * gstRate) / 100;
      basePrice = salesPrice;
      totalPrice = salesPrice + calculatedGstAmount;
    }
    // Store the final amount in the state
    const formattedAmount = totalPrice.toFixed(2);

    // Print the calculated totalPrice to the console
    console.log(
      "Calculated Total Price (Including or Excluding GST):",
      formattedAmount
    );
    setFinalAmount(formattedAmount);
  };

  const handleItemDataChange = (event) => {
    const { name, value } = event.target;
    setItemData({ ...itemData, [name]: value });
    if (
      (name === "salesPrice" ||
        name === "shopPrice" ||
        name === "purchasePrice") &&
      value !== ""
    ) {
      setAtLeastOnePriceFilled(true);
    } else {
      setAtLeastOnePriceFilled(false);
    }
  };

  const handleInterStateChange = (selectedOption) => {
    setSelectedInterState(selectedOption);
  };

  const handleIntraStateChange = (selectedOption) => {
    setSelectedIntraTax(selectedOption.value);
  };

  const handleSelectexemptionReason = (selectedOption) => {
    setSelectexemptionReason(selectedOption);
  };

  const [selectedExceptionReason, setSelectedExceptionReason] = useState("");

  const [taxable, setTaxable] = useState(false);
  const [nonTaxable, setNonTaxable] = useState(false);

  const handleTaxChange = (selectedOption) => {
    setSelectedTax(selectedOption);

    if (selectedOption.value === "Taxable") {
      setTaxable(true);
    } else {
      setTaxable(false);
    }

    if (selectedOption.value === "Non Taxable") {
      setNonTaxable(true);
    } else {
      setNonTaxable(false);
    }
  };
  const [formDataVendor, setFormDataVendor] = useState({
    uniqueId: decryptedUniqueId,
    primaryContact: "Mr.",
    companyName: "",
    vendorDisplayName: "",
    vendorEmail: "",
    vendorPhone1: "",
    vendorPhone2: "",
    designation: "",
    department: "",
    website: "",
    currency: "INR- Indian Rupee",
    openingBalance: "",
    paymentTerms: "Net 15",
    facebook: "",
    twitter: "",
    billingAttention: "",
    billingCountry: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZipcode: "",
    billingPhone: "",
    shipAttention: "",
    shipCountry: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipcode: "",
    shipPhone: "",
    sourceOfSupply: "[MH] - Maharashtra",
    gstTreatment: "",
    contactPerson: [
      {
        other: "",
        firstName: "",
        lastName: "",
        email: "",
        workphone: "",
        mobile: "",
      },
    ],
  });

  const [row, setRow] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searchIndex, setSearchIndex] = useState(0);
  const handleNewVendorClose = () => setShowNewVendor(false);
  const handleNewVendorShow = () => setShowNewVendor(true);
  const [showNewVendor, setShowNewVendor] = useState(false);

  const [validationMessage1, setValidationMessage1] = useState("");

  const handleAddVendor = () => {
    let newValidationMessage = "";

    if (!formDataVendor.vendorDisplayName) {
      newValidationMessage += "Please enter a vendor display name.\n";
    } else if (
      formDataVendor.email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formDataVendor.email)
    ) {
      newValidationMessage += "Please enter a valid email address.\n";
    }
    setValidationMessage1(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }
    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + "/insertVendor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formDataVendor),
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Vendor Created",
            text: "",
          });
          setShowNewVendor(false);
          fetchAllVendors();
        } else {
          swal({
            icon: "error",
            title: "Failed to Create Vendor!",
            text: "",
          });
          setShowNewVendor(true);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
        setShowNewVendor(true);
      });
  };
  const fetchItemData = (selectedItemId) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = `${BASE_URL}/findWareHouseItems`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        const filteredData = responseData.filter(
          (item) => item.itemDetails && item.itemDetails !== selectedItemId
        );
        setDropdownData(filteredData);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.itemDetails.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);
  useEffect(() => {
    calculateGST();
  }, [selectedIntraTax, selectedGst, itemData.salesPrice]);

  // const handleItemDataChange = (e) => {
  //   setItemData({ ...itemData, name: e.target.value });
  //   setSearchTerm(e.target.value);
  //   setSearchPerformed(e.target.value !== '');
  // };

  const handleItemSelect = (selectedItem, itemDetails2) => {
    setSearchResults([]);
    setItemData({ ...itemData, name: selectedItem });
    setFetchOldItem(itemDetails2);
  };

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index] = { itemDetails: value };
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setRow(newRow);
    setSearchIndex(index);
    setItemData({ ...itemData, name: value });
  };

  // const fetchItemRate = (itemId, index) => {
  //   const jwtToken = localStorage.getItem("jwtToken");
  //   const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

  //   fetch(getApiUrl, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${jwtToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data.data.salesPrice;
  //       const hsnSac = data.data.hsnCode;
  //       const updatedRow = [...row];
  //       updatedRow[index].rate = rate;
  //       updatedRow[index].hsnSac = hsnSac;
  //       setRow(updatedRow);
  //     })
  //     .catch((error) => {
  //       console.error("Error making POST request:", error);
  //     });
  // };
  const handleCreateVendor = (event) => {
    const { name, value } = event.target;
    setFormDataVendor((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const generateNamePairs = () => {
    const { primaryContact, firstName, lastName, companyName } = formDataVendor;
    const pairs = [];

    if (firstName && lastName) {
      pairs.push(`${primaryContact} ${firstName} ${lastName}`);
      pairs.push(`${firstName} ${lastName}`);
      pairs.push(`${lastName} , ${firstName}`);
      pairs.push(`${companyName}`);
    }

    return pairs;
  };
  const getTaxPreference = () => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchTaxPreference`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSelectedIntraTax(data.responseMessage.intraStateTaxPreference);
          setSelectedInterState(data.responseMessage.interStateTaxPreference);
          setSelectedTax("Taxable");
          setTaxable(true);
          setSelectedGst(data.responseMessage.taxType);
        } else {
          console.error("Failed to fetch tax:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching tax:", error);
      });
  };

  const handleGSTChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedGst(selectedValue);
  };
  return (
    <>
      <Container fluid className="">
        <Row>
          <Col>
            <div className="my-3">
              <div className="px-2 d-flex">
                <div
                  className="cursor-pointer d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "35px",
                    width: "38px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIosIcon
                    width={2}
                    onClick={handleCancleClick}
                    className="arrow-child"
                  />
                </div>
                <h5
                  className="mt-1 mx-2 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  New Item
                </h5>
              </div>
            </div>
            <Card className="card-style">
              {validationMessage && (
                <Alert severity="error" className="mt-0">
                  {validationMessage.split("\n").map((message, index) => (
                    <div key={index}>{message}</div>
                  ))}
                </Alert>
              )}{" "}
              <Card.Body>
                <form onSubmit={handleSubmit}>
                  <label
                    className="text-primary"
                    style={{ fontSize: 16, fontWeight: "600" }}
                  >
                    ITEM INFO
                  </label>

                  <Row>
                    <Col md="3" className="">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Item type
                        </label>
                        <div className="d-flex my-2">
                          <label className="rdiobox">
                            <input
                              className="mx-1 text"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Goods"
                              checked={selectedType === "Goods"}
                              onChange={handleTypeChange}
                            />
                            <span>Goods</span>
                          </label>
                          <label className="rdiobox ml-3">
                            <input
                              className="mx-1 text"
                              type="radio"
                              name="cus_type"
                              id="cus_indivi"
                              value="Service"
                              checked={selectedType === "Service"}
                              onChange={handleTypeChange}
                            />
                            <span>Service</span>
                          </label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Item name <span className="valid"> *</span>
                        </label>
                        <Form.Control
                          className="border border-1"
                          placeholder="Add Item Name"
                          name="name"
                          type="text"
                          value={itemData.name}
                          onChange={(e) => handleSearchChange(e, searchIndex)}
                          autocomplete="off"
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "10px",
                          }}
                        ></Form.Control>
                        {searchPerformed && itemData.name && (
                          <div
                            style={{ position: "relative", marginTop: "10px" }}
                          >
                            {searchResults.length === 0 && ""}
                            {searchResults.length > 0 && (
                              <ul
                                style={{
                                  listStyleType: "none",
                                  padding: 0,
                                  position: "absolute",
                                  top: "calc(100% + 10px)",
                                  left: 0,
                                  zIndex: 1000,
                                  backgroundColor: "#FFF",
                                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                  borderRadius: "5px",
                                  width: "100%",
                                }}
                              >
                                {searchResults.map((result, index) => (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      handleItemSelect(
                                        result.itemDetails,
                                        result.itemDetails2
                                      )
                                    }
                                    onMouseEnter={(e) => {
                                      e.target.style.backgroundColor =
                                        "#408dfb";
                                      e.target.style.color = "#FFF";
                                      e.target.style.borderRadius = "5px";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.target.style.backgroundColor = "";
                                      e.target.style.color = "#000";
                                      e.target.style.borderRadius = "";
                                    }}
                                    style={{
                                      padding: "12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {result.itemDetails}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Unit <span className="valid"> *</span>
                        </label>
                        <Select
                          className="mt-0"
                          options={unit}
                          value={selectedTcsOption}
                          onChange={handleTcsOptionChange}
                          placeholder="Select Item Unit"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "8px",
                              padding: "4px",
                              height: "46px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          HSN Code
                        </label>
                        <Form.Control
                          className="border border-1"
                          placeholder="HSN Code"
                          name="hsnCode"
                          type="text"
                          value={itemData.hsnCode}
                          onChange={handleItemDataChange}
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "10px",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          SKU
                        </label>
                        <Form.Control
                          className="border border-1"
                          placeholder="Enter SKU"
                          name="sku"
                          type="text"
                          value={itemData.sku}
                          onChange={handleItemDataChange}
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "10px",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Tax Preference
                        </label>
                        <Select
                          className="mt-0"
                          options={optionTax}
                          value={optionTax.find(
                            (option) => option.value === selectedTax
                          )}
                          onChange={handleTaxChange}
                          placeholder="Select Tax Preference"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "8px",
                              padding: "4px",
                              height: "46px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {nonTaxable && (
                    <Row>
                      <Col className="" md="3">
                        <Form.Group>
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Exemption Reason
                          </label>
                          <Select
                            className="mt-0"
                            options={optionExceptionReaon}
                            value={optionExceptionReaon.find(
                              (option) => option.value === selectexemptionReason
                            )}
                            onChange={handleSelectexemptionReason}
                            placeholder="Select Tax Preference"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#F6F6F6",
                                borderRadius: "8px",
                                padding: "4px",
                                height: "46px",
                              }),
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <input
                    className="mr-2 mt-3"
                    type="checkbox"
                    id="purchaseinfo-checkbox"
                    onClick={changePurchaseInfo}
                    checked={isPurchaseInformationChecked}
                    name="purchaseinfo"
                  />
                  <label
                    className="text-primary mt-3"
                    style={{ fontSize: 16, fontWeight: "600" }}
                  >
                    PURCHASE INFO
                  </label>

                  <Row>
                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Cost Price <span className="valid"> *</span>
                        </label>
                        <Form.Control
                          className="border border-1"
                          placeholder="Cost Price"
                          type="text"
                          name="purchasePrice"
                          value={itemData.purchasePrice}
                          onChange={handleItemDataChange}
                          disabled={
                            isPurchaseInformationChecked === true ? false : true
                          }
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "10px",
                            cursor: isPurchaseInformationChecked
                              ? "default"
                              : "not-allowed",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Purchase Account
                        </label>
                        <Select
                          className="mt-0"
                          options={purchaseAccount}
                          value={selectedPurchaseAccount}
                          onChange={handlePurchaseAccountOptionChange}
                          placeholder="Select Purchase Account"
                          isDisabled={
                            isPurchaseInformationChecked === true ? false : true
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "8px",
                              padding: "4px",
                              height: "46px",
                              cursor: isPurchaseInformationChecked
                                ? "default"
                                : "notAllowed", // corrected here
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Description
                        </label>
                        <Form.Control
                          className="border border-1"
                          name="descriptionP"
                          placeholder="Add Purchase Info Description"
                          value={itemData.descriptionP}
                          onChange={handleItemDataChange}
                          disabled={
                            isPurchaseInformationChecked === true ? false : true
                          }
                          type="text"
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "10px",
                            cursor: isPurchaseInformationChecked
                              ? "default"
                              : "not-allowed",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Preffered Vendor
                        </label>
                        <Select
                          className="mt-0"
                          options={vendorNames.map((names) => ({
                            value: names.vendorDisplayName,
                            label: names.vendorDisplayName,
                          }))}
                          isDisabled={
                            isPurchaseInformationChecked === true ? false : true
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "8px",
                              padding: "4px",
                              height: "46px",
                              cursor: isPurchaseInformationChecked
                                ? "default"
                                : "not-allowed",
                            }),
                          }}
                          value={selectedVendor}
                          onChange={handleVendorChange}
                          placeholder="Select a Vendor"
                        />
                        <button
                          type="button"
                          class="btn p-0"
                          onClick={handleNewVendorShow}
                        >
                          <span
                            class="text-primary"
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            New Vendor
                          </span>
                        </button>
                        <Modal
                          size="lg"
                          show={showNewVendor}
                          onHide={handleNewVendorClose}
                        >
                          <div class="modal-header d-flex align-items-center pb-2 pt-2">
                            <h5 class="modal-title fs-5" id="newcustomerPopup">
                              New Vendor
                            </h5>
                            <button
                              type="button"
                              className="btn pr-0"
                              onClick={handleNewVendorClose}
                            >
                              <ion-icon
                                name="close-circle-outline"
                                size="large"
                                style={{ width: "28px" }}
                              ></ion-icon>
                            </button>
                          </div>
                          <Modal.Body className="p-0 pb-3">
                            {validationMessage1 && (
                              <Alert severity="error" className="mt-0">
                                {validationMessage1
                                  .split("\n")
                                  .map((message, index) => (
                                    <div key={index}>{message}</div>
                                  ))}
                              </Alert>
                            )}{" "}
                            <div className="container">
                              <div className="row d-inline-flex">
                                <div className="modalinputchild">
                                  <div className="text-style"> Salutation </div>
                                  <select
                                    className="form-control"
                                    name="primaryContact"
                                    value={formDataVendor.primaryContact}
                                    onChange={handleCreateVendor}
                                    style={{
                                      borderRadius: "7px",
                                      backgroundColor: "#f5f5f5",
                                      width: "200px",
                                      height: "35px",
                                      fontSize: "13px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Miss.">Miss.</option>
                                    <option value="Dr.">Dr.</option>
                                  </select>
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">First Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="First Name"
                                    name="firstName"
                                    value={formDataVendor.firstName}
                                    onChange={handleCreateVendor}
                                    id="firstName"
                                    type="text"
                                  />
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">Last Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    value={formDataVendor.lastName}
                                    onChange={handleCreateVendor}
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="row d-inline-flex pt-2">
                                <div className="modalinputchild">
                                  <div className="text-style">
                                    {" "}
                                    Vendor Email
                                  </div>
                                  <input
                                    className="form-control"
                                    placeholder="Customer Email"
                                    name="vendorEmail"
                                    type="text"
                                    value={formDataVendor.vendorEmail}
                                    onChange={handleCreateVendor}
                                  />
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">
                                    {" "}
                                    Vendor Display Name{" "}
                                    <span className="valid">*</span>{" "}
                                  </div>
                                  <input
                                    className="form-control"
                                    placeholder="Vendor Display Name"
                                    list="vendorDisplayName"
                                    name="vendorDisplayName"
                                    value={formDataVendor.vendorDisplayName}
                                    onChange={handleCreateVendor}
                                    style={{
                                      borderRadius: "7px",
                                      backgroundColor: "#f5f5f5",
                                      width: "200px",
                                      height: "40px",
                                      fontSize: "13px",
                                      marginTop: "10px",
                                    }}
                                  />
                                  <datalist id="vendorDisplayName">
                                    {generateNamePairs().map((pair, index) => (
                                      <option key={index} value={pair} />
                                    ))}
                                  </datalist>{" "}
                                </div>

                                <div className="modalinputchild">
                                  <div className="text-style">Company Name</div>
                                  <input
                                    className="form-control"
                                    placeholder="Add company name"
                                    name="companyName"
                                    type="text"
                                    value={formDataVendor.companyName}
                                    onChange={handleCreateVendor}
                                  />
                                </div>
                              </div>
                              <div className="row d-inline-flex pt-2">
                                <div className="modalinputchild">
                                  <div className="text-style">Vendor Phone</div>
                                  <input
                                    className="form-control"
                                    placeholder="Work Phone"
                                    name="vendorPhone1"
                                    type="text"
                                    value={formDataVendor.vendorPhone1}
                                    onChange={handleCreateVendor}
                                  />
                                </div>
                                <div className="modalinputchild">
                                  <div className="text-style">Mobile</div>
                                  <input
                                    className="form-control"
                                    placeholder="Mobile"
                                    name="vendorPhone2"
                                    type="text"
                                    value={formDataVendor.vendorPhone2}
                                    onChange={handleCreateVendor}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer className="p-2">
                            <div className="modal-bottombtns">
                              <button
                                type="button"
                                class="modalbtn-1 btn mt-0"
                                onClick={handleNewVendorClose}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="modalbtn-3 btn btn-primary"
                                onClick={handleAddVendor}
                              >
                                Create Vendor
                              </button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </Form.Group>
                    </Col>
                  </Row>

                  <input
                    className="mr-2 mt-3"
                    type="checkbox"
                    id="salesinfo-checkbox"
                    onChange={handleSalesInfoChange}
                    checked={isSalesInformationChecked}
                    name="salesinfo"
                  />
                  <label
                    className="text-primary mt-3"
                    style={{ fontSize: 16, fontWeight: "600" }}
                  >
                    SALES INFO
                  </label>
                  <Row>
                    <Col md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Selling Price <span className="valid"> *</span>{" "}
                          {selectedGst ? ` ${selectedGst} GST: ${finalAmount}`:""}
                        </label>
                        <Form.Control
                          className="border border-1"
                          placeholder="Sales Price"
                          id="sp"
                          type="text"
                          name="salesPrice"
                          value={itemData.salesPrice}
                          onChange={handleItemDataChange}
                          disabled={
                            isSalesInformationChecked === true ? false : true
                          }
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "10px",
                            cursor: isSalesInformationChecked
                              ? "default"
                              : "not-allowed",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Sales Account
                        </label>
                        <Select
                          className="mt-0"
                          options={salesAccount}
                          value={selectedSalesAccount}
                          onChange={handleSalesAccountOptionChange}
                          placeholder="Select Sales Account"
                          isDisabled={
                            isSalesInformationChecked === true ? false : true
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "8px",
                              padding: "4px",
                              height: "46px",
                              cursor: isSalesInformationChecked
                                ? "default"
                                : "not-allowed",
                            }),
                          }}
                          menuPlacement="auto" // or "bottom"
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Description
                        </label>
                        <Form.Control
                          className="border border-1"
                          name="description"
                          placeholder="Add Sales Info Description"
                          value={itemData.description}
                          onChange={handleItemDataChange}
                          disabled={
                            isSalesInformationChecked === true ? false : true
                          }
                          type="text"
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "10px",
                            cursor: isSalesInformationChecked
                              ? "default"
                              : "not-allowed",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    {taxable && (
                      <Col className="" md="3">
                        <Form.Group>
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Sales GST Price
                          </label>
                          <div className="d-flex my-2">
                            <label className="rdiobox">
                              <input
                                className="mx-1 text"
                                type="radio"
                                name="cus_GSt"
                                id="cus_in"
                                value="Including"
                                checked={selectedGst === "Including"}
                                onChange={handleGSTChange}
                              />
                              <span>Including</span>
                            </label>
                            <label className="rdiobox ml-3">
                              <input
                                className="mx-1 text"
                                type="radio"
                                name="cus_GSt"
                                id="cus_ex"
                                value="Excluding"
                                checked={selectedGst === "Excluding"}
                                onChange={handleGSTChange}
                              />
                              <span>Excluding</span>
                            </label>
                          </div>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>

                  {taxable && (
                    <Row>
                      <Col className="" md="3">
                        <Form.Group>
                          <label
                            className="text-primary"
                            style={{ fontSize: 16, fontWeight: "600" }}
                          >
                            Default Tax Rates
                          </label>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {taxable && (
                    <Row>
                      <Col className="" md="3">
                        <Form.Group>
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Intra State Tax Rate
                          </label>
                          <Select
                            // className="dropdown-field"
                            className="mt-0"
                            options={optionIntraStateTax}
                            value={optionIntraStateTax.find(
                              (option) => option.value === selectedIntraTax
                            )}
                            onChange={handleIntraStateChange}
                            placeholder="Select a Tax"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#F6F6F6",
                                // border:'none',
                                borderRadius: "8px",
                                padding: "4px",
                                height: "46px",
                              }),
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="" md="3">
                        <Form.Group>
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Inter State Tax Rate
                          </label>
                          <Select
                            // className="dropdown-field"
                            className="mt-1"
                            options={optionInterStateTax}
                            value={optionInterStateTax.find(
                              (option) => option.value === selectedInterState
                            )}
                            onChange={handleInterStateChange}
                            placeholder="Select a Tax"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#F6F6F6",
                                // border:'none',
                                borderRadius: "8px",
                                padding: "4px",
                              }),
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {isSalesInformationChecked &&
                    isPurchaseInformationChecked && (
                      <div>
                        <input
                          className="mr-2 mt-3"
                          type="checkbox"
                          id="check_Inventory"
                          onClick={changeInventory}
                          checked={isInventory}
                          name="isInventory"
                        />
                        <label
                          className="text-primary mt-3"
                          style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                          Track Inventory for this item
                        </label>
                      </div>
                    )}

                  {isInventory && (
                    <Row>
                      <Col md="3">
                        <Form.Group>
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Inventory Account <span className="valid"> *</span>
                          </label>
                          <Form.Control
                            className="border border-1"
                            value="Inventory Asset"
                            readOnly
                            type="text"
                            style={{
                              backgroundColor: "#F6F6F6",
                              padding: "10px",
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col className="" md="3">
                        <Form.Group>
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Opening Stock
                          </label>
                          <Form.Control
                            className="border border-1"
                            name="openingStock"
                            value={itemData.openingStock}
                            onChange={handleItemDataChange}
                            placeholder="Add Opening Stock"
                            type="text"
                            style={{
                              backgroundColor: "#F6F6F6",
                              padding: "10px",
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      <Col className="" md="3">
                        <Form.Group>
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            Reorder Point
                          </label>
                          <Form.Control
                            className="border border-1"
                            name="reorderPoint"
                            value={itemData.reorderPoint}
                            onChange={handleItemDataChange}
                            placeholder="Add Reorder Point"
                            type="text"
                            style={{
                              backgroundColor: "#F6F6F6",
                              padding: "10px",
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      {/* <Col className="" md="3">
                        <Form.Group>
                          <label style={{ fontSize: "14px", color: "grey" }}>
                            WareHouse
                          </label>
                          <Select
                            className="mt-0"
                            options={wareHouseNames.map((names) => ({
                              value: names.wareHouseName,
                              label: names.wareHouseName,
                              id: names.id
                            }))}
                            isDisabled={
                              isInventory === true ? false : true
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#F6F6F6",
                                borderRadius: "8px",
                                padding: "4px",
                                height: "46px",
                                cursor: isInventory
                                  ? "default"
                                  : "not-allowed",
                              }),
                            }}
                            value={selectedWareHouse}
                            onChange={handleWareHouseChange}
                            placeholder="Select a WareHouse"
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>
                  )}
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      type="button "
                      className="btn-1 border border-2 border-primary mt-0"
                      disabled={loading}
                      onClick={handleCancleClick}
                    >
                      Cancel
                    </button>
                    {loading ? (
                      <Loader />
                    ) : (
                      <button
                        type="button "
                        className="btn-3 btn btn-primary mt-0"
                        onClick={handleSubmit}
                      >
                        Create Item
                      </button>
                    )}
                  </div>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ItemsNew;
