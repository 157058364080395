import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./Users.css";
import Select from "react-select";
import UsersDetails from "./UsersDetails";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function Users(props) {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showUserPage, setShowUserPage] = useState(true);
  const [showUserDetailsPage, setShowUserDetailsPage] = useState(false);
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showPassword, setShowPassword] = useState(false); // State variable to manage showing password

  const handleModalClose = () => {
    setShowInviteModal(false);
    fetchItemData();
  };
  const [selectedPaidThrough, setSelectedPaidThrough] = useState(null);

  const paidThrough = [
    { value: "Admin", label: "Admin" },
    { value: "Staff", label: "Staff" },
  ];
  const [selectState, setSelectState] = useState(null);
  const [userId, setUserId] = useState("");
  const statesInIndia = [
    {
      value: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
    },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Dadra and Nagar Haveli", label: "Dadra and Nagar Haveli" },
    { value: "Daman and Diu", label: "Daman and Diu" },
    { value: "Delhi", label: "Delhi" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Ladakh", label: "Ladakh" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];

  const handelStateChanage = (selectedOption) => {
    setSelectState(selectedOption);
  };

  const handlePaidThroughChange = (selectedOption) => {
    setSelectedPaidThrough(selectedOption);
  };
  const handleOpenUsersDetails = (id) => {
    setShowUserPage(false);
    setShowUserDetailsPage(true);
    setUserId(id);
  };

  const handleCancelDetails = () => {
    setShowUserPage(true);
    setShowUserDetailsPage(false);
    fetchItemData();
  };
  const [companyName, setCompanyName] = useState("");
  const [state, setState] = useState("");
  const fetchItemData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/fetchSubUserProfile`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUserData(data.responseMessage);
        const nameOfCompanyName = data.responseMessage[0].companyName;
        const NameOfState = data.responseMessage[0].state;
        setCompanyName(nameOfCompanyName);
        setState(NameOfState);

        console.log(
          "print the comapmay name ",
          data.responseMessage[0].companyName
        );
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  // console.log("this is also comapny name ",companyName)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    uniqueId: decryptedUniqueId,
    companyName: companyName,
    contact: "",
    state: state,
    password: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      role: selectedPaidThrough?.value || "", // Access selected value and set it as role, or set an empty string if selectedPaidThrough is null
      state: selectState?.value || "",
    });
  }, [selectedPaidThrough]);

  const jwtToken = localStorage.getItem("jwtToken");

  const handleSaveClick = () => {
    fetch(BASE_URL + "/userSignUp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          swal({
            icon: "success",
            title: "Data Added Successfully",
            text: "",
          });
          handleClose();
          handleModalClose();
          setFormData({
            name: "",
            email: "",
            role: "",
            uniqueId: decryptedUniqueId,
            companyName: companyName,
            contact: "",
            state: state,
            password: "",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed to post employee data",
            text: "",
          });
          handleShow();
          setFormData({
            name: "",
            email: "",
            role: "",
            uniqueId: decryptedUniqueId,
            companyName: companyName,
            contact: "",
            state: state,
            password: "",
          });
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      {showUserPage && (
        <div className="container-fluid users-container">
          <div className="col-lg-12 p-0">
            <div className="card users-card">
              <div className="header pb-0">
                <div className="pt-1 pl-2 d-flex justify-content-between align-items-center">
                  <h5
                    className="font-600"
                    style={{ letterSpacing: "1px", color: "#086bd5de" }}
                  >
                    All Users
                  </h5>
                  <button
                    type="button"
                    className="btn btn-primary px-2 py-1"
                    onClick={handleShow}
                  >
                    Invite User
                  </button>

                  {/*Invite User Popup */}
                  <Modal show={show} onHide={handleClose}>
                    <div className="modal-header d-flex align-items-center pb-2 pt-2">
                      <h5 className="modal-title fs-5" id="newcustomerPopup">
                        Invite User
                      </h5>
                      <button
                        type="button"
                        className="btn pr-0"
                        onClick={handleClose}
                      >
                        <ion-icon
                          name="close-circle-outline"
                          size="large"
                          style={{ width: "28px" }}
                        ></ion-icon>
                      </button>
                    </div>
                    <Modal.Body>
                      <div className="form-group">
                        <label htmlFor="username">
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Enter username"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">
                          Contact<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="Enter Contact Number"
                          name="contact"
                          value={formData.contact}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">
                          Password<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="password-input">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id=""
                            placeholder="Enter Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                          <span
                            className="toggle-password-icon"
                            onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on icon click
                          >
                            {showPassword ? (
                              <ion-icon name="eye-off-outline"></ion-icon> // Show eye-off icon when password is visible
                            ) : (
                              <ion-icon name="eye-outline"></ion-icon> // Show eye icon when password is hidden
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">
                          State<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={state}
                          readOnly
                        />
                      </div>
                      <div className="form-group mb-0">
                        <label htmlFor="email">
                          Role<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          options={paidThrough}
                          onChange={handlePaidThroughChange}
                          value={selectedPaidThrough}
                          name="role"
                          placeholder="Select..."
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              borderRadius: "7px",
                            }),
                          }}
                          isSearchable
                          required
                        />
                        {formData.role === "Admin" ? (
                          <span style={{ color: "grey" }}>
                            {" "}
                            Unrestricted access to all modules.
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="p-2">
                      <div className="modal-bottombtns">
                        <button
                          type="button"
                          className="modalbtn-1 btn mt-0"
                          onClick={handleClose}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="modalbtn-3 btn btn-primary"
                          onClick={handleSaveClick}
                        >
                          Invite
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              <div className="body table-responsive ">
                <table className="table table-hover mb-0 c_list">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>ROLE</th>
                      <th>EMAIL</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {/* <div className="mt-2"></div> */}
                  <tbody>
                    {userData.map((data) => (
                      <>
                        <tr>
                          <td>
                            <AccountCircleIcon
                              className="icon-menu"
                              style={{ width: "30px", color: "black" }}
                            />{" "}
                            <p className="c_name">{data.name}</p>
                          </td>
                          <td>
                            <span className="badge badge-info ml-0 hidden-sm-down">
                              {data.role}
                            </span>
                          </td>
                          <td>
                            <a
                              href="#"
                              onClick={() => handleOpenUsersDetails(data.id)}
                            >
                              <span className="phone">{data.email}</span>
                            </a>
                          </td>
                          <td>
                            <button
                              className="btn btn-light icon-border btn-sm mr-2"
                              onClick={() => handleOpenUsersDetails(data.id)}
                            >
                              <i className="icon-settings"></i>
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUserDetailsPage && (
        <UsersDetails userId={userId} handleCancel={handleCancelDetails} />
      )}
    </>
  );
}

export default Users;
