import React from "react";
import Header from "../../../Components/Common/Header";
import TimeSheetLogCard from "../../../Components/Apps/TimeSheetLogCard";
class TimeSheetLog extends React.Component {
    constructor(props){
        super(props)
        this.state={
            isCompose:false
        }
    }
  render() {
    return (
        <div className="container-fluid">
            <Header headerText="TimeSheetLog" mainNavigate="Office" currentPage="TimeSheetLog" childNav={this.state.isCompose?"Compose":""} />
            <div className="row clearfix ">
              {/* <h1>Time Sheet Log</h1>             */}
              <TimeSheetLogCard/>
            </div>
        </div>    
    );
  }
}
export default TimeSheetLog
