import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import "./GoogleAnalyticCard.css";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";
import { AES, enc } from "crypto-js";
import * as htmlToImage from "html-to-image";

const GoogleAnalyticCard = () => {
  const [salesData, setSalesData] = useState([]);
  const [salesData1, setSalesData1] = useState([]);
  useEffect(() => {
    fetchDataForIncome();
  }, []);

  useEffect(() => {
    fetchDataForExpense();
  }, []);

  const fetchDataForIncome = async () => {
    try {
      const encryptedUniqueId = localStorage.getItem("uniqueId1");
      const decryptedUniqueId = AES.decrypt(
        encryptedUniqueId,
        "1234567812345678"
      ).toString(enc.Utf8);

      const jwtToken = localStorage.getItem("jwtToken");

      const response = await fetch(BASE_URL + "/fetchForGraph", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const salesDataForGraph = Object.values(data).map(month => parseFloat(month.totalSales));
      setSalesData(salesDataForGraph);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataForExpense = async () => {
    try {
      const encryptedUniqueId = localStorage.getItem("uniqueId1");
      const decryptedUniqueId = AES.decrypt(
        encryptedUniqueId,
        "1234567812345678"
      ).toString(enc.Utf8);

      const jwtToken = localStorage.getItem("jwtToken");

      const response = await fetch(BASE_URL + "/fetchForGraphForExpense", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const salesDataForGraph1 = Object.values(data).map(month => parseFloat(month.totalSales));
      setSalesData1(salesDataForGraph1);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const calculateTotalIncome = () => {
    if (!salesData) return 0;
    const totalIncome = salesData.reduce((total, monthData) => total + monthData, 0);
    return totalIncome.toFixed(2);
  };

  const calculateTotalExpense = () => {
    if (!salesData1) return 0;
    const totalExpense = salesData1.reduce((total, monthData) => total + monthData, 0);
    return totalExpense.toFixed(2);
  };

  const maxSales = Math.max(
    Math.max(...salesData),
    Math.max(...salesData1)
  );

  const [downloadType, setDownloadType] = useState(null);

  const handleDownload = (type) => {
    setDownloadType(type);
    setDownloadType(type);
    if (type === "png") {
      downloadAsPNG();
    } else if (type === "csv") {
      downloadAsCSV();
    }
  };

  const downloadAsPNG = () => {
    const chartElement = document.getElementById("chart-container");
    htmlToImage.toPng(chartElement)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "chart.png";
        link.href = dataUrl;
        link.click();
      });
  };

  const downloadAsCSV = () => {

    const xAxisData = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nav", "Dec"
    ];

    const combinedData = salesData.map((value, index) => ({
      month: xAxisData[index], // Assuming xAxisData contains month names
      income: value,
      expense: salesData1[index]
    }));

    const csvContent = [
      "Month,Income,Expense",
      ...combinedData.map(item => `${item.month},${item.income},${item.expense}`)
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };


  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="header">
          <h2>Income and Expense</h2>
        </div>
        <div className="body">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div class="dropdown">
                <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Export
                </button>
                <ul class="dropdown-menu">
                  <li
                    className="dropdown-item"
                    onClick={() => handleDownload("csv")}
                  >
                    Export as CSV
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => handleDownload("png")}
                  >
                    Export as Image
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <h6 className="text-center">Income and Expenses</h6>

          <div id="chart-container">
            <ReactEcharts
              option={{
                color: ["rgb(89, 196, 188)", "rgb(99, 122, 174)"],
                tooltip: {
                  trigger: "axis",
                  axisPointer: {
                    type: "shadow",
                  },
                },
                toolbox: {
                  show: false,
                },
                xAxis: [
                  {
                    type: "category",
                    axisTick: {
                      show: true,
                      lineStyle: {
                        color: "rgb(89, 196, 188)",
                      },
                    },
                    data: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nav",
                      "Dec",
                    ],
                  },
                ],
                yAxis: [
                  {
                    show: true,
                    type: "value",
                    axisTick: { show: true },
                    data: maxSales // Set the maximum limit for the y-axis
                  },
                ],
                series: [
                  {
                    name: "Income",
                    type: "bar",
                    barGap: 0,
                    // data: [0, 0, 0, 200, 2380, 0, 0, 0, 0, 0, 0, 0],
                    data: salesData
                  },
                  {
                    name: "Expense",
                    type: "bar",
                    barGap: 0,
                    // data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2748],                  
                    data: salesData1
                  },
                ],
              }}
              opts={{ renderer: "svg" }}
            />
          </div>

          <div className="text-center d-flex justify-content-center">
            <div className="circle inr-circle"></div>
            <h6>INR</h6>
          </div>

          <hr />
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="circle income-circle"></div>
                <h6>Income</h6>
              </div>

              <div className="row">
                <div className="circle expenses-circle"></div>
                <h6>Expenses</h6>
              </div>
            </div>
            <div className="vertical-line"></div>

            <div className="col">
              <h6 style={{ color: "MediumSeaGreen" }}>Total Income</h6>
              <h6>₹ {calculateTotalIncome()}</h6>
            </div>

            <div className="vertical-line"></div>

            <div className="col">
              <h6 style={{ color: "red" }}>Total Expenses</h6>
              <h6>₹ {calculateTotalExpense()}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAnalyticCard;
