import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import swal from "sweetalert"; // import { OptionsManager } from "@fullcalendar/core/OptionsManager";
import { BASE_URL } from "../CommonApi";
import NoDataImage from "../../../asset/images/nodata.jpg";
import DeliveryChallanUpdate from "../SalesNew/DeliveryChallanUpdate";
import DeliveryChallanDetails from "../SalesNew/DeliveryChallanDetails";

function DeliveryChallan({ navigation }) {
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [deliveryNumber, setDeliveryNumber] = useState(null);
  const [deliveryChallan, setDeliveryChallan] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const jwtToken = localStorage.getItem("jwtToken");
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const handleChallanClick = () => {
    handleNavigation("DeliveryChallansNew");
  };
  const fetchDeliveryChallan = () => {
    $("#DataTables_Table_0").DataTable();
    fetch(BASE_URL + `/fetchDeliveryChallan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId,fromDate:"",toDate:"" }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setDeliveryChallan(data.responseMessage);
          setLoading(false);
        } else {
          console.error(
            "Failed to fetch DeliveryChallan:",
            data.responseMessage
          );
          setLoading(false);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error fetching DeliveryChallan:", error);
        setLoading(false);

        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchDeliveryChallan();
  }, []);

  const handleDelete = (deliveryChallan) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this delivery challan!"
    );

    if (userConfirmed) {
      // Send a DELETE request to your backend API
      fetch(BASE_URL + `/deleteByDeliveryChallan/${deliveryChallan}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => {
          if (response.status === 200) {
            setDeliveryChallan((prevDelivery) =>
              prevDelivery.filter(
                (data) => data.deliveryChallan !== deliveryChallan
              )
            );
            swal({
              icon: "success",
              title: "Delivery Challan Deleted  ",
            });
            setLoading(true);
            fetchDeliveryChallan();
          } else {
            console.error("Failed to delete Delivery Challan");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting Delivery Challan:", error);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Delivery Challan deletion canceled!");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleEdit = (deliveryChallan) => {
    setDeliveryNumber(deliveryChallan);
    setShowUpdate(true);
    setShow(false);
    setShowCard(false);
    fetchDeliveryChallan();
  };

  const handleCancelUpdate = () => {
    setShowUpdate(false);
    setShow(false);
    setShowCard(true);
    fetchDeliveryChallan();
  };
  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    setShow(false);
    setShowUpdate(false);
    fetchDeliveryChallan();
  };

  const handleInvoice = (deliveryChallan, customerId) => {
    setnewInvoice(deliveryChallan);
    setCustomerId(customerId);
    setShowDetails(true);
    setShow(false);
    setShowCard(false);
    setShowUpdate(false);
  };

  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Delivery Challans
            </div>
            <div className="d-flex">
              <div className="">
                  <button
                    className="btn btn-primary px-1 py-2 new-purchase"
                    onClick={handleChallanClick}
                  >
                    + New Challan
                  </button>
              </div>
              <div className="ml-2 px-1 py-2 icon-dot">
                <i
                  className="fa fa-ellipsis-v "
                  style={{ fontSize: 18, color: "gray" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <div className="page-content">
            <Container fluid={true}>
              <Row>
                <Col>
                  <Card>
                    {deliveryChallan && deliveryChallan.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Date
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Delivery challan
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Reference number
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Customer name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Challan type
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {deliveryChallan.map((delivery) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={delivery.id}
                                        >
                                          <td>
                                            {delivery.deliveryChallanDate}
                                          </td>

                                          <td className="sorting_1">
                                            {" "}
                                            <a
                                              href="#"
                                              onClick={() =>
                                                handleInvoice(
                                                  delivery.deliveryChallan,
                                                  delivery.customerId
                                                )
                                              }
                                            >
                                              {delivery.deliveryChallan}
                                            </a>
                                          </td>
                                          <td>{delivery.reference}</td>
                                          <td>{delivery.customerName}</td>
                                          <td>{delivery.challanType}</td>

                                          <td>
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(
                                                  delivery.deliveryChallan
                                                )
                                              }
                                            >
                                              <i className="fa fa-edit text1"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(delivery.deliveryChallan)
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Delivery challan
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Reference number
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Customer name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      {showUpdate && (
        <DeliveryChallanUpdate
          deliveryChallan={deliveryNumber}
          handleCancel={handleCancelUpdate}
        />
      )}
      {showDetails && (
        <DeliveryChallanDetails
          newinvoiceNumber={newinvoiceNumber}
          custId={customerId}
          handleCancel={handleCancelDetails}
        />
      )}
    </div>
  );
}
export default DeliveryChallan;
