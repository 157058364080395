import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function HorizontalBalanceSheet({navigation}) {
    const handleNavigation = (key) => {
        if (navigation.state.routes[navigation.state.index].key !== key) {
          navigation.navigate(key);
        }
      };
      const handleCancelClick = () => {
        handleNavigation("reports");
      };

  return (
    <>
      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={handleCancelClick}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5 style={{ letterSpacing: "1px", color: "#086bd5de" }}>
            Horizontal Balance Sheet
          </h5>
        </div>
      </div>
      <div className="balance-sheet-section my-3 mx-0" style={{height: "100%", padding: "30px", backgroundColor: "#fff"}}>
      <div className="head-container mb-5 d-flex flex-column justify-content-center align-items-center">
        <h5 style={{ fontWeight: "normal" }}>Code World</h5>
        <h4 style={{ fontWeight: "normal" }}>Horizontal Profile and Loss</h4>
        <p className="mb-1">Basis: Accrual</p>
        <p style={{ fontWeight: "normal" }}>From 01/11/2023 To 30/11/2023</p>
        {/* <div>
          <button className="btn" style={buttonStyle} onClick={cancle}>
            <XCircle size={32} weight="fill" />
          </button>
        </div> */}
      </div>
      <div style={{borderTop: "1px solid #ddd"}}>
        <p style={{
          marginTop: "20px",
          display: "inline-block",
          marginLeft: "8px",
        }}>**Amount is displayed in your base currency <span style={{backgroundColor: "rgb(56,138,16)",padding: "2px 4px",fontWeight: "600", fontSize: "12px"}}>INR</span></p>
      </div>
      </div>
    </>
  );
}
