import React, { useEffect, useState, useRef } from "react";
import "./Setting.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { BASE_URL } from "../CommonApi";
import { AES, enc } from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Profile icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import {
  faFileInvoice,
  faUser,
  faBuilding,
  faCog,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import Alert from "@mui/material/Alert";

function Setting({ navigation }) {
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleDis, setToggleDis] = useState(false);
  const [toggleSize, setToggleSize] = useState(false);
  const [toggleHSN, setToggleHSN] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);
  const handleSalesClose = () => setShowSalesModal(false);
  const handleSalesShow = () => setShowSalesModal(true);

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleProfileClick = () => {
    handleNavigation("profile");
  };

  const handleTemplateClick = () => {
    handleNavigation("templates");
  };

  const handleSettingClick = () => {
    handleNavigation("Setting");
  };

  const handleTaxClick = () => {
    handleNavigation("taxes");
  };

  const notifySuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // Function to show blue toast
  const notifyBlue = (message) => {
    toast.info(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // Function to show error toast
  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleSwitchToggle = (event) => {
    const isChecked = event.target.checked;
    console.log(isChecked ? "YES" : "NO");
    setToggleValue(isChecked);

    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const bodyData = {
      uniqueId: decryptedUniqueId,
      businessRegistered: isChecked ? "YES" : "NO",
    };

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/OnOff`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log("Tax updated successfully:", data.responseMessage);
          if (isChecked) {
            // Show success toast
            notifySuccess(
              "GST Setting Turned On Successfully: " + data.responseMessage
            );
          } else {
            // Show blue toast
            notifyBlue(
              "GST Setting Turned Off Successfully: " + data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchDisToggle = (event) => {
    const isChecked = event.target.checked;
    console.log(isChecked ? "YES" : "NO");
    setToggleDis(isChecked);

    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const bodyData = {
      uniqueId: decryptedUniqueId,
      discountCheck: isChecked ? "YES" : "NO",
      // id:1
    };
    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/updateDiscountCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            // Show success toast
            notifySuccess(
              "GST Setting Turned On Successfully: " + data.responseMessage
            );
          } else {
            // Show blue toast
            notifyBlue(
              "GST Setting Turned Off Successfully: " + data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchSizeToggle = (event) => {
    const isChecked = event.target.checked;
    setToggleSize(isChecked);
    console.log("toggleSize : ", toggleSize);
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const bodyData = {
      uniqueId: decryptedUniqueId,
      // discountCheck: isChecked ? "YES" : "NO",
      sizeCheck: isChecked ? "YES" : "NO",
      // id:1
    };
    const id = 1;
    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/updateSizeCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            // Show success toast
            notifySuccess(
              "GST Setting Turned On Successfully: " + data.responseMessage
            );
          } else {
            // Show blue toast
            notifyBlue(
              "GST Setting Turned Off Successfully: " + data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const handleSwitchHSNToggle = (event) => {
    const isChecked = event.target.checked;
    setToggleHSN(isChecked);
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const bodyData = {
      uniqueId: decryptedUniqueId,
      hsnCheck: isChecked ? "YES" : "NO",
    };
    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/updateHsnCheck`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (isChecked) {
            notifySuccess(
              "GST Setting Turned On Successfully: " + data.responseMessage
            );
          } else {
            notifyBlue(
              "GST Setting Turned Off Successfully: " + data.responseMessage
            );
          }
        } else {
          console.error("Failed to update tax:", data.responseMessage);
          notifyError("Failed to Update GST Setting: " + data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
        notifyError("Failed to Update GST Setting " + error.message);
      });
  };

  const fetchAdditionalData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const businessRegistered = data.responseMessage[0].businessRegistered;
          setToggleValue(businessRegistered === "YES");
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchDiscountSetting = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {          
          const businessRegistered = data.responseMessage.discountCheck;
          const sizeCheck = data.responseMessage.sizeCheck;
          const hsnCheck = data.responseMessage.hsnCheck;
          setToggleDis(businessRegistered === "YES");
          setToggleSize(sizeCheck === "YES");
          setToggleHSN(hsnCheck === "YES");
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  useEffect(() => {
    fetchAdditionalData();
    fetchDiscountSetting();
  }, []);
  const textareaRef = useRef(null);
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [validationMessage2, setValidationMessage2] = useState("");
  useEffect(() => {
    fetchItemData();
  }, []);
  useEffect(() => {
    adjustTextareaHeight();
  }, [termsAndCondition]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleTermsAndConditionChange = (event) => {
    setTermsAndCondition(event.target.value);
  };
  const fetchItemData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/fetchTermsAndConditions`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        // setDropdownData(responseData.map((item) => item));
        setTermsAndCondition(responseData.termsAndCondition);
        setTimeout(() => adjustTextareaHeight(), 0);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const handleTermsAndCondition = (event) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    event.preventDefault();
    let newValidationMessage = "";

    if (!termsAndCondition) {
      newValidationMessage += "Please enter a terms and condition.\n";
    }

    setValidationMessage2(newValidationMessage);

    // Display a single validation alert if necessary
    if (newValidationMessage) {
      window.scrollTo(0, 0);
      // alert(newValidationMessage.trim()); // Trim trailing newline for better formatting
      return; // Exit function if validation fails
    }

    const data = {
      uniqueId: decryptedUniqueId,
      termsAndCondition: termsAndCondition,
    };
    fetch(BASE_URL + "/updateTermsAndCondition", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Terms And Condition Created ",
          });
          setShowSalesModal(false);
          fetchItemData();
        } else {
          swal({
            icon: "error",
            title: "Failed To Create Terms And Condition! ",
          });
          setShowSalesModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "error",
          title: "An Error Occured While Creating Terms And Condition",
        });
        setShowSalesModal(true);
      });
  };

  return (
    <>
      <div className="">
        <div className="my-3 ml-2">
          <div className="px-2 d-flex">
            <SettingsIcon
              className="icon mt-2 text-primary"
              size={26}
              onClick={""}
            />
            <h4
              className="mt-1 mx-2 text-primary"
              style={{ letterSpacing: "1px" }}
            >
              Settings
            </h4>
          </div>
        </div>
        <div>
          <div class="card-group container1">
            <div class="card1">
              <div class="card-body">
                <h5 class="card-title">
                  <FontAwesomeIcon
                    icon={faBuilding} // Organization icon
                    style={{ width: "18px", marginRight: "8px" }}
                  />
                  Organization
                </h5>
                <div className="cardchild ">
                  <p onClick={handleProfileClick} className="hover-effect">
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={faUser}
                      style={{ width: "16px" }}
                    />{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      Profile
                    </span>
                  </p>
                  {/* <p onClick={handleTemplateClick} className="hover-effect">
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={faFile}
                      style={{ width: "16px" }}
                    />{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      Templates
                    </span>
                  </p> */}
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="card-body">
                <h5 class="card-title">
                  <FontAwesomeIcon
                    icon={faFileInvoice} // Taxes & Compliance icon
                    style={{ width: "16px", marginRight: "8px" }}
                  />
                  Taxes & Compliance
                </h5>
                <div className="cardchild">
                  <p onClick={handleTaxClick} className="hover-effect">
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={faFileInvoice}
                      style={{ width: "18px" }}
                    />{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      Taxes / GST
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card1">
              <div class="card-body">
                <h5 class="card-title">
                  <FontAwesomeIcon
                    icon={faCog} // Others icon
                    style={{ width: "16px", marginRight: "8px" }}
                  />
                  Others
                </h5>
                <div className="cardchild">
                  <p onClick={handleSalesShow} className="hover-effect">
                    <FontAwesomeIcon
                      className="mr-0"
                      icon={faFileContract}
                      style={{ width: "18px" }}
                    />{" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      Add Terms and Condition
                    </span>
                  </p>
                </div>
                <Modal show={showSalesModal} onHide={handleSalesClose}>
                  <div class="modal-header d-flex align-items-center pb-2 pt-2">
                    <h5 class="modal-title fs-5" id="salespersonHead">
                      Add Terms And Condition
                    </h5>
                    <button
                      type="button"
                      className="btn pr-0"
                      onClick={handleSalesClose}
                    >
                      <ion-icon
                        name="close-circle-outline"
                        size="large"
                        style={{ width: "28px" }}
                      ></ion-icon>
                    </button>
                  </div>
                  <Modal.Body>
                    {validationMessage2 && (
                      <Alert severity="error" className="mt-0">
                        {validationMessage2
                          .split("\n")
                          .map((message, index) => (
                            <div key={index}>{message}</div>
                          ))}
                      </Alert>
                    )}{" "}
                    <div className="row pt-2">
                      <div className="modalsalesinput">
                        <label className="form-label">
                          <span style={{ color: "red" }}>
                            Terms And Condition *
                          </span>
                        </label>
                        <div className="col-md-12">
                          <textarea
                            className="invoicetextarea"
                            placeholder="Terms and Condition..."
                            name="termsAndCondition"
                            value={termsAndCondition}
                            ref={textareaRef}
                            onChange={handleTermsAndConditionChange}
                            rows={8}
                            style={{
                              backgroundColor: "#F6F6F6",
                              borderRadius: "9px",
                              padding: "12px",
                              height: "auto",
                            }}
                            onInput={adjustTextareaHeight}
                          />
                          <i className="text-muted">
                            This terms will be displayed on the invoice
                          </i>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="p-2">
                    <div className="modal-bottombtns">
                      <button
                        type="button"
                        onClick={handleTermsAndCondition}
                        class="modalbtn-3 btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        Create Terms
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
                <div className="cardchild">
                  <div className="body mt-0">
                    <ul className="list-group">
                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${toggleDis ? "text-success" : "text-danger"
                            }`}
                        >
                          {toggleDis ? "Discount On" : "Discount Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchDisToggle}
                            checked={toggleDis}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>
                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${toggleSize ? "text-success" : "text-danger"
                            }`}
                        >
                          {toggleSize ? "Size On" : "Size Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchSizeToggle}
                            checked={toggleSize}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>

                      <li
                        className=" mt-1 d-flex justify-content-between list-group-item align-items-center"
                        style={{ border: "none" }}
                      >
                        <h6
                          className={`mb-0 ${toggleHSN ? "text-success" : "text-danger"
                            }`}
                        >
                          {toggleHSN ? "HSN On" : "HSN Off"}
                        </h6>
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            onChange={handleSwitchHSNToggle}
                            checked={toggleHSN}
                          />
                          <span className="toggle-switch-slider"></span>
                        </label>
                      </li>

                    </ul>
                    <div>
                      <ToastContainer
                        //className="toast toast-info"
                        hideProgressBar={true}
                      ></ToastContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Setting;
