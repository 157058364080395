import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert"; // import { OptionsManager } from "@fullcalendar/core/OptionsManager";
import EstimateUpdate from "../SalesNew/EstimateUpdate";
import EstimateDetails from "../SalesNew/EstimateDetails";
import * as XLSX from "xlsx"; // Import the xlsx library

function Estimates({ navigation }) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [estimateNumber, setEstimateNumber] = useState(null);
  const [fetchedStatus,setFetchedStatus] = useState("");
  const [estimate, setEstimate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const jwtToken = localStorage.getItem("jwtToken");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const btnRef = useRef();
  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleEstimateClick = () => {
    handleNavigation("EstimatesNewButton");
  };

  const fetchEstimates = () => {
    $("#DataTables_Table_0").DataTable();

    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchEstimate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId ,fromDate:"",toDate:"" }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setEstimate(data.responseMessage);
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  const downloadEstimateExel = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/exportToExcelEstimates`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "403") {
          const Data = data.responseMessage;
        } else if (data.responseCode === "400") {
          console.log("this is error data ");
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  useEffect(() => {
    fetchEstimates();
  }, []);
  
  const handleDelete = (estimateNumber) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this estimate!"
    );

    if (userConfirmed) {
      fetch(BASE_URL + `/deleteByEstimateNumber/${estimateNumber}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => {
          if (response.status === 200) {
            setEstimate((prevEstimate) =>
              prevEstimate.filter(
                (data) => data.estimateNumber !== estimateNumber
              )
            );
            setLoading(true); 
            fetchEstimates();
            swal({
              icon: "success",
              title: "Estimate Deleted Successfully",
            });
    
          } else {
            console.error("Failed to delete estimate");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error deleting estimate:", error);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Estimate deletion canceled!");
    }
  };

  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    setShowUpdate(false);
    fetchEstimates();
  };

  const handleInvoice = (estimateNumber, customerId) => {
    setnewInvoice(estimateNumber);
    setCustomerId(customerId);
    setShowDetails(true);
    setShowCard(false);
    setShowUpdate(false);
  };

  const handleEdit = (estimateNumber,status) => {
    setFetchedStatus(status);
    setEstimateNumber(estimateNumber);
    setShowUpdate(true);
    setShowCard(false);
    fetchEstimates();
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
  let i = 1;

  const handleCancelUpdate = () => {
    setShowUpdate(false);
    setShowCard(true);
    fetchEstimates();
  };

  const downloadEstimateExcel = () => {
    // Convert estimate data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(estimate);
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Estimates");
  
    // Set the header row to bold
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C }); // First row, column C
      if (worksheet[cellAddress]) {
        worksheet[cellAddress].s = {
          font: {
            bold: true,
          },
        };
      }
    }
  
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, "Estimates.xlsx");
  };
  
  return (
    <div className="container-fluid">
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Estimates
            </div>
            <div className="d-flex">
              <div className="">
                  <button
                    type="button"
                    className="btn btn-primary px-1 py-2 new-purchase"
                    onClick={handleEstimateClick}
                  >
                    + New Estimate
                  </button>
              </div>
              <div className="estimatess-dropdown-container d-flex align-items-end">
                <button
                  className="ml-2 px-1 py-2 icon-dot"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "white",
                  }}
                  onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown on click
                  ref={btnRef} // Ref for icon button
                >
                  <i
                    className="fa fa-ellipsis-v"
                    style={{ fontSize: 18, color: "gray" }}
                  ></i>
                  {dropdownOpen && (
                    <div className="estimates-dropdown">
                      <ul
                        style={{
                          listStyleType: "none",
                          margin: 0,
                          padding: "3px",
                          width: "170px",
                        }}
                      >
                        <li
                          className="estimates-option1"
                          onClick={downloadEstimateExcel}
                        >
                          <ion-icon
                            name="download-outline"
                            size="small"
                          ></ion-icon>
                          <span className="pl-2" >Export Estimate</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card>
                  {estimate && estimate.length > 0 ? (
                    <>
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Estimate
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Reference
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Customer name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Due date
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Total
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody className="text1">
                                    {estimate.map((esti) => (
                                      <tr
                                        role="row"
                                        className="odd"
                                        key={esti.id}
                                      >
                                        <td>{esti.estimateDate}</td>
                                        <td className="sorting_1">
                                          {" "}
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleInvoice(
                                                esti.estimateNumber,
                                                esti.customerId
                                              )
                                            }
                                          >
                                            {esti.estimateNumber}{" "}
                                          </a>
                                        </td>
                                        <td>{esti.referenceNumber}</td>
                                        <td>{esti.customerName}</td>
                                        <td>{esti.expiryDate}</td>
                                        <td>₹{esti.total}</td>

                                        <td>
                                          <button
                                            className="btn icon-border btn-sm mr-2"
                                            onClick={() =>
                                              handleEdit(esti.estimateNumber,esti.status)
                                            }
                                          >
                                            <i className="fa fa-edit text1"></i>
                                          </button>
                                          <button
                                            className="btn icon-border btn-sm"
                                            onClick={() =>
                                              handleDelete(esti.estimateNumber)
                                            }
                                          >
                                            <i className="fa fa-trash text1"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ):(
                    <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="heading-text">
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-con
                                      trols="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                      aria-label="Date: activate to sort column descending"
                                    >
                                      Date
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Type: activate to sort column ascending"
                                    >
                                      Estimate
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Amount BTC: activate to sort column ascending"
                                    >
                                      Reference
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="BTC Remaining: activate to sort column ascending"
                                    >
                                      Customer name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Due date
                                    </th>

                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Total
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      
      {showUpdate && (
        <EstimateUpdate
          fetchedStatus={fetchedStatus}
          estimateNumber={estimateNumber}
          handleCancel={handleCancelUpdate}
        />
      )}
      {showDetails && (
        <EstimateDetails
          newinvoiceNumber={newinvoiceNumber}
          custId={customerId}
          handleCancel={handleCancelDetails}
        />
      )}
    </div>
  );
}

export default Estimates;
