import React, { useState } from "react";
import { XCircle, User } from "phosphor-react"
function StudentImport(props) {

    const handleCancleClick = () => {
        props.handleCancle();
    }   
    
    return (
        <div className="container my-3">
            <div className="border border-2 p-2" >
                <div className="px-2 d-flex justify-content-between">
                    <h4 className="mt-3 mx-2" style={{letterSpacing:"1px"}}><b>Import Excel</b></h4>
                    <button className="btn" onClick={handleCancleClick}><XCircle size={32} weight="fill" /></button>
                </div>
                <hr />
                <div className="container">                                        

                <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                        <div className="col-md-3">
                            <label className="form-label   mg-b-0"><span className="text-dashed-underline"><b>Import : </b></span></label>
                        </div>
                        <div className="" style={{width:"312px"}}>
                            <select className="form-control w-60">
                                <option value="">Select</option>
                                <option value="item">Item</option>
                                <option value="customer">Customer</option>
                                <option value="vendor">Vendor</option>
                            </select>
                        </div>                        
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-3">
                            <label className="form-label required mg-b-0"><b>Select File to Import : </b></label>
                        </div>
                        <div>
                            <input className="form-control" name="file" type="file" />
                        </div>
                    </div>                   
                                                       
                    <div className="my-2 text-center">
                        <button className="btn btn-success">Save</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StudentImport;