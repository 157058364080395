import React from "react";
import Header from "../../../Components/Common/Header";
import ManageemployeeCard from "../../../Components/Apps/ManageEmployeeCard";
import ManageEmployeeNew from "./ManageEmployeeNew";

class ManageEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompose: false,
      show: false,
      show1: false,
    };
  }

  handleNewButtonClick = () => {
    this.setState({ show: true });
  };

  handleNewButtonClick1 = () => {
    this.setState({ show1: true });
    console.log("show 1 clicked");
  };

  handleCancelNew = () => {
    this.setState({ show: false });
  };

  handleCancelNew1 = () => {
    this.setState({ show1: false });
  };

  render() {
    return (
      <div className="container-fluid">
        {/* {!(this.state.show || this.state.show1) && (
          <Header
            headerText="ManageEmployee"
            mainNavigate="Office"
            currentPage="ManageEmployee"
            childNav={this.state.isCompose ? "Compose" : ""}
          />
        )} */}
        <div className="row clearfix ">
          {!this.state.show && (
            <div className="container mx-2 mb-2">
              <div className="d-flex justify-content-between">
                {/* <select name="filter" id="filter" class="form-control1 border-0">
                <option value="All">All Employee</option>
                <option value="Active">Active Employee</option>
                <option value="InActive">Inactive Employee</option>
              </select> */}

                <div className="pt-1">
                  <h4 className="font-600">All Employees</h4>
                </div>
                <div className="d-flex">
                  <div className="">
                    {!this.state.show && (
                      <button
                        className="btn btn-secondary"
                        onClick={this.handleNewButtonClick}
                        style={{ backgroundColor: "#06699d" }}
                      >
                        + NEW
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.show ? (
            <ManageEmployeeNew handleCancle={this.handleCancelNew} />
          ) : (
            ""
          )}
          {/* {this.state.show1 ? <ManageEmployeeImport handleCancle={this.handleCancelNew1}/> : ""} */}
          {!(this.state.show || this.state.show1) && <ManageemployeeCard />}
        </div>
      </div>
    );
  }
}

export default ManageEmployee;
