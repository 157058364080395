import React, { useState } from "react";
import { XCircle } from "phosphor-react"
import Select from "react-select";
import axios from "axios";
import swal from "sweetalert";
function ManageProjectNew(props) {
    const handleCancleClick = () => {
        props.handleCancle();
    }

   
    const customerNameOptions = [
        { value: 'Ratikant Patil', label: 'Ratikant Patil' },
        { value: 'Aniket Rane', label: 'Aniket Rane' },
        { value: 'Samarth', label: 'Samarth' }
    ];
    const serverNameOptions = [
        { value: 'Server 1', label: 'Server 1' },
        { value: 'Server 2', label: 'Server 2' },
        { value: 'Server 3', label: 'Server 3' }
    ];

    const [serverData, setServerData] = useState({
        projectName: "",
        type: "",
        technology: "",
        customerName: "",
        serverName: "",
        dateOfStart: "",
        endOfDate: "",
        amcDate: "",
        amcAmount: "",
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setServerData({
            ...serverData,
            [name]: value,
        });
    };

    const handleSaveClick = () => {
        // Define the URL of your API endpoint
        const apiUrl = "http://localhost:8096/api/insertProject";

        // Define the request headers
        const headers = {
            'Content-Type': 'application/json',
        };

        // Create the request body by converting the serverData object to JSON
        const requestBody = JSON.stringify(serverData);

        // Send a POST request to the API
        fetch(apiUrl, {
            method: 'POST',
            headers,
            body: requestBody,
        })
        .then(response => {
            if (response.status === 200) {
                console.log("Project added successfully");
                swal({
                    icon: "success",
                    title: "Estimate Data Inserted Successfully",
                });
            } else {
                console.error("Error adding project");
                swal({
                    icon: "error",
                    title: "Failed to insert data",
                });
            }
        })
        .catch(error => {
            console.error("Error adding project", error);
        });
    };
    return (
        <div className="container my-3">
            <div className="border border-2 p-2" >
                <div className="px-2 d-flex justify-content-between">
                    <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}>Add New Project</h4>
                    <button className="btn" onClick={handleCancleClick}><XCircle size={32} weight="fill" /></button>
                </div>
                <hr />
                <div className="container">

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label required mg-b-0">Project Name </label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control" placeholder="Project Name"  value={serverData.projectName}
                                name="projectName"
                                type="text"
                                onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label required mg-b-0">Type </label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control" placeholder="Type" value={serverData.type} name="type" onChange={handleInputChange} type="text" />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label required mg-b-0">Technology </label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control" placeholder="Technology" value={serverData.technology} name="technology" onChange={handleInputChange} type="text" />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label mg-b-0"><span className="text-dashed-underline">Customer Name </span></label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <Select  options={customerNameOptions}
                          value={customerNameOptions.find(option => option.value === serverData.customerName)} // Set value based on the selected value
                                onChange={(selectedOption) => setServerData({ ...serverData, customerName: selectedOption.value })}
                                placeholder="Select Customer..."/>
                                {/* <select className="form-control" name="customerName" value={customerName} onChange={handleInputChange}>
                                    <option value="1">1</option>
                                </select> */}
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label mg-b-0"><span className="text-dashed-underline">Server Name </span></label>
                        </div>
                        <div className="col-md-3 pl-0">
                        <Select
                                options={serverNameOptions}
                                value={serverNameOptions.find(option=>option.value===serverData.serverName)}
                                onChange={(selectedOption) => setServerData({ ...serverData, serverName: selectedOption.value })}
                                placeholder="Select Server..."
                            />
                          
                              </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label">Date Of Start </label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control"  name="dateOfStart"
                                value={serverData.dateOfStart}
                                type="date"
                                onChange={handleInputChange}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">Date Of End </label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control"  value={serverData.endOfDate}
                                name="endOfDate"
                                type="date"
                                onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                        <div className="col-md-2">
                            <label className="form-label">Amc Date </label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control" value={serverData.amcDate}
                                name="amcDate"
                                type="date"
                                onChange={handleInputChange}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">Amc Amount </label>
                        </div>
                        <div className="col-md-3 pl-0">
                            <input className="form-control" placeholder="Amc Amount"  value={serverData.amcAmount}
                                name="amcAmount"
                                type="text"
                                onChange={handleInputChange} />
                        </div>
                    </div>


                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">

                    </div>
                    <div className="my-2 text-right">
              <button
                className="btn btn-primary px-2 py-1"
                style={{ backgroundColor: "var(--primary-color)" }}
                onClick={handleSaveClick}
              >
                Save
                {"  "}
                <i className="fa fa-save"></i>
              </button>
              <button
                className="btn btn-secondary mx-2 px-2 py-1"
                onClick={handleCancleClick}>
                Cancel <i className="fa fa-times"></i>
              </button>
            </div>
                </div>
            </div>
            

        </div>
    )
}

export default ManageProjectNew;