// TinyMceEditor.js
import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";

class TinyMceEditor extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  onChange(e) {
    console.log(e.target.getContent());
  }

  handleEditorChange(content, editor) {
    // Call the parent component's onChange prop to update its state
    if (this.props.onChange) {
      this.props.onChange(content);
    }
  }
  render() {
    const { initialContent } = this.props;

    return (
      <div>
        <Editor
          initialValue={initialContent}
          apiKey="4h8oo2lv4rl9gi28mb7hv90bwsf4k369lnf0gmxragfpofwj"
          init={{
            branding: false,
            height: 500,
            menubar: true,
            plugins:
              "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
            toolbar:
              "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
            image_advtab: true,
          }}
          onChange={this.onChange}
          onEditorChange={this.handleEditorChange}
        />
      </div>
    );
  }
}

export default TinyMceEditor;
