import React, { useState, useEffect } from "react";
import { CaretLeft, Jeep, Boat, Airplane, TrainSimple } from "phosphor-react";
// import { Container, Row, Col, Card, } from "react-bootstrap";
import { addDays, endOfMonth, addMonths } from "date-fns";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import { differenceInDays } from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {
  ButtonGroup,
  Dropdown,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import EwayBills from "./EwayBills";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function EwayBillsNew({ navigation }) {
  const [row, setRow] = useState([
    {
      itemDetails: '',
      hsnSac: '',
      size: '',
      quantity: '',
      rate: '',
      discount: 0,
      discountType: '₹',
      tax: 0,
      amount: 0,
      rowDiscount: 0,
    },
  ]);

  const jwtToken = localStorage.getItem("jwtToken");

  const handleAdd = () => {
    const newItem = {
      itemDetails: "",
      account: "",
      hsnSac: "",
      quantity: "1.0",
      rate: "0.0",
      discount: "0.0",
      discountType: "₹",
    };
    const newRow = [...row, newItem];
    setRow(newRow);
  };

  // const handleTaxChange = (e, index) => {
  //   setSelectedTaxRate(e.target.value);
  //   const newTaxRate = parseFloat(e.target.value);
  //   const updatedRow = [...row];
  //   updatedRow[index].tax = newTaxRate;
  //   setRow(updatedRow);
  // };

  const handleTaxChange = (e, index) => {
    setSelectedTaxRate(e.target.value);
    const newTaxRate = parseFloat(e.target.value);
    const updatedRow = [...row];
    updatedRow[index].tax = newTaxRate;
    setRow(updatedRow);
  };

  const handleDeleteRow = (i) => {
    const updatedRow = [...row];
    updatedRow.splice(i, 1);
    setRow(updatedRow);
    updateTotal(updatedRow);
  };

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancleClick = () => {
    handleNavigation("EwayBills");
  };

  const [show1, setShow1] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    fetchSalesData();
  };

  const [showInvoice, setShowInvoice] = useState(false);

  const openInvoice = () => {
    setShowInvoice(true);
  };

  const closeInvoice = () => {
    setShow1(false);
    setShowInvoice(false);
  };

  // const [selectedTerms, setSelectedTerms] = useState("");
  const [bussinessRegistered, setBussinessRegistered] = useState("");
  const [discountCheck, setDiscountCheck] = useState("");
  const [sizeCheck, setSizeCheck] = useState("");

  const handleDiscountChange = (e, i) => {
    const { name, value } = e.target;
    const newValues =
      name === "discount"
        ? { discount: value }
        : { discountType: showDisType1 };
    const newRow = [...row];
    newRow[i] = { ...newRow[i], ...newValues };
    setRow(newRow);
    updateTotal(newRow);
  };

  const handleQuantityChange = (e, i) => {
    const newQuantity = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], quantity: newQuantity };
    setRow(newRow);
    updateTotal(row);
  };

  const handleRateChange = (e, i) => {
    const newRate = e.target.value;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], rate: newRate };
    setRow(newRow);
    updateTotal(row);
  };

  const updateTotal = (rowData) => {
    let calculatedTotal = 0;
    rowData.forEach((data) => {
      const amount = calculateAmount(data);
      calculatedTotal += parseFloat(amount);
    });
    setTotal(calculatedTotal.toFixed(2));
  };

  const [total, setTotal] = useState(0);

  //calculateAmount
  const calculateAmount = (data, taxRate) => {
    const quantity = parseFloat(data.quantity || 0);
    const rate = parseFloat(data.rate || 0);
    const discount = parseFloat(data.discount || 0);
    const amount = quantity * rate;
    let discountedAmount = amount;
    if (data.discountType === "%") {
      discountedAmount -= (amount * discount) / 100;
    } else if (data.discountType === "₹") {
      discountedAmount -= discount;
    }
    let calculatedTaxAmount = 0;
    if (taxRate > 0) {
      calculatedTaxAmount = discountedAmount * taxRate;
    }
    const calculatedAmount = discountedAmount + calculatedTaxAmount;
    return calculatedAmount.toFixed(2);
  };

  const calculateDisRupees = (data, taxRate) => {
    const quantity = parseFloat(data.quantity || 0);
    const rate = parseFloat(data.rate || 0);
    const discount = parseFloat(data.discount || 0);
    const amount = quantity * rate;
    let discountedAmount = 0;
    if (data.discountType === "%") {
      discountedAmount = (amount * discount) / 100;
    } else if (data.discountType === "₹") {
      discountedAmount = discount;
    }

    return discountedAmount.toFixed(2);
  };

  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  //CalculateTaxAmount
  const calculateTaxAmount = (amount, taxRate) => {
    if (taxRate > 0) {
      let taxIncrease = 0;

      // Set tax increase based on tax rate
      switch (taxRate) {
        case 0.05:
          taxIncrease = 0.5;
          break;
        case 0.12:
          taxIncrease = 1.0;
          break;
        case 0.18:
          taxIncrease = 1.5;
          break;
        case 0.28:
          taxIncrease = 2.0;
          break;
        default:
          break;
      }

      return amount * (taxRate + taxIncrease);
    }
    return 0;
  };

  const calculateSubTotal = () => {
    let subTotal = 0;
    row.forEach((data) => {
      const amount = calculateAmount(data);
      subTotal += parseFloat(amount);
    });
    return subTotal.toFixed(2);
  };

  const [discountAmountTds, setDiscountAmountTds] = useState(null);

  const applyDiscount = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tdsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTds(discountAmount.toFixed(2)); // Set discountAmount in state

    return tdsDis;
  };

  const handleTdsOptionChange = (selectedOption) => {
    setSelectedTdsOption(selectedOption);
    const subtotal = calculateSubTotal();

    // Check if a TDS option is selected and has a discount
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscount(subtotal, selectedOption.discount);
    }
  };

  const [discountAmountTcs, setDiscountAmountTcs] = useState(null);

  const applyDiscountTcs = (subtotal, discountPercentage) => {
    const discountAmount = (subtotal * discountPercentage) / 100;
    const tcsDis = (subtotal - discountAmount).toFixed(2);
    setDiscountAmountTcs(discountAmount.toFixed(2)); // Set discountAmount in state

    return tcsDis;
  };

  const handleTcsOptionChange = (selectedOption) => {
    setSelectedTcsOption(selectedOption);
    const subtotal = calculateSubTotal();

    // Check if a TDS option is selected and has a discount
    if (selectedOption && selectedOption.discount) {
      const discountedTotal = applyDiscountTcs(
        subtotal,
        selectedOption.discount
      );
    }
  };

  // => IGST
  // const calculateIGST = () => {
  //   let totalIGST = 0;
  //   row.forEach((data) => {
  //     const taxRate = parseFloat(data.tax || 0);
  //     const amount = calculateAmount(data, taxRate);
  //     const taxAmount = calculateTaxAmount(parseFloat(amount), taxRate);
  //     totalIGST += taxAmount;
  //   });
  //   return totalIGST.toFixed(2);
  // };

  // => IGST - 1
  const calculateIGST = () => {
    let totalIGST = 0;

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      totalIGST += parseFloat(amount) - amount / (1 + taxRate); // Subtract the original tax amount
    });

    return totalIGST.toFixed(2);
  };

  //CalculateSGST
  const calculateSGST = () => {
    let totalSGST = 0;
    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const taxAmount = calculateTaxAmount(parseFloat(amount), taxRate);
      const sgstAmount = taxAmount / 2; // Assuming SGST is half of the total tax amount
      totalSGST += sgstAmount;
    });
    return totalSGST.toFixed(2);
  };

  //Calculate CGST
  const calculateCGST = () => {
    let totalCGST = 0;
    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const taxAmount = calculateTaxAmount(parseFloat(amount), taxRate);
      const cgstAmount = taxAmount / 2;
      totalCGST += cgstAmount;
    });
    return totalCGST.toFixed(2);
  };

  //calculate SGST and CGSt
  const calculateSGSTandCGST = () => {
    let totalSGST = 0;
    let totalCGST = 0;

    row.forEach((data) => {
      const taxRate = parseFloat(data.tax || 0);
      const amount = calculateAmount(data, taxRate);
      const increasedTaxAmount = parseFloat(amount) - amount / (1 + taxRate);
      const sgst = increasedTaxAmount / 2;
      const cgst = increasedTaxAmount / 2;
      totalSGST += sgst;
      totalCGST += cgst;
    });
    return {
      totalSGST: totalSGST.toFixed(2),
      totalCGST: totalCGST.toFixed(2),
    };
  };

  const [showing1, setShowing1] = useState(false);
  const [showing2, setShowing2] = useState(false);
  const tds = [
    {
      value: "Commission or Brokerag 5%",
      label: "Commission or Brokerag 5%",
      discount: 5,
    },
    {
      value: "Commission or Brokerag (Reduced) 3.75%",
      label: "Commission or Brokerag (Reduced) 3.75%",
      discount: 3.75,
    },
    { value: "Divident 10%", label: "Divident 10%", discount: 10 },
    {
      value: "Divident (Reduced) 7.5%",
      label: "Divident (Reduced) 7.5%",
      discount: 7.5,
    },
    {
      value: "Other Interest then securities 10%",
      label: "Other Interest then securities 10%",
      discount: 10,
    },
    {
      value: "Other Interest then securities (Reduced) 7.5%",
      label: "Other Interest then securities (Reduced) 7.5%",
      discount: 7.5,
    },
    {
      value: "Payment of Contractors Others 2%",
      label: "Payment of Contractors Others 2%",
      discount: 2,
    },
    {
      value: "Payment of Contractors Others (Reduced) 1.5%",
      label: "Payment of Contractors Others (Reduced) 1.5%",
      discount: 1.5,
    },
    {
      value: "Payment of Contractors Others HUD/Indiv 1%",
      label: "Payment of Contractors Others HUD/Indiv 1%",
      discount: 1,
    },
    {
      value: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      label: "Payment of Contractors Others HUD/Indiv (Reduced) 0.75%",
      discount: 0.75,
    },
    {
      value: "Professional Fees 10%",
      label: "Professional Fees 10%",
      discount: 10,
    },
    {
      value: "Rent on land or furniture etc 10%",
      label: "Rent on land or furniture etc 10%",
      discount: 10,
    },
  ];
  // const jwtToken=localStorage.getItem("jwtToken");

  const tcs = [
    // { value: "0 GST [0%]", label: "0 GST [0%]", discount: 0 },
    { value: "5 GST [5%]", label: "5 GST [5%]", discount: 0.5 },
    { value: "12 GST [12%]", label: "12 GST [12%]", discount: 0.12 },
    { value: "18 GST [18%]", label: "18 GST [18%]", discount: 0.18 },
    { value: "28 GST [28%]", label: "28 GST [28%]", discount: 0.28 },
  ];

  const [adjustmentValue, setAdjustmentValue] = useState(""); // Step 1: State for adjustment value
  const [oppositeValue, setOppositeValue] = useState(""); // State for opposite value

  // const [disAllValue , setDisAllValue] = useState("");
  const [disAllValue, setDisAllValue] = useState("");
  const [discountType, setDiscountType] = useState("₹");
  const [disValue, setDisValue] = useState("");
  // const [totalTaxAmount , setTotalTaxAmount] = useState("");
  const [selectedTaxType, setSelectedTaxType] = useState(""); // To store the selected tax type (TDS or TCS)
  const [selectedTdsOption, setSelectedTdsOption] = useState(null); // To store the selected TDS option
  const [selectedTcsOption, setSelectedTcsOption] = useState(null);
  const [tType, setTType] = useState("");
  const totalTax =
    calculateIGST() ||
    calculateSGSTandCGST().totalSGST + calculateSGSTandCGST().totalCGST;

  const calculateDiscount = () => {
    const amount = parseFloat(calculateSubTotal()).toFixed(2);

    if (discountType === "₹") {
      setDisValue(disAllValue);
    } else if (discountType === "%") {
      const percentageDiscount = (amount * disAllValue) / 100;
      setDisValue(percentageDiscount.toFixed(2));
    }
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDisAllValueChange = (e) => {
    setDisAllValue(e.target.value);
  };

  useEffect(() => {
    calculateDiscount();
  }, [disAllValue, discountType]);


  useEffect(() => {
    const parsedValue = parseFloat(adjustmentValue);
    if (!isNaN(parsedValue)) {
      // Calculate the opposite value only if the adjustmentValue is a valid number
      const opposite = parsedValue * 1;
      setOppositeValue(opposite.toFixed(2));
    } else {
      // If adjustmentValue is not a valid number, set the opposite value to an empty string
      setOppositeValue("");
    }
  }, [adjustmentValue]);

  const calculateTotal = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, otherwise, default to 0
    const total1 = subTotal + igst + adjustment;
    const total = total1 - disValue;
    const totalFinal = total - discountAmountTcs;
    return totalFinal.toFixed(2);
  };

  const calculateTotal2 = () => {
    const subTotal = parseFloat(calculateSubTotal());
    const igst = parseFloat(calculateIGST());
    const adjustment = parseFloat(adjustmentValue) || 0; // Use the adjustment value if it's a valid number, 
  };

  const fetchSalesData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSalesPersonData(data.responseMessage);
        } else {
          console.error("Failed to fetch salesperson:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching salesperson:", error);
      });
  };
  useEffect(() => {
    fetchSalesData();
  }, []);

  const [custNames, setCustNames] = useState([]);
  const [fetchedInv, setFetchedInv] = useState([]);
  const [fetchedCreditNote, setFetchedCreditNote] = useState([]);
  const [fetchedDeliveryChallans, setFetchedDeliveryChallans] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
  const [selectedCreditNoteNumber, setSelectedCreditNoteNumber] = useState("");
  const [selectedDeliveryChallan, setSelectedDeliveryChallan] = useState("");
  const [selectedAddressDetails, setSelectedAddressDetails] = useState("");
  const [selectedTransactionType, setSelectedTransactionType] = useState("");
  const [distance, setDistance] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [docNo, setDocNo] = useState("");
  const [docDate, setDocDate] = useState("");
  const [rrNo, setRrNo] = useState("");
  const [rrDate, setRrDate] = useState("");
  const [landingNo, setLandingNo] = useState("");
  const [landingDate, setLandingDate] = useState("");
  const [airbillNo, setAirbillNo] = useState("");
  const [airbillDate, setAirbillDate] = useState("");
  const [fetchedDate, setFetchedDate] = useState("");

  const [selectedCustomerId, setSelectedCustomerId] = useState(""); // State to store the selected customer's ID

  // Fetch customer data
  useEffect(() => {
    fetchAllCustomers();
  }, [])

  const fetchAllCustomers = () => {
    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Customer Data:", data);

        if (data.responseCode === "200") {
          console.log("Customer Name Response Message:", data.responseMessage);
          setCustNames(data.responseMessage);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchAllInvoices = (id) => {
    fetch(BASE_URL + `/fetchInvoiceByCustomerId/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched invoices:", data);
        console.log("invoices Response Message:", data.responseMessage);
        setFetchedInv(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching invoice numbers:", error);
      });
  };

  const fetchAllCreditNotes = (id) => {
    fetch(BASE_URL + `/fetchCreditNotesByCustomerId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, customerId: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched credit note:", data);
        setFetchedCreditNote(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching credit note:", error);
      });
  };

  const fetchAllDeliveryChalllan = (id) => {
    fetch(BASE_URL + `/fetchDeliveryChallanByCustomerId/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Deliver Challan:", data);
        setFetchedDeliveryChallans(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching Deliver Challan", error);
      });
  };


  const handleCustomerChange = (value, id) => {
    const selectedValue = value;
    const selectedValueId = id;
    setSelectedCustomer(selectedValue);
    setSelectedCustomerId(selectedValueId);

    const selectedCustomerObject = custNames.find(
      (person) => person.customerName === selectedValue
    );

    if (selectedCustomerObject) {
      const customerState = selectedCustomerObject.billState;
      setCustomerState(customerState);
      setSelectedCustomerId(selectedCustomerObject.id);
    } else {
      console.log("Selected Customer: (Not found)");
    }

    if (selectedDocumentType.value == "invoices") {
      fetchAllInvoices(selectedCustomerObject.id);
    };

    if (selectedDocumentType.value == "creditNotes") {
      fetchAllCreditNotes(selectedCustomerObject.id);
    };

    if (selectedDocumentType.value == "deliveryChallans") {
      fetchAllDeliveryChalllan(selectedCustomerObject.id);
    };
  }

  const handleInvoiceChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedInvoiceNumber(selectedValue);

    fetch(BASE_URL + `/fetchEwayBillsByNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, documentType: "Invoice", invoiceNumber: selectedValue.value }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched ewaybill by number - invoice :", data);
        setFetchedDate(data.responseMessage[0].invoiceDate);
        setRow(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching ewaybill by number - invoice", error);
      });
  }

  const handleCreditNoteChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedCreditNoteNumber(selectedValue);

    fetch(BASE_URL + `/fetchEwayBillsByNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, documentType: "Credit Note", creditNoteNumber: selectedValue.value }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched ewaybill by number - credit note :", data);
        setFetchedDate(data.responseMessage[0].creditNoteDate);
        setRow(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching ewaybill by number - credit note", error);
      });
  }

  const handleDeliveryChallanChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedDeliveryChallan(selectedValue);

    fetch(BASE_URL + `/fetchEwayBillsByNumber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, documentType: "Delivery Challan", deliveryChallan: selectedValue.value }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched ewaybill by number - invoice :", data);
        setFetchedDate(data.responseMessage[0].deliveryChallanDate);
        setRow(data.responseMessage);
      })
      .catch((error) => {
        console.error("Error fetching ewaybill by number - invoice", error);
      });

  }

  const handleAddressChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedAddressDetails(selectedValue);
  }

  const handleTransactionTypeChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedTransactionType(selectedValue);
  }

  const handleDistanceChange = (selectedOption) => {
    const selectedValue = selectedOption.target.value;
    setDistance(selectedValue);
  }

  const handleRadioClick = (value) => {
    setVehicleType(value);
  }

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("GST DATA Data:", data);

        if (data.responseCode === "200") {
          console.log("GST DATA  Response Message:", data.responseMessage[0]);
          const Data = data.responseMessage[0].businessRegistered;
          console.log(Data, " businessRegistered");

          setBussinessRegistered(Data);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchDiscountCheck`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("GST DATA Data:", data);

        if (data.responseCode === "200") {
          console.log("GST DATA  Response Message:", data.responseMessage);
          const Data = data.responseMessage.discountCheck;
          const sizeCheck = data.responseMessage.sizeCheck;
          setDiscountCheck(Data);
          setSizeCheck(sizeCheck);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  }, []);

  const [userState, setUserState] = useState([]);
  const [customerState, setCustomerState] = useState([]);

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId , userId:userId}),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          const userState = data.responseMessage.state;
          console.log("====================================");
          console.log(userState);
          console.log("====================================");

          setUserState(userState);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle customer selection

  const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState([]);
  const [invoiceNumberNext, setInvoiceNumberNext] = useState([]);

  const [salesPersonData, setSalesPersonData] = useState([]);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  // Decrypt the uniqueId using your decryption method
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [errors, setErrors] = useState({});
  const [searchIndex, setSearchIndex] = useState(0);

  const insertDataIntoDatabase = async () => {
    const itemDetails = row.map((data) => {
      return {
        itemDetails: data.itemDetails || '', // Assuming you have an input for itemDetails
        hsnSac: data.hsnSac,
        size: data.size,
        quantity: data.quantity,
        rate: data.rate,
        discount: data.discount || 0,
        discountType: data.discountType,
        tax: data.tax, // Assuming the tax rate is the same for all items
        amount: calculateAmount(data, selectedTaxRate),
        rowDiscount: calculateDisRupees(data),
      };
    });

    const formData = {
      uniqueId: decryptedUniqueId,
      documentType: selectedDocumentType?.value || "",
      transactionSubType: selectedTransactionSubType?.value || "",
      customerName: selectedCustomer || "",
      invoice: selectedInvoiceNumber?.value || "",
      creditNote: selectedCreditNoteNumber?.value || "",
      deliveryChallan: selectedDeliveryChallan?.value || "",
      date: fetchedDate || "",
      transactionType: selectedTransactionType?.value || "",
      placeOfDelivery: selectedState?.value || "",
      addressDetails: selectedAddressDetails?.value || "",
      itemDetails: itemDetails,
      transporter: selectedTransporter?.value || "",
      distance: distance || 0,
      modeOfTransportation: (road === true && "Road") || (rail === true && "Rail") || (ship === true && "Ship") || (air === true && "Air"),
      vehicleType: vehicleType,
      vehicleNumber: vehicleNumber || "",
      transportersDocNo: docNo || "",
      transportersDocDate: docDate || normalDateFormat(startDate),
      RRNo: rrNo || "",
      RRDate: rrDate || "",
      billOflandingNo: landingNo || "",
      billOflandingDate: landingDate || "",
      airwayBillNo: airbillNo || "",
      airwayBillNDate: airbillDate || "",
    };

    try {
      const response = await fetch(BASE_URL + `/insertEwayBill`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Data inserted successfully
        console.log("Data inserted successfully");
        swal({
          icon: "success",
          title: "EwayBill Create Successfully",
        });
        handleCancleClick();
      } else {
        // Handle error
        console.error("Failed to insert data");
        swal({
          icon: "error",
          title: "Failed to insert data",
        });
      }
    } catch (error) {
      console.error(error);
    }

  };

  // console.log(":::::::::::::::::::::::::::::::::::",calculateIGST() || (calculateSGSTandCGST().totalSGST+calculateSGSTandCGST().totalCGST) )
  // Call the function when the user submits the form
  const handleSubmit = () => {
    insertDataIntoDatabase();
    // Optionally, you can perform other actions after data insertion
  };

  // const handleItemDetailsChange = (e, i) => {
  //   const { value } = e.target;
  //   const newRow = [...row];
  //   newRow[i] = { ...newRow[i], itemDetails: value };
  //   setRow(newRow);
  // };

  const handleHsnSacChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], hsnSac: value };
    setRow(newRow);
  };

  const handleSizeChange = (e, i) => {
    const { value } = e.target;
    const newRow = [...row];
    newRow[i] = { ...newRow[i], size: value };
    setRow(newRow);
  };
  // const [show1, setShow1] = useState(false);

  const [generatedInvoiceNumber, setGeneratedInvoiceNumber] = useState("");

  const setBill = () => {
    setShow1(true);
    fetchBill();
  };

  const fetchBill = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Prepare the data to send in the POST request
    const requestData = {
      // Include any other data you need to send in the request
      uniqueId: decryptedUniqueId,
      type: "invoice",
    };

    // Define the POST request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
        // Add any other headers if required
      },
      body: JSON.stringify(requestData), // Convert the data to JSON format
    };

    // Make the POST request to your API endpoint
    fetch(BASE_URL + `/getCurruntNumberList2`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Assuming your API returns an object with prefix and next number properties
        const { prefix, nextNumber, uniqueId } = data;
        console.log("Bill No : ", data.responseMessage.prefix);
        // Set the fetched values in your state
        setInvoiceNumberPrefix(data.responseMessage.prefix);
        setInvoiceNumberNext(data.responseMessage.numbers);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }; // Empty dependency array to fetch data once when the component mounts

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/getCurruntNumberList`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ type: "invoice", uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Number List Data:", data);

        if (data.status === "success") {
          console.log("Number List Response Message:", data.responseMessage);
        } else {
          console.error("Failed to fetch numberList:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Number List:", error);
      });
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRow = [...row];
    updatedRow[index][name] = value;
    setRow(updatedRow);
  };

  const [salesData, setSalesData] = useState({
    uniqueId: decryptedUniqueId,
    name: "",
    email: "",
  });

  const handleSalesPerson = (event) => {
    event.preventDefault();

    fetch(BASE_URL + "/createSalesperson", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(salesData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Salesperson Added Successfully",
          });
          closePopup();
        } else {
          swal({
            icon: "success",
            title: "Failed To Create Tax Rate",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "success",
          title: "An Error Occured While Creating Tax RAte ",
        });
      });
  };

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const [selectedTerms, setSelectedTerms] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [dueDateChangedManually, setDueDateChangedManually] = useState(false);

  // const handleSalesOrderDateChange = (e) => {
  //   const newDate = e.target.value;
  //   setCurrentDate(newDate);

  //   if (selectedTerms) {
  //     updateDueDate(new Date(newDate), selectedTerms);
  //   }
  // };

  // const handlePaymentTermsChange = (e) => {
  //   const terms = e.target.value;
  //   setSelectedTerms(terms);

  //   if (currentDate && terms) {
  //     updateDueDate(new Date(currentDate), terms);
  //   }
  // };

  const handleExpectedShipmentDateChange = (e) => {
    setDueDateChangedManually(true);
    setDueDate(new Date(e.target.value));
    updatePaymentTerms(new Date(e.target.value));
    // Add any validation or error handling for the expected shipment date here if needed
  };

  const updatePaymentTerms = (newDueDate) => {
    // Logic to determine the terms based on the newDueDate
    // For example, you might want to check the difference in days and set terms accordingly
    const daysDifference = differenceInDays(newDueDate, new Date());

    if (daysDifference === 15) {
      setSelectedTerms("Net 15");
    } else if (daysDifference === 30) {
      setSelectedTerms("Net 30");
    } else if (daysDifference === 45) {
      setSelectedTerms("Net 45");
    } else if (daysDifference === 60) {
      setSelectedTerms("Net 60");
    } else {
      setSelectedTerms("Custom");
    }
  };

  useEffect(() => {
    if (currentDate && selectedTerms && !dueDateChangedManually) {
      updateDueDate(new Date(currentDate), selectedTerms);
    }
  }, [currentDate, selectedTerms, dueDateChangedManually]);

  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fetchItemData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");

    const postApiUrl = BASE_URL + `/getAllItemData`;

    fetch(postApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const responseData = data.responseMessage;
        setDropdownData(responseData.map((item) => item));
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  useEffect(() => {
    const results = dropdownData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, dropdownData]);

  const handleSearchChange = (e, index) => {
    const value = e.target.value;
    const newRow = [...row];
    newRow[index].itemDetails = value;
    setSearchTerm(value);
    setSearchPerformed(value !== "");
    setRow(newRow);
    setSearchIndex(index);
  };

  const handleItemDetailsChange = (e, index) => {
    handleSearchChange(e, index);
  };

  const handleItemSelect = (selectedItem, selectedItemId, index) => {
    setSearchResults([]);
    const newRow = [...row];
    newRow[index].itemDetails = selectedItem;
    setRow(newRow);
    fetchItemRate(selectedItemId, index);
  };

  const fetchItemRate = (itemId, index) => {
    const jwtToken = localStorage.getItem("jwtToken");
    const getApiUrl = BASE_URL + `/getItemData/${itemId}`;

    fetch(getApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data getItemData : ", data.data);
        const rate = data.data.salesPrice;
        console.log("Rate : ", rate);
        const updatedRow = [...row];
        updatedRow[index].rate = rate;
        setRow(updatedRow);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const [showDueDate, setShowDueDate] = useState(true);

  const updateDueDate = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(startDate, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(startDate)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(startDate, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(startDate));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const updateDueDate1 = (date, terms) => {
    setDueDateChangedManually(false);
    if (terms.startsWith("Net ")) {
      const daysToAdd = parseInt(terms.replace("Net ", ""));
      setDueDate(normalDateFormat(addDays(date, daysToAdd)));
      setShowDueDate(true);
    } else if (terms === "Due End Of The Month") {
      setDueDate(normalDateFormat(endOfMonth(date)));
      setShowDueDate(true);
    } else if (terms === "Due End Of Next Month") {
      setDueDate(normalDateFormat(endOfMonth(addMonths(date, 1))));
      setShowDueDate(true);
    } else if (terms === "Due On Receipt") {
      setDueDate(normalDateFormat(date));
      setShowDueDate(true);
    } else if (terms === "Custom") {
      setShowDueDate(false);
    } else {
      setDueDate("");
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  useEffect(() => {
    setStartDate(new Date());
    setStartDate1(new Date());
  }, []);

  const [dateValue, setDateValue] = useState("");
  const [dateValue1, setDateValue1] = useState("");

  const handleDate = (date) => {
    setStartDate(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    console.log("normalFormat : ", normalFormat);
    setDateValue(normalFormat);
    updateDueDate1(date, selectedTerms);
  };

  const handleDate1 = (date) => {
    setStartDate1(date);
    let normalFormat = date === null ? "" : normalDateFormat(date);
    console.log("normalFormat : ", normalFormat);
    setDateValue1(normalFormat);
  };

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return date;
  };

  const [showDisType1, setShowDisType1] = useState("%");
  const changeDiscountType = () => {
    setShowDisType1((prevType) => (prevType === "₹" ? "%" : "₹"));
  };

  const [showDisType2, setShowDisType2] = useState("%");
  const changeDiscountType1 = () => {
    setShowDisType2((prevType) => (prevType === "₹" ? "%" : "₹"));
  };

  const optionAddressDetails = [
    { value: "Address 1", label: "Address 1" },
    { value: "Address 2", label: "Address 2" },
    { value: "Address 3", label: "Address 3" },
  ];

  const options = custNames.map((customer) => ({
    value: customer.customerName,
    label: customer.customerName,
  }));

  const optionInvoices = fetchedInv.map((inv) => ({
    value: inv.invoiceNumber,
    label: inv.invoiceNumber,
  }));

  const optionCreditNotes = fetchedCreditNote.map((inv) => ({
    value: inv.creditNoteNumber,
    label: inv.creditNoteNumber,
  }));

  const optionDeliveryChallans = fetchedDeliveryChallans.map((del) => ({
    value: del.deliveryChallan,
    label: del.deliveryChallan,
  }));

  const optionSales = salesPersonData.map((person) => ({
    value: person.id,
    label: person.name,
  }));

  const optionPaymentTerms = [
    { value: "Net 15", label: "Net 15" },
    { value: "Net 30", label: "Net 30" },
    { value: "Net 45", label: "Net 45" },
    { value: "Net 60", label: "Net 60" },
    { value: "Due End Of The Month", label: "Due End Of The Month" },
    { value: "Due End Of Next Month", label: "Due End Of Next Month" },
    { value: "Due On Receipt", label: "Due On Receipt" },
    { value: "Custom", label: "Custom" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedTerms(selectedOption.value);
    updateDueDate(dateValue, selectedOption.value);
  };

  const optionTaxes = [
    { value: "0", label: "0 GST [0%]" },
    { value: "0.05", label: "5 GST [5%]" },
    { value: "0.12", label: "12 GST [12%]" },
    { value: "0.18", label: "18 GST [18%]" },
    { value: "0.28", label: "28 GST [28%]" },
  ];

  const [taxValues, setTaxValues] = useState(Array(row.length).fill(0));

  const handleTaxChangeForRow = (taxValue, rowIndex) => {
    const newTaxValues = [...taxValues];
    newTaxValues[rowIndex] = taxValue;
    setTaxValues(newTaxValues);
  };

  //========================================================================================

  const [selectedDocumentType, setselectedDocumentType] = useState("invoies");
  const [selectedTransactionSubType, setSelectedTransactionSubType] = useState("");
  const [addressDetails, setAddressDetails] = useState(true);
  const [selectedState, setSelectedState] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [selectedTransporter, setSelectedTransporter] = useState(false);
  const [road, setRoad] = useState(true);
  const [rail, setRail] = useState(false);
  const [ship, setShip] = useState(false);
  const [air, setAir] = useState(false);

  const optionTransactionType = [
    { value: "Trans 1", label: "Trans 1" },
    { value: "Trans 2", label: "Trans 2" },
    { value: "Trans 3", label: "Trans 3" },
  ]

  const optionDocumentType = [
    { value: "invoices", label: "Invoices" },
    { value: "creditNotes", label: "Credit Notes" },
    { value: "deliveryChallans", label: "Delivery Challans" },
  ];

  const optionTransactionSubType = [
    { value: "suply", label: "Suply" },
    { value: "export", label: "Export" },
    { value: "skd/ckd", label: "SKD/CKD" },
  ];

  const optionTransactionSubType1 = [
    { value: "Sales Return", label: "Sales Return" },
    { value: "Job Work Return", label: "Job Work Return" },
  ];

  const optionTransactionSubType2 = [
    { value: "Job Work", label: "Job Work" },
    { value: "SKD/CKD", label: "SKD/CKD" },
    { value: "Recipient Not Known", label: "Recipient Not Known" },
    { value: "For Own Use", label: "For Own Use" },
    { value: "Exhibition or Fairs", label: "Exhibition or Fairs" },
    { value: "Line Sales", label: "Line Sales" },
    { value: "Others", label: "Others" },
  ];

  const statesOfIndia = [
    {
      label: "Andaman and Nicobar Islands",
      value: "andaman_and_nicobar_islands",
    },
    { label: "Andhra Pradesh", value: "andhra_pradesh" },
    { label: "Arunachal Pradesh", value: "arunachal_pradesh" },
    { label: "Assam", value: "assam" },
    { label: "Bihar", value: "bihar" },
    { label: "Chandigarh", value: "chandigarh" },
    { label: "Chhattisgarh", value: "chhattisgarh" },
    {
      label: "Dadra and Nagar Haveli and Daman and Diu",
      value: "dadra_and_nagar_haveli_and_daman_and_diu",
    },
    { label: "Delhi", value: "delhi" },
    { label: "Goa", value: "goa" },
    { label: "Gujarat", value: "gujarat" },
    { label: "Haryana", value: "haryana" },
    { label: "Himachal Pradesh", value: "himachal_pradesh" },
    { label: "Jammu and Kashmir", value: "jammu_and_kashmir" },
    { label: "Jharkhand", value: "jharkhand" },
    { label: "Karnataka", value: "karnataka" },
    { label: "Kerala", value: "kerala" },
    { label: "Ladakh", value: "ladakh" },
    { label: "Lakshadweep", value: "lakshadweep" },
    { label: "Madhya Pradesh", value: "madhya_pradesh" },
    { label: "Maharashtra", value: "maharashtra" },
    { label: "Manipur", value: "manipur" },
    { label: "Meghalaya", value: "meghalaya" },
    { label: "Mizoram", value: "mizoram" },
    { label: "Nagaland", value: "nagaland" },
    { label: "Odisha", value: "odisha" },
    { label: "Puducherry", value: "puducherry" },
    { label: "Punjab", value: "punjab" },
    { label: "Rajasthan", value: "rajasthan" },
    { label: "Sikkim", value: "sikkim" },
    { label: "Tamil Nadu", value: "tamil_nadu" },
    { label: "Telangana", value: "telangana" },
    { label: "Tripura", value: "tripura" },
    { label: "Uttar Pradesh", value: "uttar_pradesh" },
    { label: "Uttarakhand", value: "uttarakhand" },
    { label: "West Bengal", value: "west_bengal" },
  ];

  const optionTransporter = [
    { label: "Transporter 1", value: "Transporter 1" },
    { label: "Transporter 2", value: "Transporter 2" },
  ];

  const handleDocumentTypeChange = (value) => {
    const selectedValue = value;
    setselectedDocumentType(selectedValue);

    if (selectedValue.value === "invoices") {

    }

  };

  const handleTransactionSubTypeChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedTransactionSubType(selectedValue);
  };

  const handleTableChange = () => {
    if (showTable) setShowTable(false);
    else setShowTable(true);
  };

  const handleTransporterChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedTransporter(selectedValue);
  };

  const handleStateChange = (selectedOption) => {
    const selectedValue = selectedOption;
    setSelectedState(selectedValue);
  };

  // const normalDateFormatForDocDate = (date) => {
  //   if (date) {
  //     return (
  //       ("0" + date.getDate()).slice(-2) +
  //       "-" +
  //       ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
  //       "-" +
  //       date.getFullYear()
  //     );
  //   }
  //   return date;
  // };

  // const handleDocDate = (date) => {
  //   setDocDate(date);
  //   let normalFormat = date === null ? "" : normalDateFormatForDocDate(date);
  //   setDocDateToInsert(normalFormat);
  // };

  return (
    <Container fluid className="">
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <h4
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                New e-Way Billl
              </h4>

            </div>
          </div>
          <Card className="card-style p-2">
            <Card.Body>
              <Form>
                <Row>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className="label-title"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Document Type
                      </label>
                      <Select
                        // className="dropdown-field"
                        options={optionDocumentType}
                        value={selectedDocumentType}
                        onChange={handleDocumentTypeChange}
                        placeholder="Select Document Type"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="" md="4">
                    <Form.Group>
                      {selectedDocumentType.value &&
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Transaction Sub Type
                        </label>
                      }
                      {selectedDocumentType.value === "invoices" && (
                        <Select
                          // className="dropdown-field"
                          options={optionTransactionSubType}
                          placeholder="Select Tranasaction Sub Type"
                          onChange={handleTransactionSubTypeChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      )}

                      {selectedDocumentType.value === "creditNotes" && (<Select
                        // className="dropdown-field"
                        options={optionTransactionSubType1}
                        placeholder="Select Tranasaction Sub Type"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />)}

                      {selectedDocumentType.value === "deliveryChallans" && (<Select
                        // className="dropdown-field"
                        options={optionTransactionSubType2}
                        placeholder="Select Tranasaction Sub Type"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />)}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Customer Name <span className="valid"> *</span>
                      </label>
                      <Select
                        className="mt-0"
                        // className="dropdown-field"
                        options={options}
                        value={options.find(
                          (option) => option.value === selectedCustomer
                        )}
                        onChange={(selectedOption) =>
                          handleCustomerChange(
                            selectedOption.label,
                            selectedOption.value
                          )
                        }
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            borderRadius: "7px",
                            height: "46px",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            height: "19px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>

                  {(selectedDocumentType === "invoices" || selectedDocumentType.value === "invoices") && (
                    <Col className="" md="4">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Invoices
                        </label>
                        <Select
                          // className="dropdown-field"
                          options={optionInvoices}
                          value={optionInvoices.find(
                            (option) => option.value === selectedInvoiceNumber
                          )}
                          onChange={handleInvoiceChange}
                          placeholder="Select Invoice"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>)}

                  {selectedDocumentType.value === "creditNotes" && (
                    <Col className="" md="4">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Credit Notes
                        </label>
                        <Select
                          // className="dropdown-field"
                          options={optionCreditNotes}
                          value={optionCreditNotes.find(
                            (option) => option.value === selectedCreditNoteNumber
                          )}
                          placeholder="Select Credit Note"
                          onChange={handleCreditNoteChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>)}

                  {selectedDocumentType.value === "deliveryChallans" && (
                    <Col className="" md="4">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Delivery Challan
                        </label>
                        <Select
                          // className="dropdown-field"
                          options={optionDeliveryChallans}
                          value={optionDeliveryChallans.find(
                            (option) => option.value === selectedDeliveryChallan
                          )}
                          placeholder="Select Delivery Challan"
                          onChange={handleDeliveryChallanChange}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>)}

                  <Col className="" md="4">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Date
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={fetchedDate}
                          style={{
                            backgroundColor: "#F6F6F6",
                            padding: "12px",
                            borderRadius: "5px",
                          }}
                          readOnly
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="4">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Transaction Type
                      </label>
                      <Select
                        // className="dropdown-field"
                        options={optionTransactionType}
                        value={optionTransactionType.find(
                          (option) => option.value === selectedTransactionType
                        )}
                        onChange={handleTransactionTypeChange}
                        placeholder="Select Customer"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="" md="4">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Place of Delivery
                      </label>
                      <Select
                        // className="dropdown-field"
                        options={statesOfIndia}
                        value={statesOfIndia.find(
                          (option) => option.value === selectedState
                        )}
                        onChange={handleStateChange}
                        placeholder="Select State"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="my-3" md="4">
                    <Form.Group>
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        Address Details
                      </label>
                      {addressDetails ? (
                        <div>
                          <b>DISPATCH FROM</b>
                          <p
                            className="text-primary mt-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setAddressDetails(false);
                            }}
                          >
                            Select Address
                          </p>
                        </div>
                      ) : (
                        <Select
                          // className="dropdown-field"
                          options={optionAddressDetails}
                          value={optionAddressDetails.find(
                            (option) => option.value === selectedAddressDetails
                          )}
                          onChange={handleAddressChange}
                          placeholder="Select Customer"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                            }),
                          }}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className="text-primary"
                        style={{ fontSize: 14, fontWeight: "600" }}
                        onClick={handleTableChange}
                      // onClick={() => { showTable === true ? setShowTable(true) : setShowTable(false) }}
                      >
                        ITEM DETAILS
                      </label>
                    </Form.Group>
                  </Col>
                </Row>

                {showTable && (
                  <Row>
                    <Col>
                      <Card>
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead className="text-center">
                              <tr>
                                <th scope="col" style={{ color: "grey" }}>
                                  ITEM DETAILS
                                </th>
                                {bussinessRegistered === "YES" && (
                                  <th scope="col" style={{ color: "grey" }}>
                                    HSN/SAC
                                  </th>
                                )}
                                {sizeCheck === "YES" && (
                                  <th scope="col" style={{ color: "grey" }}>
                                    SIZE
                                  </th>
                                )}
                                <th scope="col" style={{ color: "grey" }}>
                                  QUANTITY
                                </th>
                                <th scope="col" style={{ color: "grey" }}>
                                  RATE
                                </th>
                                {discountCheck === "YES" && (
                                  <th scope="col" style={{ color: "grey" }}>
                                    DISCOUNT
                                  </th>
                                )}
                                {bussinessRegistered === "YES" && (
                                  <th scope="col" style={{ color: "grey" }}>
                                    Tax
                                  </th>
                                )}
                                <th scope="col" style={{ color: "grey" }}>
                                  AMOUNT
                                </th>
                                <th scope="col" style={{ color: "grey" }}>
                                  ACTION
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.map((data, index) => {
                                const amount = calculateAmount(data, data.tax);
                                const totalAmountWithTax = parseFloat(amount);

                                return (
                                  <tr key={index}>
                                    <td style={{ width: "35%" }}>
                                      <input
                                        className="form-control noBorder"
                                        placeholder="Search items..."
                                        value={data.itemDetails}
                                        onChange={(e) => handleItemDetailsChange(e, index)}
                                      />

                                      {searchPerformed &&
                                        data.itemDetails &&
                                        index === searchIndex && (
                                          <div
                                            style={{
                                              position: "relative",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {searchResults.length === 0 && ""}
                                            {searchResults.length > 0 && (
                                              <ul
                                                style={{
                                                  listStyleType: "none",
                                                  padding: 0,
                                                  position: "absolute",
                                                  top: "calc(100% + 10px)",
                                                  left: 0,
                                                  zIndex: 1000,
                                                  backgroundColor: "#FFF",
                                                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                                  borderRadius: "5px",
                                                  width: "100%",
                                                }}
                                              >
                                                {searchResults.map((result, resultIndex) => (
                                                  <li
                                                    key={resultIndex}
                                                    onClick={() =>
                                                      handleItemSelect(result.name, result.id, index)
                                                    }
                                                    onMouseEnter={(e) => {
                                                      e.target.style.backgroundColor = "#408dfb";
                                                      e.target.style.color = "#FFF";
                                                      e.target.style.borderRadius = "5px";
                                                    }}
                                                    onMouseLeave={(e) => {
                                                      e.target.style.backgroundColor = "";
                                                      e.target.style.color = "#000";
                                                      e.target.style.borderRadius = "";
                                                    }}
                                                    style={{
                                                      padding: "12px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {result.name}
                                                    <br />
                                                    Rate : ₹{result.salesPrice}
                                                  </li>
                                                ))}
                                              </ul>
                                            )}
                                          </div>
                                        )}
                                    </td>
                                    {bussinessRegistered === "YES" && (
                                      <td style={{ width: "10%" }}>
                                        <input
                                          className="form-control noArrowNumber noBorder"
                                          value={data.hsnSac || ""}
                                          name="hsnSac"
                                          type="text"
                                          placeholder="hsnSac"
                                          onChange={(e) => handleHsnSacChange(e, index)}
                                        />
                                      </td>
                                    )}
                                    {sizeCheck === "YES" && (
                                      <td style={{ width: "10%" }}>
                                        <input
                                          className="form-control noArrowNumber noBorder"
                                          value={data.size || ""}
                                          name="size"
                                          type="text"
                                          placeholder="size"
                                          onChange={(e) => handleSizeChange(e, index)}
                                        />
                                      </td>
                                    )}
                                    <td style={{ width: "10%" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.quantity || ""}
                                        type="number"
                                        onChange={(e) => handleQuantityChange(e, index)}
                                      />
                                    </td>

                                    <td style={{ width: "10%" }}>
                                      <input
                                        className="form-control noArrowNumber noBorder"
                                        value={data.rate}
                                        type="number"
                                        onChange={(e) => handleRateChange(e, index)}
                                      />
                                    </td>
                                    {discountCheck === "YES" && (
                                      <td style={{ width: "10%", padding: "0px" }}>
                                        <div className="d-flex justify-content-center">
                                          <input
                                            className="form-control col-md-6 noBorder"
                                            value={data.discount || ""}
                                            type="number"
                                            onChange={(e) => handleDiscountChange(e, index)}
                                            name="discount"
                                            style={{
                                              textAlign: "center",
                                              padding: "0px",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <select
                                            className="border-0 noBorder"
                                            value={data.discountType}
                                            onChange={(e) => handleDiscountChange(e, index)}
                                            name="discountType"
                                          >
                                            <option>₹</option>
                                            <option>%</option>
                                          </select>
                                        </div>
                                      </td>
                                    )}
                                    {bussinessRegistered === "YES" && (
                                      <td style={{ width: "10%" }}>
                                        <select
                                          className="form-control"
                                          value={data.tax}
                                          onChange={(e) => handleTaxChange(e, index)}
                                        >
                                          <option value="0">0 GST [0%]</option>
                                          <option value="0.05">5 GST [5%]</option>
                                          <option value="0.12">12 GST [12%]</option>
                                          <option value="0.18">18 GST [18%]</option>
                                          <option value="0.28">28 GST [28%]</option>
                                        </select>
                                      </td>
                                    )}
                                    <td style={{ width: "10%" }}>
                                      <span>{parseFloat(totalAmountWithTax).toFixed(2)}</span>
                                    </td>
                                    <td className="text-center" style={{ width: "7%" }}>
                                      <button
                                        type="button"
                                        className="btn px-2 btn-light icon-border btn-sm"
                                        onClick={(e) => handleDeleteRow(e, index)}
                                      >
                                        <i className="fa fa-trash text-danger"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                )}

                <hr />
                {/* endtable */}

                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className="text-primary"
                        style={{ fontSize: 14, fontWeight: "600" }}
                      >
                        TRANSPORTATION DETAILS
                      </label>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className="label-title"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Transporter
                      </label>
                      <Select
                        // className="dropdown-field"
                        options={optionTransporter}
                        value={optionTransporter.find(
                          (option) => option.value === selectedTransporter
                        )}
                        onChange={handleTransporterChange}
                        placeholder="Select Transporter"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F6F6F6",
                            padding: "5px",
                          }),
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        Distance (in Km)
                      </label>
                      <Form.Control
                        placeholder="Distance"
                        type="text"
                        name="distance"
                        value={distance}
                        onChange={handleDistanceChange}
                        // value={invoiceNumber}
                        // onChange={(e) => setInvoiceNumber(e.target.value)}
                        style={{
                          padding: "12px",
                          backgroundColor: "#F6F6F6",
                          color: "black",
                        }}
                      />
                      <label
                        className="mt-1"
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        If you enter 0 as the distance, e-Way Bill system will
                        automatically calculate it based on the dispatch and
                        delivery locations.
                      </label>
                    </Form.Group>
                  </Col>
                  <Col className="" md="4">
                    <Form.Group>
                      <label
                        className=""
                        style={{ fontSize: "14px", color: "grey" }}
                      >
                        <a href="#">- Calculate Distance</a>
                      </label>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="" md="3">
                    <Form.Group>
                      <label
                        className="text-primary"
                        style={{ fontSize: 14, fontWeight: "600" }}
                      >
                        PART B
                      </label>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="mt-3" md="4">
                    <label
                      className="form-label"
                      style={{ fontSize: "14px", color: "grey" }}
                    >
                      Mode of Transportation
                    </label>
                  </Col>
                  <Col className="" md="4">
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn px-2 btn-light icon-border btn-sm mx-2"
                        onClick={() => {
                          setRoad(true);
                          setRail(false);
                          setShip(false);
                          setAir(false);
                        }}
                      >
                        <Jeep size={28} /> Road
                      </button>
                      <button
                        type="button"
                        className="btn px-2 btn-light icon-border btn-sm mx-2"
                        onClick={() => {
                          setRoad(false);
                          setRail(true);
                          setShip(false);
                          setAir(false);
                        }}
                      >
                        <TrainSimple size={28} /> Rail
                      </button>
                      <button
                        type="button"
                        className="btn px-2 btn-light icon-border btn-sm mx-2"
                        onClick={() => {
                          setRoad(false);
                          setRail(false);
                          setShip(true);
                          setAir(false);
                        }}
                      >
                        <Boat size={28} /> Ship
                      </button>
                      <button
                        type="button"
                        className="btn px-2 btn-light icon-border btn-sm"
                        onClick={() => {
                          setRoad(false);
                          setRail(false);
                          setShip(false);
                          setAir(true);
                        }}
                      >
                        <Airplane size={28} /> Air
                      </button>
                    </div>
                  </Col>
                </Row>

                {road && (
                  <Row className="mt-5">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Vehicle Type
                        </label>
                        <div className="d-flex my-2">
                          <label className="rdiobox">
                            <input
                              className="mx-1"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Regular"
                              onClick={() => handleRadioClick("Regular")}
                            />
                            <span>Regular</span>
                          </label>
                          <label className="rdiobox ml-3">
                            <input
                              className="mx-1"
                              type="radio"
                              name="cus_type"
                              id="cus_indivi"
                              value="Over Dimensional Cargo"
                              onClick={() => handleRadioClick("Over Dimensional Cargo")}
                            />
                            <span>Over Dimensional Cargo</span>
                          </label>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Vehicle Number
                        </label>
                        <Form.Control
                          placeholder="Vehicle Number"
                          type="text"
                          name="vehiclenumber"
                          value={vehicleNumber}
                          onChange={(e) => setVehicleNumber(e.target.value)}
                          style={{
                            padding: "12px",
                            backgroundColor: "#F6F6F6",
                            color: "black",
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {road && (
                  <Row className="mt-3">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Transporter's Doc No
                        </label>
                        <Form.Control
                          placeholder="Document Number"
                          type="text"
                          value={docNo}
                          onChange={(e) => setDocNo(e.target.value)}
                          style={{
                            padding: '12px',
                            backgroundColor: '#F6F6F6',
                            color: 'black',
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Transporter's Doc Date
                        </label>
                        <div>
                          <DatePicker
                            className="custom-datepicker"
                            selected={startDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => handleDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {rail && (
                  <Row className="mt-3">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          RR No
                        </label>
                        <Form.Control
                          placeholder="RR Number"
                          type="text"
                          value={rrNo}
                          onChange={(e) => setRrNo(e.target.value)}
                          style={{
                            padding: '12px',
                            backgroundColor: '#F6F6F6',
                            color: 'black',
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          RR Date
                        </label>
                        <div>
                          <DatePicker
                            className="custom-datepicker"
                            selected={rrDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setRrDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {ship && (
                  <Row className="mt-3">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Bill of Lading No
                        </label>
                        <Form.Control
                          placeholder="Landing Number"
                          type="text"
                          value={landingNo}
                          onChange={(e) => setLandingNo(e.target.value)}
                          style={{
                            padding: '12px',
                            backgroundColor: '#F6F6F6',
                            color: 'black',
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Bill of Lading Date
                        </label>
                        <div>
                          <DatePicker
                            className="custom-datepicker"
                            selected={landingDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setLandingDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {air && (
                  <Row className="mt-3">
                    <Col md="4" className="mb-3">
                      <Form.Group>
                        <label style={{ fontSize: "14px", color: "grey" }}>
                          Airway Bill No
                        </label>
                        <Form.Control
                          placeholder="Airway Bill Number"
                          type="text"
                          value={airbillNo}
                          onChange={(e) => setAirbillNo(e.target.value)}
                          style={{
                            padding: '12px',
                            backgroundColor: '#F6F6F6',
                            color: 'black',
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="" md="4">
                      <Form.Group>
                        <label
                          className=""
                          style={{ fontSize: "14px", color: "grey" }}
                        >
                          Airway Bill Date
                        </label>
                        <div>
                          <DatePicker
                            className="custom-datepicker"
                            selected={airbillDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setAirbillDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {/* <div className="d-flex justify-content-end mt-5">
                  <Button
                    className="btn-fill pull-right mt-3 mr-2"
                    type="button"
                    onClick={handleCancleClick}
                    style={{
                      borderRadius: "7px",
                      background: "var(--Primary, #3C7BD4)",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="btn-fill pull-right mt-3"
                    type="button"
                    variant="primary"
                    onClick={handleSubmit}
                    style={{
                      borderRadius: "7px",
                      background: "var(--Primary, #3C7BD4)",
                    }}
                  >
                    Create
                    </Button>
                  </div>
                  */}

                <div className="text-right bottom-buttons">
                  <button type="button" className="btn-1 btn mt-0" onClick={handleCancleClick}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-3 btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Create
                  </button>
                </div>

                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default EwayBillsNew;
