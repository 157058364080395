import React from "react";
import "./format.css";

export default function Format(props) {
  return (
    <div className="App">
      <div className="formatdata">
        <img src={props.img} />
        <div className="textimage">
        <h1>{props.name}</h1>
         <p>{props.paragraph}</p>
        </div>
        
      </div>
    </div>
  );
}
