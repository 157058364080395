import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import swal from "sweetalert";
import EstimateUpdate from "./EstimateUpdate";
import "./InvoiceDetails.css";
import TinyMceEditor from "../Settings/TinyMceEditor";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Spinner } from "react-bootstrap"; // Import the Bootstrap Spinner component
// import numberToWords from "number-to-words";
import jsPDF from "jspdf";
import { CiMail, CiEdit } from "react-icons/ci";
import { MdOutlinePrint } from "react-icons/md";
import numberToWords from "number-to-words";

function EstimateDetails(props) {
  const { newinvoiceNumber, custId } = props;
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading3, setIsLoading3] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [itemDetails, setItemDetails] = useState([]);
  const [estimateNumber, setInvoiceId] = useState(null);
  const [invoiceEdit, setInvoiceEdit] = useState(false);
  const [detailsPage, setDetailsPage] = useState(true);
  const [companyName, setCompanyName] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [openNew, setopenNew] = useState(false);
  const [customerId, setcustomerId] = useState({});
  const [showDetails, setShowDetails] = useState(true);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [openMail, setMail] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [userEmail, setUserEmail] = useState([]);
  const [custEmail, setCustomerEmail] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [Image, setImage] = useState(null);
  const [customerData, setCustomerData] = useState({});
  const jwtToken = localStorage.getItem("jwtToken");
  const [loading2, setLoading2] = useState(true);
  const [subject, setSubject] = useState("");
  const formatAmountInWords = (amount) => {
    const [integerPart, fractionalPart] = amount.toString().split(".");
    let amountInWords = numberToWords.toWords(integerPart);
  
    if (fractionalPart) {
      const fractionalInWords = numberToWords.toWords(fractionalPart);
      amountInWords += ` and ${fractionalInWords} paisa`;
    }
  
    // Capitalize the first letter
    amountInWords = amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1);
  
    return amountInWords;
  };
  const amountInWords = formatAmountInWords(totalAmount);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleUserEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleCustomerEmail = (event) => {
    setCustomerEmail(event.target.value);
  };

  const handleCancleClick = () => {
    props.handleCancel();
  };

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchEstimate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        fromDate: "",
        toDate: "",
      }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const count = data.responseMessage1;
          setInvoices(data.responseMessage);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchByEstimateNumber/${newinvoiceNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const itemDetails = data.responseMessage;
          setItemDetails(itemDetails);

          const invoiceDetails = data.responseMessage[0];
          setInvoiceDetails(invoiceDetails);
          setTotalAmount(data.responseMessage[0].total);

          const customerId = data.responseMessage[0].customerId;
          setcustomerId(customerId);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, [newinvoiceNumber]);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchCustomerById/${custId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setCustomerData(Data);
          const custEmail = data.responseMessage.email;
          setCustomerEmail(custEmail);
        } else {
          console.error("Failed to fetch invoices by id:");
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, [customerId]);

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");

  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userData = data.responseMessage;
          setImage(
            `https://documents.zarp.software/zarp_images/${userData.logo}`
          );

          const UserEmail = userData.email;
          setUserEmail(UserEmail);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const userId = localStorage.getItem("userId");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const userDetails = data.responseMessage;

          const address1 = data.responseMessage.address1;
          const address2 = data.responseMessage.address2;

          setUserDetails(userDetails);

          const companyName = data.responseMessage.companyName;
          setCompanyName(companyName);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  }, []);

  const [bankData, setBankData] = useState([]);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");

  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const postData = {
    uniqueId: decryptedUniqueId,
  };

  useEffect(() => {
    const apiUrl = BASE_URL + "/fetchBankForPdf";
    const makePostRequest = async () => {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        const responseData = await response.json();
        setBankData(responseData.responseMessage);
        setLoading(false);
      } else {
        console.error("POST request failed");
        setLoading(false);
      }
    };

    makePostRequest();
  }, []);

  const handleCancelUpdate = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    setopenNew(false);
  };

  const handleCancelNew = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    setopenNew(false);
  };

  const handleCloseMailClick = () => {
    setInvoiceEdit(false);
    setDetailsPage(true);
    setopenNew(false);
    setMail(false);
  };

  const handleMailSubmit = async () => {
    // You can access the content of the editor here
    const postData = {
      to: custEmail,
      from: userEmail,
      subject: subject,
      body: editorContent,
      total: invoiceDetails.total,
      estimateNumber: invoiceDetails.estimateNumber,
      estimateDate: invoiceDetails.estimateDate,
      dueDate: invoiceDetails.expiryDate,
      organizationName: companyName,
      customerName: customerData.customerName,
      companyName: companyName,
      termsAndCondition: invoiceDetails.termsAndCondition,
      items: itemDetails1,
      name: bankData.name,
      bank: bankData.bankName,
      accountNo: bankData.accountNumber,
      ifscCode: bankData.ifsc,
      type: bankData.accountType,
      totalInWords: amountInWords,
      subTotal: invoiceDetails.subtotal,
      adjustment: invoiceDetails.adjustment,
      totalDue: invoiceDetails.total,
      notes: invoiceDetails.customerNotes,
      state: invoiceDetails.state,
      address: userDetails.address1,
      imageName: userDetails.logo,
      uniqueId: decryptedUniqueId,
      sizeColumn: invoiceDetails.sizeCheck,
      discountColumn: invoiceDetails.discountCheck,
      taxColumn: invoiceDetails.taxCheck,
      igst: invoiceDetails.igst,
      cgst: invoiceDetails.cgst,
      sgst: invoiceDetails.sgst,
      orderNo: invoiceDetails.referenceNumber,
      orgMob1: userDetails.contact,
      organizationStreetName: userDetails.address1,
      organizationAddress: userDetails.address2,
      organizationPinCode: userDetails.zipcode,
      custmorAddress: customerData.billAddress1,
      custmorCity: customerData.billCity,
      custmorMob: customerData.billPhone,
    };

    setIsLoading3(true);
    const response = await fetch(BASE_URL + "/send-estimate-email-with-pdf", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Mail Send Successfully",
          });
          handleCloseMailClick();
          setIsLoading3(false);
          // const pdfBlob =  response.blob();
          // setPdfData(URL.createObjectURL(pdfBlob));
        } else if (data.status === "error") {
          swal({
            icon: "error",
            title: data.responseMessage,
          });
          setIsLoading3(false);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.message,
        });
        setIsLoading3(false);
      });
  };

  const handleMailClick = () => {
    setInvoiceEdit(false);
    setDetailsPage(false);
    setopenNew(false);
    setMail(true);
  };

  const initialContent = `
  <div class="main-container" style="margin: 10px; padding: 10px; text-align: center; background-color: #4190f2;">
  <h2 style="margin: 10px; padding: 10px; text-align: center;">${invoiceDetails.estimateNumber}</h2>
</div>

<div class="content-container">
  <h4 style="margin: 20px; padding: 20px;"> Dear  ${customerData.customerName},</h4>
  <p style="margin: 20px; padding: 20px;" class="my-custom-paragraph">Thank you for your business. Your estimate can be viewed, printed and downloaded as PDF from the link below. You can also choose to pay it online.</p>
  <div class="card" style="border: 1px solid #e8deb5; border-radius: 3px; padding: 26px; margin: 26px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); width: 50%; margin: 0 auto;  background-color: #fefff1;">
      <h4 style="margin: 5px; padding: 5px; text-align: center;">ESTIMATE AMOUNT</h4>
      <h4 style=" text-align: center;">${invoiceDetails.total}</h4>
      <table style="width: 100%; text-align: center;">
      <tr>
          <td>Estimate No</td>
          <td>${invoiceDetails.estimateNumber}</td>
      </tr>
      <tr>
          <td>Estimate Date</td>
          <td>${invoiceDetails.estimateDate}</td>
      </tr>
      <tr>
          <td>Expiry Date</td>
          <td>${invoiceDetails.expiryDate}</td>
      </tr>
  </table>
  <div style="text-align: center; padding-top:5px;">

  </div>

  </div>

    <div style=" padding: 10px; width: 80%; margin: 0 auto;">
      <h4>Regards</h3>
      <p>${companyName}</p>
    </div>
</div>`;

  const getCustomerData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchCustomerById/${customerId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setCustomerData(Data);
          const custEmail = data.responseMessage.email;
          setCustomerEmail(custEmail);
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleInvoiceClick = (estimateNumber) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchByEstimateNumber/${estimateNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const itemDetails = data.responseMessage;
          setItemDetails(itemDetails);
          const invoiceDetails = data.responseMessage[0];
          setInvoiceDetails(invoiceDetails);
          const customerId = data.responseMessage[0].customerId;
          setTotalAmount(data.responseMessage[0].total);

          setcustomerId(customerId);
          getCustomerData();
        } else {
          console.error(
            "Failed to fetch invoices by id:",
            data.responseMessage
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };

  const handleEditClick = (estimateNumber) => {
    setInvoiceId(estimateNumber);
    setInvoiceEdit(true);
    setDetailsPage(false);
    setopenNew(false);
  };
  const handleAddNew = () => {
    setDetailsPage(false);
    setInvoiceEdit(false);
    setopenNew(true);
  };

  const itemDetails1 = itemDetails.map((item) => {
    return {
      items: item.itemDetails || "",
      quantity: item.quantity || "",
      rate: item.rate || "",
      discount: item.discount || item.discountType,
      amount: item.amount || "",
      discountType: item.discountType || "",
    };
  });

  const insertDataIntoDatabase = async () => {
    const formDataObject = {
      total: invoiceDetails.total,
      billNumber: invoiceDetails.estimateNumber,
      estimateDate: invoiceDetails.estimateDate,
      dueDate: invoiceDetails.expiryDate,
      payButtonNow: invoiceDetails.payButtonNow,
      organizationName: companyName,
      customerName: invoiceDetails.customerName,
      companyName: invoiceDetails.companyName,
      terms: invoiceDetails.terms,
      items: itemDetails1,
      subTotal: invoiceDetails.subtotal,
      adjustment: invoiceDetails.adjustment,
      totalDue: invoiceDetails.total,
      notes: invoiceDetails.termsAndCondition,
      state: invoiceDetails.state,
      address: userDetails.address1,
      uniqueId: userDetails.uniqueId,
      // type:invoiceDetails.discountType,
      name: bankData.name,
      bank: bankData.bankName,
      accountNo: bankData.accountNumber,
      ifscCode: bankData.ifsc,
      type: bankData.accountType,
    };

    setPdfLoading(true);
    try {
      const response = await fetch(BASE_URL + "/generate-estimate-pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formDataObject),
      });

      if (response.ok) {
        const pdfBlob = await response.blob();
        setPdfData(URL.createObjectURL(pdfBlob));
        setPdfLoading(false);
        // Set the PDF data for preview (optional)
      } else {
        console.error("Error: Failed to generate PDF");
        setPdfLoading(false);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleUpdate = () => {
    insertDataIntoDatabase();
    setShowPdf(true);
    setLoading2(true);
    setShowDetails(false);
  };

  function groupByTax(items) {
    return items.reduce((acc, item) => {
      (acc[item.tax] = acc[item.tax] || []).push(item);
      return acc;
    }, {});
  }

  const groupedItems = groupByTax(itemDetails);

  function groupByTax1(items) {
    return items.reduce((acc, item) => {
      const taxRate = item.tax;
      if (!acc[taxRate]) {
        acc[taxRate] = [];
      }
      acc[taxRate].push(item);
      return acc;
    }, {});
  }

  const groupedItems1 = groupByTax1(itemDetails);

  const handlePrint = () => {
    const printableContent = document.getElementById("printable-content");
    if (printableContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <!DOCTYPE html>
 <html lang="en">
 <head>
     <title>${invoiceDetails.estimateNumber}</title>
     <style>
         .item {
             margin: 10px 0;
         }
         *{
             box-sizing: border-box;
         }
         @media print {
             .page-break {
                 display: block;
                 page-break-before: always;
             }
         }
         body {
             font-family: Arial, sans-serif;
             margin: 0px;
             padding: 0px;
             height: 100vh;
         }
         .invoice-container {
             max-width: 900px;
             margin: auto;
             padding: 10px;
             /* border: 1px solid #ddd; */
             /* background-color: #fff; */
             /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
         }
         .table {
             width: 100%;
             height: 100%;
             border-collapse: collapse;
             margin-top: -1px;
         }
         .table th {
             padding: 8px;
             border: 1px solid #ddd;
         }
         .table td {
             padding: 6px;
             border: 1px solid #ddd;
         }
         .table thead th {
             background-color: #f2f2f2;
             font-weight: bold;
         }
         .bg-light {
             background-color: black;
         }
         .fw-600 {
             font-weight: 600;
         }
         .text-center {
             text-align: center;
         }
         .text-end {
             text-align: right;
         }
         .text-uppercase {
             text-transform: uppercase;
         }
         .mb-0 {
             margin-bottom: -1px;
             margin-left: -0.5px;
         }
         .py-1 {
             padding-top: 0.25rem;
             /* padding-bottom: 0.25rem; */
         }
         .btn-group {
             display: inline-flex;
         }
         .btn {
             padding: 10px 20px;
             border: none;
             background-color: #007bff;
             color: #fff;
             text-decoration: none;
             cursor: pointer;
         }
         .btn:hover {
             background-color: #0056b3;
         }
         .fst-italic {
             font-style: italic;
         }
         .first-box {
             display: flex;
             position: relative;
         }
         .shop-info {
             position: relative;
             left: 20px;
             top: -17px;
             width: 80%;
         }
         .cust-info {
             position: relative;
             left: 38px;
             top: -17px;
         }
         .shop-logo {
             /* position: relative; */
             padding-left: 10px;
         }
         .sub-total {
             display: flex;
             justify-content: end;
             justify-content: space-between;
         }
         .sub-total-name {
             display: flex;
         }
         .sub-total-value {
             /* position: relative; */
             left: -6px;
         }
         .bill-text {
             font-size: 14px;
             padding: 10px;
             margin: 0px 0px;
         }
         .bank-detail {
             display: flex;
             flex-direction: row;
             justify-content: space-between;
             height: 120px;
         }        
         .bank-detail-main p {
             font-size: 10px;
             margin: 0px;
             padding: 2px 5px;
             font-weight: bold;
             position: relative;
             bottom: 10px;
         }
         .bank-detail-main p span {
             font-size: 10px;
             margin: 0px;
             padding: 2px 15px;
             font-weight: 200;
         }
         .bank-scanner img {
             /* border: 1px solid black;  */
             position: relative;
             top: -26px;
             right: 51px;
         }      
         .bank-scanner p {
             /* border: 1px solid black;  */
             position: relative;
             top: -11px;
             right: 90px;
         }
         #total-table {
             border-collapse: collapse;
             margin-top: 18px;
             border: 1px solid #ddd;
             width: 100%;
         }
         .table-container {
             display: table;
             width: 100%;
             /* Ensures container fills the available width */
             position: relative;
         }
         .float-container {
             width: 100%;
             /* Ensures container fills the available width */
         }
         .float-item {
             float: left;
             width: 30%;
             /* Adjust width as needed */
             margin-right: 10px;
             /* Space between items */
             /* Optional: for visual clarity */
             padding: 10px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item1 {
             float: left;
             width: 70%;
             /* Adjust width as needed */
             margin-right: 15%;
             /* Space between items */
 
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item2 {
             float: left;
             width: 30%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item3 {
             float: left;
             width: 70%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item4 {
             float: left;
             width: 30%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item5 {
             float: left;
             width: 70%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item6 {
             float: left;
             width: 30%;
             /* Adjust width as needed */
             margin-right: 20%;
             /* Space between items */
             padding: 2px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item7 {
 
             float: left;
             width: 60%;
             /* Adjust width as needed */
             margin-right: 60%;
             /* Space between items */
             padding: 0px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         .float-item8 {
             float: right;
             width: 40%;
             /* Adjust width as needed */
             height: 150px;
             margin-right: 20%;
             /* Space between items */
             padding: 0px;
             box-sizing: border-box;
             /* Ensures padding is included in the width */
         }
         #toatal-table tr td {
             border: 1px solid #ddd;
             border-collapse: collapse;
         }
         @media print {
             .no-break {
                 page-break-inside: avoid;
                 /* Prevents break inside element */
             }
             .page-break-before {
                 page-break-before: always;
                 /* Forces a break before element */
             }
             .page-break-after {
                 page-break-after: always;
                 /* Forces a break after element */
             }
                 .backgroun-dark{
             background-color:#f2f2f2 !important;
             -webkit-print-color-adjust:exact;
             print-color-adjust:exact;
            }
         }
         .font-heading{
             font-size: 16px;
             font-weight: bold;
         }
         tr td{
             border-collapse: collapse;
         }
         /* tr{
             box-sizing: border-box  ;
             padding: 20px;
             /* display: inline; */


             .pt-5{
             padding-top:5px;
             }
     </style>
 </head>
 <body>
     <!-- Container -->
     <div class=" main-table container-fluid invoice-container">
         <div class="box1" style="position: relative">
             <div class="table-responsive">
                 <table class="table table-bordered border border-secondary mb-0"
                     style="height: 100%; border-collapse: collapse; width: 100%;">
                     <tbody style="border-collapse: collapse;">
                         <tr>
                             <td style="width: 60%   ;">
                                 <div class="first-box">
                                     <div class="shop-logo">
                                         <img src=${Image} width="100" height="100" />
                                     </div>
                                     <div class="shop-info">
                                         <h3 style="margin: 14px 0px 5px 0px;">${companyName || ""}</h3>
                                         <address>
                                         ${userDetails.address1 || ""} ${userDetails.address2 || ""}<br>${userDetails.zipcode || ""} ${userDetails.city || ""}
                                         <br>${userDetails.state || ""} <br>
                                         ${userDetails.contact ? `MOB:${userDetails.contact || ""}<br>`:""} 
                                          ${invoiceDetails.gstNumber ? `GST NO: ${invoiceDetails.gstNumber}` : ""}
                                         </address>
                                     </div>
                                 </div>
                             </td>
                             <td colspan="2" class="text-center">
                                 <h3>
                                     ESTIMATE
                                 </h3>
                         </tr>
                         <tr style="border:none;">
                             <td class="col-7" style="border-left: 1px solid #ddd; border-bottom: none;">
                                 <p style="font-size: 12px; position: relative; top: -10px; left: 14px;">Buyer (Estimate
                                     To)
                                 </p>
                                 <div class="row gx-2 gy-2">
                                     <div class="col-auto"></div>
                                     <div class="col">
                                         <div class="cust-info">
                                             <h4 style="margin: 14px 0px 5px 0px;" class="font-heading"> ${invoiceDetails.customerName || ""
         }</h4>
                                             <address style="font-size: 15px;">
         ${customerData.billAddress1 ? `${customerData.billAddress1}<br />` : ""}
         ${customerData.billCity || customerData.billZipcode
           ? `
             ${customerData.billCity ? `${customerData.billCity} ` : ""}
             ${customerData.billZipcode ? `${customerData.billZipcode}` : ""}
             <br />
         `
           : ""
         }
         ${invoiceDetails.state || customerData.billCountry
           ? `
             ${invoiceDetails.state ? `${invoiceDetails.state} ` : ""}
             ${customerData.billCountry ? `${customerData.billCountry}` : ""}
             <br />
         `
           : ""
         }
         ${customerData.mobile ? `MOB: ${customerData.mobile}<br />` : ""}
         ${customerData.gstin ? `GST No: ${customerData.gstin}<br />` : ""}
     </address>
                                         </div>
                                     </div>
                                 </div>
                             </td>
                             <td style=" margin: 0px; padding: 0px;">
                                 <!-- <div class="col-5" >Invoice No :<span class="float-end">:</span></div>
                                         <div class="col-7">Invoice Date :</div>
                                         <div class="col-5">Due :<span class="float-end">:</span></div>
                                         <div class="col-7">Terms :</div>
                                         <div class="col-7">P.O. :</div> -->
                                 <table
                                     style="margin: 0px; padding: 0px; width: 100%; padding: 0px; margin: 0px; border-collapse: collapse; height:100%;">
                                     <tr>
                                         <td style="width: 100px; border: none; width: 50%;">Estimate No:</td>
                                         <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">
                                             ${invoiceDetails.estimateNumber || ""}
                                         </td>
                                     </tr>
                                     <tr>
                                         <td style="border-left:none; border-right: none; border-top: 1px solid #ddd;">
                                             Estimate Date:</td>
                                         <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">
                                             ${invoiceDetails.estimateDate || ""}
                                         </td>
                                     </tr>
                                     <tr>
                                         <td style="border-left:none; border-right: none; border-top: 1px solid #ddd;">
                                             Expiry Date:</td>
                                         <td style="border-left:1px solid #ddd; border-right: none; border-top: none;">
                                             ${invoiceDetails.expiryDate || ""}
                                         </td>
                                     </tr>                              
                                 </table>
                             </td>
                         </tr>
                     </tbody>
                 </table>
                 <table class="table table-sm mb-0"
                     style="width: 100%; padding:0px; margin: 0px; border: none; border-collapse: collapse;">
                     <tr class="bg-light backgroun-dark" style="border-collapse: collapse; ">
                         <td class="col-1 text-center  font-heading"><strong>SrNo</strong></td>
                         <td class="col-6 font-heading"><strong>Product Name</strong></td>
                         ${invoiceDetails.hsnCheck === "YES"
           ? `<td class="col-1 text-center font-heading"><strong>HSN</strong></td>`
           : ""
         }
                         ${invoiceDetails.sizeCheck === "YES"
           ? '<td class="col-1 text-center font-heading"><strong>Size</strong></td>'
           : ""
         }
                         <td class="col-1 text-center font-heading"><strong>Qty</strong></td>
                         <td class="col-2 text-end font-heading"><strong>Rate</strong></td>
                         ${invoiceDetails.discountCheck === "YES"
           ? '<td class="col-2 text-end font-heading"><strong>Discount</strong></td>'
           : ""
         }
                         ${invoiceDetails.taxCheck === "YES"
           ? '<td class="col-2 text-end font-heading"><strong>Tax</strong></td>'
           : ""
         }
                         <td class="col-2 text-end font-heading"><strong>Amount</strong></td>
                     </tr>
                     ${itemDetails
           .map(
             (item, index) => `
                     <tr class="produc-des " style="page-break-inside: avoid;">
                         <td class="col-1 text-center bill-text">${index + 1
               }</td>
                         <td class="col-6 bill-text" style="white-space: pre-line;">${item.itemDetails || ""
               }<br />${item.description2 || ""}</td>
                         ${invoiceDetails.hsnCheck === "YES"
                 ? `<td class="col-2 text-end bill-text">${item.hsnSac || ""
                 }</td>`
                 : ""
               }
                                           ${invoiceDetails.sizeCheck === "YES"
                 ? `<td class="col-1 text-center bill-text">${item.size || ""
                 }</td>`
                 : ""
               }
                                           <td class="col-1 text-center bill-text">${item.quantity || ""
               }</td>
                                           <td class="col-1 text-center bill-text">${item.rate || ""
               }</td>
                                           ${invoiceDetails.discountCheck ===
                 "YES"
                 ? `<td class="col-1 text-center bill-text">${item.discount || ""
                 }(${item.discountType || ""
                 })</td>`
                 : ""
               }
                                           ${invoiceDetails.taxCheck === "YES"
                 ? `<td class="col-1 text-center bill-text">${item.tax || ""
                 }%</td>`
                 : ""
               }
                                           <td class="col-2 text-end bill-text">${item.amount || ""
               }</td>
                                       </tr>`
           )
           .join("")}
                                       
                   
                 </table>
             </div>
             <div class="box2" style="position: relative; top: 0px; page-break-inside: avoid;">
                 <!-- <div class="breckingpg"></div> -->
                 <table style=" border-collapse: collapse; border: none;  width: 100%;;" id="toatal-table">
                     <tr class="bg-light fw-600 backgroun-dark">
                         <td class="col-7 py-1" style="width:60%; border: none; border-left: 1px solid #ddd;"></td>
                         <td class="" style="width:40% ;border: none; border-right: 1px solid #ddd; border-left: 1px solid #ddd;">
                             <div class="sub-total">
                                 <div class="sub-total-name font-heading pt-5" style="padding-left:5px;">
                                     Sub Total:
                                 </div>
                                 <div class="sub-total-valuept-5 " style="padding-right:5px;">
                                 ${invoiceDetails.subtotal || ""}
                                 </div>
                             </div>
                             <span class="float-end"></span>
                         </td>
                     </tr>
                 </table>
             </div>
             <table style="border-collapse: collapse; width: 100%; margin: 0; padding: 1px; page-break-inside: avoid ;" id="total-table">
                 <tr>
                     <!-- Total In Word Section -->
                     <td style="width: 60%; vertical-align: top; padding: 0; border-right: 1px solid #ddd;border-bottom: none; border-top: none; border-collapse: collapse;">
                         <h4 style="margin: 0; padding-left:5px;" class="font-header pt-5">Total In Word</h4>
                         <p 
                             style="margin: 0; font-size: 15px; width: 90%;   text-indent: 2em;">
                            Indian Rupee ${amountInWords || ""}  Only                      </p>
                     </td>
                     <!-- CGST and SGST Section -->
                     <td style="width: 40%; vertical-align: top; padding: 0;">
                         <div style="display: flex; flex-direction: column; justify-content: flex-start; height: 100%; padding-top: 5px;">
                         ${invoiceDetails.adjustment && invoiceDetails.adjustment != 0 && invoiceDetails.adjustment != 0.0 && invoiceDetails.adjustment != "0"
                          ? `
                             <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                        <div class="sub-total-name" style="width: 50%;">${invoiceDetails.adjustmentLabel || "Adjustment"}:
                                       </div>
                                       <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">₹${invoiceDetails.adjustment}</div>
                                       </div>
                                       ` : ""}
                             ${invoiceDetails.overallDiscount > 0|| 0.0 ? `<div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                              <div class="sub-total-name" style="width: 50%;">Discount(
                                   ${invoiceDetails.totalDiscount}
                                   ${invoiceDetails.overallDiscountType})
                             </div>
                             <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">-₹${invoiceDetails.overallDiscount}</div>
                             </div>`: ""}
                             ${invoiceDetails.taxCheck === "YES" 
           ? userDetails.state !== invoiceDetails.state
             ? Object.entries(groupedItems1)
               .map(([taxRate, items]) => {
                 let totalIGST = 0.0;
                 // Loop through items to accumulate IGST
                 items.forEach((item) => {
                   const igstValue =
                     parseFloat(item.igst) || 0.0;
                   if (item.customerTaxType === 1) {
                     totalIGST += igstValue * 2;
                   } else {
                     totalIGST += igstValue;
                   }
                 });
                 return `
                            <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                          <div class="sub-total-name" style="width: 50%;">IGST (${taxRate}%):
                                         </div>
                                         <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalIGST.toFixed(
                   2
                 )}</div>
                                         </div>
                                       `;
               })
               .join("")
             : Object.entries(groupedItems)
               .map(([taxRate, items]) => {
                 let totalCGST = 0.0;
                 let totalSGST = 0.0;
                 items.forEach((item) => {
                   const cgstValue =
                     parseFloat(item.cgst) || 0.0;
                   const sgstValue =
                     parseFloat(item.sgst) || 0.0;
 
                   totalCGST += cgstValue;
                   totalSGST += sgstValue;
                 });
                 return `
                                      <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                          <div class="sub-total-name" style="width: 50%;">CGST (${taxRate / 2
                   }%):
                                         </div>
                                         <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalCGST.toFixed(
                     2
                   )}</div>
                                         </div>
                                          <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                          <div class="sub-total-name" style="width: 50%;">SGST (${taxRate / 2
                   }%):
                                         </div>
                                         <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">${totalSGST.toFixed(
                     2
                   )}</div>
                                         </div>
                                             `;
               })
               .join("")
           : ""
         }
                             ${invoiceDetails.value > 0 || 0.0 ? invoiceDetails.type === "TDS"? `
                                     <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                       <div class="sub-total-name" style="width: 50%;">TDS (${invoiceDetails.tdsTax})</div>
                                       <div class="sub-total-value" style="width: 50%; text-align: right;padding-right:5px;">₹${invoiceDetails.value}</div>
                                     </div><br/>`
            `               ` : invoiceDetails.type === "TCS"
               ? `
                                       <div class="sub-total pt-5" style="display: flex; justify-content: space-between; padding-left: 5px;">
                                         <div class="sub-total-name" style="width: 50%;">TCS (${invoiceDetails.tcsTax})</div>
                                         <div class="sub-total-value" style="width: 50%; text-align: right; padding-right:5px;">₹${invoiceDetails.value}</div>
                                       </div><br/>`
               : ""
           : ""
         }
                         </div>
                     </td>
                 </tr>
             </table>
             <table style="border-collapse: collapse; margin-top: 0px; width: 100%; border: none; border-left: 1px solid #ddd; border-radius:1px solid #ddd ;" id="total-table">
                 <tr>
                     <td style="width: 60%; border-right: 1px solid #ddd; ">
                         <div style="display: inline-block; font-weight: bold; padding-bottom: 5px; padding-left:3px;" class="font-header pt-5">
                             Note:
                         </div>
                         <div style="display: flex; align-items: start;">
                             <span style="font-size: 14px; padding-left:6px;word-break: break-word; text-indent:2em;">${invoiceDetails.customerNotes||""}
                             </span>
                         </div>
                     </td>
                     <td style="width: 40%; vertical-align: top;  border-right: 1px solid #ddd;" class=" backgroun-dark">
                         <div class="sub-total" style="vertical-align: top;">
                             <div class="sub-total-name font-heading pt-5" style="font-weight: bold; padding-left:5px;">
                                 Total:
                             </div>
                             <div class="sub-total-value font-heading pt-5" style="font-size:14px; font-weight: bold; padding-right:5px;">
                                 ${invoiceDetails.total || ""
         }                            </div>
                         </div>
                     </td>
                 </tr>
             </table>
             <div class="breakingele"></div>           
             <table style=" border-collapse: collapse; margin-top:0px; width: 100%; vertical-align:top; break-inside:avoid;" id="toatal-table">
                 <tr>
                     <td class="col-7 text-1" style="width:60%;margin-top:20px;height:100%; vertical-align:top;">
                         <div style="margin:5px">
                             <div class="fw-600 font-heading">Terms &amp; Condition :</div>
                                 <p><span style="font-size: 14px; padding-left:6px;word-break: break-word; text-indent:2em;">${invoiceDetails.termsAndCondition || ""}</span></p>
                         </div>
                     </td>
                     <td class="col-5 pe-1 text-end" style="width:40%; margin-top:20px; height:100%; vertical-align: bottom;">
                         <div class="text-end"
                             style="height:120px; display: flex; flex-direction: column; align-items: flex-end;vertical-align: bottom ; justify-content: flex-end;margin:5px">
                             For, ${companyName}
                             <div class="text-1 fst-italic mt-5">(Authorised Signatory)</div>
                         </div>
                     </td>
                 </tr>
             </table>                
         </div>
 </body>
 </html>      
         `);
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.onafterprint = () => {
          printWindow.close();
          printWindow.onafterprint = null;
        };
        printWindow.print();
      };
    }
  };

  let i = 1;
  return (
    <>
      {detailsPage && (
        <>
          {/****Head****/}
          <div
            className="d-flex justify-content-between mt-3"
            style={{ padding: "5px 5px 0px 5px" }}
          >
            <div className="d-flex align-items-center ml-1 ">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancleClick}
                  className="arrow-child"
                />
              </div>
              <div className="newcustomer-head ml-2">
                <h5
                  className="ml-1 mb-0"
                  style={{ letterSpacing: "1px", color: "#086bd5de" }}
                >
                  All Details
                </h5>
              </div>
            </div>
            <div className="add-invoice pr-2 d-flex align-items-baseline">
              {/* <button
                type="button"
                className="btn btn-primary px-1 py-1 "
                style={{ borderRadius: "12px", width: "145px", height: "40px" }}
              >
                <ion-icon name="add-outline" size="small" />
                <span> New Estimate</span>
              </button> */}
            </div>
          </div>
          <div
            className="card border-2 invoice-border mt-3"
            style={{
              borderRadius: "20px",
              height: "auto",
              marginBottom: "0px",
            }}
          >
            {/* <hr style={{ marginTop: "6px", marginBottom: "0px" }} /> */}
            <div className="custom-templates-container">
              {/* Left Sidebar */}
              <div className="left-invoice custom-scrollbar">
                {/* <h6 className="mt-2 ml-4 mb-2">
                <strong>All INVOICE</strong>
              </h6> */}
                {/* <button
                      className="btn btn-success btn-icon"
                      onClick={handleAddNew}
                      type="button"
                      style={{ textAlign: "left" }}
                    >
                      +
                    </button> */}
                {/* <div className="horizontal-line"></div> */}

                <div>
                  <div
                    className="text-left custom-scrollbar"
                    style={{ cursor: "pointer" }}
                  >
                    {invoices.map((invoice, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="invoice-row"
                          onClick={() => {
                            handleInvoiceClick(invoice.estimateNumber);
                            setShowDetails(true);
                            setShowPdf(false);
                          }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "1px",
                            }}
                          >
                            {invoice.customerName}
                          </p>

                          <p
                            className="text-muted"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            {invoice.estimateNumber}
                          </p>
                          <div className="d-flex justify-content-between mt-1">
                            <p
                              className=""
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginLeft: "10px",
                                marginBottom: "0px",
                              }}
                            >
                              {`₹ ${invoice.total}`}
                            </p>
                            <span className="fs-1 text-muted">
                              {invoice.expiryDate}
                            </span>
                          </div>
                        </div>
                        {index < invoices.length - 0 && (
                          <div className="horizontal-line"></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className="middle-line"></div>

              {/* Right Side Content */}
              {showDetails && (
                <div className="right-invoice">
                  {/* Top Template Bar */}

                  <ul
                    className="nav nav-tabs-new d-flex"
                    style={{ padding: "0px 0px 10px 0px" }}
                  >
                    <h5
                      className="flex-grow-1 mb-0 ml-1"
                      style={{ color: "#3C7BD4" }}
                    >
                      {invoiceDetails.estimateNumber}
                    </h5>
                    {/* <li className="nav-item mr-1 cursor-pointer">
                      <a className="nav-link border-dark" onClick={() => handleEditClick(invoiceDetails.invoiceNumber)}>
                        <i className="fa fa-edit text-primary cursor-pointer"></i>
                        <span className="ml-2">EDIT</span>
                      </a>
                    </li> */}
                    <li className="nav-item mr-1 cursor-pointer">
                      <a
                        className="nav-link"
                        href="#"
                        role="button"
                        onClick={() =>
                          handleEditClick(invoiceDetails.estimateNumber)
                        }
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiEdit size={18} /> {/* Set the size to 24 */}
                        <span>Edit</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item mr-1 cursor-pointer">
                      <a
                        className="nav-link "
                        onClick={() =>
                          handleMailClick(invoiceDetails.estimateNumber)
                        }
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <CiMail size={18} /> {/* Set the size to 24 */}
                        <span>Mail</span>{" "}
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                      <a
                        className="nav-link "
                        onClick={handlePrint}
                        style={{
                          borderRadius: "10px",
                          padding: "6px 10px",
                          border: "1.4px solid #c1bebe",
                        }}
                      >
                        <MdOutlinePrint size={18} /> {/* Set the size to 24 */}
                        <span>Print</span>{" "}
                      </a>
                    </li>
                  </ul>

                  <div className="horizontal-line"></div>

                  {/* Section Below Top Template Bar */}
                  <div className="custom-container mt-2">
                    {/*****Invoice*****/}
                    <div className="section1 custom-scrollbar invoice-data m-0">
                      <div className="invoice-container" id="printable-content">
                        <div className="invoice-head border-bottom-0">
                          <div className="invoice-head-top">
                          {userDetails && (
                              <div className="invoice-head-top-left text-start p-2">
                                <ul className="text-start mb-0">
                                  {companyName && (
                                    <li className="text-bold">{companyName}</li>
                                  )}
                                  {userDetails.address1 && (
                                    <li>{userDetails.address1}</li>
                                  )}
                                  {userDetails.address2 && (
                                    <li>{userDetails.address2}</li>
                                  )}
                                  {userDetails.zipcode && (
                                    <li>{userDetails.zipcode}</li>
                                  )}
                                  {userDetails.city && (
                                    <li>{userDetails.city}</li>
                                  )}
                                  {userDetails.state && (
                                    <li>{userDetails.state}</li>
                                  )}
                                  {userDetails.businessLocation && (
                                    <li>{userDetails.businessLocation}</li>
                                  )}
                                  {userDetails.contact && (
                                    <li><span className="text-bold">MOB :</span>{" "}{userDetails.contact}</li>
                                  )}
                                  {invoiceDetails.gstNumber && (
                                    <li>
                                      <span className="text-bold">GST NO:</span>{" "}
                                      {invoiceDetails.gstNumber}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                            <div className="invoice-head-top-right text-end p-2 d-flex align-items-end justify-content-end">
                              <h3 className="mb-0">ESTIMATE</h3>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div className="invoice-head-bottom p-0">
                            <div className="invoice-head-bottom-left right-border p-2">
                              <ul>
                                <div className="row">
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      <b>Estimate No </b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      : {invoiceDetails.estimateNumber}
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      <b>Estimate Date </b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li className="mb-2">
                                      : {invoiceDetails.estimateDate}
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li>
                                      <b>Due Date</b>
                                    </li>
                                  </div>
                                  <div className="col-lg-5">
                                    <li>: {invoiceDetails.expiryDate}</li>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div
                            className="invoice-head-middle"
                            style={{ backgroundColor: "#f2f3f4" }}
                          >
                            <div className="invoice-head-middle-left text-start pl-2">
                              <p className="mb-0">
                                <span className="text-bold">Estimate To: </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                          <div className="invoice-head-middle">
                            <div className="invoice-head-middle-left text-start pl-2">
                            <p className="mb-0">
                                {invoiceDetails.customerName && (
                                  <span className="text-primary">
                                    {invoiceDetails.customerName}
                                  </span>
                                )}
                                {customerData.billAddress1 && (
                                  <>
                                    <br />
                                    {customerData.billAddress1}
                                  </>
                                )}
                                {customerData.billCity &&
                                  customerData.billZipcode && (
                                    <>
                                      <br />
                                      {customerData.billCity}{" "}
                                      {customerData.billZipcode}
                                    </>
                                  )}
                                {invoiceDetails.state && (
                                  <>
                                    <br />
                                    {invoiceDetails.state}
                                  </>
                                )}
                                {customerData.billCountry && (
                                  <>
                                    <br />
                                    {customerData.billCountry}
                                  </>
                                )}
                                {customerData.mobile && (
                                  <>
                                    <br />
                                    {customerData.mobile}
                                  </>
                                )}
                                {customerData.gstin && (
                                  <>
                                    <br />
                                    <span className="text-bold">
                                      GST No:
                                    </span>{" "}
                                    {customerData.gstin}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ border: "1px solid #9e9e9e" }}
                          ></div>
                        </div>
                        <div className="overflow-view">
                          <div className="invoice-body">
                            <table>
                              <thead>
                                <tr>
                                  <td
                                    className="td-left-border text-bold"
                                    style={{ border: "0px" }}
                                  >
                                    ID
                                  </td>
                                  <td className="text-bold">
                                    Items & Description
                                  </td>
                                  {invoiceDetails.hsnCheck === "YES" && (
                                    <td className="text-bold">HSN</td>
                                  )}
                                  {invoiceDetails.sizeCheck === "YES" && (
                                    <td className="text-bold">Size</td>
                                  )}
                                  <td className="text-bold">QTY</td>
                                  <td className="text-bold">Rate</td>
                                  {invoiceDetails.discountCheck === "YES" && (
                                    <td className="text-bold">Discount</td>
                                  )}
                                  {invoiceDetails.taxCheck === "YES" && (
                                    <td className="text-bold">Tax</td>
                                  )}
                                  <td className="text-bold">Amount</td>
                                </tr>
                              </thead>
                              <tbody>
                                {itemDetails.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ border: "0px" }}>{i++}</td>
                                    <td style={{ whiteSpace: "pre-line" }}> {item.itemDetails}<br/>{item.description2}</td>
                                    {invoiceDetails.hsnCheck === "YES" && (
                                      <td>{item.hsnSac}</td>
                                    )}
                                    {invoiceDetails.sizeCheck === "YES" && (
                                      <td>{item.size}</td>
                                    )}
                                    <td>{item.quantity}</td>
                                    <td>{item.rate}</td>
                                    {invoiceDetails.discountCheck === "YES" && (
                                      <td>
                                        {item.discount} {item.discountType}
                                      </td>
                                    )}
                                    {invoiceDetails.taxCheck === "YES" && (
                                      <td>{item.tax}%</td>
                                    )}
                                    <td>{item.amount}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ width: "100%", marginTop: "1px" }}>
                              <div
                                style={{
                                  width: "50%",
                                  padding: "4px 4px 3px 7px",
                                  float: "left",
                                }}
                              >
                                <div style={{ margin: "10px 0 5px" }}>
                                  <div style={{ paddingRight: "10px" }}>
                                    Total In Words :
                                  </div>
                                  <span>
                                    <b>
                                      <i>Indian Rupee {amountInWords} Only</i>
                                    </b>
                                  </span>
                                </div>
                                <div style={{ paddingTop: "15px" }}>
                                  <p
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                    }}
                                    className="pcs-notes"
                                  >
                                    <h6>Terms And Condition:</h6>
                                    {invoiceDetails.termsAndCondition}
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{ width: "43.6%", float: "right" }}
                                className="pcs-totals"
                              >
                                <table
                                  className="left-border"
                                  id="itemTable"
                                  cellSpacing="0"
                                  border="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td valign="middle">Sub Total</td>
                                      <td
                                        id="tmp_subtotal"
                                        valign="middle"
                                        style={{ width: "110px" }}
                                      >
                                        ₹{invoiceDetails.subtotal}
                                      </td>
                                    </tr>
                                    {invoiceDetails.adjustment !== 0 && invoiceDetails.adjustment !== "0" && invoiceDetails.adjustment !=="0.0" ?
                                    <tr>
                                      <td valign="middle">{invoiceDetails.adjustmentLabel || "Adjustment"}</td>
                                      <td
                                        id="tmp_total"
                                        valign="middle"
                                        style={{ width: "110px" }}
                                      >
                                        ₹{invoiceDetails.adjustment}
                                      </td>
                                    </tr>:""}
                                    {invoiceDetails.overallDiscount > 0 && (
                                      <tr>
                                        <td valign="middle">
                                          Discount(
                                          {invoiceDetails.totalDiscount}
                                          {invoiceDetails.totalDiscountType})
                                        </td>
                                        <td
                                          id="tmp_total"
                                          valign="middle"
                                          style={{ width: "110px" }}
                                        >
                                          - ₹{invoiceDetails.overallDiscount}
                                        </td>
                                      </tr>
                                    )}
                                    {/* <tr style={{ height: "10px" }}>
                                      <td valign="middle">Payment Made</td>
                                      <td valign="middle" style={{ width: "110px", color: "red" }}>
                                        (-) 1,000.00
                                      </td>
                                    </tr> */}

                                    {invoiceDetails.taxCheck === "YES" &&
                                      (userDetails.state !==
                                      invoiceDetails.state
                                        ? Object.entries(groupedItems1).map(
                                            ([taxRate, items]) => {
                                              let totalIGST = 0.0;

                                              // Loop through items to accumulate IGST
                                              items.forEach((item) => {
                                                const igstValue =
                                                  parseFloat(item.igst) || 0.0;
                                                if (
                                                  item.customerTaxType === 1
                                                ) {
                                                  totalIGST += igstValue * 2;
                                                } else {
                                                  totalIGST += igstValue;
                                                }
                                              });

                                              return (
                                                <React.Fragment key={taxRate}>
                                                  <tr>
                                                    <td className="headings">
                                                      IGST ({taxRate}%):
                                                    </td>
                                                    <td className="headings">
                                                      {totalIGST.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                </React.Fragment>
                                              );
                                            }
                                          )
                                        : Object.entries(groupedItems).map(
                                            ([taxRate, items]) => {
                                              let totalCGST = 0.0;
                                              let totalSGST = 0.0;

                                              items.forEach((item) => {
                                                const cgstValue =
                                                  parseFloat(item.cgst) || 0.0;
                                                const sgstValue =
                                                  parseFloat(item.sgst) || 0.0;

                                                totalCGST += cgstValue;
                                                totalSGST += sgstValue;
                                              });

                                              return (
                                                <React.Fragment key={taxRate}>
                                                  <tr>
                                                    <td className="headings">
                                                      CGST ({taxRate / 2}%):
                                                    </td>
                                                    <td className="headings">
                                                      {totalCGST.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="headings">
                                                      SGST ({taxRate / 2}%):
                                                    </td>
                                                    <td className="headings">
                                                      {totalSGST.toFixed(2)}
                                                    </td>
                                                  </tr>
                                                </React.Fragment>
                                              );
                                            }
                                          ))}

                                    {invoiceDetails.value > 0 && (
                                      <tr>
                                        <td valign="middle">
                                          TCS({invoiceDetails.tcsTax})
                                        </td>
                                        <td
                                          id="tmp_total"
                                          valign="middle"
                                          style={{ width: "110px" }}
                                        >
                                          ₹{invoiceDetails.value}
                                        </td>
                                      </tr>
                                    )}

                                    <tr
                                      style={{ height: "10px" }}
                                      className="pcs-balance"
                                    >
                                      <td valign="middle">
                                        <b>Total</b>
                                      </td>
                                      <td
                                        id="tmp_balance_due"
                                        valign="middle"
                                        style={{ width: "110px" }}
                                      >
                                        <strong>₹{invoiceDetails.total}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingTop: "5px",
                                        }}
                                        colSpan="2"
                                      >
                                        <div
                                          style={{ minHeight: "75px" }}
                                        ></div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan="2"
                                      >
                                        <label
                                          style={{ marginBottom: "0px" }}
                                          className="pcs-totals"
                                        >
                                          Authorized Signature
                                        </label>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ clear: "both" }}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showPdf && (
                <div className="right-invoice">
                  <div className="">
                    <h5 style={{ color: "#3C7BD4", marginBottom: "0px" }}>
                      {invoiceDetails.estimateNumber}:
                    </h5>
                    <br />
                    {pdfLoading ? (
                      <div className="text-center" style={{ marginTop: "50%" }}>
                        {/* Show spinner while loading */}
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <embed
                        src={pdfData}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {invoiceEdit && (
        <EstimateUpdate
          estimateNumber={estimateNumber}
          handleCancel={handleCancelUpdate}
        />
      )}

      {openMail && (
        <>
          <div className="d-flex align-items-center ml-1 mt-4">
            <div
              className="cursor-pointer d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "35px",
                width: "38px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon
                width={2}
                onClick={handleCloseMailClick}
                className="arrow-child"
              />
            </div>
            <div className="headitems ml-2">
              <h5
                className="ml-1 mb-0"
                style={{ letterSpacing: "1px", color: "#086bd5de" }}
              >
                Mail
              </h5>
            </div>
          </div>
          <div
            className="profit-loss-section my-3 mx-0"
            style={{
              padding: "30px",
              backgroundColor: "#fff",
              borderRadius: "9px",
            }}
          >
            <br />
            <div className="radio row mg-t-15 pt-1 align-items-center mg-b-20">
              <div className="col-md-1 mt-1">
                <label className="form-label">From</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="from"
                  id=""
                  name="from"
                  type="text"
                  value={userEmail}
                  onChange={handleUserEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-10  align-items-left mg-b-20">
              <div className="col-md-1 mt-1">
                <label className="form-label">Send To</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Send To"
                  id=""
                  name="to"
                  type="text"
                  value={custEmail}
                  onChange={handleCustomerEmail}
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15 align-items-center mg-b-20">
              <div className="col-md-1">
                <label className="form-label">CC</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="CC"
                  id=""
                  name=""
                  type="text"
                />
              </div>
            </div>
            <br />
            <div className="radio row mg-t-15  align-items-center">
              <div className="col-md-1">
                <label className="form-label">Subject</label>
              </div>
              <div className="col-md-4 pl-0">
                <input
                  className="form-control"
                  placeholder="Subject"
                  id=""
                  value={subject}
                  name="subject"
                  type="text"
                  onChange={handleSubjectChange}
                />
              </div>
            </div>
            <br />
            <div className="radio col-md-12 row mg-t-15 align-items-center">
              <div className="col-md-1 pl-0">
                {/* <label className="form-label">
            <b>Subject : </b>
          </label> */}
              </div>
              <div className="col-md-10 pl-0">
                <div className="body">
                  <TinyMceEditor
                    onChange={setEditorContent}
                    initialContent={initialContent}
                  />

                  <div className="mx-10 mt-4">
                    <button
                      className="btn-1 btn mt-0"
                      onClick={handleCloseMailClick}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn-3 btn btn-primary"
                      onClick={handleMailSubmit}
                    >
                      {isLoading3 ? "Sending..." : "Send"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EstimateDetails;
