// LineChart.js
import React ,{useState} from "react";
import ReactEcharts from "echarts-for-react";
import "./Analytical.css";
const CashFlowChart = ({ lineChartData }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div style={{ marginLeft: "10px", marginTop: "10px" }}>
        <h5 style={{ color: "black", fontSize: "20px" }}>Cash Flow</h5>
      </div>
      <div className="row">
        <div className="col-md-9">
          <div>
            <ReactEcharts
              option={{
                xAxis: {
                  type: "category",
                  data: lineChartData.xAxisData,
                },
                yAxis: {
                  type: "category",
                  data: lineChartData.yAxisData,
                },
                series: [
                  {
                    data: lineChartData.seriesData,
                    type: "line",
                  },
                ],
              }}
              opts={{ renderer: "svg" }}
              style={{
                height: "500px",
                width: "100%", // Set to 100% to fill the container
              }}
            />
          </div>
        </div>
        <div className="vertical-line1"></div>

        <div className="col-md-3 current-column2">
          {/* Add your additional text or content here */}
          <br />
          <div className="row">
            <div className="col">
              <span
                style={{ color: "MediumSeaGreen", fontSize: "16px" }}
                className="text-center"
              >
                Incoming
              </span>
            </div>
            <div className="col mt-2">
              <h6>₹ 0.00 +</h6>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col">
              <span
                style={{ color: "red", fontSize: "16px" }}
                className="text-center"
              >
                Outgoing
              </span>
            </div>
            <div className="col mt-2">
              <h6>₹ 0.00</h6>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <span
                style={{ color: "green", fontSize: "16px" }}
                className="text-center"
              >
                Cash as on 21/11/2023
              </span>
            </div>
            <div className="col mt-2">
              <h6>₹ 0.00</h6>
            </div>
          </div>
          <br />
        </div>
      </div>     
    </>
  );
};

export default CashFlowChart;
