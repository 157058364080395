import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import ManageEmployeeUpdate from "../../screens/Dashboard/Office/ManageEmployeeUpdate";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import "../../asset/css/inbox.css";

function ManageemployeeCard() {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
  });

  const [employeeData, setEmployeeData] = useState([]);
  const [edit, setEdit] = useState(true);
  const [itemId, setItemID] = useState(true);
  const [loading, setLoading] = useState(true);

  const fetchEmployee=() => {
    $("#DataTables_Table_0").DataTable();
    const jwtToken = localStorage.getItem("jwtToken");

    const apiUrl = BASE_URL+`/fetchEmployee`;

    const makePostRequest = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,

          },
          body: JSON.stringify(postData),
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log("POST request successful");
          console.log("Response Data:", responseData.responseMessage);
          setEmployeeData(responseData.responseMessage);
          setLoading(false);
          $("#DataTables_Table_0").DataTable();

        } else {
          console.error("POST request failed");
          setLoading(false);
          $("#DataTables_Table_0").DataTable();


        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    makePostRequest();
  }
  useEffect(() => {
    fetchEmployee();
  }, []);
  const handleEdit = (id) => {
    setItemID(id);
    setEdit(false);
    fetchEmployee();
  };
  
  const handleCancelNew = () => {
    fetchEmployee();
    setEdit(true);
    $("#DataTables_Table_0").DataTable().destroy();
    $("#DataTables_Table_0").DataTable();

  };
  useEffect(() => {
    const dataTable = $("#DataTables_Table_0").DataTable();

    dataTable.destroy();

    $("#DataTables_Table_0").DataTable();
  }, [employeeData]);

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this Employee data!"
    );
    const jwtToken = localStorage.getItem("jwtToken");
    if (userConfirmed) {
    fetch(BASE_URL+`/deleteEmployee/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          swal({
            icon: "success",
            title: "Employee Deleted Successfully",
          });
          $("#DataTables_Table_0").DataTable().destroy();
          $("#DataTables_Table_0").DataTable();

        } else {
          console.error("Failed to delete Bill");
        }
      })
      .catch((error) => {
        console.error("Error deleting Bill:", error);
      });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Employee data deletion canceled!");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
 let i =1;
  return (
    <>
      {edit ? (
        <div className="col-lg-12">
          <div className="page-content">
            <Container fluid={true}>
              <Row>
                <Col>
                  <Card>
                    {employeeData && employeeData.length > 0 ? (
                      <>
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="thead-light">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        SR NO
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        NAME
                                      </th>
                                     
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        TYPE
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        EMAIL
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        WORK PHONE
                                      </th>
                                      <th
                                        className="sorting"
                                        tabindex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        ACTION
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {employeeData.map((data) => (
                                      <tr
                                        role="row"
                                        className="odd"
                                        key={data.id}
                                      >
                                        <td>{i++}</td>
                                        <td className="sorting_1">
                                          <strong>{data.employeeDisplayName}</strong>
                                        </td>
                                        <td>{data.employeeType}</td>
                                        <td>{data.employeeEmail}</td>
                                        <td>{data.phone1}</td>
                                        

                                        <td>
                                          {/* <button className="btn btn-sm round btn-outline-success">
                                            {" "}
                                            Accept
                                          </button> */}
                                          <button
                                            className="btn btn-light icon-border btn-sm mr-2"
                                            onClick={() =>
                                              handleEdit(data.id)
                                            }
                                          >
                                            <i className="fa fa-edit text-primary"></i>
                                          </button>
                                          <button
                                            className="btn px-2 btn-light icon-border btn-sm"
                                            onClick={() =>
                                              handleDelete(data.id)
                                            }
                                          >
                                            <i className="fa fa-trash text-danger"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="thead-light">
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-sort="ascending"
                                      aria-label="Date: activate to sort column descending"
                                    >
                                      SR No
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Type: activate to sort column ascending"
                                    >
                                      NAME
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Amount BTC: activate to sort column ascending"
                                    >
                                      TYPE
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="BTC Remaining: activate to sort column ascending"
                                    >
                                      EMAIL
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      WORK PHONE
                                    </th>
                                    <th
                                      className="sorting"
                                      tabindex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      ACTION
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              {/* <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                  </h6> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  </Card>
                </Col>
              </Row>
              {/* Previous and Next buttons */}
              {/* <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-primary mx-1">Previous</button>
                <button className="btn btn-primary mx-1">1</button>
                <button className="btn btn-primary mx-1">Next</button>
              </div> */}
            </Container>
          </div>
        </div>
      ) : (
        <ManageEmployeeUpdate id={itemId} handleCancle={handleCancelNew} />
      )}
    </>
  );
}

export default ManageemployeeCard;
