import React,{useState,useEffect} from "react";
import "../../asset/css/inbox.css";
import { Container, Card, Row, Col } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import ManageSalesPersonUpdate from "../../screens/Dashboard/Shop/ManageSalesPersonUpdate";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";

function ManageSalesPersonCard() {
  
  const [bill, setBill] = useState([]);
  const [billId, setBillId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(true);  
  
  let i=1;

  useEffect(() => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL+`/getAllSalesPersonByUniqueId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setBill(data.responseMessage);
        } else {
          console.error("Failed to fetch Project:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Project Data:", error);
        setLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    console.log("handleDelete123");
    // Send a DELETE request to your backend API
    fetch(BASE_URL+`/deleteSalesperson/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 200) {
          // Invoice deleted successfully, update the UI
          setBill((prevBills) => prevBills.filter((bill) => bill.id !== id));
          swal({
            icon: "success",
            title: "Project Data Deleted Successfully",
          });
        } else {
          console.error("Failed to delete Project Data");
        }
      })
      .catch((error) => {
        console.error("Error deleting project Data:", error);
      });
  };

  const handleEdit = (id) => {
    console.log("Fetched Id : ", id);
    setBillId(id);
    setEdit(false);
  };

  const handleCancelNew = () => {
    setEdit(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  

  

    return (

      <>
    {edit ? (
      <div className="container-fluid">
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped mb-0">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">SERIAL NO</th>
                          <th scope="col">NAME</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col">ACTION UNIT</th>
                        </tr>
                      </thead>
                      <tbody>
                      {bill.map((bill1, index) => (
                      <tr key={index}>
                        <th scope="row">{i++}</th>
                        <td>{bill1.name}</td>
                        <td>{bill1.email}</td>
                        {/* <td>{bill1.link}</td>
                        <td>{bill1.loginId}</td>
                        <td>{bill1.password}</td> */}
                        <td>
                          {" "}
                          <button
                            className="btn btn-primary btn-sm mr-2"
                            onClick={() => handleEdit(bill1.id)}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(bill1.id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                        

                    
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
             {/* Previous and Next buttons */}
           <div className="d-flex justify-content-end mt-3">
                  <button className="btn btn-primary mx-1" >
                    Previous
                  </button>
                  <button className="btn btn-primary mx-1">
                    1
                  </button>
                  <button className="btn btn-primary mx-1">
                    Next
                  </button>
                </div>
          </Container>
        </div>
      </div>
       ) : (
        <ManageSalesPersonUpdate id={billId} handleCancle={handleCancelNew} />
      )}
      </>
    );
  }


export default ManageSalesPersonCard;
