import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import { BASE_URL } from "../CommonApi";
import NoDataImage from "../../../asset/images/nodata.jpg";
import BillsUpdate from "./BillsUpdate";
import BillsDetails from "./BillsDetails";
function Bills({ navigation }) {
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [bill, setBill] = useState([]);
  const [billId, setBillId] = useState(null);
  const [bid, setBid] = useState(null);
  const [fetchedStatus, setFetchedStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const [vendorId,setVendorId]=useState("")
  const jwtToken = localStorage.getItem("jwtToken");
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleBillClick = () => {
    handleNavigation("BillsNew");
  };
  const fetchBillData = () => {
    $("#DataTables_Table_0").DataTable();

    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchBill`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setBill(data.responseMessage);
          setLoading(false);

          $("#DataTables_Table_0").DataTable();
        } else {
          console.error("Failed to fetch Bill:", data.responseMessage);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error fetching Bill:", error);
        setLoading(false);

        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchBillData();
  }, []);
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const handleDelete = (id) => {
    const formattedId = id.replace(/\//g, "!");

    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this bill!"
    );

    if (userConfirmed) {
      // Send a DELETE request to your backend API
      fetch(BASE_URL + `/deleteByBillNumber/${formattedId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => {
          if (response.status === 200) {
            setBill((prevBills) => prevBills.filter((bill) => bill.billsNumber !== id));
            swal({
              icon: "success",
              title: "Bill Deleted Successfully",
            });
            $("#DataTables_Table_0").DataTable();
          } else {
            console.error("Failed to delete Bill");
          }
        })
        .catch((error) => {
          console.error("Error deleting Bill:", error);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Bill deletion canceled!");
    }
  };

  const handleEdit = (billsNumber, id, status) => {
    setBid(id);
    setFetchedStatus(status);
    setBillId(billsNumber);
    setShow(false);
    setShowCard(false);
    setShowUpdate(true);
    fetchBillData();
  };
  
  const handleCancelUpdate = () => {
    setShowCard(true);
    setShowDetails(false);
    setShowUpdate(false);
    fetchBillData();
  };
  const handleCancelInvoice = () => {
    setShowDetails(false);
    setShowUpdate(false);
    setShowCard(true);
    fetchBillData();
  };

  const handleBill = (billsNumber,vendorId) => {
    setVendorId(vendorId)
    setnewInvoice(billsNumber);
    setShowCard(false);
    setShow(false);
    setShowDetails(true);
    setShowUpdate(false);
    fetchBillData();
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {!show && !showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Bills
            </div>
            <div className="d-flex">
              <div className="">
                {!show && (
                  <button
                    className="btn btn-primary px-1 py-2 new-purchase"
                    onClick={handleBillClick}
                  >
                    + New Bill
                  </button>
                )}
              </div>
              <div className="ml-2 px-1 py-2 icon-dot">
                <i
                  className="fa fa-ellipsis-v "
                  style={{ fontSize: 18, color: "gray" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCard && (
        <div className="container-fluid">
          <div className="page-content ">
            <Container fluid={true}>
              <Row>
                <Col>
                  <Card>
                    {bill && bill.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Date
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Bill no.
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Vendor name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Order number
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Total
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {bill.map((data) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={data.id}
                                        >
                                          <td>{data.billsDate}</td>
                                          <td className="sorting_1">
                                            <a
                                              href="#"
                                              onClick={() =>
                                                handleBill(data.billsNumber,data.vendorId)
                                              }
                                            >
                                              {data.billsNumber}
                                            </a>
                                          </td>
                                          <td>{data.vendorName}</td>
                                          <td>{data.orderNumber}</td>
                                          <td>₹{data.total}</td>
                                          <td>
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(
                                                  data.billsNumber,
                                                  data.id,
                                                  data.status
                                                )
                                              }
                                            >
                                              <i className="fa fa-edit text1"></i>
                                            </button>
                                            <button
                                              className="btn icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(data.billsNumber)
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Bill no.
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Vendor name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Order number
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Total
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
      {showUpdate && (
        <BillsUpdate
          fetchedStatus={fetchedStatus}
          billsNumber={billId}
          id={bid}
          handleCancel={handleCancelUpdate}
        />
      )}

      {showDetails && (
        <BillsDetails
          newinvoiceNumber={newinvoiceNumber}
          Id={vendorId}
          handleCancel={handleCancelInvoice}
        />
      )}
    </div>
  );
}
export default Bills;
