import React, { useState, useEffect } from "react";
import { XCircle, User } from "phosphor-react";
import New1 from "./New1";
import New2 from "./New2";
import New3 from "./New3";
// import "./ManageEmployeeNewStyle.css";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import { BASE_URL } from "../CommonApi";

function ManageEmployeeUpdate(props) {
  const { id } = props;

  const handleCancleClick = () => {
    props.handleCancle();
  };

  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [new1, showNew1] = useState(true);
  const [new2, showNew2] = useState(false);
  const [new3, showNew3] = useState(false);

  const handleEmployeeTypeChange = (e, value) => {
    setFormData({
      ...formData,
      employeeType: value,
    });
  };
  const [formData, setFormData] = useState({
    primaryContact: "Mr.",
    firstName: "",
    lastName: "",
    companyName: "Code World Infotech",
    employeeDisplayName: "",
    employeeEmail: "",
    phone1: "",
    phone2: "",
    mobile: "",
    designation: "",
    experience: "",

    payment: "",
    dateOfJoining: "",

    attention: "",
    country: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    fax: "",

    documents: selectedFile,

    uniqueId: decryptedUniqueId,
  });
  const jwtToken = localStorage.getItem("jwtToken");

  const handleSaveClick = () => {
    fetch(BASE_URL +`/updateEmployee/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,

      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Employee data Updated successfully");
          swal({
            icon: "success",
            title: "Updated Successfully",
            text: "",
          });
        } else {
          console.error("Failed to Update employee data");
          swal({
            icon: "error",
            title: "Failed to Updated employee data",
            text: "",
          });
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
        swal({
          icon: "error",
          title: "Network Error",
          text: "An error occurred while communicating with the server.",
        });
      });

    props.handleCancle();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const apiUrl = BASE_URL +`/getEmployee/${id}`; // Replace with your GET endpoint

  const fetchData = () => {
    const data = {
      uniqueId: decryptedUniqueId, // Replace with the uniqueId you want to send
    };
    const jwtToken = localStorage.getItem("jwtToken");


    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,

      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("POST request response:", response.responseMessage[0]);
        const Data = response.responseMessage[0];

        setFormData({
          firstName: Data.firstName,
          lastName: Data.lastName,
          companyName: Data.companyName,
          employeeType:Data.employeeType,
          employeeDisplayName: Data.employeeDisplayName,
          employeeEmail: Data.employeeEmail,
          phone1: Data.phone1,
          phone2: Data.phone2,
          mobile: Data.mobile,
          designation: Data.designation,
          experience: Data.experience,

          payment: Data.payment,
          dateOfJoining: Data.dateOfJoining,

          attention: Data.attention,
          country: Data.country,
          address1: Data.address1,
          address2: Data.address2,
          city: Data.city,
          state: Data.state,
          zipcode: Data.zipcode,
          phone: Data.phone,
          fax: Data.fax,

          documents: Data.documents,
        });
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container my-3">
      <div className="border border-2 p-2">
        <div className="px-2 d-flex justify-content-between">
          <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}>
          Update Employee
          </h4>
          <button className="btn" onClick={handleCancleClick}>
            <XCircle size={32} weight="fill" />
          </button>
        </div>
        <hr />

        <div className="container">
          <div className="radio row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label">
                <span className="text-dashed-underline">
                  Employee Type 
                </span>{" "}
              </label>
            </div>
            <div>
              <label className="rdiobox">
                <input
                  className="mx-1"
                  type="radio"
                  name="employeeType"
                  id="employeeType"
                  value="Permanent"
                  checked={formData.employeeType === "Permanent"}
                  // onChange={handleEmployeeTypeChange}
                  onChange={(e) => handleEmployeeTypeChange(e, "Permanent")}

                />
                <span>Permanent</span>
              </label>
            </div>
            <div>
              <label className="rdiobox ml-3">
                <input
                  className="mx-1"
                  type="radio"
                  name="employeeType"
                  id="employeeType"
                  value="Intern"
                  checked={formData.employeeType === "Intern"}
                  // onChange={handleEmployeeTypeChange}
                  onChange={(e) => handleEmployeeTypeChange(e, "Intern")}
                />
                <span>Intern</span>
              </label>
            </div>
            <div>
              <label className="rdiobox ml-3">
                <input
                  className="mx-1"
                  type="radio"
                  name="employeeType"
                  id="employeeType"
                  value="Contract"
                  checked={formData.employeeType === "Contract"}
                  // onChange={handleChange}
                  onChange={(e) => handleEmployeeTypeChange(e, "Contract")}
                />
                <span>Contract</span>
              </label>
            </div>
          </div>

          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  Primary Contact 
                </span>
              </label>
            </div>
            <div className="">
              <select
                className="form-control"
                name="primaryContact"
                value={formData.primaryContact}
                onChange={handleChange}
              >
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Ms.">Ms.</option>
                <option value="Miss.">Miss.</option>
                <option value="Dr.">Dr.</option>
              </select>
            </div>
            <div className="col-md-12 col-lg-3 col-xl-2 ">
              <input
                className="form-control"
                placeholder="First Name"
                name="firstName"
                id="firstname"
                type="text"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Last Name"
                id="lastname"
                name="lastName"
                type="text"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                Company Name 
              </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Company Name"
                name="companyName"
                type="text"
                value={formData.companyName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                Employee Display Name 
              </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Employee Display Name"
                name="employeeDisplayName"
                type="text"
                value={formData.employeeDisplayName}
                onChange={handleChange} // Add the event handler
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                Employee Email 
              </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Employee Email"
                name="employeeEmail"
                type="text"
                value={formData.employeeEmail}
                onChange={handleChange} // Add the event handler
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label   mg-b-0">
                <span className="text-dashed-underline">
                  Employee Phone 
                </span>
              </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Work Phone"
                name="phone1"
                type="text"
                value={formData.phone1}
                onChange={handleChange} // Add the event handler
              />
            </div>
            <div>
              <input
                className="form-control ml-2 "
                placeholder="Mobile"
                name="phone2"
                type="text"
                value={formData.phone2}
                onChange={handleChange} // Add the event handler
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                Designation 
              </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="Designation"
                name="designation"
                type="text"
                value={formData.designation}
                onChange={handleChange} // Add the event handler
              />
            </div>
          </div>

          <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
            <div className="col-md-2">
              <label className="form-label required mg-b-0">
                Experience 
              </label>
            </div>
            <div>
              <input
                className="form-control"
                placeholder="experience"
                name="experience"
                type="text"
                value={formData.experience}
                onChange={handleChange} // Add the event handler
              />
            </div>
          </div>

          <hr />

          <div className="mt-2 my-3">
            <div className="">
              <button
                className={`btn outline-none ${
                  new1 ? "btn-secondary" : "border border-1"
                }`}
                onClick={() => {
                  showNew1(true);
                  showNew2(false);
                  showNew3(false);
                }}
              >
                Other Details
              </button>
              {/* <button
                className={`btn outline-none ml-2 ${
                  new3 ? "btn-secondary" : "border border-1"
                }`}
                onClick={() => {
                  showNew3(true);
                  showNew1(false);
                  showNew2(false);
                }}
              >
                Documents
              </button> */}
              <button
                className={`btn outline-none ml-2 ${
                  new2 ? "btn-secondary" : "border border-1"
                }`}
                onClick={() => {
                  showNew2(true);
                  showNew1(false);
                  showNew3(false);
                }}
              >
                Address
              </button>
              {/* {new1 && <New1 />} */}

              {new1 && (
                <div>
                  <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                    <div className="col-lg-2.5 ml-4">
                      <label className="form-label required mg-b-0">
                        Payment (CTC)/Annum 
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        className="form-control"
                        placeholder="Payment (CTC)/Annum"
                        name="payment"
                        type="text"
                        value={formData.payment}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                    <div className="col-md-2 ml-3">
                      <label className="form-label required mg-b-0">
                        Date of Joining 
                      </label>
                    </div>
                    <div className="col-md-3 pl-0">
                      <input
                        className="form-control"
                        placeholder="Date of Joining"
                        name="dateOfJoining"
                        type="date"
                        value={formData.dateOfJoining}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* {new2 && <New2 />} */}

              {new2 && (
                <div>
                  <div className="flex-item">
                    <p className="text-left">
                      PERMANENT ADDRESS
                    </p>

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                      <div className="col-md-2">
                        <label className="form-label   mg-b-0">
                          <span className="text-dashed-underline">
                            Attention
                          </span>
                        </label>
                      </div>

                      <div className="col-md-3 pl-0">
                        <input
                          className="form-control"
                          placeholder="Attention"
                          id="attention"
                          name="attention"
                          type="text"
                          value={formData.attention}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                      <div className="col-md-2">
                        <label className="form-label  mg-b-0">
                          Country
                        </label>
                      </div>
                      <div className="col-md-3 pl-0">
                        <select
                          class="form-control"
                          name="country"
                          id="country"
                          value={formData.country}
                          onChange={handleChange}
                        >
                          <option value="Afganistan">Afghanistan</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antigua &amp; Barbuda">
                            Antigua &amp; Barbuda
                          </option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bonaire">Bonaire</option>
                          <option value="Bosnia &amp; Herzegovina">
                            Bosnia &amp; Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Ter">
                            British Indian Ocean Ter
                          </option>
                          <option value="Brunei">Brunei</option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Canary Islands">Canary Islands</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Channel Islands">
                            Channel Islands
                          </option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">
                            Christmas Island
                          </option>
                          <option value="Cocos Island">Cocos Island</option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote DIvoire">Cote DIvoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Curaco">Curacao</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">
                            Dominican Republic
                          </option>
                          <option value="East Timor">East Timor</option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands">
                            Falkland Islands
                          </option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">
                            French Polynesia
                          </option>
                          <option value="French Southern Ter">
                            French Southern Ter
                          </option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Great Britain">Great Britain</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Hawaii">Hawaii</option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="India" selected="true">
                            India
                          </option>
                          <option value="Iran">Iran</option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea North">Korea North</option>
                          <option value="Korea Sout">Korea South</option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Laos">Laos</option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libya">Libya</option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macau">Macau</option>
                          <option value="Macedonia">Macedonia</option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">
                            Marshall Islands
                          </option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Midway Islands">Midway Islands</option>
                          <option value="Moldova">Moldova</option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Nambia">Nambia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherland Antilles">
                            Netherland Antilles
                          </option>
                          <option value="Netherlands">
                            Netherlands (Holland, Europe)
                          </option>
                          <option value="Nevis">Nevis</option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau Island">Palau Island</option>
                          <option value="Palestine">Palestine</option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">
                            Papua New Guinea
                          </option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Phillipines">Philippines</option>
                          <option value="Pitcairn Island">
                            Pitcairn Island
                          </option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Republic of Montenegro">
                            Republic of Montenegro
                          </option>
                          <option value="Republic of Serbia">
                            Republic of Serbia
                          </option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russia">Russia</option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="St Barthelemy">St Barthelemy</option>
                          <option value="St Eustatius">St Eustatius</option>
                          <option value="St Helena">St Helena</option>
                          <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                          <option value="St Lucia">St Lucia</option>
                          <option value="St Maarten">St Maarten</option>
                          <option value="St Pierre &amp; Miquelon">
                            St Pierre &amp; Miquelon
                          </option>
                          <option value="St Vincent &amp; Grenadines">
                            St Vincent &amp; Grenadines
                          </option>
                          <option value="Saipan">Saipan</option>
                          <option value="Samoa">Samoa</option>
                          <option value="Samoa American">Samoa American</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome &amp; Principe">
                            Sao Tome &amp; Principe
                          </option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syria">Syria</option>
                          <option value="Tahiti">Tahiti</option>
                          <option value="Taiwan">Taiwan</option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania">Tanzania</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad &amp; Tobago">
                            Trinidad &amp; Tobago
                          </option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks &amp; Caicos Is">
                            Turks &amp; Caicos Is
                          </option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Erimates">
                            United Arab Emirates
                          </option>
                          <option value="United States of America">
                            United States of America
                          </option>
                          <option value="Uraguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Vatican City State">
                            Vatican City State
                          </option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Vietnam">Vietnam</option>
                          <option value="Virgin Islands (Brit)">
                            Virgin Islands (Brit)
                          </option>
                          <option value="Virgin Islands (USA)">
                            Virgin Islands (USA)
                          </option>
                          <option value="Wake Island">Wake Island</option>
                          <option value="Wallis &amp; Futana Is">
                            Wallis &amp; Futana Is
                          </option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zaire">Zaire</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                      <div className="col-md-2">
                        <label className="form-label required mg-b-0">
                          Address
                        </label>
                      </div>
                      <div className="col-md-3 pl-0">
                        <textarea
                          className="form-control "
                          placeholder="Site1"
                          name="address1"
                          type="text"
                          value={formData.address1}
                          onChange={handleChange}
                        />
                        <textarea
                          className="form-control my-2"
                          placeholder="Site2"
                          name="address2"
                          type="text"
                          value={formData.address2}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                      <div className="col-md-2">
                        <label className="form-label   mg-b-0">
                          <span className="text-dashed-underline">
                            City
                          </span>
                        </label>
                      </div>

                      <div className="col-md-3 pl-0">
                        <input
                          className="form-control"
                          placeholder="City"
                          id="city"
                          name="city"
                          type="text"
                          value={formData.city}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                      <div className="col-md-2">
                        <label className="form-label  mg-b-0">
                          State
                        </label>
                      </div>
                      <div className="col-md-3 pl-0">
                        <select
                          id="state"
                          name="state"
                          class="form-control"
                          value={formData.state}
                          onChange={handleChange}
                        >
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadar and Nagar Haveli">
                            Dadar and Nagar Haveli
                          </option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                      <div className="col-md-2">
                        <label className="form-label   mg-b-0">
                          <span className="text-dashed-underline">
                            Zipcode
                          </span>
                        </label>
                      </div>

                      <div className="col-md-3 pl-0">
                        <input
                          className="form-control"
                          placeholder="Zipcode"
                          id="zipcode"
                          name="zipcode"
                          type="text"
                          value={formData.zipcode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                      <div className="col-md-2">
                        <label className="form-label   mg-b-0">
                          <span className="text-dashed-underline">
                            Phone
                          </span>
                        </label>
                      </div>

                      <div className="col-md-3 pl-0">
                        <input
                          className="form-control"
                          placeholder="Phone"
                          id="phone"
                          name="phone"
                          type="text"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row mg-t-15 pt-3  align-items-center mg-b-20">
                      <div className="col-md-2">
                        <label className="form-label   mg-b-0">
                          <span className="text-dashed-underline">
                            Fax
                          </span>
                        </label>
                      </div>

                      <div className="col-md-3 pl-0">
                        <input
                          className="form-control"
                          placeholder="Fax"
                          id="fax"
                          name="fax"
                          type="text"
                          value={formData.fax}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* {new3 && <New3 />} */}
              {new3 && (
                <div>
                  <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
                    <div className="col-md-2">
                      <label className="form-label required mg-b-0">
                        Documents 
                      </label>
                    </div>
                    <form onClick={handleSaveClick}>
                      <div
                        className={`drag-drop-input ${
                          isDragging ? "dragging" : ""
                        }`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      >
                        {selectedFile ? (
                          <p>Selected file: {selectedFile.name}</p>
                        ) : (
                          <div className="text-center">
                            <p className="instructions">
                              Drag and drop a file here
                            </p>
                            <p className="or my-3">-OR-</p>
                            <label className="file-input-label">
                              Browse
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleDrop}
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="my-2 text-right">
                <button
                  className="btn btn-primary px-2 py-1"
                  style={{ backgroundColor: "var(--primary-color)" }}
                  onClick={handleSaveClick}>
                  Save
                                {"  "}<i className="fa fa-save"></i>
                </button>

                <button
              className="btn btn-secondary mx-2"
              onClick={handleCancleClick}>
                  Cancel <i className="fa fa-times"></i>
                </button>
              </div>
      </div>
      {/* <div className="my-2">
        <button className="btn btn-success" onClick={handleSaveClick}>
          Save
        </button>
        <button className="btn btn-dark mx-2" onClick={handleCancleClick}>
          Cancel
        </button>
      </div> */}
    </div>
  );
}

export default ManageEmployeeUpdate;
