import React from "react";
import "../../asset/css/inbox.css"
import { Container,Card,Row,Col } from "react-bootstrap";

class ManageRecipeCard extends React.Component {
    constructor(props){
        super(props)
        this.state={
            isCompose:false
        }
    }
  render() {
    return (
        <div className="container-fluid">
            <div className="page-content">
        <Container fluid={true}>
          
          <Row>
            <Col> 
              <Card>
                
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped mb-0">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">SERIAL NO</th>
                          <th scope="col">PRODUCT NAME</th>
                          
                        </tr>
                      </thead>
                      <tbody>
               


                       
                      </tbody>
                    </table>
                    
                  </div>
                
                
              </Card>
            </Col>
          </Row>
           {/* Previous and Next buttons */}
           <div className="d-flex justify-content-end mt-3">
                  <button className="btn btn-primary mx-1" onClick={this.handlePrevious}>
                    Previous
                  </button>
                  <button className="btn btn-primary mx-1" onClick={this.handleNo}>
                    1
                  </button>
                  <button className="btn btn-primary mx-1" onClick={this.handleNext}>
                    Next
                  </button>
                </div>

                
          
        </Container>
      </div> 
      
    </div>
        
    
    );
  }
}
export default ManageRecipeCard;
