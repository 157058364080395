import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css"; // Import styles for the rich text editor
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactToPrint from "react-to-print";
import Select, { components } from "react-select"; // Import components from react-select
import DatePicker from "react-datepicker"; // Import DatePicker for manual date selection
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles
import "./GstR1.css";

function GstR2({ navigation }) {
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const cancle = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleNavigation("GstFilling");
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const [loading, setLoading] = useState(true);
  const componentRef = useRef();

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const jwtToken = localStorage.getItem("jwtToken");
  const [gstData, setGstData] = useState([]);
  const [totalOfGst, setTotalOfGst] = useState({});

  const [itemData, setItemData] = useState({
    organizationName: "",
    companyName: "",
  });
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          setItemData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            name: portfolioItem.name,
            role: portfolioItem.role,

            uniqueId: portfolioItem.uniqueId,
          });
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const options = [
    { value: "today", label: "Today" },
    { value: "this_week", label: "This week" },
    { value: "this_month", label: "This month" },
    { value: "this_year", label: "This year" },
    { value: "this_quarter", label: "This Quarter" },
    { value: "yesterday", label: "Yesterday" },
    { value: "previous_week", label: "Previous Week" },
    { value: "previous_month", label: "Previous Month" },
    { value: "previous_quarter", label: "Previous Quarter" },
    { value: "previous_year", label: "Previous Year" },
    { value: "custom", label: "Custom" },
  ];

  const formatDate1 = (date) => {
    return date.toLocaleDateString("en-GB").replace(/\//g, "-"); // DD-MM-YYYY format
  };
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
  };
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    if (selectedOption) {
      const today = new Date();
      switch (selectedOption.value) {
        case "today":
          setFromDate(today);
          setToDate(today);
          break;
        case "yesterday":
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          setFromDate(yesterday);
          setToDate(yesterday);
          break;
        case "this_week":
          const sunday = new Date(today);
          sunday.setDate(today.getDate() - today.getDay());
          const saturday = new Date(today);
          saturday.setDate(today.getDate() + (6 - today.getDay()));
          setFromDate(sunday);
          setToDate(saturday);
          break;
        case "previous_week":
          const prevSunday = new Date(today);
          prevSunday.setDate(today.getDate() - today.getDay() - 7);
          const prevSaturday = new Date(today);
          prevSaturday.setDate(today.getDate() - today.getDay() - 1);
          setFromDate(prevSunday);
          setToDate(prevSaturday);
          break;
        case "this_month":
          const firstDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          const lastDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );
          setFromDate(firstDayOfMonth);
          setToDate(lastDayOfMonth);
          break;
        case "previous_month":
          const prevMonthStart = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
          );
          const prevMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          );
          setFromDate(prevMonthStart);
          setToDate(prevMonthEnd);
          break;
        case "this_quarter":
          const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
          const quarterStartDate = new Date(
            today.getFullYear(),
            quarterStartMonth,
            1
          );
          const quarterEndDate = new Date(
            today.getFullYear(),
            quarterStartMonth + 3,
            0
          );
          setFromDate(quarterStartDate);
          setToDate(quarterEndDate);
          break;
        case "previous_quarter":
          const prevQuarterStartMonth =
            Math.floor((today.getMonth() - 3) / 3) * 3;
          const prevQuarterStartDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth,
            1
          );
          const prevQuarterEndDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth + 3,
            0
          );
          setFromDate(prevQuarterStartDate);
          setToDate(prevQuarterEndDate);
          break;
        case "this_year":
          const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
          const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
          setFromDate(firstDayOfYear);
          setToDate(lastDayOfYear);
          break;
        case "previous_year":
          const prevYearStart = new Date(today.getFullYear() - 1, 0, 1);
          const prevYearEnd = new Date(today.getFullYear() - 1, 11, 31);
          setFromDate(prevYearStart);
          setToDate(prevYearEnd);
          break;
        case "custom":
          setFromDate(today);
          setToDate(today);
          break;
        default:
          setFromDate(null);
          setToDate(null);
          break;
      }
    }
  }, [selectedOption]); // This effect runs when selectedOption state changes

  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      fetchStatement1(fromDate, toDate);
    }
  }, [fromDate, toDate]); // This effect runs when either fromDate or toDate changes

  const [dataPost, setDataPost] = useState({
    uniqueId: decryptedUniqueId,
  });

  const [totalOfRate, setTotalOfRate] = useState(0);
  const [totalOfCGST, setTotalOfCGST] = useState(0);
  const [totalOfSGST, setTotalOfSGST] = useState(0);
  const [totalOfIGST, setTotalOfIGST] = useState(0);
  const [totalOfTotal, settotalOfTotal] = useState(0);

  useEffect(() => {
    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/gstrReports2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(dataPost), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setGstData(Data);
          setTotalOfGst(data.total);

          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const fetchStatement1 = (fromDate, toDate) => {
    setLoading(true);
    fetch(BASE_URL + `/gstrReports2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        fromDate: formatDate1(fromDate),
        toDate: formatDate1(toDate),
      }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setGstData(Data);
          setTotalOfGst(data.total);
          setLoading(false);

          const totalRates = Data.reduce((sum, item) => {
            const rate = parseFloat(item.rate) || 0;
            return sum + rate;
          }, 0);
          setTotalOfRate(totalRates);

          const totalCGST = Data.reduce((sum, item) => {
            const rate = parseFloat(item.cgst) || 0;
            return sum + rate;
          }, 0);
          setTotalOfCGST(totalCGST);

          const totalSGST = Data.reduce((sum, item) => {
            const rate = parseFloat(item.sgst) || 0;
            return sum + rate;
          }, 0);
          setTotalOfSGST(totalSGST);

          const totalIGST = Data.reduce((sum, item) => {
            const rate = parseFloat(item.igst) || 0;
            return sum + rate;
          }, 0);
          setTotalOfIGST(totalIGST);

          const totalOfTotal = Data.reduce((sum, item) => {
            const rate = parseFloat(item.total) || 0;
            return sum + rate;
          }, 0);
          settotalOfTotal(totalOfTotal);

        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      border: "1px solid #ccc",
      borderRadius: "4px",
      paddingLeft: "6px",
      width: "100%",
      maxWidth: "400px", // Ensure responsiveness
      boxSizing: "border-box",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: "400px",
    }),
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <span className="custom-control-icon">📅</span>
      {children}
    </components.Control>
  );
  return (
    <>
      <style>
        {`
          .custom-datepicker-wrapper {
            position: relative;
            display: inline-block;
            width: 200px;
          }

          .custom-datepicker-calendar {
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          .react-datepicker__input-container {
            width: 100%;
          }

          .react-datepicker__input-container input {
            width: 100%;
            height: 40px;
            padding: 0 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }

          .custom-label {
            display: block;
            margin-bottom: 5px;
            font-size: 16px;
            color: #333;
          }

          .custom-date-picker {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        `}
      </style>

      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={cancle}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5
            className="ml-1 mb-0"
            style={{ letterSpacing: "1px", color: "#086bd5de" }}
          >
            Summary of Inward Supplies (GSTR-2)
          </h5>
        </div>
      </div>
      <div
        className="by-salesperson-section my-3 mx-0"
        style={{ padding: "30px", backgroundColor: "#fff" }}
      >
        <div className="d-flex justify-content-between mt-1 mb-1 ml-3">
          <div className="d-flex align-items-center">
            <Select
              value={selectedOption}
              onChange={handleOptionChange}
              options={options}
              styles={customStyles}
              components={{ Control: CustomControl }}
            />
          </div>
          {selectedOption.value === "custom" && (
            <div className="d-flex align-items-center ml-auto">
              <div className="custom-date-picker">
                <label htmlFor="from-date" className="custom-label mr-2">
                  From Date:
                </label>{" "}
                <DatePicker
                  id="from-date"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                  style={{ backgroundColor: "white" }}
                />
              </div>
              <div className="custom-date-picker">
                <label htmlFor="to-date" className="custom-label mr-2">
                  To Date:
                </label>
                <DatePicker
                  id="to-date"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                />
              </div>
            </div>
          )}
          <div className="d-flex align-items-center">
            <ReactToPrint
              trigger={() => (
                <span
                  className="input-group-text"
                  id="pdf-icon"
                  style={{
                    backgroundColor: "#F6F6F6",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  📑
                </span>
              )}
              content={() => componentRef.current}
            />{" "}
          </div>
        </div>
        <div ref={componentRef} className="content-to-print" style={{ backgroundColor: "white", overflow: 'auto' }}>
          <div
            className=" head-container container col-md-12"
            style={{ textAlign: "center", color: "black" }}
          >
            <h5 style={{ fontWeight: "normal" }}>{itemData.companyName}</h5>
            <h4 style={{ fontWeight: "normal" }}>GSTR-2 Report</h4>

            <p style={{ fontWeight: "normal" }}>
              FromTo {formatDate(fromDate)} - ToDate {formatDate(toDate)}
            </p>

            <div className="container mt-2">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Bill No.</th>
                    <th>Purchase Date</th>
                    <th>Supplier Name</th>
                    <th>GST Number</th>
                    <th>Rate</th>
                    <th>Discount Amount</th>
                    <th>CGST</th>
                    <th>SGST</th>
                    <th>IGST</th>
                    {/* <th>Round Off</th> */}
                    <th>Bill Total</th>
                  </tr>
                </thead>
                <tbody>
                  {gstData.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.billsNumber || "0"}</td>
                      <td>{data.billsDate || "0"}</td>
                      <td>{data.vendorName || "0"}</td>
                      <td>{data.gstNumber || "0"}</td>
                      <td>₹{parseFloat(data.rate || 0).toFixed(2)}</td>
                      <td>₹{data.overallDiscount || "0"}</td>
                      <td>₹{parseFloat(data.cgst || 0).toFixed(2)}</td>
                      <td>₹{parseFloat(data.sgst || 0).toFixed(2)}</td>
                      <td>₹{parseFloat(data.igst || 0).toFixed(2)}</td>
                      {/* <td>₹{parseFloat(data.adjustment || 0).toFixed(2)}</td> */}
                      <td>₹{parseFloat(data.total || 0).toFixed(2)}</td>

                    </tr>
                  ))}
                </tbody>

                <thead>
                  <tr>
                    <th colSpan={5} className="text-right">
                      Total
                    </th>

                    <th>₹{totalOfRate.toFixed(2)}</th>
                    <th>₹{totalOfGst.discountAmount}</th>
                    <th>₹{totalOfCGST.toFixed(2)}</th>
                    <th>₹{totalOfSGST.toFixed(2)}</th>
                    <th>₹{totalOfIGST.toFixed(2)}</th>
                    {/* <th>₹{totalOfGst.roundOff}</th> */}
                    <th>₹{totalOfTotal.toFixed(2)}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div>
          <p
            style={{
              marginTop: "20px",
              display: "inline-block",
              marginLeft: "8px",
            }}
          >
            **Amount is displayed in your base currency{" "}
            <span
              style={{
                backgroundColor: "rgb(56,138,16)",
                padding: "2px 4px",
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              INR
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default GstR2;
