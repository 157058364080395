import React from "react";
import "react-quill/dist/quill.snow.css"; // Import styles for the rich text editor
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Badge from "react-bootstrap/Badge";
import commenticon from "./commenticon.svg";

function CRMPage(props) {
  const cancle = () => {
    props.handleCancle();
  };

  return (
    <>
      <style>
        {`
          .card-group {
            margin-top: 20px;
          }
          .card {
            padding: 20px;
            margin-bottom: 10px;
          }
          .quote-number {
            font-size: 16px;
            color: #086bd5de;
          }
        
          .customer-info {
            text-align: right;
          }
          .amount {
            font-size: 16px;
            color: #333;
          }
          .customer-name {
            font-size: 14px;
            color: #666;
          }
        `}
      </style>
      <div className="">
        <div className="d-flex align-items-center mt-4">
          <div
            className="cursor-pointer d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              height: "35px",
              width: "38px",
              cursor: "pointer",
            }}
          >
            <ArrowBackIosIcon
              width={2}
              onClick={cancle}
              className="arrow-child"
            />
          </div>
          <div className="headitems ml-2">
            <h5
              className="ml-1 mb-0"
              style={{ letterSpacing: "1px", color: "#086bd5de" }}
            >
              Customer Report Management
            </h5>
          </div>
        </div>

        <div className="card-group container1">
          <div className="card">
            <div className="d-flex justify-content-between align-items-center">
              <div className="quote-number">Quote No : QU-000001</div>
              <div className="customer-info">
                <div className="amount">Total- ₹ 1,500.00</div>
                <div className="customer-name">John Doe</div>
              </div>
            </div>

            <div className="tab-pane mt-2">
            <div className="custom-card-body">
              <div className="custom-container mt-2">
                <div className="custom-form-floating">
                  <div className="textarea-comment-box mt-2">
                    <textarea
                      className="tc-textarea"
                      placeholder="Leave a comment here"
                      id="floatingTextarea"
                      style={{
                        backgroundColor: "#F6F6F6",
                        padding: "12px",
                        borderRadius: "9px",
                      }}
                      cols={100}
                    ></textarea>
                  </div>
                  <br />
                </div>
              </div>
              <button
                className="custom-btn btn btn-primary rounded-2"
                // Disable button while loading
              >
                Create
              </button>

              {/* All Comments Label */}
            </div>
            <div className="pl-4 pr-4">
              <h6>
                ALL COMMENTS{" "}
                <Badge className="btn-primary">
                  {/* {commentFetch.length}{" "} */}
                </Badge>
              </h6>
              <hr className="mb-0" />
            </div>
            <div className="comments-history custom-scrollbar">
              <ul className="list-unstyled">
                <li className="mb-4">
                  <div className="comment-section float-start">
                    <div className="comment-container">
                      <div className="float-comment-icon mr-2">
                        <div className="txn-comment-icon circle-box">
                          <img
                            src={commenticon}
                            alt="commenticon"
                            style={{
                              height: "16px",
                              width: "16px",
                              verticalAlign: "middle",
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="ms-3 mb-2 d-flex flex-wrap align-items-baseline justify-content-between">
                          <span className="mw-50">
                            <span className="text-md pe-3">
                              <b className="comment-name">Jean</b>
                            </span>
                          </span>
                          <div className="">
                            <button className="btn btn-light icon-border btn-sm">
                              <ion-icon name="trash-outline"></ion-icon>
                            </button>
                          </div>
                        </div>
                        <div className="wrapper-comment mb-2">
                          <div className="wrapper-commentbox">
                            <div className="comment-box">
                              <p>comments</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="mb-4">
                  <div className="comment-section float-start">
                    <div className="comment-container">
                      <div className="float-comment-icon mr-2">
                        <div className="txn-comment-icon circle-box">
                          <img
                            src={commenticon}
                            alt="commenticon"
                            style={{
                              height: "16px",
                              width: "16px",
                              verticalAlign: "middle",
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="ms-3 mb-2 d-flex flex-wrap align-items-baseline justify-content-between">
                          <span className="mw-50">
                            <span className="text-md pe-3">
                              <b className="comment-name">Jean</b>
                            </span>
                          </span>
                          <div className="">
                            <button className="btn btn-light icon-border btn-sm">
                              <ion-icon name="trash-outline"></ion-icon>
                            </button>
                          </div>
                        </div>
                        <div className="wrapper-comment mb-2">
                          <div className="wrapper-commentbox">
                            <div className="comment-box">
                              <p>comments</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="mb-4">
                  <div className="comment-section float-start">
                    <div className="comment-container">
                      <div className="float-comment-icon mr-2">
                        <div className="txn-comment-icon circle-box">
                          <img
                            src={commenticon}
                            alt="commenticon"
                            style={{
                              height: "16px",
                              width: "16px",
                              verticalAlign: "middle",
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="ms-3 mb-2 d-flex flex-wrap align-items-baseline justify-content-between">
                          <span className="mw-50">
                            <span className="text-md pe-3">
                              <b className="comment-name">Jean</b>
                            </span>
                          </span>
                          <div className="">
                            <button className="btn btn-light icon-border btn-sm">
                              <ion-icon name="trash-outline"></ion-icon>
                            </button>
                          </div>
                        </div>
                        <div className="wrapper-comment mb-2">
                          <div className="wrapper-commentbox">
                            <div className="comment-box">
                              <p>comments</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="mb-4">
                  <div className="comment-section float-start">
                    <div className="comment-container">
                      <div className="float-comment-icon mr-2">
                        <div className="txn-comment-icon circle-box">
                          <img
                            src={commenticon}
                            alt="commenticon"
                            style={{
                              height: "16px",
                              width: "16px",
                              verticalAlign: "middle",
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="ms-3 mb-2 d-flex flex-wrap align-items-baseline justify-content-between">
                          <span className="mw-50">
                            <span className="text-md pe-3">
                              <b className="comment-name">Jean</b>
                            </span>
                          </span>
                          <div className="">
                            <button className="btn btn-light icon-border btn-sm">
                              <ion-icon name="trash-outline"></ion-icon>
                            </button>
                          </div>
                        </div>
                        <div className="wrapper-comment mb-2">
                          <div className="wrapper-commentbox">
                            <div className="comment-box">
                              <p>comments</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          </div>

        

          {/* Add more cards as needed */}
        </div>
      </div>
    </>
  );
}

export default CRMPage;
