import React, { useState, useEffect } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import NoDataImage from "../../../asset/images/nodata.jpg";
import { Container, Card, Row, Col } from "react-bootstrap";
import SalesOrderUpdate from "../SalesNew/SalesOrderUpdate";
import SalesOrderDetails from "../SalesNew/SalesOrderDetails";

function SalesOrder({ navigation }) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [SalesOrder, setSalesOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [salesOrderNumber, setOrderNumber] = useState(true);
  const [fetchedStatus, setFetchedStatus] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [newinvoiceNumber, setnewInvoice] = useState([]);
  const jwtToken = localStorage.getItem("jwtToken");

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleSalesOrderClick = () => {
    handleNavigation("SalesOrderNewButton");
  };

  const fetchSalesOrder = () => {
    $("#DataTables_Table_0").DataTable();
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchSalesOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSalesOrder(data.responseMessage);
          setLoading(false);
        } else {
          console.error("Failed to fetch SalesOrder:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error fetching SalesOrder:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();

      });
  };

  useEffect(() => {
    fetchSalesOrder();
  }, []);

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this sales order!"
    );

    if (userConfirmed) {
      // Send a DELETE request to your backend API
      fetch(BASE_URL + `/deleteBySalesOrderNumber/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      })
        .then((response) => {
          if (response.status === 200) {
            setSalesOrder((prevSales) =>
              prevSales.filter((salesOrder) => salesOrder.salesOrderNumber !== id)
            );
            swal({
              icon: "success",
              title: "Sales Order Deleted Successfully",
            });
            setLoading(true);
            fetchSalesOrder();
          } else {
            console.error("Failed to delete Sales Order");
            setLoading(false)
          }
        })
        .catch((error) => {
          console.error("Error deleting Sales Order:", error);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Sales Order deletion canceled!");
    }
  };

  const handleInvoice = (salesOrderNumber, customerId) => {
    setnewInvoice(salesOrderNumber);
    setCustomerId(customerId);
    setShowDetails(true);
    setShowCard(false);
    setShowUpdate(false);
  };

  const handleEdit = (salesOrderNumber, status) => {
    setFetchedStatus(status);
    setOrderNumber(salesOrderNumber);
    fetchSalesOrder();
    setShowCard(false);
    setShowUpdate(true);
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
  const handleCancelUpdate = () => {
    fetchSalesOrder();
    setShowUpdate(false);
    setShowCard(true);
  };
  const handleCancelDetails = () => {
    setShowDetails(false);
    setShowCard(true);
    setShowUpdate(false);
    fetchSalesOrder();
  };

  return (
    <div className="container-fluid" style={{ backgroundColor: "" }}>
      {!showUpdate && !showDetails && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              Sales Order
            </div>
            <div className="d-flex">
              <div className="">
                <button
                  className="btn btn-primary px-1 py-2 new-purchase"
                  onClick={handleSalesOrderClick}
                >
                  + New SalesOrder
                </button>
              </div>
              <div className="ml-2 px-1 py-2 icon-dot">
                <i
                  className="fa fa-ellipsis-v "
                  style={{ fontSize: 18, color: "gray" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <Container fluid={true}>
            <Row>
              <Col>
                <Card>
                  {SalesOrder && SalesOrder.length > 0 ? (
                    <>
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Date
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Sales order
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Reference
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Customer name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Expected shipment date
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Total
                                      </th>

                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody className="text1">
                                    {SalesOrder.map((salesOrder) => (
                                      <tr
                                        role="row"
                                        className="odd"
                                        key={salesOrder.id}
                                      >
                                        <td>{salesOrder.salesOrderDate}</td>

                                        <td className="sorting_1">
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleInvoice(
                                                salesOrder.salesOrderNumber,
                                                salesOrder.customerId
                                              )
                                            }
                                          >
                                            {salesOrder.salesOrderNumber}
                                          </a>
                                        </td>
                                        <td>{salesOrder.reference}</td>
                                        <td>{salesOrder.customerName}</td>
                                        <td>
                                          {salesOrder.expectedShipmentDate}
                                        </td>
                                        <td>₹{salesOrder.total}</td>

                                        <td>
                                          <button
                                            className="btn icon-border btn-sm mr-2"
                                            onClick={() =>
                                              handleEdit(
                                                salesOrder.salesOrderNumber, salesOrder.status
                                              )
                                            }
                                          >
                                            <i className="fa fa-edit text1"></i>
                                          </button>
                                          <button
                                            className="btn icon-border btn-sm"
                                            onClick={() =>
                                              handleDelete(salesOrder.salesOrderNumber)
                                            }
                                          >
                                            <i className="fa fa-trash text1"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="body">
                      <div className="table-responsive1">
                        <div
                          id="DataTables_Table_0_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                id="DataTables_Table_0"
                                role="grid"
                                aria-describedby="DataTables_Table_0_info"
                              >
                                <thead className="heading-text">
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                      aria-label="Date: activate to sort column descending"
                                    >
                                      Date
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Type: activate to sort column ascending"
                                    >
                                      Sales order
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Amount BTC: activate to sort column ascending"
                                    >
                                      Reference
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="BTC Remaining: activate to sort column ascending"
                                    >
                                      Customer name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Expected shipment date
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="DataTables_Table_0"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Price: activate to sort column ascending"
                                    >
                                      Total
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                              <h6 className="text-center">
                                <img
                                  src={NoDataImage}
                                  style={{ width: "50%", height: "50%" }}
                                  alt="No Data Logo"
                                />{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {showUpdate && (
        <SalesOrderUpdate
          salesOrderNumber={salesOrderNumber}
          fetchedStatus={fetchedStatus}
          handleCancel={handleCancelUpdate}
        />
      )}

      {showDetails && (
        <SalesOrderDetails
          custId={customerId}
          newinvoiceNumber={newinvoiceNumber}
          handleCancel={handleCancelDetails}
        />
      )}
    </div>
  );
}

export default SalesOrder;
