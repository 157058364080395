import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./GetPaid.css";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";

export default function CreditNoteDetails({ navigation }) {
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancelClick = () => {
    handleNavigation("reports");
  };
  const [customerData, setCustomerData] = useState([]);
  const [totalLessThan15, setTotalLessThan15] = useState("");
  const [totalLessThan30, setTotalLessThan30] = useState("");
  const [totalLessThan45, setTotalLessThan45] = useState("");
  const [totalMoreThan45, setTotalMoreThan45] = useState("");
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const jwtToken = localStorage.getItem("jwtToken");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get the encrypted uniqueId from session storage

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchCreditNotes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage;
          setCustomerData(Data);
         
          setLoading(false);
        } else {
          console.error("Failed to fetch invoices:", data.responseMessage);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);

  const [itemData, setItemData] = useState({
    organizationName: "",
    companyName: "",
  });
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          // const fetchid = portfolioItem.id;
          // setId(fetchid);

          setItemData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            uniqueId: portfolioItem.uniqueId,
          });
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={handleCancelClick}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5 style={{ letterSpacing: "1px", color: "#086bd5de" }}>
            Credit Note Details
          </h5>
        </div>
      </div>
      <div
        className="my-3 mx-0"
        style={{ height: "100%", padding: "30px", backgroundColor: "#fff" }}
      >
        <div className="head-container mb-5 d-flex flex-column justify-content-center align-items-center">
          <h5 style={{ fontWeight: "normal" }}>{itemData.companyName}</h5>
          <h4 style={{ fontWeight: "normal" }}>Credit Note Details</h4>
          <p className="mb-1">Basis: Accrual</p>
          {/* <p style={{ fontWeight: "normal" }}>As of 30/11/2023</p> */}
          <div className="container mt-2">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Credit Date</th>
                  <th>Credit Note#</th>
                  <th>Customer Name</th>
                  <th>Credit Note Amount</th>
                  <th>Balance Amount</th>
                </tr>
              </thead>
              <tbody>
                {customerData && customerData.length > 0 ? (
                  customerData.map((data) => (
                    <tr key={data.customerName}>
                    <td> {data.status}</td>
                    <td> {data.creditNoteDate}</td>
                    <td> {data.creditNoteNumber}</td>
                      <td>
                        <a href="#">{data.customerName}</a>
                      </td>
                      
                      <td>₹ {data.total}</td>
                      <td>₹ {data.total}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan="5">No data available</td>
                  </tr>
                )}
              </tbody>
              <thead>
                <tr>
                  <th>Total</th>
                  <th></th>
                  <th></th>
                  <th>₹ </th>
                  <th>₹</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>

        <div>
          <p
            style={{
              marginTop: "20px",
              display: "inline-block",
              marginLeft: "8px",
            }}
          >
            **Amount is displayed in your base currency{" "}
            <span
              style={{
                backgroundColor: "rgb(56,138,16)",
                padding: "2px 4px",
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              INR
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
