import React, { useState } from "react";
import { BASE_URL } from "../CommonApi";
import { Container, Form } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "react-datepicker/dist/react-datepicker.css";
import "./temp.css";
import template from "./../../../asset/images/template.PNG";
import template1 from "./../../../asset/images/template1.PNG";
import Modal from "react-bootstrap/Modal";

function Template({ navigation }) {

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancel = () => {
    handleNavigation("Setting");
  };

  const [selectedImage, setSelectedImage] = useState(null);

  // const handleImageClick = (src) => {
  //   setSelectedImage(src);
  // };

  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleSelectClick = (template) => {
    setSelectedTemplate(template);
    console.log("Selected Template : ", template);
  
    const jwtToken = localStorage.getItem("jwtToken");
    const apiUrl1 = BASE_URL + "/insertUserProfile";
      
    const formData = new FormData();
    formData.append("userId", "");
    formData.append("uniqueId", "");
    formData.append("templateFormat", template);
    
    const requestOptions1 = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formData,
    };
  
    fetch(apiUrl1, requestOptions1)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Response was non-200");
        }
      })
      .then((data) => {
        console.log("Data : ", data);
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  };
  

  const [showItemModal, setShowItemModal] = useState(false);

  const handlePreviewClick = (src) => {
    setSelectedImage(src);
    setShowItemModal(true);
  };

  const handleItemClose = () => {
    setShowItemModal(false);
  };

  return (
    <>
      <Container fluid>
        <div className="mt-3 mb-3">
          <div className="my-3">
            <div className="px-2 d-flex">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancel}
                  className="arrow-child"
                />
              </div>
              <h4
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Template
              </h4>
            </div>
          </div>
          <div className="templates-container">

            <div className="template-bg">
              <div className="template-gallery">
                {/* Template 1 */}
                <div className={`template-item ${selectedTemplate === 'template' ? 'selected' : ''}`}>
                  <img src={template} alt="Template 1" className="template-img" />
                  <span>Template 1</span>
                  <div className="template-buttons">
                    <button
                      className="template-button"
                      onClick={() => handleSelectClick('template')}
                    >
                      Select
                    </button>
                    <button
                      className="template-button preview"
                      onClick={() => handlePreviewClick('template')}
                    >
                      Preview
                    </button>
                  </div>
                </div>

                {/* Template 2 */}
                <div className={`template-item ${selectedTemplate === 'template1' ? 'selected' : ''}`}>
                  <img src={template1} alt="Template 2" className="template-img" />
                  <span>Template 2</span>
                  <div className="template-buttons">
                    <button
                      className="template-button"
                      onClick={() => handleSelectClick('template1')}
                    >
                      Select
                    </button>
                    <button
                      className="template-button preview"
                      onClick={() => handlePreviewClick('template1')}
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>


            <Modal
              show={showItemModal}
              onHide={handleItemClose}
              className="template-modal"
            >
              <div className="modal-header d-flex align-items-center pb-2 pt-2">
                <h5 className="modal-title fs-5">Template</h5>
                <button type="button" className="btn pr-0" onClick={handleItemClose}>
                  <ion-icon name="close-circle-outline" className="close-icon"></ion-icon>
                </button>
              </div>

              <Modal.Body>
                <div className="modal-image-container">
                  {selectedImage === 'template1' &&
                    <img
                      src={template1}
                      alt="Selected Template"
                      className="modal-template-img"
                    />
                  }
                  {selectedImage === 'template' &&
                    <img
                      src={template}
                      alt="Selected Template"
                      className="modal-template-img"
                    />
                  }
                </div>
              </Modal.Body>
            </Modal>

          </div>
        </div>
      </Container>
    </>
  );
}

export default Template;
