import React, { useState, useEffect,useRef } from "react";
import "react-quill/dist/quill.snow.css"; // Import styles for the rich text editor
import { AES, enc } from "crypto-js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./balancesheet.css";
import { BASE_URL } from "../CommonApi";
import ReactToPrint from "react-to-print";
import Select, { components } from "react-select"; // Import components from react-select
import DatePicker from "react-datepicker"; // Import DatePicker for manual date selection
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles

function BalanceSheet({navigation}) {
  

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancelClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
    handleNavigation("reports")
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };
 
  const [balanceSheet, setBalanceSheet] = useState([]);
  const jwtToken = localStorage.getItem("jwtToken");
  const [loading, setLoading] = useState(true);
  const componentRef = useRef();
  const options = [
    { value: "today", label: "Today" },
    { value: "this_week", label: "This week" },
    { value: "this_month", label: "This month" },
    { value: "this_year", label: "This year" },
    { value: "this_quarter", label: "This Quarter" },
    { value: "yesterday", label: "Yesterday" },
    { value: "previous_week", label: "Previous Week" },
    { value: "previous_month", label: "Previous Month" },
    { value: "previous_quarter", label: "Previous Quarter" },
    { value: "previous_year", label: "Previous Year" },
    { value: "custom", label: "Custom" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(encryptedUniqueId, "1234567812345678").toString(enc.Utf8);

  const formatDate1 = (date) => {
    return date.toLocaleDateString("en-GB").replace(/\//g, "-"); // DD-MM-YYYY format
  };
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
  };
 
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  useEffect(() => {
    if (selectedOption) {
      const today = new Date();
      switch (selectedOption.value) {
        case "today":
          setFromDate(today);
          setToDate(today);
          break;
        case "yesterday":
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          setFromDate(yesterday);
          setToDate(yesterday);
          break;
        case "this_week":
          const sunday = new Date(today);
          sunday.setDate(today.getDate() - today.getDay());
          const saturday = new Date(today);
          saturday.setDate(today.getDate() + (6 - today.getDay()));
          setFromDate(sunday);
          setToDate(saturday);
          break;
        case "previous_week":
          const prevSunday = new Date(today);
          prevSunday.setDate(today.getDate() - today.getDay() - 7);
          const prevSaturday = new Date(today);
          prevSaturday.setDate(today.getDate() - today.getDay() - 1);
          setFromDate(prevSunday);
          setToDate(prevSaturday);
          break;
        case "this_month":
          const firstDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          const lastDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );
          setFromDate(firstDayOfMonth);
          setToDate(lastDayOfMonth);
          break;
        case "previous_month":
          const prevMonthStart = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
          );
          const prevMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          );
          setFromDate(prevMonthStart);
          setToDate(prevMonthEnd);
          break;
        case "this_quarter":
          const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
          const quarterStartDate = new Date(
            today.getFullYear(),
            quarterStartMonth,
            1
          );
          const quarterEndDate = new Date(
            today.getFullYear(),
            quarterStartMonth + 3,
            0
          );
          setFromDate(quarterStartDate);
          setToDate(quarterEndDate);
          break;
        case "previous_quarter":
          const prevQuarterStartMonth =
            Math.floor((today.getMonth() - 3) / 3) * 3;
          const prevQuarterStartDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth,
            1
          );
          const prevQuarterEndDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth + 3,
            0
          );
          setFromDate(prevQuarterStartDate);
          setToDate(prevQuarterEndDate);
          break;
        case "this_year":
          const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
          const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
          setFromDate(firstDayOfYear);
          setToDate(lastDayOfYear);
          break;
        case "previous_year":
          const prevYearStart = new Date(today.getFullYear() - 1, 0, 1);
          const prevYearEnd = new Date(today.getFullYear() - 1, 11, 31);
          setFromDate(prevYearStart);
          setToDate(prevYearEnd);
          break;
          case "custom":
          setFromDate(today);
          setToDate(today);
          break;
        default:
          setFromDate(null);
          setToDate(null);
          break;
      }

      // Call fetchStatement1 with the appropriate arguments
      // fetchStatement1(custID, fromDate, toDate);
    }
  }, [selectedOption]); // This effect runs when selectedOption state changes

  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      fetchStatement1( fromDate, toDate);
    }
  }, [fromDate, toDate ]); // This effect runs when either fromDate or toDate changes

  const [dataPost, setDataPost] = useState({
    uniqueId: decryptedUniqueId,
    fromDate: formatDate1(fromDate),
    toDate: formatDate1(toDate),
  });
  useEffect(() => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL+`/balanceSheet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(dataPost), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        const Data = data

        setBalanceSheet(Data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  }, []);
  const [itemData, setItemData] = useState({
    organizationName: "",
    companyName: "",
  });
 
  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          // const fetchid = portfolioItem.id;
          // setId(fetchid);

          setItemData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            name: portfolioItem.name,
            role: portfolioItem.role,

            uniqueId: portfolioItem.uniqueId,
          });

          console.log("itemDaata::", itemData);
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchStatement1 = (fromDate,toDate) => {
    fetch(BASE_URL + `/balanceSheet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({uniqueId: decryptedUniqueId,fromDate: formatDate1(fromDate), toDate: formatDate1(toDate),}), // Send uniqueId in the request body
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "success") {
        const Data = data

        setBalanceSheet(Data);
        setLoading(false);
      } else {
        console.error("Failed to fetch invoices:", data.responseMessage);
        setLoading(false);
      }
      setLoading(false);
    })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };
 
  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div class="center-div">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    );
  }
 
  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      border: "1px solid #ccc",
      borderRadius: "4px",
      paddingLeft: "6px",
      width: "100%",
      maxWidth: "400px", // Ensure responsiveness
      boxSizing: "border-box",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: "400px",
    }),
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <span className="custom-control-icon">📅</span>
      {children}
    </components.Control>
  )

 


  return (
    <>
    <>
    <style>
        {`
          .custom-datepicker-wrapper {
            position: relative;
            display: inline-block;
            width: 200px;
          }

          .custom-datepicker-calendar {
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          .react-datepicker__input-container {
            width: 100%;
          }

          .react-datepicker__input-container input {
            width: 100%;
            height: 40px;
            padding: 0 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }

          .custom-label {
            display: block;
            margin-bottom: 5px;
            font-size: 16px;
            color: #333;
          }

          .custom-date-picker {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        `}
      </style>

      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={handleCancelClick}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5 style={{ letterSpacing: "1px", color: "#086bd5de" }}>
           Balance Sheet
          </h5>
        </div>
      </div>
      <div className="balance-sheet-section my-3 mx-0" style={{height: "100%", padding: "30px", backgroundColor: "#fff"}}>
      <div className="d-flex justify-content-between mt-1 mb-1 ml-3">
          <div className="d-flex align-items-center">
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={options}
            styles={customStyles}
            components={{ Control: CustomControl }}
          />
          </div>
          {selectedOption.value === "custom" && (
            <div className="d-flex align-items-center ml-auto">
             <div className="custom-date-picker">
                <label htmlFor="from-date" className="custom-label mr-2">
                  From Date:
                </label>{" "}
                <DatePicker
                  id="from-date"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                  style={{backgroundColor:"white"}}
                />
              </div>
              <div className="custom-date-picker">
                <label htmlFor="to-date" className="custom-label mr-2">
                  To Date:
                </label>
                <DatePicker
                  id="to-date"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                />
              </div>
            </div>
          )}
          <div className="d-flex align-items-center">
            <ReactToPrint
              trigger={() => (
                <span
                  className="input-group-text"
                  id="pdf-icon"
                  style={{
                    backgroundColor: "#F6F6F6",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  📑
                </span>
              )}
              content={() => componentRef.current}
            />{" "}
          </div>
        </div>
        <div ref={componentRef} style={{backgroundColor:"white"}}>
      <div  className="head-container mb-5 d-flex flex-column justify-content-center align-items-center">
        <h5 style={{ fontWeight: "normal" }}>{itemData.companyName}</h5>
        <h4 style={{ fontWeight: "normal" }}>Balance Sheet</h4>
        <p style={{ fontWeight: "normal" }}>
            FromTo {formatDate(fromDate)} - ToDate {formatDate(toDate)}
          </p>
      </div>
      <table className="balance-sheet-table mb-3 mx-auto" style={{width: "75%"}}>
        <colgroup>
        <col span="1" style={{width: "70%"}} />
        <col span="1" style={{width: "30%"}} /> 
        </colgroup>
        <thead>
        <tr style={{borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd"}}>
                <td className="text-uppercase">account</td>
                <td className="text-uppercase text-end">total</td>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-bold">Assets</td>
            <td className="text-end"></td>
          </tr>
          <tr>
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;Current Assets</td>
            <td className="text-end"></td>
          </tr>
          <tr>
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cash</td>
            <td className="text-end"></td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Petty Cash</td>
            <td className="text-primary text-end">{balanceSheet.PettyCash}</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total For Cash</td>
            <td className="text-end">{balanceSheet.TotalForCash}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accounts Receivable</td>
            <td className="text-primary text-end">{balanceSheet.AccountsReceivable}</td>
          </tr>
          <tr>
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Other Current Assets</td>
            <td className="text-end"></td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advance Tax</td>
            <td className="text-primary text-end">{balanceSheet.AdvanceTax}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Employee Advance</td>
            <td className="text-primary text-end">{balanceSheet.EmployeeAdvance}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Input Tax Credits</td>
            <td className="text-primary text-end">0.00</td>
          </tr>
          <tr>
            <td className="text-semi-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Input CGST</td>
            <td className="text-primary text-end">0.00</td>
          </tr>
          <tr>
            <td className="text-semi-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Input IGST</td>
            <td className="text-primary text-end">0.00</td>
          </tr>
          <tr>
            <td className="text-semi-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Input SGST</td>
            <td className="text-primary text-end">0.00</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Totals For Input 
            Tax Credits</td>
            <td className="text-primary text-end">0.00</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Prepaid Expenses</td>
            <td className="text-primary text-end">0.00</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reverse Charge Tax Input But Not Due</td>
            <td className="text-primary text-end">0.00</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total For Other Current Assets</td>
            <td className="text-end">{balanceSheet.TotalforOtherCurrentassets}</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;Total For Current Assets</td>
            <td className="text-end">{balanceSheet.TotalforCurrentAssets}</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">Total For Assets</td>
            <td className="text-end">{balanceSheet.TotalforAssets}</td>
          </tr>
          <tr>
            <td className="text-bold">Liabilities And Equities</td>
            <td className="text-end"></td>
          </tr>
          <tr>
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;Liabilities</td>
            <td className="text-end"></td>
          </tr>
          <tr>
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current Liabilities</td>
            <td className="text-end"></td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accounts Payable</td>
            <td className="text-end text-primary">{balanceSheet.AccountPaybles}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Employee Reimbursements</td>
            <td className="text-end text-primary">{balanceSheet.EmployeeReimbursements}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Opening Balance Adjustments</td>
            <td className="text-end text-primary">{balanceSheet.OpeningBalanceAdjustments}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TDS Payable</td>
            <td className="text-end text-primary">{balanceSheet.TDSPayble}</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total For Current Liabilities</td>
            <td className="text-end">{balanceSheet.TotalforCurrentLiabilities}</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;Total For Liabilities</td>
            <td className="text-end">{balanceSheet.TotalforLiabilities}</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;Equities</td>
            <td className="text-end"></td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Capital Stock</td>
            <td className="text-primary text-end">{balanceSheet.CapitalStock}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Distributions</td>
            <td className="text-primary text-end">{balanceSheet.Distribution}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dividends Paid</td>
            <td className="text-primary text-end">{balanceSheet.DividendsPaid}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Owner's Equity</td>
            <td className="text-primary text-end">{balanceSheet.OwnersEquity}</td>
          </tr>
          <tr>
            <td className="text-bold text-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current Years Earnings</td>
            <td className="text-primary text-end">{balanceSheet.CurrentYearEarnings}</td>
          </tr>
          <tr>
            <td className="text-semi-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Retained Earnings</td>
            <td className="text-end">0</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">&nbsp;&nbsp;&nbsp;&nbsp;Total For Equities</td>
            <td className="text-end">{balanceSheet.TotalforEquities}</td>
          </tr>
          <tr className="border-bottom">
            <td className="text-bold">Total For Liabilities Equities</td>
            <td className="text-end">{balanceSheet.TotalforLiabilitiesEquities}</td>
          </tr>
        </tbody>
      </table>
      </div>
      <div>
        <p style={{
          fontSize: "12px",
          marginTop: "20px",
          display: "inline-block",
          marginLeft: "8px",
        }}>**Amount is displayed in your base currency <span style={{backgroundColor: "rgb(56,138,16)",padding: "2px 4px",fontWeight: "600", fontSize: "12px"}}>INR</span></p>
      </div>
      </div>
      </>
    </>
  );
}

export default BalanceSheet;
