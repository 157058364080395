import React from "react";

import { Container, Row, Col, Card } from "react-bootstrap";
class ManageServerCard extends React.Component {
    render() {
        const { navigation, onCompose } = this.props;
        return (
            <div className="col-lg-12">
                {/* <div className="card"> */}
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <div className="table-responsive">
                                    <table class="table table-bordered table-striped mb-0">
                                        <thead style={{ fontSize: "12px" }}>
                                            <tr>
                                                <th scope="col">SR NO</th>
                                                <th scope="col">NAME</th>
                                                <th scope="col">LINK</th>
                                                <th scope="col">LOGIN ID</th>
                                                <th scope="col">PASSWORD</th>
                                                <th scope="col">PURCHASE DATE</th>
                                                <th scope="col">PURCHASE AMOUNT</th>
                                                <th scope="col">EXPIRY DATE</th>
                                                <th scope="col">ACTION UNIT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Miss. Sumaiyya Mansur</td>
                                                <td><a href="www.example.com">www.example.com</a></td>
                                                <td>yash</td>
                                                <td>123</td>
                                                <td>2023-07-29</td>
                                                <td>355</td>
                                                <td>2023-07-30</td>
                                                <td>  <button
                                                    className="btn btn-primary btn-sm mr-2"
                                                    onClick={() => this.handleEdit(2)}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => this.handleDelete(2)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {/* Previous and Next buttons */}
                    <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-primary mx-1" onClick={this.handlePrevious}>
                            Previous
                        </button>
                        <button className="btn btn-primary mx-1" onClick={this.handleNo}>
                            1
                        </button>
                        <button className="btn btn-primary mx-1" onClick={this.handleNext}>
                            Next
                        </button>
                    </div>
                </Container>
            </div>
        );
    }
}
export default ManageServerCard
