import React, { useState, useEffect,useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import "../../../asset/css/inbox.css";
import { Container, Card, Row, Col } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import { BASE_URL } from "../CommonApi";
import "../../../Components/Apps/BankAccCard.css";
import BankUpdate from "./BankUpdate";
import NoDataImage from "../../../asset/images/nodata.jpg";
import * as XLSX from "xlsx"; // Import the xlsx library

function BankAcc({ navigation }) {
  const [showCard, setShowCard] = useState(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const btnRef = useRef();

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleBankClick = () => {
    handleNavigation("BankAccNew");
  };

  const handleCancelNew = () => {
    setShowCard(true);
    setShowUpdate(false);
    fetchDataAndInitializeDataTable();
  };

  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);

  const [postData, setPostData] = useState({
    uniqueId: decryptedUniqueId,
  });

  const [bankData, setBankData] = useState([]);
  const [itemId, setItemID] = useState(true);
  const [loading, setLoading] = useState(true);

  const fetchDataAndInitializeDataTable = () => {
    $("#DataTables_Table_0").DataTable();
    const jwtToken = localStorage.getItem("jwtToken");

    const apiUrl = BASE_URL + "/fetchBanks";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("POST request failed");
        }
      })
      .then((responseData) => {
        setBankData(responseData.responseMessage);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        $("#DataTables_Table_0").DataTable();
      });
  };

  useEffect(() => {
    fetchDataAndInitializeDataTable();
  }, []);

  const handleEdit = (id) => {
    setItemID(id);
    fetchDataAndInitializeDataTable();
    setShowCard(false);
    setShowUpdate(true);
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure? Once deleted, you will not be able to recover this bank data!"
    );
    const jwtToken = localStorage.getItem("jwtToken");

    if (userConfirmed) {
      fetch(BASE_URL + `/deleteBank/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setBankData((prevBankData) =>
              prevBankData.filter((data) => data.id !== id)
            );

            swal({
              icon: "success",
              title: "Bank Data Deleted Successfully",
            });
            setLoading(true);
            fetchDataAndInitializeDataTable();
          } else {
            console.error("Failed to delete bank", id);
          }
        })
        .catch((error) => {
          console.error("Error deleting bank:", error);
        });
    } else {
      // If the user clicks "Cancel", show a cancellation message
      alert("Bank data deletion canceled!");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="page-content">
          <div className="center-div">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  const downloadBankInXl = () => {
    // Convert estimate data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(bankData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BankAcc");

    // Export the workbook to Excel file
    XLSX.writeFile(workbook, "BankAcc.xlsx");
  };
  return (
    <div className="container-fluid">
      {!showUpdate && (
        <div className="item-page">
          <div className="d-flex justify-content-between">
            <div
              className="mt-2"
              style={{
                color: "#3C7BD4",
                fontSize: 20,
                fontWeight: "600",
                wordWrap: "break-word",
                textAlign: "left",
              }}
            >
              All Banks
            </div>
            <div className="d-flex">
              <div className="">
                <button
                  className="btn btn-primary px-1 py-2 new-purchase"
                  onClick={handleBankClick}
                >
                  + New Bank
                </button>
              </div>
              <div className="estimatess-dropdown-container d-flex align-items-end">
                <button
                  className="ml-2 px-1 py-2 icon-dot"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "white",
                  }}
                  onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown on click
                  ref={btnRef} // Ref for icon button
                >
                  <i
                    className="fa fa-ellipsis-v"
                    style={{ fontSize: 18, color: "gray" }}
                  ></i>
                  {dropdownOpen && (
                    <div className="estimates-dropdown">
                      <ul
                        style={{
                          listStyleType: "none",
                          margin: 0,
                          padding: "3px",
                          width: "170px",
                        }}
                      >
                        <li
                          className="estimates-option1"
                          onClick={downloadBankInXl}
                        >
                          <ion-icon
                            name="download-outline"
                            size="small"
                          ></ion-icon>
                          <span className="pl-2" >Export Bank</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCard && (
        <div className="container-fluid">
          <div className="page-content">
            <Container fluid={true}>
              <Row>
                <Col>
                  <Card>
                    {bankData && bankData.length > 0 ? (
                      <>
                        <div className="body">
                          <div className="table-responsive1">
                            <div
                              id="DataTables_Table_0_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                    id="DataTables_Table_0"
                                    role="grid"
                                    aria-describedby="DataTables_Table_0_info"
                                    style={{ background: "transparent" }}
                                  >
                                    <thead className="heading-text">
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Date: activate to sort column descending"
                                        >
                                          Bank name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                        >
                                          Account type
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Amount BTC: activate to sort column ascending"
                                        >
                                          Account name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="BTC Remaining: activate to sort column ascending"
                                        >
                                          Currency
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="DataTables_Table_0"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Price: activate to sort column ascending"
                                        ></th>
                                      </tr>
                                    </thead>
                                    <tbody className="text1">
                                      {bankData.map((data) => (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={data.id}
                                        >
                                          <td className="sorting_1">
                                            <strong>{data.bankName}{" "}{data.primaryAccount === "YES" && <span style={{color:"green"}}>(Primary)</span>}</strong>
                                          </td>
                                          <td>{data.accountType}</td>
                                          <td>{data.name}</td>
                                          <td>{data.currency}</td>
                                          <td>
                                
                                            <button
                                              className="btn icon-border btn-sm mr-2"
                                              onClick={() =>
                                                handleEdit(data.id)
                                              }
                                            >
                                              <i className="fa fa-edit text1"></i>
                                            </button>
                                            <button
                                              className="btn px-2  icon-border btn-sm"
                                              onClick={() =>
                                                handleDelete(data.id)
                                              }
                                            >
                                              <i className="fa fa-trash text1"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="body">
                        <div className="table-responsive1">
                          <div
                            id="DataTables_Table_0_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table table-hover js-basic-example dataTable table-custom mb-0 no-footer"
                                  id="DataTables_Table_0"
                                  role="grid"
                                  aria-describedby="DataTables_Table_0_info"
                                >
                                  <thead className="heading-text">
                                    <tr role="row">
                                      <th
                                        className="sorting_asc"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Date: activate to sort column descending"
                                      >
                                        Bank name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Type: activate to sort column ascending"
                                      >
                                        Account type
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Amount BTC: activate to sort column ascending"
                                      >
                                        Account name
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="BTC Remaining: activate to sort column ascending"
                                      >
                                        Currency{" "}
                                      </th>
                                      <th
                                        className="sorting"
                                        tabIndex="0"
                                        aria-controls="DataTables_Table_0"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Price: activate to sort column ascending"
                                      ></th>
                                    </tr>
                                  </thead>
                                  <tbody></tbody>
                                </table>
                                <h6 className="text-center">
                                  <img
                                    src={NoDataImage}
                                    style={{ width: "50%", height: "50%" }}
                                    alt="No Data Logo"
                                  />{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      {showUpdate && <BankUpdate id={itemId} handleCancle={handleCancelNew} />}
    </div>
  );
}

export default BankAcc;
