import React, { useState, useEffect } from "react";
import { AES, enc } from "crypto-js";
import swal from "sweetalert";
import { BASE_URL } from "../CommonApi";
import "./OnOffSwitch.css";
import { Container, Form } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TruckRemove } from "iconic-react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import Alert from "@mui/material/Alert";

function Taxes({ navigation }) {
  const [showBank, setShowBank] = useState(false);
  const [GSTSettings, setGSTsettings] = useState(true);
  const [Preference, setPreference] = useState(false);
  const [checkView, setCheckView] = useState(false);
  const [checkView1, setCheckView1] = useState(false);
  const [taxId, setTaxId] = useState(null);
  const [taxData, setTaxData] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [gstData, setGSTData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIntraTax, setSelectedIntraTax] = useState("0");
  const [selectedInterState, setSelectedInterState] = useState("0");
  const [selectedGst, setSelectedGst] = useState("Excluding")
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");

  const [startDate, setStartDate] = useState(new Date());
  const [dateValue, setDateValue] = useState("");

  const normalDateFormat = (date) => {
    if (date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (Number(date.getMonth()) + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    }
    return "";
  };

  const [isChecked, setIsChecked] = useState(false);
  const [businessesRegistered, setBusinessesRegistered] = useState("NO");
  const [showInvDate, setShowInvDate] = useState(true);

  const [formData, setFormData] = useState({
    businessRegistered: "NO",
    gstin: "",
    gstRegisteredOn: normalDateFormat(startDate),
    compositionScheme: "",
    reverseCharge: "",
    importExport: "",
    digitalServices: "",
    compositionSchemePercentage: "",
    customDutyTrackingAccount: "",
  });

  useEffect(() => {
    getGSTData();
    getTaxRate();
  }, []);
  useEffect(() => {
    const formattedDate = normalDateFormat(startDate);
    setDateValue(formattedDate);
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstRegisteredOn: formattedDate,
    }));
  }, [startDate]);

  const handleDate = (date) => {
    setStartDate(date);
  };

  const [taxRate, setTaxRate] = useState({
    uniqueId: decryptedUniqueId,
    taxName: "",
    rate: 0,
  });

  const handleRadioChange = (e, value) => {
    setFormData({
      ...formData,
      compositionSchemePercentage: value,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTaxRate({ ...taxRate, [name]: value });
  };

  const handleSwitchToggle = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    setShowGST(!showGST);
    const newBusinessesRegistered = newCheckedState ? "YES" : "NO";
    setBusinessesRegistered(newBusinessesRegistered);
    setFormData((prevFormData) => ({
      ...prevFormData,
      businessRegistered: newBusinessesRegistered,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(BASE_URL + "/createTaxRate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(taxRate),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          closePopup();
          swal({
            icon: "success",
            title: "Tax Rate Added Successfully",
          });
          getTaxRate();
        } else {
          swal({
            icon: "success",
            title: "Failed To Create Tax Rate",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          icon: "success",
          title: "An Error Occured While Creating Tax RAte ",
        });
      });
  };

  const handleTaxRate = () => {
    setShowPopup(false);
    setShowBank(true);
    setGSTsettings(false);
    setPreference(false);
  };
  const handleTaxPreference = () => {
    setShowBank(false);
    setGSTsettings(false);
    setPreference(true);
    getTaxPreference()
  };

  const getTaxRate = () => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/getTaxRate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setInvoices(data.responseMessage);
        } else {
          console.error("Failed to fetch tax:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching tax:", error);
        setLoading(false);
      });
  };
  const getTaxPreference = () => {
    // Get the encrypted uniqueId from session storage
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    // Decrypt the uniqueId using your decryption method
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    // Fetch invoices for the decrypted uniqueId
    fetch(BASE_URL + `/fetchTaxPreference`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }), // Send uniqueId in the request body
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSelectedIntraTax(data.responseMessage.intraStateTaxPreference)
          setSelectedInterState(data.responseMessage.interStateTaxPreference)
          setSelectedGst(data.responseMessage.taxType)
        } else {
          console.error("Failed to fetch tax:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching tax:", error);
      });
  };
  const [isLoading, setIsLoading] = useState(false)
  const UpdateTaxPreference = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const bodyData = {
      uniqueId: decryptedUniqueId,
      interStateTaxPreference: selectedInterState?.value || selectedInterState,
      intraStateTaxPreference: selectedIntraTax?.value || selectedIntraTax,
      taxType: selectedGst,

    };
    const jwtToken = localStorage.getItem("jwtToken");
    setIsLoading(true)
    fetch(BASE_URL + `/updateTaxPreference`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "Tax Preference is Updated",
          });
          setIsLoading(false)
        } else {
          swal({
            icon: "error",
            title: "Failed To Update Tax Preference! ",
          });
          setIsLoading(false)
          console.error("Failed to update tax:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
      });
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this tax rate?"
    );

    if (!isConfirmed) {
      return;
      // Do nothing if the user cancels the operation
    } // Send a DELETE request to your backend API
    fetch(BASE_URL + `/deleteTaxRate/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          // Invoice deleted successfully, update the UI
          setInvoices((prevInvoices) =>
            prevInvoices.filter((invoice) => invoice.id !== id)
          );
          swal({
            icon: "success",
            title: "Tax Deleted Successfully",
          });
        } else {
          console.error("Failed to delete Tax Rate ");
        }
      })
      .catch((error) => {
        console.error("Error Deleting Tax Rate:", error);
      });
  };

  const handleEditSubmit = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const updatedData = {
      taxName: taxData.taxName,
      rate: taxData.rate,
    };

    fetch(BASE_URL + `/updateTaxRate/${taxId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        ...updatedData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "GST Updated Successfully",
          });
        } else {
          swal({
            icon: "error",
            title: "Failed To Update GST Settting",
          });
          console.error("Failed to update tax:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error updating tax:", error);
      });
  };

  const handleGSTSettings = () => {
    setEditPopup(false);
    setGSTsettings(true);
    setShowBank(false);
    setShowPopup(false);
    setPreference(false);
    getGSTData();
  };

  const [showGST, setShowGST] = useState(false);
  const [showNoGST, setShowNoGST] = useState(false);

  const handleGSTNo = () => {
    setShowNoGST(true);
    setShowGST(false);
    // setFormData({
    //   ...formData,
    //   businessRegistered: "NO",
    // });

    setFormData({
      businessRegistered: "NO",
      gstin: "",
      gstRegisteredOn: "",
      compositionScheme: "",
      reverseCharge: "",
      importExport: "",
      digitalServices: "",
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleReverseChargeChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSaveClick = () => {
    fetch(BASE_URL + "/insertGstSetting", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        ...formData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          swal({
            icon: "success",
            title: "GST Updated Successfully",
          });
          const  check = formData.businessRegistered;
          localStorage.setItem("check", check);
        } else {
          swal({
            icon: "error",
            title: "Failed To Update GST Settting",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCheckView = () => {
    setCheckView(true);
    if (checkView) {
      setCheckView(false);
    }
  };

  const getCheckView1 = () => {
    setCheckView1(true);
    if (checkView1) {
      setCheckView1(false);
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setEditPopup] = useState(false);

  const openEditPopup = (id) => {
    setTaxId(id);
    setEditPopup(true);
    fetchData(id);
    setShowBank(false);
  };

  const fetchData = (id) => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");

    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchById/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setTaxData(data.responseMessage);
        } else {
          console.error("Failed to fetch taxes by id:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        setLoading(false);
      });
  };

  const [validationMessage, setValidationMessage] = useState("");
  const [fetchedGSTName, setFetchedGSTName] = useState("");
  const validateAndFetchGstDetails = (e) => {
    setFetchedGSTName("");
    const gstNum = e.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      gstin: gstNum,
    }));
    console.log("gstin : ", gstNum);
    let newValidationMessage = "";
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/;

    if (!gstRegex.test(e.target.value)) {
      newValidationMessage += "Please enter valid GST Number.\n";
    }
    setValidationMessage(newValidationMessage);

    if (newValidationMessage) {
      window.scrollTo(0, 0); // Scroll to top
      return;
    }

    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const apiUrl = `${BASE_URL}/searchGST?gstin=${gstNum}&uniqueId=${decryptedUniqueId}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error fetching GST details: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        setFetchedGSTName(data.data.lgnm);
      })
      .catch(error => {
        console.error("API call failed:", error);
      });
  };


  const closeEditPopup = () => {
    setEditPopup(false);
    setShowBank(true);
  };

  const openPopup = () => {
    setShowPopup(true);
    setShowBank(false);
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowBank(true);
  };

  const handleUpdateInput = (e) => {
    const { name, value } = e.target;
    setTaxData((prevTaxData) => ({
      ...prevTaxData,
      [name]: value,
    }));
  };

  const handleCompositionSchemePercentageChange = (e) => {
    setFormData({
      ...formData,
      customDutyTrackingAccount: e.target.value,
    });
  };

  const optionInterStateTax = [
    { value: "0", label: "GST0 [0%]" },
    { value: "5", label: "GST5 [5%]" },
    { value: "12", label: "GST12 [12%]" },
    { value: "18", label: "GST18 [18%]" },
    { value: "28", label: "GST28 [28%]" },  ];
  const optionIntraStateTax = [
    { value: "0", label: "GST0 [0%]" },
    { value: "5", label: "GST5 [5%]" },
    { value: "12", label: "GST12 [12%]" },
    { value: "18", label: "GST18 [18%]" },
    { value: "28", label: "GST28 [28%]" },
  ];
  const handleInterStateChange = (selectedOption) => {
    setSelectedInterState(selectedOption);
  };

  const handleIntraStateChange = (selectedOption) => {
    setSelectedIntraTax(selectedOption);
  };

  const getGSTData = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    fetch(BASE_URL + `/fetchGst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const Data = data.responseMessage[0];
          setGSTData(data.responseMessage[0]);
          setFormData({
            businessRegistered: Data.businessRegistered,
            gstin: Data.gstin,
            gstRegisteredOn: Data.gstRegisteredOn,
            compositionScheme: Data.compositionScheme === "true" ? true : false,
            reverseCharge: Data.reverseCharge === "true" ? true : false,
            importExport: Data.importExport === "true" ? true : false,
            digitalServices: Data.digitalServices === "true" ? true : false,
            compositionSchemePercentage: Data.compositionSchemePercentage,
            customDutyTrackingAccount: Data.customDutyTrackingAccount,
          });
          setCheckView1(Data.importExport === "true" ? true : false);
          setIsChecked(Data.businessRegistered === "YES" ? true : false);
          setShowGST(Data.businessRegistered === "YES" ? true : false);
        } else {
          console.error("Failed to fetch GST:", data.responseMessage);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching GST:", error);
        setLoading(false);
      });
  };
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleCancel = () => {
    handleNavigation("Setting");
  };
  const handleGSTChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedGst(selectedValue)
  }

  return (
    <>
      <Container fluid>
        <div className="mt-3 mb-3">
          <div className="my-3">
            <div className="px-2 d-flex">
              <div
                className="cursor-pointer d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  height: "35px",
                  width: "38px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon
                  width={2}
                  onClick={handleCancel}
                  className="arrow-child"
                />
              </div>
              <h4
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Taxes
              </h4>
            </div>
          </div>
          <div className="templates-container">
            <div className="left-invoice">
              <div>
                <div className="text-left" style={{ cursor: "pointer" }}>
                  <div className="text-left mt-3">
                    <h6 className="hover-effect" onClick={handleGSTSettings}>
                      GST Settings
                    </h6>
                    <hr />
                    <h6 className="hover-effect" onClick={handleTaxRate}>
                      Tax Rates
                    </h6>
                    <hr />
                    <h6 className="hover-effect" onClick={handleTaxPreference}>
                      Default Tax Preference
                    </h6>
                    <hr />
                  </div>
                </div>
              </div>
            </div>

            <div className="right-invoice">
              {showBank && (
                <div className="border border-0">
                  <div className="d-flex justify-content-between my-2">
                    <h4 className="" style={{ letterSpacing: "1px" }}>
                      Tax Rates
                    </h4>
                    <button
                      className="btn  btn-primary text-white p-2"
                      onClick={openPopup}
                    >
                      + New Tax
                    </button>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Tax Name</th>
                        <th scope="col">Rate (%)</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices.map((invoice) => (
                        <tr key={invoice.id}>
                          <td>{invoice.taxName}</td>
                          <td>{invoice.rate}</td>
                          <td>
                            <button
                              className="btn text-white btn-sm mr-2"
                              style={{ backgroundColor: "#007bff" }}
                              onClick={() => openEditPopup(invoice.id)}
                            >
                              <i className="fa fa-edit"></i>
                            </button>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDelete(invoice.id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {showPopup && (
                <div className="container">
                  <div className="">
                    <div className="d-flex">
                      <h5
                        className="text-primary ml-2"
                        style={{ letterSpacing: "1px" }}
                      >
                        New Tax
                      </h5>
                    </div>
                  </div>
                  <hr />
                  <div className="radio row mg-t-15 pt-3 align-items-center mg-b-20">
                    <div className="col-md-2">
                      <label className="form-label">
                        <span>Tax Name</span>{" "}
                      </label>
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="text"
                        name="taxName"
                        value={taxRate.taxName}
                        onChange={handleInputChange}
                        style={{ width: "190px", backgroundColor: "white" }}
                      />
                    </div>
                  </div>
                  <div className="radio row mg-t-15 pt-3 align-items-center mg-b-20">
                    <div className="col-md-2">
                      <label className="form-label">
                        <span className="form-label">Rate (%)</span>{" "}
                      </label>
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="number"
                        name="rate"
                        value={taxRate.rate}
                        onChange={handleInputChange}
                        style={{ width: "190px" }}
                      />{" "}
                    </div>
                  </div>
                  <hr />
                  <button
                    className="button1 border border-1"
                    onClick={closePopup}
                  >
                    Cancel
                  </button>
                  <button
                    className="button2 btn-primary ml-2"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              )}

              {GSTSettings && (
                <div className="border border-0">
                  <div className="my-2">
                    <h4 className="" style={{ letterSpacing: "1px" }}>
                      GST Settings
                    </h4>
                  </div>
                  <hr />
                  <div className="container row mg-t-15 pt-3 my-2 align-items-center mg-b-20">
                    <div className="onoffbg d-flex justify-content-between">
                      <div>
                        <label className="form-label">
                          <span className="text-dashed-underline">
                            Is your business registered for GST?
                          </span>
                        </label>
                      </div>
                      <div className="d-flex">
                        <span className="mr-2">{isChecked ? "YES" : "NO"}</span>
                        <div
                          className={`onoffswitch ${isChecked ? "on" : "off"}`}
                          onClick={handleSwitchToggle}
                        >
                          <div className="toggle"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showGST && (
                    <div className="p-2">
                      <div className="row align-items-center mg-b-20 mb-2">
                        <div className="col-md-4">
                          <label className="form-label text-danger required mg-b-0">
                            <h6>GSTIN *</h6>
                          </label>
                        </div>
                        <div className="col-md-4">
                          <Form.Control
                            className="form-control"
                            placeholder="Enter GST Number"
                            name="gstin"
                            type="text"
                            value={formData.gstin}
                            onChange={validateAndFetchGstDetails}
                            style={{
                              padding: "12px",
                              backgroundColor: "#F6F6F6",
                              borderRadius: "7px",
                              color: "black",
                              height: "40px",
                            }}
                          />
                          {validationMessage && (
                            <Alert severity="error" className="mt-3">
                              {validationMessage.split("\n").map((message, index) => (
                                <div key={index}>{message}</div>
                              ))}
                            </Alert>
                          )}
                          <p className="ml-1 text-secondary">{fetchedGSTName || ""}</p>
                        </div>
                      </div>


                      <div className="row align-items-center my-4">
                        <div className="col-md-4">
                          <label className="form-label required mg-b-0">
                            <h6>GST Registered On :</h6>
                          </label>
                        </div>

                        <div className="col-md-4">
                          {showInvDate ? (
                            <input
                              className="form-control"
                              type="text"
                              value={formData.gstRegisteredOn}
                              onClick={(e) => {
                                setShowInvDate(false);
                                setFormData({
                                  ...formData,
                                  gstRegisteredOn: normalDateFormat(startDate),
                                });
                                handleDate(startDate);
                              }}
                              style={{
                                padding: "12px",
                                backgroundColor: "#F6F6F6",
                                borderRadius: "7px",
                                color: "black",
                                height: "40px",
                                "::placeholder": {
                                  color:
                                    "var(--Black-light-text, rgba(0, 0, 0, 0.25))",
                                },
                              }}
                              readOnly
                            />
                          ) : (
                            <div className="customDatePickerWidth">
                              <DatePicker
                                className="form-control"
                                selected={startDate}
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => handleDate(date)}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center my-4">
                        <div className="col-md-4">
                          <label className="form-label required mg-b-0">
                            <h6>Composition Scheme :</h6>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className="ckbox">
                            <input
                              type="checkbox"
                              name="compositionScheme"
                              onClick={handleCheckboxChange}
                              checked={formData.compositionScheme}
                            />
                            <span className="ml-2">
                              My business is registered for Composition Scheme.
                            </span>
                          </label>
                        </div>
                      </div>

                      {formData.compositionScheme && (
                        <div className="row align-items-center my-4">
                          <div className="col-md-4">
                            <label className="form-label mg-b-0">
                              <h6>Composition Scheme Percentage :</h6>
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div>
                              <label className="rdiobox">
                                <input
                                  className="mx-1"
                                  type="radio"
                                  name="CompositionSchemePercentage"
                                  id="onep"
                                  value="1%"
                                  checked={
                                    formData.compositionSchemePercentage ===
                                    "1%"
                                  }
                                  onChange={(e) => handleRadioChange(e, "1%")}
                                />
                                <span>1% (For Traders and Manufacturers)</span>
                              </label>
                            </div>
                            <div>
                              <label className="rdiobox mt-2">
                                <input
                                  className="mx-1"
                                  type="radio"
                                  name="CompositionSchemePercentage"
                                  id="twop"
                                  value="2%"
                                  checked={
                                    formData.compositionSchemePercentage ===
                                    "2%"
                                  }
                                  onChange={(e) => handleRadioChange(e, "2%")}
                                />
                                <span>
                                  2% (For Manufacturers - GSTN has lowered the
                                  rate for manufacturers to 1%.)
                                </span>
                              </label>
                            </div>
                            <div>
                              <label className="rdiobox mt-2">
                                <input
                                  className="mx-1"
                                  type="radio"
                                  name="CompositionSchemePercentage"
                                  id="fivep"
                                  value="5%"
                                  checked={
                                    formData.compositionSchemePercentage ===
                                    "5%"
                                  }
                                  onChange={(e) => handleRadioChange(e, "5%")}
                                />
                                <span>5% (For Restaurant sector)</span>
                              </label>
                            </div>
                            <div>
                              <label className="rdiobox mt-2">
                                <input
                                  className="mx-1"
                                  type="radio"
                                  name="CompositionSchemePercentage"
                                  id="sixp"
                                  value="6%"
                                  checked={
                                    formData.compositionSchemePercentage ===
                                    "6%"
                                  }
                                  onChange={(e) => handleRadioChange(e, "6%")}
                                />
                                <span>
                                  6% (For Suppliers of Services or Mixed
                                  Suppliers)
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row align-items-center my-4">
                        <div className="col-md-4">
                          <label className="form-label required mg-b-0">
                            <h6>Reverse Charge :</h6>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className="ckbox">
                            <input
                              type="checkbox"
                              name="reverseCharge"
                              onChange={handleCheckboxChange}
                              checked={formData.reverseCharge}
                            />
                            <span className="ml-2">
                              Enable Reverse Charge in Sales transactions.
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="row align-items-center my-4">
                        <div className="col-md-4">
                          <label className="form-label required mg-b-0">
                            <h6>Import / Export :</h6>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className="ckbox">
                            <input
                              type="checkbox"
                              name="importExport"
                              // onClick={getCheckView1}
                              onClick={() => setCheckView1(!checkView1)}
                              onChange={handleCheckboxChange}
                              checked={formData.importExport}
                            />
                            <span className="ml-2">
                              My business is involved in SEZ / Overseas Trading.
                            </span>
                          </label>
                        </div>
                      </div>

                      {checkView1 && (
                        <div className="row align-items-center my-4">
                          <div className="col-md-4">
                            <label className="form-label mg-b-0">
                              Custom Duty Tracking Account :
                            </label>
                          </div>
                          <div className="col-md-4">
                            <div>
                              <select
                                className="form-control"
                                name="customDutyTrackingAccount"
                                value={formData.customDutyTrackingAccount}
                                onChange={
                                  handleCompositionSchemePercentageChange
                                }
                              >
                                <option disabled style={{ color: "black" }}>
                                  Expense
                                </option>
                                <option value="Advertising And Marketing">
                                  Advertising And Marketing
                                </option>
                                <option value="Automobile Expense">
                                  Automobile Expense
                                </option>
                                <option value="Bad Debt">Bad Debt</option>
                                <option value="Bank Fees and Charges">
                                  Bank Fees and Charges
                                </option>
                                <option value="Consultant Expense">
                                  Consultant Expense
                                </option>
                                <option value="Contract Assets">
                                  Contract Assets
                                </option>
                                <option value="Credit Card Charges">
                                  Credit Card Charges
                                </option>
                                <option value="Depreciation And Amortisation">
                                  Depreciation And Amortisation
                                </option>
                                <option value="Depreciation Expense">
                                  Depreciation Expense
                                </option>
                                <option value="IT and Internet Expenses">
                                  IT and Internet Expenses
                                </option>
                                <option value="Janitorial Expense">
                                  Janitorial Expense
                                </option>
                                <option value="Lodging">Lodging</option>
                                <option value="Meals and Entertainment">
                                  Meals and Entertainment
                                </option>
                                <option value="Merchandise">Merchandise</option>
                                <option value="Office Supplies">
                                  Office Supplies
                                </option>
                                <option value="Other Expenses">
                                  Other Expenses
                                </option>
                                <option value="Postage">Postage</option>
                                <option value="Printing and Stationery">
                                  Printing and Stationery
                                </option>
                                <option value="Raw Materials And Consumables">
                                  Raw Materials And Consumables
                                </option>
                                <option value="Rent Expense">
                                  Rent Expense
                                </option>
                                <option value="Repairs and Maintenance">
                                  Repairs and Maintenance
                                </option>
                                <option value="Salaries and Employee Wages">
                                  Salaries and Employee Wages
                                </option>
                                <option value="Telephone Expense">
                                  Telephone Expense
                                </option>
                                <option value="Transportation Expense">
                                  Transportation Expense
                                </option>
                                <option value="Travel Expense">
                                  Travel Expense
                                </option>
                                <option value="Uncategorized">
                                  Uncategorized
                                </option>
                                <option disabled style={{ color: "black" }}>
                                  Cost Of Good Sold
                                </option>
                                <option value="Cost of Goods Sold">
                                  Cost of Goods Sold
                                </option>
                                <option value="Job Costing">Job Costing</option>
                                <option value="Labor">Labor</option>
                                <option value="Materials">Materials</option>
                                <option value="Subcontractor">
                                  Subcontractor
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row align-items-center mt-4">
                        <div className="col-md-4">
                          <label className="form-label required mg-b-0">
                            <h6>Digital Services :</h6>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <label className="ckbox">
                            <input
                              type="checkbox"
                              name="digitalServices"
                              onChange={handleCheckboxChange}
                              checked={formData.digitalServices}
                            />
                            <span className="ml-2">
                              Track sale of digital services to overseas
                              customers
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {showNoGST && ""}
                  <button
                    className="btn btnsave mt-1"
                    onClick={handleSaveClick}
                  >
                    Save
                  </button>
                </div>
              )}

              {showEditPopup && (
                <div className="container">
                  <div className="">
                    <div className="d-flex">
                      {/* <CaretLeft
                        className="icon"
                        size={26}
                        onClick={closePopup}
                      /> */}
                      <h5
                        className="text-primary ml-2"
                        style={{ letterSpacing: "1px" }}
                      >
                        Edit Tax
                      </h5>
                    </div>
                  </div>
                  <hr />
                  <div className="radio row mg-t-15 pt-3 align-items-center mg-b-20">
                    <div className="col-md-2">
                      <label className="form-label">
                        <span>Tax Name</span>{" "}
                      </label>
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="text"
                        name="taxName"
                        value={taxData.taxName}
                        onChange={handleUpdateInput}
                        style={{ width: "190px", backgroundColor: "white" }}
                      />{" "}
                    </div>
                  </div>
                  <div className="radio row mg-t-15 pt-3 align-items-center mg-b-20">
                    <div className="col-md-2">
                      <label className="form-label">
                        <span className="form-label">Rate (%)</span>{" "}
                      </label>
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="number"
                        name="rate"
                        value={taxData.rate}
                        style={{ width: "190px" }}
                        onChange={handleUpdateInput}
                      />{" "}
                    </div>
                  </div>
                  <hr />
                  <button
                    className="button1 border border-1"
                    onClick={closeEditPopup}
                  >
                    Cancel
                  </button>
                  <button
                    className="button2 btn-primary ml-2"
                    onClick={handleEditSubmit}
                  >
                    Save
                  </button>
                </div>
              )}

              {Preference && (
                <div className="border border-0">
                  <div className="my-2">
                    <h4 className="" style={{ letterSpacing: "1px" }}>
                      Default Tax Preference
                    </h4>
                  </div>
                  <hr />
                  <Row>
                    <Col md="3" className="mt-2">
                      <label style={{ fontSize: "14px", color: "grey" }}>
                        <span style={{ color: "red" }}>
                          Intra State Tax Rate
                        </span><br />
                        <i className="text-muted">(Within your State)</i>
                      </label>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <Select
                          options={optionIntraStateTax}
                          value={optionIntraStateTax.find((option) => option.value === selectedIntraTax)}
                          onChange={handleIntraStateChange}
                          placeholder="Select..."
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                              borderRadius: "7px",
                            }),
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" className="mt-2">
                      <label style={{ fontSize: "14px" }}>
                        <span style={{ color: "red" }}>
                          Inter State Tax Rate
                        </span><br />
                        <i className="text-muted">(Outside your State)</i>
                      </label>

                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <Select
                          options={optionInterStateTax}
                          value={optionInterStateTax.find((option) => option.value === selectedInterState)}
                          onChange={handleInterStateChange}
                          placeholder="Select..."
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "#F6F6F6",
                              padding: "5px",
                              borderRadius: "7px",
                            }),
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" className="mt-2">
                      <label style={{ fontSize: "14px" }}>
                        <span>
                          Sales GST Price
                        </span><br />
                      </label>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <div className="d-flex my-2">
                          <label className="rdiobox">
                            <input
                              className="mx-1 text"
                              type="radio"
                              name="cus_type"
                              id="cus_bus"
                              value="Including"
                              checked={selectedGst === "Including"}
                              onChange={handleGSTChange}
                            />
                            <span>Including</span>
                          </label>
                          <label className="rdiobox ml-3">
                            <input
                              className="mx-1 text"
                              type="radio"
                              name="cus_type"
                              id="cus_indivi"
                              value="Excluding"
                              checked={selectedGst === "Excluding"}
                              onChange={handleGSTChange}
                            />
                            <span>Excluding</span>
                          </label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="3" className="mt-1">
                      <button
                        type="button mt-1"
                        className="btn-3 btn btn-primary"
                        onClick={UpdateTaxPreference}
                      >
                        {isLoading ? "Updating..." : "Update"}
                      </button>

                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Taxes;
