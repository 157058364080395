import React, { useState } from "react";
import { XCircle } from "phosphor-react";
import { AES, enc } from "crypto-js";

function ManagePortfolioNew(props) {
  const handleCancleClick = () => {
    props.handleCancle();
  };

  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [serverData, setServerData] = useState({
    title: "",
    description: "",
  });
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImageUrl(imageUrl);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImageUrl(imageUrl);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServerData({
      ...serverData,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    if (!serverData.title || !serverData.description) {
      alert("Title and description are required.");
      return;
    }
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const titleAndDescriptionData = {
      title: serverData.title,
      description: serverData.description,
      uniqueId: decryptedUniqueId,
    };

    fetch("http://localhost:8096/api/insertPortfolio", {
      method: "POST",
      body: JSON.stringify(titleAndDescriptionData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const id = data.responseMessage;
        if (data.status === "success") {
          console.log("Title and description added successfully");
          if (selectedFile) {
            const formData = new FormData();
            formData.append("files", selectedFile);

            fetch(`http://localhost:8096/api/files/${id}`, {
              method: "POST",
              body: formData,
            })
              .then((imageResponse) => imageResponse.json())
              .then((imageData) => {
                if (imageData.status === "success") {
                  console.log("Image added successfully");
                } else {
                  console.error("Error adding image");
                }
              })
              .catch((imageError) => {
                console.error("Error adding image", imageError);
              });
          }
        } else {
          console.error("Error adding title and description");
        }
      })
      .catch((error) => {
        console.error("Error adding title and description", error);
      });
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setSelectedImageUrl(null);
  };

  return (
    <div className="container my-3">
      <form method="POST" action="/api/files" encType="multipart/form-data">
        <div className="border border-2 p-2">
          <div className="px-2 d-flex justify-content-between">
            <h4 className="mt-3 mx-2" style={{ letterSpacing: "1px" }}>
              Add New Portfolio
            </h4>
            <button className="btn" onClick={handleCancleClick}>
              <XCircle size={32} weight="fill" />
            </button>
          </div>
          <hr />

          <div className="container">
            <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
              <div className="col-md-2">
                <label className="form-label required mg-b-0">
                  Title 
                </label>
              </div>
              <div className="col-md-3 pl-0">
                <input
                  className="form-control"
                  placeholder="Title"
                  value={serverData.title}
                  name="title"
                  type="text"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
              <div className="col-md-2">
                <label className="form-label font-weight-bold mg-b-0">
                  Portfolio Image
                </label>
              </div>
              <div
                className={`drag-drop-input ${isDragging ? "dragging" : ""}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="image-container">
                  {selectedFile ? (
                    <div className="position-relative">
                      <img
                        src={selectedImageUrl}
                        alt="Selected Image"
                        style={{ maxWidth: "100%", maxHeight: "80%" }}
                      />
                      <button
                        className="btn btn-danger position-absolute top-0 end-0"
                        onClick={handleRemoveImage}
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <div className="text-center">
                      <p className="instructions">Drag and drop a file here</p>
                      <p className="or my-3">-OR-</p>
                      <label className="file-input-label">
                        Browse
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileInputChange}
                          accept="image/*"
                        />
                      </label>
                    </div>
                  )}
                </div>
                {/* {selectedFile && (
                                            <button className="btn btn-danger" onClick={handleRemoveImage}>Remove</button>
                                        )} */}
              </div>
              <div className="col-md-4">
                <font-xs className="text-muted">
                  This Portfolio Image will appear on the documents (estimates,
                  invoices, etc.) that are created.
                </font-xs>
                <br />
                <small className="text-muted">
                  Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of
                  1MB.
                </small>
              </div>
            </div>

            <div className="row mg-t-15 pt-3 align-items-center mg-b-20">
              <div className="col-md-2">
                <label className="form-label required mg-b-0">
                  Description 
                </label>
              </div>
              <div className="col-md-3 pl-0">
                <textarea
                  className="form-control"
                  value={serverData.description}
                  name="description"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="my-2 text-right">
              <button
                className="btn btn-primary px-2 py-1"
                style={{ backgroundColor: "var(--primary-color)" }}
                onClick={handleSaveClick}
              >
                Save
                {"  "}
                <i className="fa fa-save"></i>
              </button>
              <button
                className="btn btn-secondary mx-2 px-2 py-1"
                onClick={handleCancleClick}              >
                Cancel <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Display the selected image */}
        {/* {selectedImageUrl && (
                    <div className="my-3">
                        <img src={selectedImageUrl} alt="Selected Image" style={{ maxWidth: "100%" }} />
                    </div>
                )} */}

       
      </form>
    </div>
  );
}

export default ManagePortfolioNew;
