import React from "react";
import Header from "../../../Components/Common/Header";
import StudentsCard from "../../../Components/Apps/StudentsCard";
import StudentNew from "./StudentNew";
import StudentImport from "./StudentImport";
class Students extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCompose: false,
      show: false,
      show1:false
    }
  }

  handleNewButtonClick = () => {
    this.setState({ show: true });
    console.log("handleNewButtonClick clicked...")
  }

  handleNewButtonClick1 = () => {
    this.setState({ show1: true });
    console.log("handleNewButtonClick clicked...")
  }

  handleCancelNew = () => {
    this.setState({ show: false });
  }

  handleCancelNew1 = () => {
    this.setState({ show1: false });
  }

  render() {
    return (
      <div className="container-fluid">
        {!(this.state.show || this.state.show1) && <Header headerText="Student" mainNavigate="Others" currentPage="Student" childNav={this.state.isCompose ? "Compose" : ""} />}
        <div className="row clearfix ">

          {!(this.state.show || this.state.show1) && <div className="container mx-2 mb-2">
            <div className="d-flex justify-content-between">

              {/* <div>
                <h3>Add Student</h3>
              </div> */}

              <select name="filter" id="filter" class="form-control1 border-0">
                <option value="All">All Students</option>
                <option value="Active">Active Students</option>
                <option value="InActive">Inactive Students</option>
              </select>

              <div className="d-flex">
                <form id="navbar-search" className="navbar-form search-form">
                  <input className="form-control" placeholder="Search here..." type="text" />
                  <button type="button" className="btn btn-default"><i className="icon-magnifier"></i></button>
                </form>
                <div className="">
                  {!this.state.show && <button className="btn btn-secondary" onClick={this.handleNewButtonClick}>+ NEW</button>}
                </div> 
                <div>
                  <div class="dropdown ml-2">
                    <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      More
                    </button>
                    <div class="dropdown-menu mt-2 mr-4" aria-labelledby="">
                      <h6 className="text-center"><b>Sort By</b></h6>
                      <button class="dropdown-item" type="button">Name</button>
                      <button class="dropdown-item" type="button">Company Name</button>
                      <button class="dropdown-item" type="button">Receivables</button>
                      <div class="dropdown-divider"></div>
                      <button class="dropdown-item" type="button" onClick={this.handleNewButtonClick1}>Import Students</button>
                      <button class="dropdown-item" type="button">Exprot Students</button>
                      <button class="dropdown-item" type="button">Refresh List</button>
                    </div>
                  </div>
                </div>             
              </div>
            </div>
          </div>}
          {this.state.show ? <StudentNew handleCancle={this.handleCancelNew} /> : ""}          
          {this.state.show1 ? <StudentImport handleCancle={this.handleCancelNew1}/> : ""}
          {!(this.state.show || this.state.show1) && <StudentsCard/>}
        </div>
      </div>
    );
  }
}
export default Students
