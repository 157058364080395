import React ,{useEffect,useState,useRef} from "react";
import "./BalanceSheetSchedule.css"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReactToPrint from "react-to-print";
import Select, { components } from "react-select"; // Import components from react-select
import DatePicker from "react-datepicker"; // Import DatePicker for manual date selection
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles

export default function BalanceSheetSchedule({ navigation }) {
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  function handleCancelClick() {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
    handleNavigation("reports")
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  }
  const [loading, setLoading] = useState(true);
  const componentRef = useRef();
  const options = [
    { value: "today", label: "Today" },
    { value: "this_week", label: "This week" },
    { value: "this_month", label: "This month" },
    { value: "this_year", label: "This year" },
    { value: "this_quarter", label: "This Quarter" },
    { value: "yesterday", label: "Yesterday" },
    { value: "previous_week", label: "Previous Week" },
    { value: "previous_month", label: "Previous Month" },
    { value: "previous_quarter", label: "Previous Quarter" },
    { value: "previous_year", label: "Previous Year" },
    { value: "custom", label: "Custom" },
  ];
 
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const formatDate1 = (date) => {
    return date.toLocaleDateString("en-GB").replace(/\//g, "-"); // DD-MM-YYYY format
  };
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
  };
 
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  useEffect(() => {
    if (selectedOption) {
      const today = new Date();
      switch (selectedOption.value) {
        case "today":
          setFromDate(today);
          setToDate(today);
          break;
        case "yesterday":
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          setFromDate(yesterday);
          setToDate(yesterday);
          break;
        case "this_week":
          const sunday = new Date(today);
          sunday.setDate(today.getDate() - today.getDay());
          const saturday = new Date(today);
          saturday.setDate(today.getDate() + (6 - today.getDay()));
          setFromDate(sunday);
          setToDate(saturday);
          break;
        case "previous_week":
          const prevSunday = new Date(today);
          prevSunday.setDate(today.getDate() - today.getDay() - 7);
          const prevSaturday = new Date(today);
          prevSaturday.setDate(today.getDate() - today.getDay() - 1);
          setFromDate(prevSunday);
          setToDate(prevSaturday);
          break;
        case "this_month":
          const firstDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          const lastDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );
          setFromDate(firstDayOfMonth);
          setToDate(lastDayOfMonth);
          break;
        case "previous_month":
          const prevMonthStart = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
          );
          const prevMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          );
          setFromDate(prevMonthStart);
          setToDate(prevMonthEnd);
          break;
        case "this_quarter":
          const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
          const quarterStartDate = new Date(
            today.getFullYear(),
            quarterStartMonth,
            1
          );
          const quarterEndDate = new Date(
            today.getFullYear(),
            quarterStartMonth + 3,
            0
          );
          setFromDate(quarterStartDate);
          setToDate(quarterEndDate);
          break;
        case "previous_quarter":
          const prevQuarterStartMonth =
            Math.floor((today.getMonth() - 3) / 3) * 3;
          const prevQuarterStartDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth,
            1
          );
          const prevQuarterEndDate = new Date(
            today.getFullYear(),
            prevQuarterStartMonth + 3,
            0
          );
          setFromDate(prevQuarterStartDate);
          setToDate(prevQuarterEndDate);
          break;
        case "this_year":
          const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
          const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
          setFromDate(firstDayOfYear);
          setToDate(lastDayOfYear);
          break;
        case "previous_year":
          const prevYearStart = new Date(today.getFullYear() - 1, 0, 1);
          const prevYearEnd = new Date(today.getFullYear() - 1, 11, 31);
          setFromDate(prevYearStart);
          setToDate(prevYearEnd);
          break;
          case "custom":
          setFromDate(today);
          setToDate(today);
          break;
        default:
          setFromDate(null);
          setToDate(null);
          break;
      }

      // Call fetchStatement1 with the appropriate arguments
      // fetchStatement1(custID, fromDate, toDate);
    }
  }, [selectedOption]); // This effect runs when selectedOption state changes
  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      border: "1px solid #ccc",
      borderRadius: "4px",
      paddingLeft: "6px",
      width: "100%",
      maxWidth: "400px", // Ensure responsiveness
      boxSizing: "border-box",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: "400px",
    }),
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <span className="custom-control-icon">📅</span>
      {children}
    </components.Control>
  )
  return (
    <>
     <style>
        {`
          .custom-datepicker-wrapper {
            position: relative;
            display: inline-block;
            width: 200px;
          }

          .custom-datepicker-calendar {
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          .react-datepicker__input-container {
            width: 100%;
          }

          .react-datepicker__input-container input {
            width: 100%;
            height: 40px;
            padding: 0 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }

          .custom-label {
            display: block;
            margin-bottom: 5px;
            font-size: 16px;
            color: #333;
          }

          .custom-date-picker {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        `}
      </style>

      <div className="d-flex align-items-center ml-1 mt-4">
        <div
          className="cursor-pointer d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "35px",
            width: "38px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon
            width={2}
            onClick={handleCancelClick}
            className="arrow-child"
          />
        </div>
        <div className="headitems ml-2">
          <h5 style={{ letterSpacing: "1px", color: "#086bd5de" }}>
            Balance Sheet (Schedule III)
          </h5>
        </div>
      </div>
      <div
        className="balance-sheet-section my-3 mx-0"
        style={{ padding: "30px", backgroundColor: "#fff" }}
      >
                        <div className="d-flex justify-content-between mt-1 mb-1 ml-3">
          <div className="d-flex align-items-center">
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={options}
            styles={customStyles}
            components={{ Control: CustomControl }}
          />
          </div>
          {selectedOption.value === "custom" && (
            <div className="d-flex align-items-center ml-auto">
             <div className="custom-date-picker">
                <label htmlFor="from-date" className="custom-label mr-2">
                  From Date:
                </label>{" "}
                <DatePicker
                  id="from-date"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                  style={{backgroundColor:"white"}}
                />
              </div>
              <div className="custom-date-picker">
                <label htmlFor="to-date" className="custom-label mr-2">
                  To Date:
                </label>
                <DatePicker
                  id="to-date"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName="custom-datepicker-wrapper"
                  calendarClassName="custom-datepicker-calendar"
                />
              </div>
            </div>
          )}
          <div className="d-flex align-items-center">
            <ReactToPrint
              trigger={() => (
                <span
                  className="input-group-text"
                  id="pdf-icon"
                  style={{
                    backgroundColor: "#F6F6F6",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  📑
                </span>
              )}
              content={() => componentRef.current}
            />{" "}
          </div>
        </div>
        <div ref={componentRef} style={{backgroundColor:"white"}}>
        <div  className="head-container mb-5">
          <h5 style={{ fontWeight: "normal" }}>Code World</h5>
          <h4 style={{ fontWeight: "normal" }}>Balance Sheet (Schedule III)</h4>
          <p className="mb-1">Basis: Accrual</p>
          <p style={{ fontWeight: "normal" }}>
            FromTo {formatDate(fromDate)} - ToDate {formatDate(toDate)}
          </p>        </div>

        <table className="balance-sheet-table mx-auto" style={{width: "80%", borderSpacing: "10px", borderBottom: "1px solid #ddd"}}>
          <colgroup>
            <col span="1" style={{ width: "40%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
          </colgroup>
          <tbody>
            <tr style={{borderTop: "1px solid #ddd",borderBottom: "1px solid #ddd"}}>
              <th>Particulars</th>
              <th className="text-center">Note No.</th>
              <th className="text-right">30/04/2024</th>
              <th className="text-right">31/03/2024</th>
            </tr>

            <tr>
                <td className="text-semi-bold text-uppercase">Equity and Liabilities</td> 
                <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
            </tr>
            <tr>
                <td className="text-semi-bold">1.Shareholder's fund</td> 
                <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">a.Share Capital</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">b.Reserves and Surples</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">c.Money recieved against share warrants</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold">2.Share application money pending allotment</td> 
                <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
            </tr>
            <tr>
                <td className="text-semi-bold">3. Non-current liabilities</td> 
                <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">a.Long Term Borrowings</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">b.Deffered Tax Liabilities (Net)</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">c.Other long term liabilities</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">d.Long term Provisions</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold">4. Current Liabilities</td> 
                <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">a. Short-term borrowings</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">b. Trade Payables</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">c. Other Current Liabilities </td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">d. Short Term Provisions</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right text-semi-bold">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className=" pl-5 text-align-right text-uppercase" colSpan={"2"}>total equity and liabilities</td> 
                <td className="text-right text-semi-bold" style={{borderBottom: "3px double #ddd"}}>0.00</td> 
                <td className="text-right text-semi-bold" style={{borderBottom: "3px double #ddd"}}>0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold text-uppercase">assets</td> 
                {/* <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td> */}
            </tr>
            <tr>
                <td className="text-semi-bold">1. Non-current Assets</td> 
                {/* <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td> */}
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">a. Fixed assets</td> 
                <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-6">i. Tangible Assets</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-6">ii. InTangible Assets</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right">0.00</td> 
                <td className="text-right text-semi-bold">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-6">iii. Capital Work-in-progres</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right">0.00</td> 
                <td className="text-right">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-6">iv. InTangible assets under development</td> 
                <td className="text-semi-bold"></td> 
                <td className="text-right">0.00</td> 
                <td className="text-right">0.00</td> 
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">b. Non-current investments</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">c. Deffered Tax Assets (Net)</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">d. Long term loans and advances</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">e. Other non-current assets</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold">2. Current Assets</td> 
                {/* <td></td>
                <td className="text-right"></td>
                <td className="text-right"></td> */}
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">a. Current Investments</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">b. Inventories</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">c. Trade Receivables</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">d. Cash and cash equivalents</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">e. Short term loans and advances</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="text-semi-bold pl-5">f. Other current assets</td> 
                <td></td>
                <td className="text-right text-semi-bold">0.00</td>
                <td className="text-righ text-semi-bold">0.00</td>
            </tr>
            <tr>
                <td className="pl-5 text-align-right text-uppercase" colSpan={"2"}>total equity and liabilities</td> 
                <td className="text-right text-semi-bold" style={{borderBottom: "3px double #ddd"}}>0.00</td> 
                <td className="text-right text-semi-bold" style={{borderBottom: "3px double #ddd"}}>0.00</td> 
            </tr>
          </tbody>
        </table>
        </div>
      <div className="mx-auto" style={{width: "80%"}}>
        <p style={{
          marginTop: "20px",
          display: "inline-block",
          marginLeft: "8px",
        }}>**Amount is displayed in your base currency <span style={{backgroundColor: "rgb(56,138,16)",padding: "2px 4px",fontWeight: "600", fontSize: "12px"}}>INR</span></p>
      </div>
      </div>
      {/* </div> */}
    </>
  );
}
